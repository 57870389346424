const settlements = (state = {
  filters: {
    params: {},
    table: '',
    timeRange: 'thisAndLastMonth'
  }
}, action) => {
  switch (action.type) {
    case 'SETTLEMENTS_FILTERS':
      return Object.assign({}, state, {
        ...state,
        ...action.filters
      })
    case 'JOBS_MONTH':
      return Object.assign({}, state, {
        ...state,
        ...action.jobsMonth
      })
    default:
      return state
  }
}

export default settlements
