import React, { useEffect } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { Card } from '../../../../components/Card/Card'
import moment from 'moment'
import { toHours, toSeconds } from '../../../TimeReport/timeFunctions'
import { generateDays } from './upApi'
import { getData, sendData } from '../worktimeApi'
import { checkIsUPUser } from '../../authData'
import { ReasonInput } from '../ReasonInput'

const UPReport = ({
  loggedUser,
  setLoader,
  setInfo,
  date,
  setData,
  data,
  generateHandle,
  setGenerateHandle,
  setConfirmAllowed
}) => {
  /* generate report */
  const generateReport = async () => {
    setLoader(true)
    setGenerateHandle(false)

    const prevMonth = moment(date).subtract(1, 'm').format('YYYY-MM')
    const isUPPrevMonth = checkIsUPUser(loggedUser, prevMonth)
    const prevMonthData = await getData('up', loggedUser.ID, prevMonth) || {}

    if (prevMonth !== '2022-01' && isUPPrevMonth && !prevMonthData.confirmed) {
      setLoader(false)
      return setInfo('Zacznij od zaakceptowania raportu za poprzedni miesiąc.')
    }

    const timemanDays = await generateDays(loggedUser.ID, date)
    let sumWorkTime = 0
    let sumFreeHours = 0
    let sumCorrectedWorkTime = 0
    let sumTotalTime = 0
    const rows = []
    timemanDays.forEach((day) => {
      const { date, scStart = '', scFinish = '', workTime = '', freeHours = '', correctedWorkTime, totalTime = '' } = day
      sumWorkTime += toSeconds(workTime)
      sumFreeHours += toSeconds(freeHours)
      sumCorrectedWorkTime += toSeconds(correctedWorkTime || workTime)
      sumTotalTime += toSeconds(totalTime)
      rows.push(`${date}|${totalTime === '0:00' ? '' : scStart}|${totalTime === '0:00' ? '' : scFinish}|${workTime}|${freeHours}|${correctedWorkTime || workTime}|${totalTime}`)
    })
    const sumOverTime = toSeconds(prevMonthData.nextMonthOverTime) + sumTotalTime - (sumWorkTime - sumFreeHours)
    const newData = {
      ...data,
      type: 'up',
      userId: loggedUser.ID,
      userName: loggedUser.userName,
      month: date,
      lastGenerate: new Date(),
      lastUpdate: new Date(),
      rows,
      confirmed: false,
      sumWorkTime: toHours(sumWorkTime),
      sumFreeHours: toHours(sumFreeHours),
      sumCorrectedWorkTime: toHours(sumCorrectedWorkTime),
      sumTotalTime: toHours(sumTotalTime),
      prevMonthOverTime: prevMonthData.nextMonthOverTime || toHours(0),
      nextMonthOverTime: `${sumOverTime < 0 ? '-' : ''}${toHours(sumOverTime)}`,
      reason: ''
    }
    const responseData = await sendData(newData)
    setData(responseData)
    setConfirmAllowed(sumTotalTime > 0)
    setLoader(false)
  }

  useEffect(() => {
    if (generateHandle) {
      (async () => generateReport())()
    }
  }, [generateHandle])

  return (
    <Row>
      <Col md={12}>
        <Card
          content={
            <div>
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: '70%', borderColor: 'transparent' }} />
                    <th style={{ width: '20%' }}>bilans nadgodzin na kolejny miesiąc</th>
                    <th style={{ width: '10%', color: '#000', textAlign: 'right' }}>{data.nextMonthOverTime}</th>
                  </tr>
                  <tr>
                    <th style={{ borderColor: 'transparent' }} />
                    <th>bilans nadgodzin z poprzednich okresów</th>
                    <th style={{ color: '#000', textAlign: 'right' }}>{data.prevMonthOverTime}</th>
                  </tr>
                </thead>
              </Table>
              {
                data.lastGenerate && data.sumTotalTime === '0:00' &&
                <ReasonInput
                  type='up'
                  data={data}
                  setData={setData}
                  setConfirmAllowed={setConfirmAllowed}
                />
              }
              <br />
              <Table style={{ textAlign: 'right' }}>
                <thead>
                  <tr>
                    <th style={{ width: '3%' }}>Lp</th>
                    <th style={{ width: '7%' }}>data</th>
                    <th style={{ width: '15%', textAlign: 'right' }}>godzina startu</th>
                    <th style={{ width: '15%', textAlign: 'right' }}>godzina zakończenia</th>
                    <th style={{ width: '15%', textAlign: 'right' }}>wymiar czasu pracy</th>
                    <th style={{ width: '15%', textAlign: 'right' }}>wolne godziny</th>
                    <th style={{ width: '15%', textAlign: 'right' }}>wymagany czas pracy</th>
                    <th style={{ width: '15%', textAlign: 'right' }}>zrealizowany czas pracy</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Array.isArray(data.rows) && data.rows.map((day, key) => {
                      const [date, start, finish, workTime, freeHours, correctedWorkTime, totalTime] = day.split('|')
                      return (
                        <tr key={key}>
                          <td style={{ textAlign: 'left' }}>{key + 1}</td>
                          <td style={{ textAlign: 'left' }}>{date}</td>
                          <td>{start}</td>
                          <td>{finish}</td>
                          <td>{workTime}</td>
                          <td>{freeHours}</td>
                          <td>{correctedWorkTime}</td>
                          <td>{totalTime}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4} />
                    <td>{data.sumWorkTime}</td>
                    <td>{data.sumFreeHours}</td>
                    <td>{data.sumCorrectedWorkTime}</td>
                    <td>{data.sumTotalTime}</td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          }
        />
      </Col>
    </Row>
  )
}

export default UPReport
