import React, { Component } from 'react'
import * as XLSX from 'xlsx'
import {
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Radio,
  Row,
  Table
} from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'
import { style } from '../../variables/Variables'
import '../style.css'

const API_SERVER = process.env.REACT_APP_API_SERVER || '..'

class AutoEditorTool extends Component {
  constructor (props) {
    super(props)
    this.state = {
      zipFile: '',
      excelFile: '',
      searchTags: '',
      ignoredAssets: '',
      errors: {},
      loader: true,
      dbSearchTags: '',
      dbIgnoredAssets: '',
      foldersPath: [],
      creationType: 'html'
    }
    this.timeout1 = null
    this.timeout2 = null
    this.onFieldChange = this.onFieldChange.bind(this)
    this.unzipFile = this.unzipFile.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.readExcel = this.readExcel.bind(this)
  }

  sendNotification (icon, msg, lvl) {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify='icon' className={icon} />,
      message: <div>{msg}</div>,
      level: lvl,
      position: 'tr'
    })
  }

  loadSearchTags () {
    fetch(`${API_SERVER}/db/api/common?filter[where][_id]=creations-autoeditor`)
      .then((result) => result.json())
      .then(([result]) => {
        const { searchTags, ignoredAssets } = result
        this.setState({
          loader: false,
          searchTags,
          ignoredAssets,
          dbSearchTags: searchTags,
          dbIgnoredAssets: ignoredAssets
        })
      })
      .catch((err) => {
        this.setState({ loader: false }, () => {
          this.sendNotification('pe-7s-close-circle', `Wystąpił błąd. Spróbuj ponownie<br/>${err}`, 'error')
        })
      })
  }

  componentDidMount () {
    this.loadSearchTags()
  }

  saveCreationsOptions (e) {
    const model = e.target.name
    const value = e.target.value.trim()
    const valueTags = value.slice(-1) === ';' ? value.slice(0, -1) : value
    const comparedModel = model === 'searchTags' ? this.state.dbSearchTags : this.state.dbIgnoredAssets
    if (valueTags !== comparedModel) {
      this.setState({ loader: true }, () => {
        fetch(`${API_SERVER}/db/api/common/creations-autoeditor`, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ [model]: valueTags })
        })
          .then((res) => res.json())
          .then(() => {
            this.sendNotification('pe-7s-check', 'Zmiany zapisane', 'success')
            this.loadSearchTags()
          })
          .catch((err) => {
            console.error(err)
            this.loadSearchTags()
          })
      })
    }
  }

  onFieldChange (e) {
    const state = this.state
    state[e.target.id] = e.target.value
    this.setState({ state })
  }

  unzipFile (e) {
    e.persist()
    this.setState({ loader: true }, () => {
      const label = document.querySelector('.file-input-holder label[for="zipFile"]')
      const _this = e.target
      if (_this.files.length === 0) {
        label.textContent = 'Plik kreacji (zip)'
        this.setState({ loader: false, errors: { zipFile: 'Brak załadowanego pliku zip' } })
      } else if (!['application/x-zip-compressed', 'application/zip'].includes(_this.files[0].type)) {
        label.textContent = _this.files[0].name
        this.setState({ loader: false, errors: { zipFile: 'Nieprawidłowe rozszerzenie pliku' } })
      } else {
        label.textContent = _this.files[0].name
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        fetch(`${API_SERVER}/api/creatives/unzip`, {
          method: 'POST',
          body: formData
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.error) {
              this.setState({ loader: false, errors: { zipFile: response.error }, foldersPath: [] })
            } else {
              this.setState(
                {
                  zipFile: _this.files[0].name,
                  loader: false,
                  errors: { zipFile: null },
                  foldersPath: response.zipContent.filter((item) =>
                    this.state.creationType === 'html' ? item.indexOf('.html') > -1 : ['.jpg', '.jpeg', '.png', '.gif'].some((imgPrefix) => item.includes(imgPrefix))
                  )
                },
                () => this.sendNotification('pe-7s-check', 'Plik rozpakowany', 'success')
              )
            }
          })
          .catch((err) => {
            _this.value = null
            console.error(err)
            this.sendNotification('pe-7s-close-circle', `Wystąpił błąd. Spróbuj ponownie<br/>${err}`, 'error')
            this.setState({ loader: false })
          })
      }
    })
  }

  applyToSelected () {
    const applayValTo = (value, element) => {
      const checkboxes = document.querySelectorAll(`.creatives-table input[class*=${element}-checkbox]`)
      checkboxes.forEach((item) => {
        if (item.checked) {
          document.querySelector('.creatives-table input[name="' + element + '[' + item.classList[0].match(/\d+/)[0] + ']"]').value = value
        }
      })
    }
    const linkAllInput = document.getElementById('link-all')
    const pixelAllInput = document.getElementById('pixel-all')
    if (linkAllInput && linkAllInput.value !== '') {
      applayValTo(linkAllInput.value, 'link')
    }
    if (pixelAllInput && pixelAllInput.value !== '') {
      applayValTo(pixelAllInput.value, 'pixel')
    }
  }

  submitForm (e) {
    e.preventDefault()
    e.persist()
    const { foldersPath, 'prefix-all': prefix, creationType, idleTime, addScreenshots } = this.state
    this.setState({ loader: true }, () => {
      const form = new FormData(e.target)
      const formObj = {}
      formObj.creationType = creationType
      formObj.idleTime = idleTime
      formObj.addScreenshots = addScreenshots
      form.forEach((value, name) => {
        const numb = name.match(/\d+/g)
        if (!(foldersPath[numb] in formObj)) {
          formObj[foldersPath[numb]] = {}
        }
        if (creationType === 'img') {
          formObj[foldersPath[numb]] = { prefix }
        } else {
          const fieldName = name.replace(`[${numb}]`, '')
          formObj[foldersPath[numb]] = { ...formObj[foldersPath[numb]], ...{ [fieldName]: value } }
        }
      })
      fetch(`${API_SERVER}/api/creatives/download-zip`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formObj)
      })
        .then((res) => {
          const contentType = res.headers.get('content-type')
          if (contentType && contentType.indexOf('application/json') > -1) {
            return res.json()
          } else {
            return res.arrayBuffer()
          }
        })
        .then((res) => {
          if (res.error) {
            console.error(res.error)
            this.sendNotification('pe-7s-close-circle', `${res.error}`, 'error')
          } else {
            const url = window.URL.createObjectURL(new Blob([res], { type: 'application/zip' }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `kreacje_${Date.now()}.zip`)
            document.body.appendChild(link)
            link.click()
            link.remove()
          }
          if (creationType === 'html') {
            document.getElementById('link-all').value = ''
            document.getElementById('pixel-all').value = ''
            const idleTimeInput = document.getElementById('idleTime')
            const addScreenshotsCheckbox = document.getElementById('addScreenshots')
            if (idleTimeInput) { idleTimeInput.value = '' }
            if (addScreenshotsCheckbox) { addScreenshotsCheckbox.checked = false }
            document.querySelectorAll('.creatives-table thead td input[type=checkbox]').forEach((item) => (item.checked = false))
          } else if (creationType === 'img') {
            document.getElementById('prefix-all').value = ''
          }
          document.querySelector('.file-input-holder label[for="zipFile"]').textContent = 'Plik kreacji (zip)'
          this.setState({ loader: false, zipFile: '', errors: {}, foldersPath: [], excelFile: '', idleTime: '', addScreenshots: false })
        })
        .catch((err) => {
          console.error(err)
          this.sendNotification('pe-7s-close-circle', typeof err === 'object' ? JSON.stringify(err) : err, 'error')
        })
    })
  }

  switchInputValues (value, element, selector) {
    const input = element.closest('tr').querySelector(selector)
    if (value) {
      input.value = input.placeholder
      input.disabled = false
    } else {
      input.value = ''
      input.disabled = true
    }
  }

  switchCheckboxes (checked, selector) {
    const checkboxes = document.querySelectorAll(selector)
    checkboxes.forEach((item) => (item.checked = checked))
  }

  disableCheckboxes (checked, selector) {
    const checkboxesToDisable = document.querySelectorAll(selector)
    checkboxesToDisable.forEach((item) => (checked ? (item.checked = false) : null))
  }

  renderNoResults (colspanNo = 9) {
    return (
      <tr className='bg-warning'>
        <td colSpan={colspanNo} className='bg-warning'>
          <p style={{ margin: '8px 0' }}>Brak wyników...</p>
        </td>
      </tr>
    )
  }

  renderRadioButtons (name, values = {}) {
    return Object.keys(values).map((key) => {
      return (
        <Radio
          key={key}
          name={name}
          value={key}
          id={`radio-${key}`}
          className={`switch-radio ${this.state.creationType === key ? 'checked' : ''}`}
          onChange={(e) => {
            e.target.checked && this.setState({ creationType: e.target.value, foldersPath: [], zipFile: '', errors: {} })
          }}
          inline
        >
          {values[key]}
        </Radio>
      )
    })
  }

  readExcel (e) {
    const [file] = e.target.files
    const { foldersPath } = this.state
    const reader = new FileReader()

    this.setState({ loader: true }, () => {
      reader.onload = (evt) => {
        const bstr = evt.target.result
        const workbook = XLSX.read(bstr, { type: 'binary' })
        const workSheetName = workbook.SheetNames[0]
        const workSheet = workbook.Sheets[workSheetName]
        const creationsTags = {}
        for (const cell in workSheet) {
          if (cell.toString()[0] === 'O') {
            const row = cell.toString().slice(1)
            const impressionTag = workSheet[`R${row}`].v
            const clickTag = workSheet[`V${row}`].v
            creationsTags[workSheet[cell].v] = { impressionTag, clickTag }
          }
        }

        foldersPath.forEach((path, i) => {
          const creationName = path.split('/')[path.split('/').length - 2] || path.split('\\')[path.split('\\').length - 2]
          if (creationsTags[creationName]) {
            this.setInputValue(`input[name="link[${i}]"]`, creationsTags[creationName].clickTag)
            this.setInputValue(`input[name="pixel[${i}]"]`, creationsTags[creationName].impressionTag)
          }
        })
        const label = document.querySelector('.file-input-holder label[for="excelFile"]')
        label.textContent = file.name
        this.setState({ loader: false, excelFile: file.name })
      }
      reader.readAsBinaryString(file)
    })
  }

  setInputValue (selector, newValue) {
    const input = document.querySelector(`${selector}`)
    input.value = newValue
  }

  render () {
    const { errors, creationType, searchTags, ignoredAssets, foldersPath, zipFile } = this.state
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Form className='bg-white form-holder' encType='multipart/form-data'>
                <Row>
                  <Col md={12}>
                    <FormGroup controlId='creationType' validationState={errors.creationType ? 'error' : null}>
                      <ControlLabel style={{ fontSize: '1.9rem' }}>
                        Typ kreacji <span style={{ textTransform: 'none', color: '#9a9a9aa1' }}> (zmiana powoduje usunięcie wgranego pliku zip)</span>
                      </ControlLabel>
                      <div className='switch-radio-box'>
                        {this.renderRadioButtons('typKreacji', { html: 'HTML/JS', img: 'Images' })}
                      </div>
                    </FormGroup>
                    {
                      creationType === 'html' &&
                        <FormGroup>
                          <ControlLabel htmlFor='addScreenshots'>
                            Dodaj screenshoty
                          </ControlLabel>&nbsp;
                          <input
                            type='checkbox'
                            name='addScreenshots'
                            value={this.state.addScreenshots}
                            id='addScreenshots'
                            style={{ margin: 5 }}
                            onChange={(e) => {
                              this.setState({ addScreenshots: e.target.checked })
                            }}
                          />
                        </FormGroup>
                    }
                    {
                      this.state.addScreenshots &&
                        <input
                          type='text'
                          id='idleTime'
                          className='form-control'
                          onChange={this.onFieldChange}
                          style={{ marginBottom: 10 }}
                          placeholder='Kiedy generować screenshoty? Jeśli potrzebujesz, możesz podać czas opóźnienia (np. dla 2 sekund wpisz 2000) lub zostaw puste.'
                        />
                    }
                  </Col>
                  <Col md={12} className={creationType !== 'html' ? 'd-none' : ''}>
                    <FormGroup controlId='searchTags' validationState={errors.searchTags ? 'error' : null}>
                      <ControlLabel>
                        Szukane atrybuty <span style={{ textTransform: 'none', color: '#9a9a9aa1' }}> (każdy atrybut rozdzielony średnikiem)</span>
                      </ControlLabel>
                      <FormControl
                        componentClass='textarea'
                        name='searchTags'
                        rows='4'
                        value={searchTags}
                        onBlur={(e) => {
                          e.persist()
                          this.timeout1 = setTimeout(() => {
                            this.saveCreationsOptions(e)
                          }, 1000)
                        }}
                        onFocus={() => clearTimeout(this.timeout1)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            this.saveCreationsOptions(e)
                          }
                        }}
                        onChange={this.onFieldChange}
                      />
                      {errors.searchTags && <HelpBlock>{errors.searchTags}</HelpBlock>}
                    </FormGroup>
                  </Col>
                  <Col md={12} className={creationType !== 'html' ? 'd-none' : ''}>
                    <FormGroup controlId='ignoredAssets' validationState={errors.ignoredAssets ? 'error' : null}>
                      <ControlLabel>
                        Ignorowane nazwy assetów <span style={{ textTransform: 'none', color: '#9a9a9aa1' }}> (każdy atrybut rozdzielony średnikiem)</span>
                      </ControlLabel>
                      <FormControl
                        componentClass='textarea'
                        name='ignoredAssets'
                        rows='4'
                        value={ignoredAssets}
                        onBlur={(e) => {
                          e.persist()
                          this.timeout2 = setTimeout(() => {
                            this.saveCreationsOptions(e)
                          }, 1000)
                        }}
                        onFocus={() => clearTimeout(this.timeout2)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            this.saveCreationsOptions(e)
                          }
                        }}
                        onChange={this.onFieldChange}
                      />
                      {errors.ignoredAssets && <HelpBlock>{errors.ignoredAssets}</HelpBlock>}
                    </FormGroup>
                  </Col>
                  <Col md={12} className={!creationType ? 'd-none' : ''}>
                    <FormGroup style={{ marginBottom: '0px' }} controlId='zipFile' className='file-input-holder' validationState={this.state.errors.zipFile ? 'error' : null}>
                      <ControlLabel>Plik kreacji (zip)</ControlLabel>
                      <FormControl type='file' name='zipFile' onInput={this.unzipFile} onClick={(e) => (e.target.value = null)} />
                      {this.state.errors.zipFile && <HelpBlock>{this.state.errors.zipFile}</HelpBlock>}
                    </FormGroup>
                  </Col>
                  <Col md={12} className={!zipFile ? 'd-none' : ''}>
                    <FormGroup style={{ marginTop: '20px' }} controlId='excelFile' className='file-input-holder' validationState={this.state.errors.excelFile ? 'error' : null}>
                      <ControlLabel>Plik excel</ControlLabel>
                      <FormControl type='file' name='excelFile' onInput={this.readExcel} onClick={(e) => (e.target.value = null)} accept='.xlsx, .xls' />
                      {this.state.errors.excelFile && <HelpBlock>{this.state.errors.excelFile}</HelpBlock>}
                    </FormGroup>
                    <ControlLabel>
                      <span style={{ textTransform: 'none', color: '#9a9a9aa1' }}> (W kolumnie 'O' musi znajdować się nazwa kreacji, w kolumnie 'R' kod pixela a w kolumnie 'V' link)</span>
                    </ControlLabel>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className='form-holder creatives-table'>
                <Form className='table-responsive' onSubmit={this.submitForm}>
                  {(() => {
                    if (creationType === 'html') {
                      return (
                        <Table hover className='mb-0 table-striped table-bordered table-condensed'>
                          <thead>
                            <tr>
                              <th className='text-center'>Lista kreacji</th>
                              <th className='text-center'>Link <i title='Podmienia linki w atrybutach elementów wskazanych w polu "Szukane atrybuty".' className='pe-7s-info' /></th>
                              <th className='text-center'>Piksel <i title='Dodaje kod <IMG> pixela na przed tagiem </body> kreacji.' className='pe-7s-info' /></th>
                              <th className='text-center'>Podlinkuj <i title='Linkuje całą kreację pod link wskazany w kolumnie LINK.' className='pe-7s-info' /></th>
                              <th className='text-center'>Hostuj assety <i title='Uploaduje assety na serwer i podlinkowuje je w kreacji.' className='pe-7s-info' /></th>
                              <th className='text-center'>Usuń zewnętrzne źródła <i title='Pobiera zewnętrzne skrypty do których linkuje kreacja, zapisuje w folderze i linkuje do nich.' className='pe-7s-info' /></th>
                              <th className='text-center'>Size tag <i title='Dodaje meta tag "ad.size" - pola należy uzupełnić w formacie wysokośćxszerokość np. 200x160.' className='pe-7s-info' /></th>
                              <th />
                            </tr>
                            <tr>
                              <td />
                              <td>
                                <table className='width-100'>
                                  <tbody>
                                    <tr>
                                      <td className='text-center'>
                                        <input
                                          id='link-checkbox'
                                          type='checkbox'
                                          onChange={(e) => {
                                            this.switchCheckboxes(e.target.checked, '.creatives-table input[class*=link]')
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input type='text' id='link-all' className='form-control' />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <table className='width-100'>
                                  <tbody>
                                    <tr>
                                      <td className='text-center'>
                                        <input
                                          id='pixel-checkbox'
                                          type='checkbox'
                                          onChange={(e) => {
                                            this.switchCheckboxes(e.target.checked, '.creatives-table input[class*=pixel]')
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input type='text' id='pixel-all' className='form-control' />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className='text-center'>
                                <input
                                  id='creation-link'
                                  type='checkbox'
                                  onChange={(e) => {
                                    this.switchCheckboxes(e.target.checked, '.creatives-table input[name*=creation-link]')
                                  }}
                                />
                              </td>
                              <td className='text-center'>
                                <input
                                  id='assets'
                                  type='checkbox'
                                  onChange={(e) => {
                                    this.switchCheckboxes(e.target.checked, '.creatives-table input[name*=assets]')
                                    this.disableCheckboxes(e.target.checked, '.creatives-table input[name*=removeExternalSources], .creatives-table #removeExternalSources')
                                  }}
                                />
                              </td>
                              <td className='text-center'>
                                <input
                                  id='removeExternalSources'
                                  type='checkbox'
                                  onChange={(e) => {
                                    this.switchCheckboxes(e.target.checked, '.creatives-table input[name*=removeExternalSources]')
                                    const selector = '.creatives-table input[name*=assets], .creatives-table #assets, .creatives-table input[name*=removeAssets], .creatives-table #removeAssets'
                                    this.disableCheckboxes(e.target.checked, selector)
                                  }}
                                />
                              </td>
                              <td className='text-center'>
                                <input
                                  id='sizeTag'
                                  type='checkbox'
                                  onChange={(e) => {
                                    const checkboxes = document.querySelectorAll('.creatives-table input[class*=sizeTag-checkbox]')
                                    checkboxes.forEach((item) => {
                                      item.checked = e.target.checked
                                      this.switchInputValues(e.target.checked, item, 'input[name*=sizeTag]')
                                    })
                                  }}
                                />
                              </td>
                              <td className='text-center'>
                                <Button type='button' className='btn btn-info btn-xs' onClick={this.applyToSelected}>
                                  zastosuj do zaznaczonych
                                </Button>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {foldersPath.length > 0
                              ? foldersPath.map((filePath, key) => {
                                return (
                                  <tr key={key} className='default'>
                                    <td>{filePath}</td>
                                    <td>
                                      <table className='width-100'>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <input
                                                type='checkbox'
                                                name={`checkedLinkInput[${key}]`}
                                                className={`link-checkbox-${key}`}
                                                onChange={(e) => {
                                                  this.disableCheckboxes(!e.target.checked, '.creatives-table #link-checkbox')
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <input type='text' name={`link[${key}]`} className='form-control input-sm' />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td>
                                      <table className='width-100'>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <input
                                                type='checkbox'
                                                name={`checkedPixelInput[${key}]`}
                                                className={`pixel-checkbox-${key}`}
                                                onChange={(e) => {
                                                  this.disableCheckboxes(!e.target.checked, '.creatives-table #pixel-checkbox')
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <input type='text' name={`pixel[${key}]`} className='form-control input-sm' />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td className='text-center'>
                                      <input
                                        type='checkbox'
                                        name={`creation-link[${key}]`}
                                        onChange={(e) => {
                                          this.disableCheckboxes(!e.target.checked, '.creatives-table #creation-link')
                                        }}
                                      />
                                    </td>
                                    <td className='text-center'>
                                      <input
                                        type='checkbox'
                                        name={`assets[${key}]`}
                                        onChange={(e) => {
                                          this.disableCheckboxes(e.target.checked, `.creatives-table input[name='removeExternalSources[${key}]'], .creatives-table #removeExternalSources`)
                                          this.disableCheckboxes(!e.target.checked, '.creatives-table #assets')
                                        }}
                                      />
                                    </td>
                                    <td className='text-center'>
                                      <input
                                        type='checkbox'
                                        name={`removeExternalSources[${key}]`}
                                        onChange={(e) => {
                                          const selector = `.creatives-table input[name='assets[${key}]'], .creatives-table #assets, .creatives-table input[name='removeAssets[${key}]'], .creatives-table #removeAssets`
                                          this.disableCheckboxes(e.target.checked, selector)
                                          this.disableCheckboxes(!e.target.checked, '.creatives-table #removeExternalSources')
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <table className='width-50'>
                                        <tbody>
                                          <tr>
                                            <td className='text-center'>
                                              <input
                                                type='checkbox'
                                                className={`sizeTag-checkbox-${key} `}
                                                onChange={(e) => {
                                                  this.switchInputValues(e.target.checked, e.target, 'input[name*=sizeTag]')
                                                  this.disableCheckboxes(!e.target.checked, '.creatives-table #sizeTag')
                                                }}
                                              />
                                            </td>
                                            <td className='text-center'>
                                              <input
                                                type='text'
                                                name={`sizeTag[${key}]`}
                                                className='form-control input-sm'
                                                placeholder={filePath.split(/(\\|\/)/)[filePath.split(/(\\|\/)/).length - 1].split('.')[0]}
                                                disabled
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td />
                                  </tr>
                                )
                              })
                              : this.renderNoResults()}
                          </tbody>
                        </Table>
                      )
                    } else {
                      return (
                        <Table hover className='mb-0 table-striped table-bordered table-condensed'>
                          <thead>
                            <tr>
                              <th className='text-center'>Lista kreacji</th>
                              <th className={`${!creationType ? 'd-none ' : ''}text-center`}>Przedrostek</th>
                            </tr>
                            <tr className={!creationType ? 'd-none' : ''}>
                              <td />
                              <td>
                                <table className='w-100'>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <input type='text' id='prefix-all' className='form-control' onChange={this.onFieldChange} />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {foldersPath.length > 0
                              ? foldersPath.map((filePath, key) => {
                                return (
                                  <tr key={key} className='default'>
                                    <td colSpan='2'>
                                      <input type='hidden' value={key} name={`prefix[${key}]`} />
                                      {filePath}
                                    </td>
                                  </tr>
                                )
                              })
                              : this.renderNoResults(creationType.length ? 2 : 1)}
                          </tbody>
                        </Table>
                      )
                    }
                  })()}
                  {foldersPath.length > 0
                    ? (
                      <div className='text-center'>
                        <hr />
                        <Button type='submit' className='btn btn-warning btn-lg'>
                          Pobierz zmodyfikowany ZIP
                        </Button>
                      </div>
                      )
                    : null}
                </Form>
              </div>
            </Col>
          </Row>
        </Grid>
        <div id='own-preloader' className={`${this.state.loader === true ? 'show' : ''}`}>
          <div id='own-loader' />
        </div>
      </div>
    )
  }
}

export default AutoEditorTool
