const formEstimation = {
  _id: { pl: 'ID' },
  accepted: { pl: 'Akceptacja' },
  company: { pl: 'Zleceniodawca', card: 1, col: 1, stickyCol: true, type: 'select', source: 'companies', frozen: true },
  main_depart: { pl: 'Dział główny', card: 1, col: 2, type: 'select', source: 'departments' },
  main_person: { pl: 'Opiekun Klienta', card: 1, col: 2, type: 'select', source: 'users' },
  from_depart: { pl: 'Dział zlecający', card: 1, col: 2, type: 'select', source: 'departments' },
  from_person: { pl: 'Osoba zlecająca', card: 1, col: 2, type: 'select', source: 'users' },
  resp_depart: { pl: 'Dział realizujący', card: 1, col: 2, stickyCol: true, type: 'select', source: 'departments' },
  resp_person: { pl: 'Osoba realizująca', card: 1, col: 2, type: 'select', source: 'users' },
  month: { pl: 'Miesiąc', card: 2, col: 2, stickyCol: true, type: 'select', width: 100 },
  profit2: { pl: 'Zysk', card: 3, col: 3, stickyCol: true, type: 'amount', width: 110 },
  profit1: { pl: 'Zysk', card: 3, col: 3, stickyCol: true, type: 'amount', width: 110 },
  profit0: { pl: 'Zysk', card: 3, col: 3, stickyCol: true, type: 'amount', width: 110 },
  turnover2: { pl: 'Realny obrót', card: 3, col: 3, type: 'amount', width: 150 },
  turnover1: { pl: 'Realny obrót', card: 3, col: 3, type: 'amount', width: 150 },
  turnover0: { pl: 'Realny obrót', card: 3, col: 3, type: 'amount', width: 150 },
  budget: { pl: 'Budżet', card: 4, col: 4, stickyCol: true, type: 'amount', width: 100, editable: true, permission: 'isFinance' },
  'week-4': { pl: '-4 tyg', card: 4, col: 4, type: 'amount', width: 100 },
  'week-3': { pl: '-3 tyg', card: 4, col: 4, type: 'amount', width: 100 },
  'week-2': { pl: '-2 tyg', card: 4, col: 4, type: 'amount', width: 100 },
  'week-1': { pl: '-1 tyg', card: 4, col: 4, type: 'amount', width: 100 },
  estimation: { pl: 'Estymacja', card: 4, col: 4, stickyCol: true, type: 'amount', width: 100, editable: true, permission: 'all' },
  change: { pl: 'Zmiana', card: 4, col: 4, stickyCol: true, type: 'amount', width: 140, permission: 'all' },
  comment: { pl: 'Komentarz', card: 5, col: 5, stickyCol: true, width: 200, editable: true, permission: 'all' },
  lastComment: { pl: 'Poprzedni komentarz', card: 5, col: 5, width: 200 },
  updated: { pl: 'Ostatnia aktualizacja', card: 5, col: 5, stickyCol: true, width: 300, permission: 'all' }
}

module.exports = {
  formEstimation
}
