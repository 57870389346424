import React, { useState, useEffect } from 'react'
import { Card } from '../../../components/Card/Card'
import { Col, FormControl, Grid, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import fetchApi, { API_SERVER } from '../../../fetchApi'
import { Notification, notificationSystemRef, useNotification as notification } from '../../../useNotification'
import example from './plik-wsadowy.xlsx'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'

const generateYearsArray = (start, end) => {
  const startYear = moment(start).format('YYYY')
  const endYear = moment(end).format('YYYY')
  const arrYears = []
  for (let year = startYear; year <= endYear; year = moment(year).add(1, 'year').format('YYYY')) {
    arrYears.push(year)
  }
  return arrYears
}
const COO = () => {
  const props = useSelector((state) => state)
  const [data, setData] = useState({})
  const [specCompanies, setSpecCompanies] = useState('')
  const [noLimitOvertime, setNoLimitOvertime] = useState('')
  const [canMissingTimereport, setCanMissingTimereport] = useState('')
  const [daysOff, setDaysOff] = useState({ cyclic: [], per_year: {} })
  const [selectedDaysOffType, setSelectedDaysOffType] = useState(moment().format('YYYY'))

  useEffect(() => {
    (async () => {
      const response = await fetchApi(`${API_SERVER}/api/timeman/spec-companies`)
      const converted = Object.entries(response || {}).map(([el1, el2]) => {
        return `${el1} -> ${el2}\n`
      }).join('')
      setSpecCompanies(converted)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const noLimitOvertimeRes = await fetchApi(`${API_SERVER}/api/timeman/no-limit-overtime`)
      const canMissingTimereportRes = await fetchApi(`${API_SERVER}/api/timeman/can-missing-timereport`)
      setNoLimitOvertime(noLimitOvertimeRes)
      setCanMissingTimereport(canMissingTimereportRes)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const response = await fetchApi(`${API_SERVER}/api/timeman/days-off`)
      setDaysOff(response)
    })()
  }, [])

  const onChange = e => {
    const { name, value } = e.target
    setData(old => {
      old[name] = value
      return old
    })
  }
  const onSubmit = async (e, type) => {
    e.preventDefault()
    let payload
    switch (type) {
      case 'noLimitOvertime':
        payload = { noLimitOvertime }
        break
      case 'canMissingTimereport':
        payload = { canMissingTimereport }
        break
      case 'updateSpecCompanies':
        payload = { specCompanies }
        break
      case 'updateDaysOff':
        payload = { daysOff }
        break
      default:
        payload = data
    }
    notification('Wysyłam...', 'info', 2)
    let init = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...payload, type
      })
    }
    if (type === 'createTimemans') {
      const formData = new FormData(e.target)
      formData.append('type', type)
      init = {
        method: 'POST',
        body: formData
      }
    }
    const response = await fetchApi(`${API_SERVER}/api/user/coo-updates`, init)
    if (response) {
      notification('Dane zostały zapisane', 'success', 4)
      setData({})
      document.getElementById('form1').reset()
      document.getElementById('form2').reset()
      document.getElementById('form3').reset()
      document.getElementById('form4').reset()
      document.getElementById('form9').reset()
      document.getElementById('form10').reset()
    }
  }

  if (['200', '360', '158', '536', '168', '46'].includes(props.user.ID)) {
    return (
      <div className='content'>
        <Notification ref={notificationSystemRef} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className='breadcrumb'>
                    <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                      to='/settlements/coo'
                                                                     >COO
                                                                     </Link>
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Uzupełnij brakującą firmę w zadaniu/wydarzeniu w SC'
                content={
                  <form id='form1' onSubmit={(e) => onSubmit(e, 'updateCrmId')}>
                    <br />
                    <FormControl
                      type='text'
                      name='taskIdToUpdate'
                      placeholder='Task ID'
                      style={{ width: 200 }}
                      onChange={onChange}
                    />
                    <br />
                    <FormControl
                      type='text'
                      name='clientId'
                      placeholder='Client ID'
                      style={{ width: 200 }}
                      onChange={onChange}
                    />
                    <br />
                    <button className='btn btn-sm'>Zapisz</button>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Usuń skasowane zadanie/wydarzenie z bazy SC'
                content={
                  <form id='form2' onSubmit={(e) => onSubmit(e, 'deleteTaskId')}>
                    <br />
                    <FormControl
                      type='text'
                      name='taskIdToDelete'
                      placeholder='Task ID'
                      style={{ width: 200 }}
                      onChange={onChange}
                    />
                    <br />
                    <button className='btn btn-sm'>Usuń</button>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Usuń dzień i wyzeruj taski, otwórz kolejne dni'
                content={
                  <form id='form3' onSubmit={(e) => onSubmit(e, 'deleteTimemans')}>
                    <br />
                    <FormControl
                      type='text'
                      name='userId'
                      placeholder='userId'
                      style={{ width: 200 }}
                      onChange={onChange}
                    />
                    <br />
                    <DayPickerInput
                      formatDate={date => moment(date).format('YYYY-MM-DD')}
                      placeholder='date'
                      name='date'
                      onDayChange={(value) => {
                        const date = value ? moment(value).format('YYYY-MM-DD') : ''
                        setData(old => {
                          old.date = date
                          return old
                        })
                      }}
                      dayPickerProps={{ firstDayOfWeek: 1 }}
                    />
                    <br />
                    <br />
                    <button className='btn btn-sm'>Usuń</button>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Dodaj zaakceptowane dni z powodu wydarzenia'
                content={
                  <form id='form4' onSubmit={(e) => onSubmit(e, 'createTimemans')}>
                    <a href={example}>Przykład xlsx</a>
                    <br />
                    <br />
                    <FormControl
                      type='file'
                      name='file'
                      id='file'
                      style={{ width: 400 }}
                      onChange={onChange}
                    />
                    <br />
                    <FormControl
                      type='text'
                      name='manualName'
                      id='manualName'
                      placeholder='Podaj nazwę wydarzenia, pojawi się w tasku manualnym Inne'
                      style={{ width: 400 }}
                      onChange={onChange}
                    />
                    <br />
                    <FormControl
                      type='text'
                      name='clientId'
                      id='clientId'
                      placeholder='Client ID'
                      style={{ width: 200 }}
                      onChange={onChange}
                    />
                    <br />
                    <button className='btn btn-sm'>Wyślij</button>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Zmień adres email w taskach'
                content={
                  <form id='form5' onSubmit={(e) => onSubmit(e, 'updateEmail')}>
                    <br />
                    <FormControl
                      type='text'
                      name='oldEmail'
                      id='oldEmail'
                      placeholder='stary email'
                      style={{ width: 200 }}
                      onChange={onChange}
                    />
                    <br />
                    <FormControl
                      type='text'
                      name='newEmail'
                      id='newEmail'
                      placeholder='nowy email'
                      style={{ width: 200 }}
                      onChange={onChange}
                    />
                    <br />
                    <button className='btn btn-sm'>Wyślij</button>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Nadgodziny bez limitu'
                content={
                  <form id='form6' onSubmit={(e) => onSubmit(e, 'noLimitOvertime')}>
                    <br />
                    <FormControl
                      componentClass='textarea'
                      name='noLimitOvertime'
                      id='noLimitOvertime'
                      value={noLimitOvertime}
                      placeholder=''
                      style={{ width: 400, height: 'auto', fontSize: '2rem' }}
                      rows={2}
                      onChange={(e) => {
                        e.persist()
                        setNoLimitOvertime(() => e.target.value)
                      }}
                    />
                    <br />
                    <button className='btn btn-sm'>Zapisz</button>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Zdjęty wymóg raportowania czasu między 90 a 105%'
                content={
                  <form id='form66' onSubmit={(e) => onSubmit(e, 'canMissingTimereport')}>
                    <br />
                    <FormControl
                      componentClass='textarea'
                      name='canMissingTimereport'
                      id='canMissingTimereport'
                      value={canMissingTimereport}
                      placeholder=''
                      style={{ width: 400, height: 'auto', fontSize: '2rem' }}
                      rows={2}
                      onChange={(e) => {
                        e.persist()
                        setCanMissingTimereport(() => e.target.value)
                      }}
                    />
                    <br />
                    <button className='btn btn-sm'>Zapisz</button>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Mapowanie firm'
                content={
                  <form id='form7' onSubmit={(e) => onSubmit(e, 'updateSpecCompanies')}>
                    <br />
                    <FormControl
                      componentClass='textarea'
                      name='specCompanies'
                      id='specCompanies'
                      value={specCompanies}
                      placeholder=''
                      style={{ width: 400, height: 'auto', fontSize: '2rem' }}
                      rows={10}
                      onChange={(e) => {
                        e.persist()
                        setSpecCompanies(() => e.target.value)
                      }}
                    />
                    <br />
                    <button className='btn btn-sm'>Zapisz</button>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Dni wolne (format dni: MM-DD)'
                content={
                  <form id='form8' onSubmit={(e) => onSubmit(e, 'updateDaysOff')}>
                    <br />
                    <FormControl
                      componentClass='select'
                      name='selectedDaysOffType'
                      id='selectedDaysOffType'
                      value={selectedDaysOffType}
                      placeholder=''
                      style={{ width: 400, height: 'auto', fontSize: '2rem' }}
                      rows={4}
                      onChange={(e) => {
                        e.persist()
                        setSelectedDaysOffType((o) => e.target.value)
                      }}
                    >
                      <option value='cyclic'>Cykliczne</option>
                      <optgroup label='Na rok'>
                        {
                          generateYearsArray('2020', moment().add(1, 'years').format('YYYY'))
                            .map((year) => <option key={year} value={year}>{year}</option>)
                        }
                      </optgroup>
                    </FormControl>
                    <br />
                    <FormControl
                      componentClass='textarea'
                      name='daysOff'
                      id='daysOff'
                      value={selectedDaysOffType === 'cyclic' ? daysOff.cyclic : daysOff.per_year[selectedDaysOffType] || ''}
                      placeholder=''
                      style={{ width: 400, height: 'auto', fontSize: '2rem' }}
                      rows={2}
                      onChange={(e) => {
                        e.persist()
                        setDaysOff((state) => {
                          const newState = { ...state }
                          selectedDaysOffType === 'cyclic'
                            ? newState.cyclic = e.target.value
                            : newState.per_year[selectedDaysOffType] = e.target.value
                          return newState
                        })
                      }}
                    />
                    <br />
                    <button className='btn btn-sm'>Zapisz</button>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Odśwież nazwę i firmę w taskach'
                content={
                  <form id='form9' onSubmit={(e) => onSubmit(e, 'updateTasksNamesAndCompanies')}>
                    <br />
                    <FormControl
                      type='text'
                      name='idsToUpdate'
                      placeholder='Rozdzielone przecinkami ID tasków'
                      style={{ width: 400 }}
                      onChange={onChange}
                    />
                    <br />
                    <button className='btn btn-sm'>Odśwież</button>
                  </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Odśwież nazwę i firmę w wydarzeniach w kalendarzu'
                content={
                  <form id='form10' onSubmit={(e) => onSubmit(e, 'updateCalendarEventsNamesAndCompanies')}>
                    <br />
                    <FormControl
                      type='text'
                      name='idsToUpdate'
                      placeholder='Rozdzielone przecinkami ID wydarzeń'
                      style={{ width: 400 }}
                      onChange={onChange}
                    />
                    <br />
                    <button className='btn btn-sm'>Odśwież</button>
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  } else {
    return (
      <div className='content SalaryGrid'>
        <div>Brak dostępu</div>
      </div>
    )
  }
}

export default COO
