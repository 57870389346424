import { FormControl } from 'react-bootstrap'
import React from 'react'
import Select from 'react-select'
const md5 = require('md5')

const DefaultSelect = ({
  row, value, data, basicValues, setState, options, onChange = (e) => {
    basicValues[row] = e.target.value
    setState({ basicValues })
  }, label
}) => <>
  {label && <label>{label}</label>}
  <FormControl
    componentClass='select'
    name={row}
    type='select'
    value={value || data[row]}
    onChange={onChange}
  >
    {options}
  </FormControl>
</>

const TextInput = (row, data, basicValues, setState) => <input
  type='text'
  name={row}
  className='form-control'
  value={data[row]}
  onChange={(e) => {
    basicValues[row] = e.target.value
    const state = { basicValues }
    if (e.target.name === 'external_id') {
      Object.assign(state, { deeplinkHtmlEnabled: e.target.value.length > 2 })
    }
    setState(state)
  }}
                                                        />

const Textarea = (row, data, basicValues, setState) => <textarea
  name={row}
  className='form-control'
  rows={7}
  style={{ width: '50%', display: 'inline-block' }}
  value={data[row]}
  onChange={(e) => {
    data[row] = e.target.value
    setState({ data })
  }}
                                                       />

const BooleanFieldSelect = (row, data, basicValues, setState) => {
  const options = (
    <>
      <option value=''>wybierz z listy</option>
      <option value={0}>nie</option>
      <option value={1}>tak</option>
    </>
  )
  return <DefaultSelect {...{ row, data, basicValues, setState, options }} />
}

// const AdformclientSelect = (row, data, basicValues, setState, adformClients) => {
//   const options = (
//     <><option value=''>wybierz z listy</option>
//       {adformClients.map((adformclient, key) => (
//         <option key={`clientadform${key}`}>{adformclient}</option>
//       ))}
//     </>
//   )
//   return <DefaultSelect {...{ row, data, basicValues, setState, options }} />
// }

// const GaviewSelect = (row, data, basicValues, setState, gaViews) => {
//   const options = (
//     <><option value=''>wybierz z listy</option>
//       {gaViews.map((gaView, key) => (
//         <option key={`gaView${key}`} value={gaView.id}>
//           {gaView.accountName} \ {gaView.propertyName} \ {gaView.name} ({gaView.websiteUrl} : {gaView.id})
//         </option>
//       ))}
//     </>
//   )
//   return <DefaultSelect {...{ row, data, basicValues, setState, options }} />
// }

// const GaUserIdSelect = (row, data, basicValues, setState) => {
//   const options = (
//     <><option value=''>wybierz z listy</option>
//       {[0, 1, 2, 3, 4, 5].map((gaView, key) => (
//         <option key={`gaView${key}`} value={gaView}>
//           {gaView}
//         </option>
//       ))}
//     </>
//   )
//   return <DefaultSelect {...{ row, data, basicValues, setState, options }} />
// }

const ReportTranslationSelect = (row, data, basicValues, setState) => {
  const options = (
    <><option value=''>wybierz z listy</option>
      {[
        ['angielski', 'en'],
        ['polski', 'pl']
      ].map((type, key) => (
        <option key={`reportTranslation${key}`} value={type[1]}>
          {type[0]}
        </option>
      ))}
    </>
  )
  return <DefaultSelect {...{ row, data, basicValues, setState, options }} />
}

const DiffRatesPerCategorySelect = (row, data, basicValues, setState) => {
  const label = 'Walidacja uwzględniająca różne stawki za kategorie produktowe'
  const value = data ? (data[row] ? data[row] : 'false') : 'false'
  const onChange = (e) => {
    data[row] = e.target.value
    setState({ data })
  }
  const options = (
    <>
      <option value='false'>nie</option>
      <option value='true'>tak</option>
    </>
  )
  return <DefaultSelect {...{ row, value, label, data, basicValues, setState, options, onChange }} />
}

const NsmVisibilitySelect = (row, data, basicValues, setState) => {
  const options = (
    <>
      <option value='ukryj'>Ukryj</option>
      <option value='pokaz'>Pokaż</option>
    </>
  )
  const onChange = (e) => {
    data[row] = e.target.value
    setState({ data })
  }
  const value = data[row] ? data[row] : 'ukryj'
  return (
    <div style={{ display: 'inline-block', margin: '10px' }}>
      <DefaultSelect {...{ row, value, data, basicValues, setState, options, onChange }} />
    </div>
  )
}

const ReportDayOfValidationInput = (row, data, basicValues, setState) => <input
  type='string'
  name={row}
  className='form-control'
  value={data[row]}
  onChange={(e) => {
    const { target: { value } } = e
    basicValues[row] = (value > 0 && value < 32) ? value : ''
    setState({ basicValues })
  }}
                                                                         />

const ShouldReportsBeSentSelect = (row, data, basicValues, setState) => {
  const label = 'Czy raport powinien być wysyłany?'
  const value = data[row] === true || data[row] === '1' ? '1' : '0'
  const onChange = (e) => {
    data[row] = e.target.value
    setState({ data })
  }
  const options = (
    <>
      <option value='0'>nie</option>
      <option value='1'>tak</option>
    </>
  )
  return <DefaultSelect {...{ row, value, label, data, basicValues, setState, options, onChange }} />
}

const ReportUserValue1Select = (row, data, basicValues, setState) => {
  const label = 'Wartość użytkownika 1 (userValue1)'
  const value = data[row] === true || data[row] === '1' ? '1' : '0'
  const onChange = (e) => {
    data[row] = e.target.value
    setState({ data })
  }
  const options = (
    <>
      <option value='0'>nie</option>
      <option value='1'>tak</option>
    </>
  )
  return <DefaultSelect {...{ row, value, label, data, basicValues, setState, options, onChange }} />
}

const ReportTypeSelect = (row, data, basicValues, setState, validationStartingValues, validationFields) => <FormControl
  componentClass='select'
  name={row}
  type='select'
  value={data[row]}
  onChange={(e) => {
    const insert = basicValues
    insert[row] = e.target.value
    if (e.target.value === '') {
      insert.reportDayOfValidation = ''
      insert.reportTranslation = ''
      insert.shouldReportsBeSent = '0'
      insert.reportUserValue1 = '0'
    } else if (!validationStartingValues.reportType) {
      insert.reportDayOfValidation = 10
      insert.shouldReportsBeSent = '1'
      insert.reportTranslation = 'pl'
    } else {
      validationFields.forEach((field) => {
        if (field !== 'Walidacja' && field !== 'reportType') {
          insert[field] = validationStartingValues[field]
        }
      })
    }
    setState({ basicValues: insert })
  }}
                                                                                                           >
  <option value=''>wybierz z listy</option>
  {[
    ['automatyczny', 'auto'],
    ['ręczny', 'manual'],
    ['reklamacyjny', 'complaint']
  ].map((type, key) => (
    <option key={`reportType${key}`} value={type[1]}>
      {type[0]}
    </option>
  ))}
</FormControl>

const nipLabel = (item = {}) => item.UF_CRM_1530178687 ? `, NIP ${item.UF_CRM_1530178687}` : ''

const getLabel = (arr, bitrix) => {
  const obj = arr.find(o => o.ID === bitrix)
  return obj ? `${obj.TITLE || ''} ${nipLabel(obj)}` : ''
}

const BitrixSelect = (row, data, basicValues, setState, companies = []) => {
  const { bitrix } = basicValues
  const options = companies.map((item) => ({
    value: item.ID,
    label: `${item.TITLE} ${nipLabel(item)}`
  }))
  const initValue = { value: bitrix, label: bitrix }
  initValue.label = getLabel(companies, bitrix)

  return (
    <Select
      options={options}
      name={row}
      value={initValue}
      onChange={(e) => {
        basicValues[row] = e.value
        setState({ basicValues })
      }}
    />
  )
}

const ReportSection = (row, data, basicValues, setState, field) => {
  const reportCheckboxFields = { seo: 0, sem: 0, adform: 0, afiliacja: 0, mailing: 0 }
  const fieldsKeys = Object.keys(reportCheckboxFields)
  let reportDomainInputFields = Array.from({ length: 8 }).map(() => '')
  if (!data.raporty) {
    data.raporty = { ...reportCheckboxFields, ...{ domains: reportDomainInputFields } }
  } else {
    Object.keys(data.raporty).forEach((key) => {
      if (fieldsKeys.includes(key)) {
        reportCheckboxFields[key] = data.raporty[key]
      } else if (key === 'domains') {
        reportDomainInputFields = reportDomainInputFields.slice(data.raporty.domains.length)
        reportDomainInputFields = [...data.raporty.domains, ...reportDomainInputFields]
      }
    })
  }
  return (
    <div>
      <div>
        {Object.entries(reportCheckboxFields).map((ele, eleKey) => (
          <div key={eleKey} style={{ display: 'inline-block', margin: '10px' }}>
            <label htmlFor={ele[0]}>{ele[0]}</label>
            <input
              style={{ marginLeft: '3px' }}
              type='checkbox'
              id={ele[0]}
              checked={Number(ele[1]) === 1 ? 'checked' : ''}
              onChange={(e) => {
                const newState = data
                newState.raporty[e.target.id] = +e.target.checked
                setState(newState)
              }}
            />
            <input type='hidden' name={`raporty[${ele[0]}]`} value={ele[1]} />
          </div>
        ))}
      </div>
      <div>
        {reportDomainInputFields.map((ele, index) => (
          <div key={`domain_${index}`} style={{ display: 'inline-block', margin: '10px' }}>
            <label htmlFor={`domain_${index}`}>{`Domena ${index + 1}`}</label>
            <input
              type='text'
              id={`domain_${index}`}
              name={`raporty[domains][${index}]`}
              className='form-control'
              value={ele}
              onChange={(e) => {
                const newState = data
                const { raporty: { domains } } = newState
                domains[index] = e.target.value
                setState(newState)
              }}
            />
          </div>
        ))}
      </div>
      {data._id
        ? (
          <p>
            <a
              href={`https://raporty.cubegroup.pl/raport/${md5(data.ITID || data._id)}`} target='_blank'
              rel='noopener noreferrer'
            >
              https://raporty.cubegroup.pl/raport/{md5(data.ITID || data._id)}
            </a>
          </p>
          )
        : null}
    </div>
  )
}

export default {
  BooleanFieldSelect,
  reportType: ReportTypeSelect,
  keywords: Textarea,
  widocznosc_NSM: NsmVisibilitySelect,
  reportDayOfValidation: ReportDayOfValidationInput,
  shouldReportsBeSent: ShouldReportsBeSentSelect,
  reportUserValue1: ReportUserValue1Select,
  bitrix: BitrixSelect,
  // adformclient: AdformclientSelect,
  // gaView: GaviewSelect,
  // gaUserId: GaUserIdSelect,
  reportTranslation: ReportTranslationSelect,
  TextInput,
  diffRatesPerCategory: DiffRatesPerCategorySelect,
  raporty: ReportSection
}
