import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from '../../../components/Card/Card'
import NotificationSystem from 'react-notification-system'
import { style } from '../../../variables/Variables'
import {
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Modal,
  Radio,
  Row
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../Components/Loader'
import AgGrid from '../Components/AgGrid'
import { formEstimation } from './estimations'
import { currencyFormatter } from '../Components/currencyFormatter'
import { getMainMap, getMapLabel, onlyActiveUsers } from '../common'
import { allowedFromDepartments, API_SERVER, checkUserType } from '../authData'
import Select from 'react-select'
import * as moment from 'moment'
import { SingleDatePicker } from 'react-dates'
import ReactMonthPicker from '../Components/ReactMonthPicker'
import ToggleSwitch from '../Components/ToggleSwitch'

const FORM_MODEL = formEstimation

class EstimationList extends Component {
  constructor (props) {
    super(props)
    this.setCheckboxesList = this.setCheckboxesList.bind(this)
    this.editEstimationValueCell = this.editEstimationValueCell.bind(this)
    this.addNewEstimation = this.addNewEstimation.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.setFilters = props.setFilters
    this.state = {
      modal: false,
      loader: true,
      colParams: FORM_MODEL,
      filtersData: {},
      date: props.filters.estimationsMonth || moment().format('YYYY/MM'),
      checkboxes: {
        list: [],
        month: ''
      },
      searchInput: '',
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      mainMap: getMainMap(props.main),
      formBody: {},
      errors: {},
      datePickerFocused: false
    }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    const isManager = (loggedUser.scHead || []).length > 0
    this.setState({
      isFinance,
      isManager
    }, () => { this.loadNext() })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.main) !== JSON.stringify(this.props.main)) {
      const mainMap = getMainMap(this.props.main)
      this.setState({ mainMap })
    }
  }

  refreshData () {
    const { date } = this.state
    this.setState({ loader: true }, () => {
      fetch(`${API_SERVER}/api/refresh-estimations`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          date
        })
      })
        .then(res => res.json())
        .then((response) => {
          if (!response || response.errors) {
            this.showNotification('error', `Wystąpił błąd: ${(response && response.errors) || ''}`)
            console.error('Error:', response.errors)
            this.setState({ loader: false })
          } else {
            this.loadNext()
          }
        })
        .catch(error => {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', error)
        })
    })
  }

  loadNext () {
    const { loggedUser, date, showAll, hideEmpty } = this.state
    fetch(`${API_SERVER}/api/get-estimations`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        person: loggedUser,
        date,
        showAll,
        hideEmpty
      })
    })
      .then(res => res.json())
      .then((response) => {
        if (!response || response.errors) {
          this.showNotification('error', `Wystąpił błąd: ${(response && response.errors) || ''}`)
          console.error('Error:', response.errors)
          this.setState({ loader: false, formBody: {}, errors: {} })
        } else
        if (response) {
          this.setState({ data: response, loader: false, formBody: {}, errors: {} }, () => {
            this.prepareCols()
            this.prepareRows()
          })
        }
      })
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }
    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  isNumber (n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  getLabel (arr = [], row) {
    const obj = arr.length ? arr.find(o => o.ID === row) : ''
    return obj ? obj.NAME || obj.TITLE : '---'
  }

  addNewEstimation () {
    this.setState({ loader: true }, () => {
      const { formBody, date } = this.state
      formBody.month = formBody.month || moment(date || []).format('YYYY-MM')
      formBody.budget = formBody.budget || 0
      formBody.source = 'add'
      formBody.updatedAt = new Date()
      formBody.updatedBy = this.state.loggedUser.ID
      fetch(`${API_SERVER}/db/api/estimations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: Object.keys(formBody).map(k => (!/^\s*$/.test(formBody[k])) ? encodeURIComponent(k) + '=' + encodeURIComponent(formBody[k]) : undefined).filter(k => k !== undefined).join('&')
      })
        .then(response => response.json())
        .then((res) => {
          if (res.error) {
            this.setState({
              errors: (res.error.details && res.error.details.messages) ? res.error.details.messages : res.error,
              loader: false
            }, () => this.showNotification('error', 'W formularzu wystąpiły błędy'))
          } else {
            this.setState({ loader: false, modal: false }, () => this.loadNext())
          }
        })
        .catch((error) => {
          console.error(error)
          window.alert('Wystąpił błąd')
          this.setState({ loader: false })
        })
    })
  }

  editEstimations () {
    this.setState({ loader: true, modal: false, toEdit: null })
    const formBody = {
      ...this.state.formBody,
      updatedAt: new Date(),
      updatedBy: this.state.loggedUser.ID,
      accepted: false
    }
    fetch(`${API_SERVER}/api/edit-estimations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: Object.keys(formBody).map(k => (!/^\s*$/.test(formBody[k])) ? encodeURIComponent(k) + '=' + encodeURIComponent(formBody[k]) : undefined).filter(k => k !== undefined).join('&')
    })
      .then(res => res.json())
      .then((response) => {
        if (!response || response.errors) {
          this.showNotification('error', `Wystąpił błąd: ${(response && response.errors) || ''}`)
          console.error('Error:', response.errors)
          this.setState({ loader: false })
        } else {
          this.showNotification('success', `Sukces: ${(response && response.success) || ''}`)
          this.loadNext()
        }
      })
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
  }

  duplicateEstimations () {
    this.setState({ loader: true })
    const { loggedUser, checkboxes, mainMap: { companies }, date } = this.state
    const { list } = checkboxes
    const estimationsIds = list.map(el => el._id)

    fetch(`${API_SERVER}/api/duplicate-estimations`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        estimationsIds,
        user: loggedUser,
        companies,
        date
      })
    })
      .then(res => res.json())
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
      .then(response => {
        if (!response || response.error) {
          console.error('Error:', (response || {}).error)
          this.showNotification('error', 'Wystąpił błąd')
        } else {
          const { message: { success = [], error = [] } = {} } = response
          if (success.length) {
            this.showNotification('success', `Poprawnie zduplikowano:  ${success.join(', ')}`, 6)
          }
          if (error.length) {
            this.showNotification('error', `Wystąpił błąd z duplikacją: ${error.join(', ')}`, 0)
          }
        }
        const _checkboxes = { list: [], month: '' }
        this.setState({ checkboxes: _checkboxes, loader: false })
      })
  }

  confirmEstimations () {
    this.setState({ loader: true })
    const { loggedUser, checkboxes: { list } } = this.state
    const estimationsIds = list.map(el => el._id)

    fetch(`${API_SERVER}/api/confirm-estimations`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        estimationsIds,
        user: loggedUser
      })
    })
      .then(res => res.json())
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
      .then(response => {
        if (!response || response.error) {
          console.error('Error:', (response || {}).error)
          this.showNotification('error', 'Wystąpił błąd')
        } else {
          this.showNotification('success', response.success)
          this.loadNext()
        }
        const _checkboxes = { list: [], month: '' }
        this.setState({ checkboxes: _checkboxes, loader: false })
      })
  }

  editEstimationValueCell (e) {
    if (e.oldValue + '' !== e.newValue) {
      this.setState({ valueChanged: true })
      const _newValue = (['budget', 'estimation'].includes(e.column.colId))
        ? e.newValue.toString().replace(/\s/g, '').replace(',', '.')
        : e.newValue
      const data = {
        [e.column.colId]: _newValue,
        updatedAt: new Date(),
        updatedBy: this.state.loggedUser.ID,
        accepted: false
      }
      if (e.column.colId === 'accepted') {
        data.accepted = true
      }
      if (e.column.colId === 'comment') {
        delete data.accepted
        data.lastComment = e.oldValue
      }
      this.showNotification('info', 'Zapisuję...')
      fetch(`${API_SERVER}/db/api/estimations/${e.data._id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(response => {
          if (!response || response.error) {
            console.error(response.error)
            this.showNotification('error', 'Wystąpił błąd')
          } else {
            this.setState((old) => ({ data: old.data.map(obj => response._id === obj._id ? response : obj) }), () => {
              this.prepareCols()
              this.prepareRows()
            })
            this.showNotification('success', 'Zmiany zapisane')
          }
        })
        .catch(err => {
          console.error(err)
          this.showNotification('error', 'Wystąpił błąd krytyczny')
        })
    } else {
      this.setState({ valueChanged: false })
    }
  }

  onFieldChange (name, value, fieldToClear = null) {
    const formBody = this.state.formBody
    formBody[name] = value
    if (fieldToClear) {
      formBody[fieldToClear] = ''
    }
    this.setState({ formBody })
  }

  allowedUsers (allUsers = [], allowed) {
    const { isFinance } = this.state
    const filteredUsers = allUsers.filter(el => (el.scDepartment || []).includes(allowed))
    return !isFinance ? onlyActiveUsers(filteredUsers) : filteredUsers
  }

  renderEstimationForm () {
    const { errors, formBody, isFinance, isManager, loggedUser, date } = this.state
    const { departments, users, companies } = this.props.main
    const btxCompanies = (companies || []).filter(item => Number(item.ID) < 90000) // bez klientow office
    const departmentOptions = ((departments || []).filter(el => allowedFromDepartments.includes(el.ID)) || []).map(item => ({ label: item.NAME, value: item.ID }))
    const mainPersonOptions = formBody.main_depart ? this.allowedUsers(users || [], formBody.main_depart + '') : (users || [])
    const fromPersonOptions = formBody.from_depart ? this.allowedUsers(users || [], formBody.from_depart + '') : (users || [])
    const respPersonOptions = formBody.resp_depart ? this.allowedUsers(users || [], formBody.resp_depart + '') : (users || [])
    return (
      <Form>
        <Row>
          <Col md={12}>
            <FormGroup controlId='month' validationState={errors.month ? 'error' : null}>
              <ControlLabel>Miesiąc</ControlLabel>
              <SingleDatePicker
                date={moment(formBody.month) || moment(date, 'YYYY/MM')}
                onDateChange={date => this.onFieldChange('month', (date) ? moment(date).format('YYYY-MM') : '')}
                displayFormat='YYYY-MM'
                focused={this.state.datePickerFocused}
                onFocusChange={({ focused }) => this.setState(() => ({ datePickerFocused: focused }))}
                id='month'
                isOutsideRange={(date) => moment(date).format('DD') !== '01'}
                isDayHighlighted={() => true}
                numberOfMonths={1}
                small
                block
                disabled={!!formBody._id}
                readOnly
              />
              {errors.month &&
                <HelpBlock>{errors.month[0]}</HelpBlock>}
            </FormGroup>
            <FormGroup controlId='company' validationState={errors.company ? 'error' : null}>
              <ControlLabel>Zleceniodawca</ControlLabel>
              <Select
                name='company'
                options={(btxCompanies || []).map(item => ({ label: item.TITLE, value: item.ID, assignedId: item.ASSIGNED_BY_ID }))}
                onChange={(e) => {
                  const formBody = this.state.formBody
                  const user = (users || []).filter(item => item.ID === e.assignedId)[0]
                  formBody.company = e.value
                  if (user &&
                    departmentOptions.filter(item => item.value.toString() === user.DEPARTMENT[0].toString()).length > 0 &&
                    mainPersonOptions.filter(item => item.ID.toString() === user.ID.toString()).length > 0) {
                    formBody.main_depart = user.DEPARTMENT[0].toString()
                    formBody.main_person = user.ID.toString()
                    formBody.from_depart = user.DEPARTMENT[0].toString()
                    formBody.from_person = user.ID.toString()
                  } else {
                    formBody.main_depart = ''
                    formBody.main_person = ''
                    formBody.from_depart = ''
                    formBody.from_person = ''
                  }
                  this.setState({ formBody }, () => console.log(this.state.formBody))
                }}
                value={(btxCompanies || []).filter(item => item.ID === formBody.company + '').map(item => ({ label: item.TITLE, value: item.ID, assignedId: item.ASSIGNED_BY_ID }))}
                isDisabled={!!formBody._id}
              />
              {errors.company &&
                <HelpBlock>{errors.company[0]}</HelpBlock>}
            </FormGroup>
            <FormGroup controlId='main_depart' validationState={errors.main_depart ? 'error' : null}>
              <ControlLabel>Dział główny</ControlLabel>
              <Select
                name='main_depart'
                options={departmentOptions}
                onChange={e => this.onFieldChange('main_depart', e ? e.value : '', 'main_person')}
                value={(departmentOptions || []).filter(item => item.value === formBody.main_depart + '')}
                isDisabled={!!formBody._id && !isFinance}
              />
              {errors.main_depart &&
                <HelpBlock>{errors.main_depart[0]}</HelpBlock>}
            </FormGroup>
            <FormGroup controlId='main_person' validationState={errors.main_person ? 'error' : null}>
              <ControlLabel>Opiekun klienta</ControlLabel>
              <Select
                name='main_person'
                options={(formBody.main_depart && mainPersonOptions.map(item => ({ label: item.NAME, value: item.ID }))) || []}
                onChange={e => this.onFieldChange('main_person', e ? e.value : '')}
                value={(mainPersonOptions || []).filter(item => item.ID === formBody.main_person + '').map(item => ({ label: item.NAME, value: item.ID }))}
                isDisabled={!!formBody._id && !isFinance && !isManager}
              />
              {errors.main_person &&
                <HelpBlock>{errors.main_person[0]}</HelpBlock>}
            </FormGroup>
            <FormGroup controlId='from_depart' validationState={errors.from_depart ? 'error' : null}>
              <ControlLabel>Dział zlecający</ControlLabel>
              <Select
                name='from_depart'
                options={departmentOptions}
                onChange={e => this.onFieldChange('from_depart', e ? e.value : '', 'from_person')}
                value={(departmentOptions || []).filter(item => item.value === formBody.from_depart + '')}
                isDisabled={!!formBody._id && !isFinance}
              />
              {errors.from_depart &&
                <HelpBlock>{errors.from_depart[0]}</HelpBlock>}
            </FormGroup>
            <FormGroup controlId='from_person' validationState={errors.from_person ? 'error' : null}>
              <ControlLabel>Osoba zlecająca</ControlLabel>
              <Select
                name='from_person'
                options={(formBody.from_depart && fromPersonOptions.map(item => ({ label: item.NAME, value: item.ID }))) || []}
                onChange={e => this.onFieldChange('from_person', e ? e.value : '')}
                value={(fromPersonOptions || []).filter(item => item.ID === formBody.from_person + '').map(item => ({ label: item.NAME, value: item.ID }))}
                isDisabled={!!formBody._id && !isFinance && !isManager}
              />
              {errors.from_person &&
                <HelpBlock>{errors.from_person[0]}</HelpBlock>}
            </FormGroup>
            <FormGroup controlId='resp_depart' validationState={errors.resp_depart ? 'error' : null}>
              <ControlLabel>Dział realizujący</ControlLabel>
              <Select
                name='resp_depart'
                options={departmentOptions}
                onChange={e => this.onFieldChange('resp_depart', e ? e.value : '', 'resp_person')}
                value={(departmentOptions || []).filter(item => item.value === formBody.resp_depart + '')}
                isDisabled={!!formBody._id && !isFinance}
              />
              {errors.resp_depart &&
                <HelpBlock>{errors.resp_depart[0]}</HelpBlock>}
            </FormGroup>
            <FormGroup controlId='resp_person' validationState={errors.resp_person ? 'error' : null}>
              <ControlLabel>Osoba realizująca</ControlLabel>
              <Select
                name='resp_person'
                options={(formBody.resp_depart && respPersonOptions.map(item => ({ label: item.NAME, value: item.ID }))) || []}
                onChange={e => this.onFieldChange('resp_person', e ? e.value : '')}
                value={(respPersonOptions || []).filter(item => item.ID === formBody.resp_person + '').map(item => ({ label: item.NAME, value: item.ID }))}
                isDisabled={!!formBody._id && !isFinance && !isManager}
              />
              {errors.resp_person &&
                <HelpBlock>{errors.resp_person[0]}</HelpBlock>}
            </FormGroup>
            {
              !formBody._id
                ? <div>
                  {
                  isFinance || loggedUser.scEstimactionNull
                    ? <FormGroup controlId='budget' validationState={errors.budget ? 'error' : null}>
                      <ControlLabel>Budżet</ControlLabel>
                      <FormControl
                        type='number'
                        name='budget'
                        onChange={(e) => this.onFieldChange('budget', e.target.value)}
                        value={formBody.budget || ''}
                      />
                      {errors.budget &&
                        <HelpBlock>{errors.budget[0]}</HelpBlock>}
                    </FormGroup>
                    : null
                }
                  <FormGroup controlId='estimation' validationState={errors.estimation ? 'error' : null}>
                    <ControlLabel>Estymacja</ControlLabel>
                    <FormControl
                      type='number'
                      name='estimation'
                      onChange={(e) => this.onFieldChange('estimation', e.target.value)}
                      value={formBody.estimation || ''}
                    />
                    {errors.estimation &&
                      <HelpBlock>{errors.estimation[0]}</HelpBlock>}
                  </FormGroup>
                  <FormGroup controlId='comment' validationState={errors.comment ? 'error' : null}>
                    <ControlLabel>Komentarz</ControlLabel>
                    <FormControl
                      type='text'
                      name='comment'
                      onChange={(e) => this.onFieldChange('comment', e.target.value)}
                      value={formBody.comment || ''}
                    />
                    {errors.comment &&
                      <HelpBlock>{errors.comment[0]}</HelpBlock>}
                  </FormGroup>
                  </div>
                : <FormGroup controlId='saveOption'>
                  <ControlLabel>Sposób zastosowania zmian:</ControlLabel>
                  <br />
                  <Radio
                    name='saveOption'
                    value='all'
                    inline
                    onChange={(e) => this.onFieldChange('saveOption', e.target.value)}
                    defaultChecked
                  >edytuj ten wpis i wszystkie późniejsze
                  </Radio>
                  <br />
                  <Radio
                    name='saveOption'
                    value='single'
                    inline
                    onChange={(e) => this.onFieldChange('saveOption', e.target.value)}
                  >edytuj tylko ten wpis
                  </Radio>
                  </FormGroup>
            }
          </Col>
        </Row>
      </Form>
    )
  }

  renderTopButtons () {
    const { loader, clearAllFilters } = this.state
    const { filters } = this.props
    const active = Object.keys(filters.params).length
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button className='btn btn-success btn-sm' disabled={this.state.date < '2021/01'} onClick={() => this.setState({ modal: true })}>Dodaj estymację</Button>
        <Button className='btn btn-sm' disabled={this.state.date < '2021/01'} onClick={() => this.refreshData()}>Odśwież dane</Button>
        <Button
          className='btn btn-primary btn-sm'
          disabled={this.state.checkboxes.list.length < 1 || this.state.date < '2021/01'}
          onClick={() => this.duplicateEstimations()}
        >
          Duplikuj na kolejny miesiąc
        </Button>
        <Button
          className='btn btn-primary btn-sm'
          disabled={this.state.checkboxes.list.length < 1 || this.state.date < '2021/01'}
          onClick={() => this.confirmEstimations()}
        >
          Potwierdź estymacje
        </Button>
        <Button className='btn btn-sm' disabled={!active || loader} onClick={() => this.setState({ clearAllFilters: !clearAllFilters })}>Usuń wszystkie filtry</Button>
      </div>
    )
  }

  onChangeMonth (months) {
    const [year, month] = this.state.date.split('/').map(el => Number(el))
    if (JSON.stringify({ year, month }) !== JSON.stringify(months.to)) {
      const newDate = `${months.to.year}/${months.to.month}`
      const { filters } = this.props
      this.setState({
        date: newDate,
        loader: true
      }, () => {
        filters.estimationsMonth = newDate
        this.setFilters({ filters })
        this.loadNext()
      })
    }
  }

  renderMonthPicker () {
    const [year, month] = this.state.date.split('/').map(el => Number(el))
    return (
      <ReactMonthPicker
        onChangeMonth={this.onChangeMonth.bind(this)}
        date={{ to: { year, month } }}
        showDel={false}
      />
    )
  }

  renderSearchInput () {
    const { searchInput = '' } = this.state
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
        <FormControl
          type='input'
          value={searchInput}
          className={`btn btn-default ${searchInput !== '' ? ' not-empty' : ''}`}
          placeholder='Szukaj...'
          style={{ textAlign: 'left' }}
          onChange={(e) => {
            this.setState({ searchInput: e.target.value })
          }}
        />
      </div>
    )
  }

  renderSavedFilters () {
    const { filters } = this.props
    const { colParams = {} } = this.state
    const savedFilters = Object.keys(filters.params || {})
    const filterNames = savedFilters.length && filters.table === 'estimations'
      ? savedFilters.map(el => colParams[el] ? colParams[el].pl : '')
      : []
    return (
      filterNames.length
        ? <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
          <small style={{ color: '#777', fontSize: 12 }}>Aktywne Filtry: {filterNames.join(', ')}</small>
          </div>
        : null
    )
  }

  renderShowAllSwitch () {
    const { showAll = false } = this.state
    return (
      <ToggleSwitch
        checked={showAll}
        style={{ marginTop: '0.75rem', marginLeft: '0.5rem' }}
        name='showAAll'
        label='wszystkie'
        toggleSwitch={() => this.setState({ showAll: !showAll, loader: true }, () => this.loadNext())}
      />
    )
  }

  renderShowEmptySwitch () {
    const { hideEmpty = false } = this.state
    return (
      <ToggleSwitch
        checked={hideEmpty}
        style={{ marginTop: '0.75rem', marginLeft: '0.5rem' }}
        name='hideEmpty'
        label='ukryj puste'
        toggleSwitch={() => this.setState({ hideEmpty: !hideEmpty, loader: true }, () => this.loadNext())}
      />
    )
  }

  renderClearAllFiltersButton () {
    const { loader, clearAllFilters } = this.state
    const { filters } = this.props
    const active = Object.keys(filters.params).length
    return (
      <Button className='btn btn-sm' disabled={!active || loader} onClick={() => this.setState({ clearAllFilters: !clearAllFilters })}>Usuń wszystkie filtry</Button>
    )
  }

  setCheckboxesList (_checkboxes) {
    const { checkboxes } = this.state
    checkboxes.list = _checkboxes
    this.setState({ ...checkboxes })
  }

  checkPermission (record) {
    if (record.editable) {
      if (record.permission === 'all') {
        return true
      } else return !!this.state[record.permission]
    }
    return false
  }

  getLastUpd (userId, time, sec) {
    return `${getMapLabel(this.state.mainMap.users, userId)} (${moment(time).local().format(`DD.MM.YYYY HH:mm${sec === 0 ? '' : ':ss'}`)})`
  }

  getWeekValue (weeks, col) {
    const key = moment().subtract(col.split('-')[1], 'week').format('GGGGWW')
    return weeks[key]
  }

  prepareRows () {
    const { data = [], colParams, mainMap } = this.state
    const rows = data.length
      ? data.map((estimation) => {
        let row = {}
        Object.keys(colParams).forEach((col) => {
          let value =
          col === 'company'
            ? (estimation[col] === 0 ? 'New Business' : `${getMapLabel(mainMap.companies, estimation[col])} ${getMapLabel(mainMap.companies, estimation[col], 'nip')}`)
            : ['main_depart', 'from_depart', 'resp_depart'].includes(col)
                ? getMapLabel(mainMap.departments, estimation[col])
                : ['main_person', 'from_person', 'resp_person'].includes(col)
                    ? getMapLabel(mainMap.users, estimation[col])
                    : col === 'change'
                      ? (estimation.estimation || 0) - (estimation.profit1 || 0)
                      : col === 'updated' && !!estimation.updatedAt
                        ? this.getLastUpd(estimation.updatedBy + '', estimation.updatedAt, 0)
                        : col.includes('week') && estimation.weeks
                          ? this.getWeekValue(estimation.weeks, col)
                          : estimation[col]
          if (value === 0) {
            value = '0.00'
          }
          row = { ...row, [col]: value || '' }
        })
        return row
      })
      : []
    this.setState({ preparedRows: rows })
  }

  prepareCols () {
    const { colParams, isFinance, isManager, loggedUser, mainMap } = this.state
    const scDepartmentNames = loggedUser.scDepartment && loggedUser.scDepartment.map(el => getMapLabel(mainMap.departments, el))

    const dontShow = []
    if (!(isFinance || loggedUser.scEstimactionNull)) {
      // wyłącza kolumnę budzet dla osob bez wartosci true na scEstimactionNull
      dontShow.push('budget')
    }
    const colHeaders = ['Ogólne', 'Działy', 'Realny Zysk', 'Estymacja Zysku', 'Dodatkowe']
    const columns = colHeaders.map((col, key) => {
      const children = []
      Object.entries(colParams).filter(el => !dontShow.includes(el[0])).forEach((row) => {
        if (row[1].col === key + 1) {
          let headerName
          switch (row[0]) {
            case 'profit0':
              headerName = this.state.date ? moment(this.state.date, 'YYYY/MM').locale('pl').format('MMMM') : 'Miesiąc'
              break
            case 'profit1':
              headerName = this.state.date ? moment(this.state.date, 'YYYY/MM').subtract(1, 'month').locale('pl').format('MMMM') : 'Miesiąc -1'
              break
            case 'profit2':
              headerName = this.state.date ? moment(this.state.date, 'YYYY/MM').subtract(2, 'months').locale('pl').format('MMMM') : 'Miesiąc -2'
              break
            case 'turnover0':
              headerName = this.state.date ? 'obrót ' + moment(this.state.date, 'YYYY/MM').locale('pl').format('MMMM') : 'Miesiąc'
              break
            case 'turnover1':
              headerName = this.state.date ? 'obrót ' + moment(this.state.date, 'YYYY/MM').subtract(1, 'month').locale('pl').format('MMMM') : 'Miesiąc -1'
              break
            case 'turnover2':
              headerName = this.state.date ? 'obrót ' + moment(this.state.date, 'YYYY/MM').subtract(2, 'months').locale('pl').format('MMMM') : 'Miesiąc -2'
              break
            default:
              headerName = row[1].pl
          }
          const cell = {
            field: row[0],
            colId: row[0],
            headerName,
            pinned: row[1].frozen ? 'left' : null,
            columnGroupShow: row[1].stickyCol ? '' : 'open',
            editable: this.checkPermission(row[1]),
            width: parseInt(row[1].width, 10) || null,
            filter: row[1].type === 'amount' ? 'agNumberColumnFilter' : 'agTextColumnFilter'
          }
          children.push(cell)
        }
      })
      return ({
        headerName: col,
        children
      })
    })

    columns.unshift({
      field: 'edit',
      colId: 'edit',
      headerName: '',
      pinned: 'left',
      width: 60,
      filter: false,
      checkboxSelection: false,
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      cellRenderer: params => {
        if (params.data._id && (isFinance || isManager)) {
          const link = document.createElement('a')
          link.href = '#'
          link.innerText = 'Edytuj'
          link.disabled = true
          link.addEventListener('click', (e) => {
            e.preventDefault()
            this.setState({
              modal: true,
              formBody: this.state.data.find(el => el._id === params.data._id) || {},
              errors: {}
            })
          })
          return link
        } else {
          return ''
        }
      }
    })

    columns.unshift({
      field: 'accepted',
      colId: 'accepted',
      headerName: '',
      pinned: 'left',
      width: 120,
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      cellRenderer: params => {
        if (!params.data.pinnedBottom) {
          if (params.value) {
            return '<i class="pe-7s-check accept-cell-icon" />'
          } else if (isFinance || scDepartmentNames.includes(params.data.main_depart)) {
            const button = document.createElement('button')
            button.innerText = 'Akceptuj'
            button.classList.value = 'btn btn-sm accept-btn btn-dark'
            button.addEventListener('click', (e) => {
              e.preventDefault()
              params.setValue(true)
            })
            return button
          } else {
            return (`<div class="tool-tip">
                    <i class="pe-7s-help1 accept-cell-icon" />
                    <span class="tool-tiptext">Tylko użytkownik z działu głównego <br> może akceptować estymację</span>
                    </div>`)
          }
        }
      },
      filterParams: {
        clearButton: true,
        closeOnApply: true,
        filterOptions: [
          'empty',
          {
            displayKey: 'Zaakceptowane',
            displayName: 'Zaakceptowane',
            test: function (filterValue, cellValue) {
              return cellValue
            },
            hideFilterInput: true
          },
          {
            displayKey: 'Niezaakceptowane',
            displayName: 'Niezaakceptowane',
            test: function (filterValue, cellValue) {
              return cellValue === ''
            },
            hideFilterInput: true
          }
        ],
        suppressAndOrCondition: true
      }
    })

    let amountCols = []
    columns.filter(el => ['Estymacja Zysku', 'Realny Zysk'].includes(el.headerName)).forEach(item => {
      amountCols = amountCols.concat(item.children)
    })

    amountCols.map(el => {
      el.cellClass = (params) => {
        if (params.colDef.editable && !params.data.pinnedBottom) {
          return 'editable-cell'
        } else if (params.data.pinnedBottom) {
          return 'disable-cell bold'
        }
        return ''
      }
      el.cellRenderer = (params) => `<span class='amount-cell'>${currencyFormatter(params.value, el.field === 'change' && params.value > 0 ? '+' : '')}</span>`
      return el
    })

    // comment field
    const commentCol = columns.find(el => el.headerName === 'Dodatkowe').children.find(el2 => el2.field === 'comment')
    commentCol.cellClass = params => (params.colDef.editable && !params.data.pinnedBottom) ? 'editable-cell text' : ''

    this.setState({ preparedCols: columns, amountCols })
  }

  renderAgGrid () {
    const { searchInput, preparedRows = [], preparedCols = [], amountCols, clearAllFilters } = this.state
    return (
      preparedCols.length && preparedRows.length
        ? <AgGrid
            columns={preparedCols}
            rows={preparedRows}
            searchInput={searchInput}
            setCheckboxesList={this.setCheckboxesList}
            amountCols={amountCols}
            table='estimations'
            cellValueChanged={this.editEstimationValueCell}
            valueChanged={this.state.valueChanged}
            clearAllFilters={clearAllFilters}
          />
        : null
    )
  }

  render () {
    const { loader } = this.state
    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className='breadcrumb'>
                    <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                      to='/settlements/estimations'
                                                                     >Lista budżetów i estymacji
                                                                     </Link>
                  </div>
                }
              />
            </Col>
          </Row>
          <div className={loader ? 'noEvents' : ''}>
            <Row>
              <Col md={12}>
                <nav className='navbar navbar-light bg-light'>
                  <div className='btn-group' style={{ display: 'flex' }}>
                    {this.renderTopButtons()}
                    {this.renderMonthPicker()}
                    {this.renderSearchInput()}
                    {this.renderSavedFilters()}
                    {this.renderShowAllSwitch()}
                    {this.renderShowEmptySwitch()}
                  </div>
                </nav>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card
                  title='Lista estymacji'
                  content={
                    <div>
                      {this.renderAgGrid()}
                    </div>
                  }
                />
              </Col>
            </Row>
          </div>
        </Grid>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Modal show={this.state.modal} onHide={() => this.setState({ modal: false })}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formBody._id ? 'Edytuj estymację' : 'Dodaj estymację'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderEstimationForm()}</Modal.Body>
          <Modal.Footer>
            <Button className='btn btn-secondary' onClick={() => this.setState({ modal: false, formBody: {} })}>Anuluj</Button>
            <Button className='btn btn-primary' onClick={() => this.state.formBody._id ? this.editEstimations() : this.addNewEstimation()}>Zapisz</Button>
          </Modal.Footer>
        </Modal>
        <Loader display={loader} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main,
    filters: state.settlements.filters
  }
}

const mapDispatchToProps = {
  setFilters: data => ({
    type: 'SETTLEMENTS_FILTERS',
    filters: data
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimationList)
