import fetchApi, { API_SERVER } from '../../fetchApi'
import { useNotification as notification } from '../../useNotification'

const getClient = async clientId => {
  const response = await fetchApi(`${API_SERVER}/db/api/clients/?filter[where][_id]=${clientId}`, {})
  return Array.isArray(response) ? response[0] : {}
}

const getMediaFolders = async clientId => {
  return await fetchApi(`${API_SERVER}/api/client/media-folders/${clientId}`, {})
}

// const getAdformClients = async () => {
//   const response = await fetchApi(`${API_SERVER}/api/chart/getadformclients`, {})
//   if (!response.clients) {
//     console.error('Brak adformClients.')
//     notification('Wystąpił błąd')
//   }
//   return response.clients || []
// }

// const getGaViews = async () => {
//   const response = await fetchApi(`${API_SERVER}/api/client/get-ga-views`, {})
//   if (!response.views) {
//     console.error('Brak GA Views.')
//     notification('Wystąpił błąd')
//   }
//   return response.views || []
// }

const btxCompanies = async () => {
  return await fetchApi(`${API_SERVER}/db/api/btx_companies`, {})
}

const getCampaignsOnOffList = async (itid, _id, setData) => {
  const response = await fetchApi(`${API_SERVER}/api/client/camp-on-off-list?itid=${itid}&_id=${_id}`, {})
  if (response.error) {
    console.error(response.error)
    notification('Wystąpił błąd')
  }
  setData(response.data || [])
}

const deleteClient = async id => {
  const response = await fetchApi(`${API_SERVER}/db/api/clients/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: ''
  })
  if (response.error) {
    console.error(response.error)
    notification('Wystąpił błąd')
  } else {
    notification('Dane zostały zapisane', 'success')
  }
}

const sendReport = async queryParams => {
  const response = await fetchApi(`${API_SERVER}/api/client/generate-report?${Object.keys(queryParams)
    .map((key) => key + '=' + queryParams[key])
    .join('&')}`, {})
  if (response.error) {
    console.error(response.error)
    notification(response.error)
  } else {
    notification(response.success, 'success')
  }
}

const clientDataPost = async (id, postBody, companyName, method) => {
  if (method !== 'change other client entry') {
    notification('Wysyłanie danych', 'info')
  }
  const response = await fetchApi(`${API_SERVER}/db/api/clients${!id ? '' : `/${id}`}`, {
    method: method === 'add' ? 'POST' : 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: postBody
  })
  return response
}

const changeCampaignsStatus = async (state, props) => {
  const { data: { ITID: clitid, _id, changedRelations = [] } } = state
  const relids = changedRelations.length ? JSON.stringify(changedRelations) : ''
  const { user: { ID: user_id = 'react_dev', EMAIL: user_email = 'react_dev', LAST_NAME, NAME, WORK_POSITION: user_pos = 'react_dev' } } = props
  const user_name = LAST_NAME && NAME ? `${NAME}_${LAST_NAME}` : 'react_dev'

  const response = await fetchApi(`${API_SERVER}/api/client/changeCampaignsStatus?itid=${clitid}&relids=${relids}&_id=${_id}&user=${`${user_id},${user_email},${user_name},${user_pos}`}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
  if (response.error) {
    console.error(response.error)
    notification(`Wystąpił błąd: ${response.error.toString()}`)
  } else {
    notification(response.data && typeof response.data !== 'string' && response.data.length && !response.tocheck
      ? (
          'Relacje zostały wyłączone - kampania wstrzymana'
        )
      : response.data && typeof response.data !== 'string' && response.data.length && response.tocheck
        ? (
      `Relacje zostały wyłączone, ale należy sprawdzić: ${response.tocheck.toString()}`
          )
        : response.data && response.data.length === 0 && !response.tocheck
          ? (
              'Przywrócono relacje - kampania została wznowiona'
            )
          : response.data && response.data.length === 0 && response.tocheck
            ? (
      `Relacje zostały włączone, ale należy sprawdzić: ${response.tocheck.toString()}`
              )
            : response.error
              ? (
                  response.error.toString()
                )
              : (
                  response.data.toString()
                ), 'success', response.tocheck ? 30 : 10)
  }
}

const clientApi = {
  getClient,
  getMediaFolders,
  // getAdformClients,
  // getGaViews,
  btxCompanies,
  getCampaignsOnOffList,
  deleteClient,
  sendReport,
  clientDataPost,
  changeCampaignsStatus
}

export default clientApi
