import React, { Component } from 'react'
import {
  // FormControl,
  Table
} from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'
import { Card } from '../../components/Card/Card'
import { style } from '../../variables/Variables'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'

class ReportsAccessTableSummary extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {}
  }

  componentDidMount () {
    this.getEmails()
  }

  getEmails () {
    fetch(`${API_SERVER}/api/clients/email-list`)
      .then(res => res.json())
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        this.setState({ data: response })
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    e.stopPropagation()

    this.showNotification('info', 'Wysyłanie danych')

    fetch(`${API_SERVER}/api/clients/email-list-delete-one`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: e.target.name })
    })
      .then(res => res.json())
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
      .then(response => {
        if (response.error) {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', response.error)
        } else {
          this.showNotification('success', response, 6)
          console.log('Success:', response)
          this.getEmails()
        }
      })
  }

  renderTableRows () {
    const { data = [] } = this.state

    return (
      data.map((el, key) => {
        const names = el.clientName ? el.clientName.map((item, key) => <span key={key}>{item}<br /></span>) : ''
        return (
          <tr key={key}>
            <td>{el.email}</td>
            <td>{names}</td>
            <td>
              <input
                type='button'
                className='btn btn-primary hover-button'
                value='usuń'
                name={el._id}
                onClick={(e) => {
                  this.handleSubmit(e)
                }}
              />
            </td>
          </tr>
        )
      }))
  }

  render () {
    return (
      <Card
        title='Lista dostępów do raporty.cubegroup.pl'
        ctTableResponsive
        content={
          <div>
            <form onSubmit={this.handleSubmit}>
              <NotificationSystem ref='notificationSystem' style={style} />
              <Table striped hover>
                <thead>
                  <tr>
                    <th style={{ width: '30%' }}>Email</th>
                    <th style={{ width: '60%' }}>Przypisany do</th>
                    <th style={{ width: '10%' }}> </th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderTableRows()}
                </tbody>
              </Table>
            </form>
          </div>
        }
      />
    )
  }
}

export default ReportsAccessTableSummary
