import React, { Component } from 'react'
import moment from 'moment'
import { Col, FormControl, Grid, Row, Table } from 'react-bootstrap'
import { Notification, notificationSystemRef, useNotification as notification } from '../../useNotification'
import Loader from '../Settlements/Components/Loader'

import clientApi from './clientApi'
import { RENDER_INPUT_TEXT_FIELDS, REQUIRED_PROPS, validationStyles, MAP } from './defaults'
import Card from '../../components/Card/Card'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReportsAccessTable from './ReportsAccessTable'
import validation from './validation'
import renderRows from './renderRows'
import { DefaultButton, MediaFolder } from './renderButtons'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'
const EXCLUDED_PROPS = ['users', 'sm_users', 'websites', 'ns_users', 'changedRelations']
const RENDER_BOOLEAN_FIELDS = ['tracking_dcm']

const validationFields = ['Walidacja', 'reportType', 'reportDayOfValidation', 'reportTranslation', 'email', 'shouldReportsBeSent', 'reportUserValue1']
REQUIRED_PROPS.push(...validationFields)
const extraFields = ['diffRatesPerCategory']
REQUIRED_PROPS.push(...extraFields)

// let ADFORM_CLIENTS = []
// let GA_VIEWS = []

const AUTH_DEPARTMENTS = [7, 338, 44, 354, 256, 208, 464]
const AUTH_DEPARTMENTS_REPORT = [7, 338, 162]
const AUTH_DEPARTMENTS_ACCOUNT = [7, 338, 162, 196, 198, 214, 348, 322, 44, 208, 464]

const isAuth = (user, authDepartments) => {
  return (user && user.UF_DEPARTMENT && user.UF_DEPARTMENT.filter((department) => authDepartments.indexOf(department) > -1).length) || process.env.NODE_ENV === 'development'
}

class ClientDetails extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSendReport = this.handleSendReport.bind(this)
    this.clientDataPost = this.clientDataPost.bind(this)
    this.updateOtherClients = this.updateOtherClients.bind(this)
    this.sendState = this.sendState.bind(this)
    this.setBtxCompanies = props.setBtxCompanies
    this.momentNow = moment().format('YYYY-MM-01')
    this.moment1MonthLess = moment().subtract(1, 'month').format('YYYY-MM-01')
    this.moment2MonthsLess = moment().subtract(2, 'month').format('YYYY-MM-01')
    this.moment3MonthsLess = moment().subtract(3, 'month').format('YYYY-MM-01')
    this.state = {
      columnNames: [],
      rowNames: [],
      additionalPropNames: [],
      basicValues: {},
      adformClients: [],
      gaViews: [],
      deeplinkHtmlEnabled: false,
      data: {},
      mediaFolders: [],
      selectedMediaFolder: '-1',
      validationStartingValues: {},
      selectedReportDate: '-1',
      popup: false,
      loader: false
    }
  }

  async componentDidMount () {
    this.setState({ loader: true })
    const { id: clientId } = this.props.match.params
    let data = {}
    let mediaFolders = []
    if (clientId !== 'add') {
      data = await clientApi.getClient(clientId)
      if (data.ITID) {
        mediaFolders = await clientApi.getMediaFolders(clientId)
      }
      this.setState({ data, mediaFolders })
    }

    const filteredPropNames = Object.keys(data).filter((key) => typeof data[key] === 'object' && !EXCLUDED_PROPS.includes(key))
    const nonEmptyFilteredPropNames = filteredPropNames.filter((prop) => Object.keys(data[prop]).length)
    const basicValues = data

    if (!('shouldReportsBeSent' in data)) {
      basicValues.shouldReportsBeSent = '0'
    }
    if (!('reportUserValue1' in data)) {
      basicValues.reportUserValue1 = '0'
    }

    const validationData = {}
    validationFields.forEach((field) => {
      if (field !== 'Walidacja' && this.state.data) {
        const formatedVal = this.state.data[field] === true ? '1' : this.state.data[field] === false ? '0' : this.state.data[field]
        validationData[field] = formatedVal
        basicValues[field] = formatedVal
      }
    })
    validationData.reportUserValue1 = validationData.reportUserValue1 || '0'
    validationData.reportTranslation = validationData.reportTranslation || 'pl'
    validationData.reportDayOfValidation = validationData.reportDayOfValidation || 10

    // ADFORM_CLIENTS = await clientApi.getAdformClients()
    // GA_VIEWS = await clientApi.getGaViews()

    this.setState({
      columnNames: ['basic', ...nonEmptyFilteredPropNames],
      rowNames: [...REQUIRED_PROPS],
      additionalPropNames: nonEmptyFilteredPropNames,
      basicValues,
      // adformClients: ADFORM_CLIENTS,
      // gaViews: GA_VIEWS,
      deeplinkHtmlEnabled: basicValues.external_id && basicValues.external_id.length > 2,
      validationStartingValues: validationData,
      loader: false
    })

    const btxCompanies = await clientApi.btxCompanies()
    this.setBtxCompanies(btxCompanies)
  }

  handleSubmit (event) {
    event.preventDefault()
    let { data, basicValues } = this.state
    data = !data._id ? basicValues : data
    const isValid = validation({ data, basicValues, validationFields })
    if (!isValid) {
      return
    }

    const id = data._id

    const params = new FormData(event.target)

    const searchParams = []
    const valiSearchParams = []
    let pair
    for (pair of params.entries()) {
      searchParams.push(`${encodeURIComponent(pair[0])}=${encodeURIComponent(pair[1])}`)
      if (validationFields.indexOf(pair[0]) !== -1 && pair[0] !== 'email') {
        valiSearchParams.push(`${encodeURIComponent(pair[0])}=${encodeURIComponent(pair[1])}`)
      }
    }
    const postBody = searchParams.join('&')
    const valiPatchBody = valiSearchParams.join('&')

    // Check if e-mail is used in other entries
    if (basicValues.email && basicValues.email !== '') {
      fetch(`${API_SERVER}/db/api/clients/?filter[where][email]=${data.email}`)
        .then((res) => res.json())
        .catch((err) => {
          console.log(`err ${err}`)
        })
        .then((response) => {
          // Check which entries have different validation values
          const res = response.filter((res) => {
            const fields = [...validationFields]
            fields.shift()
            fields.pop()
            let field
            for (field in fields) {
              if (res[fields[field]] !== data[fields[field]]) {
                return true
              }
            }
            return false
          })
          // Choose appropriate action, depending on number of entries to changes
          if (!res.length) {
            const companyName = Object.keys(basicValues).length ? basicValues.company : 'Nowa firma'
            this.clientDataPost(id, postBody, companyName)
          } else if (res.length === 1) {
            // If there is only one entry, check, if this entry isn't current one
            if (res[0]._id === id) {
              this.clientDataPost(id, postBody)
            } else {
              const client = {
                id: res[0]._id,
                company: res[0].company
              }
              this.updateOtherClients([client], valiPatchBody, id, postBody)
            }
          } else if (res.length > 1) {
            const clientsToPatch = []
            res.forEach((res) => {
              if (res._id !== id) {
                const client = {
                  id: res._id,
                  company: res.company
                }
                clientsToPatch.push(client)
              }
            })
            this.updateOtherClients(clientsToPatch, valiPatchBody, id, postBody)
          }
        })
    } else {
      const companyName = Object.keys(this.state.basicValues).length ? this.state.basicValues.company : 'Nowa firma'
      this.clientDataPost(id, postBody, companyName)
    }
  }

  updateOtherClients (clientsToChange, updateBody, currentClientId, currentClientPostBody) {
    const clientList = []
    clientsToChange.forEach((client) => {
      clientList.push(client.company)
    })
    if (
      window.confirm(
        `Wpisane dane walidacyjne nadpiszą dane walidacyjne klientów o tym samym adresie email: ${clientList.join(
          ', '
        )}. Czy jesteś pewny, że wpisane dane walidacyjne mają zostać zaktualizowane również dla tych klientów?`
      )
    ) {
      clientsToChange.forEach((client) => {
        this.clientDataPost(client.id, updateBody, client.company, 'change other client entry')
      })
      this.clientDataPost(currentClientId, currentClientPostBody)
    }
  }

  async clientDataPost (id, postBody, companyName = this.state.data.company, method = !id ? 'add' : 'update') {
    const data = await clientApi.clientDataPost(id, postBody, companyName, method).catch(console.error)
    if (data) {
      const { errors = {} } = data
      const errorsMessages = Object.values(errors)
      if (errorsMessages.length) {
        errorsMessages.forEach((errMsg) => {
          notification(errMsg, 'error', 10)
        })
      } else {
        notification(`Dane klienta ${companyName} zostały zapisane`, 'success')
        if (method === 'add') this.setState({ data })
      }
    } else {
      notification('Wystąpił błąd')
    }
  }

  async handleSendReport (event) {
    event.preventDefault()
    event.stopPropagation()
    const data = this.state.data
    const user = this.props.user
    const dates = this.state.selectedReportDate.split('|')
    const dateFrom = `${dates[0]}T00:00:00`
    const dateTo = `${dates[1]}T23:59:59`
    const reportUserValue1 = this.state.validationStartingValues.reportUserValue1 || '0'
    if (moment().diff(dateFrom, 'months') < 3) {
      window.alert('Raport nie zawiera transakcji i reklamacji otwartych z poprzednich okresów rozliczeniowych')
    }
    const queryParams = {
      email: user.EMAIL,
      dateFrom,
      dateTo,
      advertiserId: data.ITID,
      advertiserName: data.company,
      reportUserValue1
    }
    await clientApi.sendReport(queryParams)
  }

  async handleDelete (event) {
    event.preventDefault()
    const { id } = this.props.match.params
    await clientApi.deleteClient(id)
  }

  sendState (value) {
    this.setState(value)
  }

  getRowValues (col, row) {
    const { basicValues, data: stateData, validationStartingValues } = this.state
    const data = this.props.match.params.id === 'add' ? basicValues : stateData
    const basicValue = data[row]
    let rowName = row
    if (MAP[col] && MAP[col][row]) {
      rowName = MAP[col][row]
    }
    const isNestingExist = data.hasOwnProperty(col)
    const isNestedValueExist = data.hasOwnProperty(col) && data[col].hasOwnProperty(rowName)
    const value = isNestingExist ? (isNestedValueExist ? data[col][rowName] : '-') : basicValue
    if (col === 'basic' && RENDER_BOOLEAN_FIELDS.indexOf(row) > -1) {
      return renderRows.BooleanFieldSelect(row, data, basicValues, this.sendState)
    } else if (col === 'basic' && row === 'reportType') {
      return renderRows[row](row, data, basicValues, this.sendState, validationStartingValues, validationFields)
    } else if (
      col === 'basic' &&
      // ['diffRatesPerCategory', 'widocznosc_NSM', 'reportDayOfValidation', 'shouldReportsBeSent', 'reportUserValue1', 'keywords', 'gaUserId', 'reportTranslation'].includes(row)
      ['diffRatesPerCategory', 'widocznosc_NSM', 'reportDayOfValidation', 'shouldReportsBeSent', 'reportUserValue1', 'keywords', 'reportTranslation'].includes(row)
    ) {
      return renderRows[row](row, data, basicValues, this.sendState)
    // } else if (col === 'basic' && row === 'adformclient') {
    //   return renderRows[row](row, data, basicValues, this.sendState, this.state.adformClients)
    // } else if (col === 'basic' && row === 'gaView') {
    //   return renderRows[row](row, data, basicValues, this.sendState, this.state.gaViews)
    } else if (col === 'basic' && row === 'raporty') {
      const field = this.props.match.params.id === 'add' ? 'basicValues' : 'data'
      return renderRows[row](row, data, basicValues, this.sendState, field)
    } else if (col === 'basic' && RENDER_INPUT_TEXT_FIELDS.indexOf(row) > -1) {
      if (row === 'bitrix') {
        const { companies = [] } = this.props.main
        return renderRows[row](row, data, basicValues, this.sendState, companies)
      }
      return renderRows.TextInput(row, data, basicValues, this.sendState)
    }
    return (
      <span
        onClick={(event) => {
          stateData[row] = value
          this.setState({ basicValues: stateData })
        }}
      >
        {value}
      </span>
    )
  }

  renderMainTableColumnsNames () {
    return ['', ...this.state.columnNames].map((prop, key) => <th key={key}>{prop}</th>)
  }

  renderMainTableRows () {
    return this.state.rowNames.map((row, rowKey) => {
      /* Check if it is Validation form and change styles */
      let valiStyle = {}
      let headStyle = {}
      if (validationFields.indexOf(row) >= 0) {
        if (validationFields.indexOf(row) === 0) {
          valiStyle = validationStyles.top
          headStyle = {
            fontSize: '1.2em',
            fontWeight: 'bold',
            marginLeft: '10px'
          }
        } else if (validationFields.indexOf(row) === validationFields.length - 1) {
          valiStyle = validationStyles.bottom
        } else {
          valiStyle = validationStyles.middle
        }
      }
      return (
        <tr key={rowKey} style={valiStyle}>
          <td>
            <label style={headStyle}>{['diffRatesPerCategory'].includes(row) ? '' : row}</label>
          </td>
          {this.state.columnNames.map((col, colKey) => (
            <td key={colKey}>
              {this.getRowValues(col, row)}
              {colKey === 0 && row === 'website'
                ? (
                  <p className='form-text text-muted' style={{ fontSize: '12px' }}>
                    W przypadku Usług Domeny GSC musi zaczynać się od "sc-domain:" + nazwa domeny. W pozostałych przypadkach od "http://" lub "https://" + nazwa domeny
                  </p>
                  )
                : (
                    ''
                  )}
            </td>
          ))}
        </tr>
      )
    })
  }

  renderMainTable () {
    const { data } = this.state
    return (
      <Card
        title={data.company}
        category='Tabela z porównaniem różnic danych'
        ctTableFullWidth
        ctTableResponsive
        contentStyle={{ paddingBottom: '0px' }}
        content={
          <Table striped hover style={{ marginBottom: '0px', borderBottom: 'none' }}>
            <thead>
              <tr>{this.renderMainTableColumnsNames()}</tr>
            </thead>
            <tbody>{this.renderMainTableRows()}</tbody>
          </Table>
        }
      />
    )
  }

  renderAdditionalTables () {
    const { data, additionalPropNames } = this.state
    return additionalPropNames.map((prop, propKey) => (
      <Col md={6} key={propKey}>
        <Card
          title={prop}
          ctTableResponsive
          content={
            <Table striped hover>
              <tbody>
                {Object.keys(data[prop]).map((val, valKey) =>
                  data[prop][val]
                    ? (
                      <tr key={valKey}>
                        <td>
                          <label>{val}</label>
                        </td>
                        <td>{typeof data[prop][val] !== 'object' ? data[prop][val] : JSON.stringify(data[prop][val], null, ' ')}</td>
                      </tr>
                      )
                    : null
                )}
              </tbody>
            </Table>
          }
        />
      </Col>
    ))
  }

  async changeCampaignsStatus () {
    this.setState({ loader: true })
    await clientApi.changeCampaignsStatus(this.state, this.props)
    this.componentDidMount()
  }

  showCampaignsOnOffList (popupdata) {
    if (popupdata !== 'string' && popupdata.length) {
      this.setState({ popupdata, popup: true })
    }
  }

  renderPopup () {
    return this.state.popupdata.map((el, key) => (
      <tr style={key % 2 === 0 ? { backgroundColor: '#A9F5F2' } : { backgroundColor: '#CEF6E3' }} key={el._id}>
        <th>{el._id}</th>
        <th>
          {el.on
            ? `włączone: ${JSON.stringify(
              el.on.map((el) => (el.uid ? el.uid : el)),
              null,
              ' '
            )}`
            : `wyłączone: ${JSON.stringify(
              el.off.map((el) => (el.uid ? el.uid : el)),
              null,
              ' '
            )}`}
        </th>
        <th>{el.user}</th>
      </tr>
    ))
  }

  renderCloseButton () {
    return (
      <button className='close' onClick={() => this.setState({ popup: false })}>
        Zamknij
      </button>
    )
  }

  renderButtons (data) {
    const { user } = this.props
    const buttons = []

    buttons.push(<input className='btn btn-save' type='submit' value='zapisz' key='btn1' />)

    if (isAuth(user, AUTH_DEPARTMENTS)) {
      buttons.push(<input className='btn btn-danger' type='button' value='usuń' key='btn2' onClick={this.handleDelete} />)
      data.ITID && buttons.push(<MediaFolder {...{ state: this.state, setState: this.sendState }} key='btn3' />)
    }
    if (isAuth(user, AUTH_DEPARTMENTS_REPORT)) {
      data.alias &&
        buttons.push(
          <DefaultButton
            {...{
              value: (
                <>
                  <i className='pe-7s-graph3' /> Raport
                </>
              ),
              onClick: () => this.props.history.push({ pathname: `/charts/${data.alias}`, company: data.company })
            }}
            key='btn4'
          />
        )
    }
    if (data.ITID && isAuth(user, AUTH_DEPARTMENTS_ACCOUNT)) {
      buttons.push(
        <DefaultButton
          {...{
            value: (
              <>
                <i className='pe-7s-unlock' />
                {data.changedRelations && data.changedRelations.length ? ' Włącz kampanię' : ' Wyłącz kampanię'}
              </>
            ),
            onClick: () => this.changeCampaignsStatus()
          }}
          key='btn5'
        />
      )

      buttons.push(
        <DefaultButton
          {...{
            value: 'Zmiany relacji',
            onClick: async () => await clientApi.getCampaignsOnOffList(data.ITID, data._id, this.showCampaignsOnOffList.bind(this))
          }}
          key='btn6'
        />
      )
    }
    return <span>{buttons}</span>
  }

  render () {
    const { data, selectedReportDate, loader, popup } = this.state
    return (
      <div className='content'>
        <Notification ref={notificationSystemRef} />
        <Loader display={loader} />
        <Grid fluid>
          <form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={12}>
                <nav className='navbar navbar-light bg-light'>
                  <button
                    className='btn'
                    onClick={() => {
                      this.props.history.push({ pathname: '/clients' })
                    }}
                  >
                    &lt; powrót do listy klientów
                  </button>
                  {this.renderButtons(data)}
                  {data.ITID
                    ? (
                      <span>
                        <FormControl
                          componentClass='select'
                          name='reportDate'
                          type='select'
                          value={selectedReportDate}
                          style={{ width: 300, display: 'inline-block' }}
                          onChange={(e) => {
                            const generateReportBtn = document.getElementById('generateReportBtn')
                            if (generateReportBtn) {
                            }
                            this.setState({ selectedReportDate: e.target.value })
                          }}
                        >
                          <option disabled value={-1} key={-1}>
                            Wybierz zakres dat do raportu
                          </option>
                          <option value={`${this.momentNow}|${moment(this.momentNow).endOf('month').format('YYYY-MM-DD')}`}>bieżący miesiąc</option>
                          <option value={`${this.moment1MonthLess}|${moment(this.moment1MonthLess).endOf('month').format('YYYY-MM-DD')}`}>ostatni miesiąc</option>
                          <option value={`${this.moment2MonthsLess}|${moment(this.moment1MonthLess).endOf('month').format('YYYY-MM-DD')}`}>2 ostatnie miesiące</option>
                          <option value={`${this.moment3MonthsLess}|${moment(this.moment1MonthLess).endOf('month').format('YYYY-MM-DD')}`}>3 ostatnie miesiące</option>
                        </FormControl>
                        <input
                          disabled={selectedReportDate === '-1'}
                          className={`btn ${selectedReportDate !== '-1' ? 'btn-info' : 'btn-disabled'}`}
                          type='button'
                          data-date={selectedReportDate}
                          value='Pobierz raport walidacyjny'
                          onClick={this.handleSendReport}
                        />
                        <Link to={`/client/newsletter/${data.ITID}`} className='btn' role='button'>
                          Newsletter
                        </Link>
                        <Link to={`/client/autovalidation/step1/${data.ITID}`} className='btn btn-warning' role='button'>
                          Walidacja
                        </Link>
                      </span>
                      )
                    : (
                        ''
                      )}
                </nav>
              </Col>
            </Row>
            <div id='onoffpopup' style={{ zIndex: 100, position: 'absolute' }}>
              {popup ? this.renderCloseButton() : ''}
              <table style={{ fontSize: 12 }} className='table table-striped table-hover'>
                <tbody>{popup ? this.renderPopup() : null}</tbody>
              </table>
            </div>
            <Row>
              <Col md={12}>{this.renderMainTable()}</Col>
            </Row>
            <Row>{this.renderAdditionalTables()}</Row>
          </form>
          {data._id
            ? (
              <Row>
                <Col lg={10} md={12}>
                  <ReportsAccessTable clientId={data._id} />
                </Col>
              </Row>
              )
            : (
                ''
              )}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  main: state.main
})

const mapDispatchToProps = {
  setBtxCompanies: (companies) => ({
    type: 'MAIN_COMPANIES',
    companies
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails)
