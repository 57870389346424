import React from 'react'
import './tooltip.scss'
import QuestionMarkIco from '../../assets/img/question-mark.svg'

const Tooltip = (props) => {
  return (
    <span className={`info-tooltip ${props.className || ''}`}>
      &nbsp; <img src={QuestionMarkIco} alt='' />
      <div className={props.dir}>
        <p>{props.children}</p>
      </div>
    </span>
  )
}

export default Tooltip
