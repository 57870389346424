import React, { Component } from 'react'
import { Col, FormControl, Grid, Row, Table } from 'react-bootstrap'
import Card from '../../../components/Card/Card.jsx'
import NotificationSystem from 'react-notification-system'
import connect from 'react-redux/es/connect/connect'
import { style } from '../../../variables/Variables'
import FORM_MODEL from './office-clients'
import { getMainBtx } from '../common'
import { Link } from 'react-router-dom'
import Loader from '../Components/Loader'
import { API_SERVER, checkUserType } from '../authData'

class OfficeClients extends Component {
  constructor (props) {
    super(props)
    this.setBtxMain = props.setBtxMain
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      colParams: FORM_MODEL,
      loader: true,
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user
    }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    const isOffice = checkUserType(loggedUser, 'office')
    this.setState({
      isFinance,
      isOffice
    })
    this.getData()
  }

  getData () {
    fetch(`${API_SERVER}/api/office-clients`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(res => res.json())
      .then(response => {
        if (response) {
          response.push({})
          this.setState({
            officeClients: response,
            loader: false
          })
        } else {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', response)
        }
      })
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'tr',
      autoDismiss
    })
  }

  renderTableHeader () {
    const { colParams } = this.state
    const style = col => ({
      width: colParams[col] ? colParams[col].width : ''
    })

    return (
      Object.keys(colParams).map((col, key) => (
        <th key={key} style={style(col)}>{colParams[col] ? colParams[col].pl : col}</th>
      ))
    )
  }

  getRowValues (col, key, colParams, officeClients) {
    if (colParams[col].type === 'text') {
      return (
        <FormControl
          type='text'
          name={col}
          value={officeClients[key][col]}
          readOnly={col === 'ID' ? 'readonly' : ''}
          onChange={(e) => {
            officeClients[key][col] = e.target.value
            this.setState(() => ({ officeClients }))
          }}
        />
      )
    } else {
      return officeClients[key][col]
    }
  }

  renderTableContent () {
    const { officeClients = [], colParams } = this.state

    return officeClients.length
      ? officeClients.map((client, key) => (
        <tr key={key}>
          {
          Object.keys(colParams).map((col, key2) => (
            <td key={key2}>
              {this.getRowValues(col, key, colParams, officeClients)}
            </td>
          ))
        }
          <td>
            <input
              type='button'
              className='btn btn-primary btn-sm hover-button'
              value='zapisz'
              name={`values[${client.ID || ''};${client.TITLE || ''};${client.NIP || ''}]`}
              onClick={(e) => {
                this.handleSubmit(e)
              }}
            />
          </td>
        </tr>
      ))
      : ''
  }

  handleSubmit (e) {
    e.preventDefault()
    e.stopPropagation()

    this.showNotification('info', 'Wysyłanie danych')

    fetch(`${API_SERVER}/api/office-clients`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
      body: e.target.name
    })
      .then(res => res.json())
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
      .then(response => {
        if (response.success) {
          this.showNotification('success', 'Dane zostały zapisane', 6)
          console.log('Success:', response)
          this.getData()
          getMainBtx()
            .catch(() => this.showNotification('error', 'Wystąpił błąd'))
            .then(response => this.setBtxMain(response || []))
        } else {
          this.showNotification('error', `Wystąpił błąd: ${response.error || ''}`)
          console.error('Error:', response.error)
        }
      })
  }

  render () {
    const { loader, isFinance, isOffice } = this.state
    if (isFinance || isOffice) {
      return (
        <div className='content OfficeClients'>
          <NotificationSystem ref='notificationSystem' style={style} />
          <Loader display={loader} />
          <form className='form-inline' onSubmit={this.handleSubmit}>
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Card
                    content={
                      <div className='breadcrumb'>
                        <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                          to='/settlements/office-clients'
                                                                         >Klienci Office
                        </Link>
                      </div>
                    }
                  />
                </Col>
              </Row>
              <div className={loader ? 'noEvents' : ''}>
                <Row>
                  <Col md={12}>
                    <Card
                      title='Klienci Office'
                      ctTableFullWidth
                      ctTableResponsive
                      content={
                        <Table striped hover>
                          <thead>
                            <tr>
                              {this.renderTableHeader()}
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderTableContent()}
                          </tbody>
                        </Table>
                        }
                    />
                  </Col>
                </Row>
              </div>
            </Grid>
          </form>
        </div>
      )
    } else {
      return (
        <div>Brak dostępu</div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser
  }
}

const mapDispatchToProps = {
  setBtxMain: mainBtx => ({
    type: 'MAIN_BTX',
    mainBtx
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(OfficeClients)
