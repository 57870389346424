/**
 * Wylicza marżę dla pojedynczego rozliczenia
 *
 * @param {object} data - pojedyncze rozliczenie.
 * @returns {number}
 */
const countRealProfit = data => {
  let value
  // jezeli pozostaly przychod !== 0 && przychody !== rozliczony przychod z rezerwy
  //
  if (data.remained_revenue && data.remained_revenue !== '0.00' && data.revenue !== (data.realized_reserve || '0.00')) {
    // zysk - pozostaly przychod - rozliczony przychod z rezerwy
    //
    value = Number(data.profit || 0) - Number(data.remained_revenue || 0) - Number(data.realized_reserve || 0)
  } else {
    // zysk + rezerwa na przychody + zrealizowany przychod - rozliczony przychod z rezerwy
    //
    value = Number(data.profit || 0) + Number(data.income_reserve || 0) + Number(data.realized_revenue || 0) - Number(data.realized_reserve || 0)
  }
  return Number(value.toFixed(2))
}

/**
 * Wylicza obrót dla pojedynczego rozliczenia
 *
 * @param {object} data - pojedyncze rozliczenie.
 * @returns {number}
 */
const countRealTurnover = data => {
  let value
  // jeżeli pozostały przychód oraz przychód są większe lub mniejsze od zera
  //
  if (data.remained_revenue && data.remained_revenue !== '0.00' && data.revenue && data.revenue !== '0.00') {
    // przychód, minus pozostały przychód
    //
    value = Number(data.revenue) - Number(data.remained_revenue)
  } else {
    // przychód + rezerwa na przychody + zrealizowany przychód - rozliczony przychód z rezerwy
    //
    value = Number(data.revenue || 0) + Number(data.income_reserve || 0) + Number(data.realized_revenue || 0) - Number(data.realized_reserve || 0)
  }
  return Number(value.toFixed(2))
}

module.exports = {
  countRealProfit,
  countRealTurnover
}
