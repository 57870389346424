import React, { useEffect, useState } from 'react'
import { checkAuthIdUser, checkIsB2BUser, checkIsKMUser, checkUserType } from '../../authData'
import { Col, Grid, Row, Table } from 'react-bootstrap'
import { toHours, toSeconds, toShortHours } from '../../../TimeReport/timeFunctions'
import { generateRowsFromList, getUserWorkingHours } from './b2bApi'
import { getData, sendData } from '../worktimeApi'
import { countDiffTime, countSum } from '../functions'
import moment from 'moment'
import { ReasonInput } from '../ReasonInput'

const B2BReport = ({
  loggedUser,
  setLoader,
  setInfo,
  date,
  setData,
  data,
  generateHandle,
  setGenerateHandle,
  setConfirmAllowed,
  type
}) => {
  /* auth */
  const isFinance = checkUserType(loggedUser, 'finance')
  const isDev = checkUserType(loggedUser, 'dev')
  const isB2B = checkIsB2BUser(loggedUser, date)
  const b2bName = loggedUser.monthly && loggedUser.monthly[date] && loggedUser.monthly[date].b2bName
  const isKM = checkIsKMUser(loggedUser, date)
  const isAuthIdUser = checkAuthIdUser(loggedUser,
    ['200'] // Cezary Śledziak
  )

  const [origValue, setOrigValue] = useState()

  /* generate report */
  const generateReport = async () => {
    if ((type === 'b2b' && !isB2B) || (type === 'km' && !isKM)) {
      return setInfo('Brak uprawnień do wygenerowania raportu')
    }
    setLoader(true)
    setGenerateHandle(false)

    const prevMonth = moment(date).subtract(1, 'm').format('YYYY-MM')

    const [prevMonthDataKM, prevMonthDataB2B] = await Promise.all([getData('km', loggedUser.ID, prevMonth), getData('b2b', loggedUser.ID, prevMonth)])
    if ((Object.keys(prevMonthDataKM).length > 0 && !prevMonthDataKM.confirmed) || (Object.keys(prevMonthDataB2B).length > 0 && !prevMonthDataB2B.confirmed)) {
      setLoader(false)
      return setInfo('Zacznij od zaakceptowania raportu za poprzedni miesiąc.')
    }

    const workingHours = await getUserWorkingHours(loggedUser.ID, date, type)
    if (!workingHours) {
      setInfo('Wystąpił błąd podczas pobierania wymiaru czasu pracy')
    } else {
      const rows = (data.rows || []).filter(el => el.type === 'manual')
      const generatedRows = await generateRowsFromList(loggedUser.ID, loggedUser.scDepartment[0] || 0, type)

      const newData = {
        ...data,
        type,
        userId: loggedUser.ID,
        month: date,
        lastGenerate: new Date(),
        lastUpdate: new Date(),
        rows: [...generatedRows, ...rows],
        confirmed: false,
        workingTime: toSeconds(workingHours),
        userName: type === 'b2b' ? b2bName : loggedUser.userName,
        reason: ''
      }
      await save(newData)
    }
    setLoader(false)
  }

  useEffect(() => {
    if (generateHandle) {
      (async () => generateReport())()
    }
  }, [generateHandle])

  /* diff */
  const timeSum = countSum(data.rows)
  const min = type === 'km' ? 0.8 : 0.9
  const max = type === 'km' ? 1.2 : 1.05
  const diffTime = countDiffTime(timeSum, data.workingTime, min, max)
  const diffText = diffTime < 0 ? 'brakuje' : 'za dużo'

  /* save */
  const save = async newData => {
    const responseData = await sendData({ ...newData, total: timeSum })
    setData(responseData)
  }

  const checkCanConfirm = () => {
    return timeSum === 0
      ? data.reason
      : diffTime === null &&
      Array.isArray(data.rows) && data.rows
        .every(row => row.name !== '' && (row.type !== 'manual' ? row.time !== '' : true))
  }

  useEffect(() => {
    setConfirmAllowed(checkCanConfirm())
  }, [JSON.stringify(data)])

  /* add row */
  const addRow = async () => {
    setData({
      ...data,
      confirmed: false,
      rows: [
        ...data.rows,
        { name: '', time: '0:00', type: 'manual' }
      ]
    })
  }

  const rowName = (row, key) => {
    return row.type !== 'manual'
      ? row.name
      : <input
          type='text'
          name='manualName'
          placeholder='(wpisz dodatkowe wedle potrzeby)'
          className='hoverInput textarea'
          style={{ textAlign: 'left', marginLeft: -12, border: !row.name && '1px solid red' }}
          onChange={(e) => {
            const { value } = e.target
            data.rows[key].name = value
            setData((data) => ({ ...data }))
          }}
          onFocus={(e) => [setConfirmAllowed(false), setOrigValue(e.target.value)]}
          onBlur={handleSubmit}
          onKeyPress={e => e.key === 'Enter' ? handleSubmit(e) : null}
          value={row.name || ''}
        />
  }

  /* submit */
  const handleSubmit = async event => {
    event.preventDefault()
    event.stopPropagation()
    const { name, value } = event.target
    const timeValue = value !== '' ? toSeconds(value) : 0

    if (name === 'manualName' || name === 'manualTime' || (origValue && (value !== origValue))) {
      const newData = {
        ...data,
        lastUpdate: new Date(),
        confirmed: false,
        time: timeValue
      }
      await save(newData)
    } else {
      setConfirmAllowed(checkCanConfirm())
    }
  }

  return (isFinance || isDev || isAuthIdUser || isB2B || isKM) && data.userId
    ? <div className='content'>
      <Grid fluid>
        <Row>
          <Col md={12}>
            {
                type !== 'km' && !b2bName
                  ? <h4 className='text-center'>Uzupełnij firmę zleceniobiorcy</h4>
                  : <div>
                    {
                      data.lastGenerate && countSum(data.rows) === 0 && diffTime === null &&
                        <ReasonInput
                          type='km'
                          data={data}
                          setData={setData}
                          setConfirmAllowed={setConfirmAllowed}
                        />
                    }
                    <Table hover>
                      <thead>
                        {
                      Array.isArray(data.rows) && data.rows.length > 0 && !data.confirmed
                        ? <tr>
                          <th colSpan={4} style={{ textAlign: 'right', paddingRight: '2.4rem' }}>Czas do zaraportowania: od <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>{toHours(Math.floor(min * data.workingTime))}</span> do <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>{toHours(Math.floor(max * data.workingTime))}</span></th>
                          </tr>
                        : null
                    }

                        <tr>
                          <th style={{ width: '3%' }}>Lp</th>
                          <th style={{ width: '77%' }}>Zadanie</th>
                          <th style={{ width: '10%' }}>Suma zadań</th>
                          <th style={{ width: '10%' }}><input
                            readOnly
                            className='hoverInput sum'
                            style={{ fontSize: '2rem' }}
                            value={toHours(timeSum)}
                                                       />
                          </th>
                        </tr>
                        {
                      diffTime && <tr>
                        <th colSpan={2} />
                        <th style={{ color: 'red' }}>{diffText}</th>
                        <th>
                          <input
                            readOnly
                            className='border-input'
                            style={{ fontSize: '2rem', textAlign: 'right', border: 'none', color: 'red' }}
                            value={toHours(diffTime)}
                          />
                        </th>
                                  </tr>
                    }
                      </thead>
                      <tbody>
                        {
                        Array.isArray(data.rows) && data.rows.map((row, key) => {
                          return (
                            <tr key={key} className='bg-info'>
                              <td>{key + 1}</td>
                              <td colSpan={2}>{rowName(row, key)}</td>
                              <td><input
                                type='text'
                                name={row.type === 'manual' ? 'manualTime' : `row-${key}`}
                                placeholder='hh:mm'
                                className='hoverInput'
                                onChange={(e) => {
                                  const { value } = e.target
                                  data.rows[key].time = value
                                  setData((data) => ({ ...data }))
                                }}
                                onFocus={(e) => [setConfirmAllowed(false), setOrigValue(e.target.value)]}
                                onBlur={(e) => [
                                  data.rows[key].time = toShortHours(e.target.value),
                                  setData((data) => ({ ...data })),
                                  handleSubmit(e)]}
                                onKeyPress={e => e.key === 'Enter' ? handleSubmit(e) : null}
                                value={row.time !== '00:00' ? row.time : ''}
                                  />
                              </td>
                            </tr>
                          )
                        })
                      }
                        <tr>
                          <td colSpan={4}>
                            <button className='btn btn-sm' onClick={addRow}>+ Dodaj</button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                }
          </Col>
        </Row>
      </Grid>
    </div>
    : <div className='content SalaryGrid'>
      <div>Brak dostępu</div>
    </div>
}

export default B2BReport
