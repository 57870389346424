const clients = (state = {
  data: [],
  filter: {
    isInfluency: false,
    emptyWebsite: false,
    emptyDomain: false,
    query: '',
    page: 0
  }
}, action) => {
  switch (action.type) {
    case 'CLIENTS_SET_CLIENTS':
      return Object.assign({}, state, {
        data: action.clients
      })
    case 'CLIENTS_SET_PAGE':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          page: action.page
        }
      })
    case 'CLIENTS_SET_FILTER':
      const filter = {
        ...state.filter,
        query: action.query,
        page: 0
      }
      return Object.assign({}, state, {
        filter
      })
    case 'CLIENTS_SET_CHECKBOXES':
      const filter2 = {
        ...state.filter,
        emptyWebsite: false,
        emptyDomain: false,
        [action.id]: action.checkbox,
        page: 0
      }
      return Object.assign({}, state, {
        filter: filter2
      })

    default:
      return state
  }
}

export default clients
