import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, FormControl, Grid, Row, Table } from 'react-bootstrap'
import Card from '../../../components/Card/Card.jsx'
import NotificationSystem from 'react-notification-system'
import Select from 'react-select'
import Loader from '../Components/Loader'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import FORM_MODEL from './jobs'
import { allowedFromDepartments, allowedRespDepartments, API_SERVER, checkUserType } from '../authData'
import * as moment from 'moment'
import { style } from '../../../variables/Variables'
import { Link, Redirect } from 'react-router-dom'
import { getMainMap, getMapLabel, onlyActiveUsers } from '../common'

const READ_ONLY_INPUTS_ADD_ACTION = [
  'from_person',
  'order_id',
  'main_person'
]
const READ_ONLY_INPUTS_EDIT_ACTION = [
  'main_depart',
  'from_depart',
  'resp_depart',
  'start'
]

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 1 }),
  control: style => ({ ...style, minHeight: 30 })
}

class JobDetails extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      loader: true,
      colParams: FORM_MODEL,
      job_order_id: '',
      slug: props.match.params.slug,
      dateFocus: {
        start: false,
        end: false
      },
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      jobsWhereUserIsResponsiblePerson: 0,
      formValid: true,
      readOnlyInputs: READ_ONLY_INPUTS_ADD_ACTION,
      requiredInputs: Object.keys(FORM_MODEL).filter(prop => FORM_MODEL[prop].req),
      mainMap: getMainMap(props.main)
    }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    this.setState({
      isFinance,
      jobs: [{
        from_person: loggedUser.ID,
        from_depart: (loggedUser.scDepartment || [])[0] || '',
        from_depart_arr: loggedUser.scDepartment || []
      }]
    }, () => {
      this.getJobs()
      this.fetchOrders()
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.main) !== JSON.stringify(this.props.main)) {
      const mainMap = getMainMap(this.props.main)
      this.setState({ mainMap })
    }
  }

  getJobs () {
    const { slug, loggedUser, readOnlyInputs } = this.state
    if (slug !== 'add') {
      fetch(`${API_SERVER}/api/get-jobs`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          _id: slug,
          person: loggedUser
        })
      })
        .then(res => res.json())
        .then(response => {
          if (response.length) {
            if (slug !== 'add') { readOnlyInputs.push(...READ_ONLY_INPUTS_EDIT_ACTION) }
            if (response[0].end !== '') { readOnlyInputs.push('end') }
            this.setState({
              jobs: response,
              job_order_id: response[0].order_id,
              readOnlyInputs
            })
          } else {
            this.showNotification('error', 'Wystąpił błąd')
            console.error('Error:', response)
          }
        })
        .catch(error => {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', error)
        })
    }
  }

  fetchOrders () {
    const { loggedUser, jobsWhereUserIsResponsiblePerson } = this.state
    fetch(`${API_SERVER}/api/get-orders`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        person: loggedUser,
        jobsWhereUserIsResponsiblePerson,
        active: true
      })
    })
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          this.setState({
            orders: response.success,
            loader: false
          })
        } else {
          this.showNotification('error', 'Wystąpił błąd' + response && response.error)
          console.error('Error:', response && response.error)
          this.setState({ loader: false })
        }
      })
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
        this.setState({ loader: false })
      })
  }

  allowedDepartments (allDepart, allowed) {
    return allDepart.filter(el => allowed.includes(el.ID))
  }

  allowedUsers (allUsers = [], allowed) {
    const { isFinance } = this.state
    const filteredUsers = allUsers.filter(el => (el.scDepartment || []).includes(allowed))
    return !isFinance ? onlyActiveUsers(filteredUsers) : filteredUsers
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  renderOrderDetailsTable () {
    const { jobs = [], job_order_id, orders = [], mainMap } = this.state
    const thisOrder = orders.find(el => el.order_id === job_order_id) || jobs[0] || {}

    if (thisOrder.order_id) {
      return (
        <Table striped hover style={{ marginBottom: 30 }}>
          <thead>
            <tr>
              <th>ID zlecenia</th>
              <th>Nazwa zlecenia</th>
              <th>Zleceniodawca</th>
              <th>Klient</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ opacity: 0.5 }}>
              <td>{thisOrder.order_id}</td>
              <td>{thisOrder.order_name}</td>
              <td>{getMapLabel(mainMap.companies, thisOrder.company)}</td>
              <td>{thisOrder.client}</td>
              <td>{(thisOrder.orderStatus || '') || `${thisOrder.status || ''}  ${thisOrder.endDate || ''}`}</td>
            </tr>
          </tbody>
        </Table>
      )
    }
  }

  renderJobDetailsTable () {
    const { colParams, slug, jobs = [] } = this.state
    const dontShow = ['_id']
    const style = col => ({
      display: dontShow.includes(col) ? 'none' : ''
    })

    return (
      Object.keys(colParams).map((row, key) => (
        <tr key={key} style={style(row)}>
          <td><label>{colParams[row] ? colParams[row].pl : row}</label></td>
          <td>
            {this.getRowValues(row, slug, colParams, jobs.length ? jobs[0] : {})}
          </td>
        </tr>
      ))
    )
  }

  dataValidation () {
    const { jobs, requiredInputs } = this.state
    const { start, end } = jobs[0]
    const formValid = requiredInputs.every(prop => jobs[0][prop] && (jobs[0][prop] !== 'undefined' && jobs[0][prop] !== '' && jobs[0][prop].length > 0))
    this.setState({ formValid })
    if (formValid && !!end && moment(start).isAfter(end)) {
      this.showNotification('error', 'Data startu poźniejsza od daty zakończenia')
      this.setState({ formValid: false })
    }
  }

  checkInvalidInput (val, row) {
    const { requiredInputs } = this.state
    if (!this.state.formValid && requiredInputs.includes(row) && (!val)) {
      return 'error'
    }
  }

  getRowValues (row, slug, colParams, data = {}) {
    let options; let initValue = {}
    const { formValid, disabled, readOnlyInputs, isFinance, mainMap } = this.state

    if (colParams[row].type === 'select') {
      let source = this.props.main[colParams[row].source] || []

      if (['main_depart', 'from_depart', 'resp_depart'].includes(row)) {
        const arrDeparts = row !== 'resp_depart'
          ? data[`${[row]}_arr`] || []
          : Object.keys(allowedRespDepartments)

        source = isFinance
          ? this.allowedDepartments(source, Object.keys(allowedRespDepartments))
          : this.allowedDepartments(source, arrDeparts)
      }

      const { main_depart, from_depart, resp_depart } = data
      if (row === 'main_person') { source = this.allowedUsers(source, main_depart) }
      if (row === 'from_person') { source = this.allowedUsers(source, from_depart) }
      if (row === 'resp_person') { source = this.allowedUsers(source, resp_depart) }

      options = source.map(item => {
        return ({
          value: item.ID,
          label: item.NAME
        })
      })

      initValue = {
        value: data[row],
        label: getMapLabel(mainMap[colParams[row].source], data[row])
      }
    }

    if (colParams[row].type === 'select') {
      return (
        <div>
          <Select
            options={options}
            name={row}
            value={initValue}
            className={this.checkInvalidInput(data[row] || '', row)}
            styles={selectStyles}
            isDisabled={!isFinance && (readOnlyInputs.includes(row) || disabled)}
            onChange={(e) => {
              data[row] = e.value
              if (row === 'main_depart') { delete data.main_person }
              if (row === 'from_depart') { delete data.from_person }
              if (row === 'resp_depart') { delete data.resp_person }
              this.setState(() => ({ jobs: [data] }))
              if (!formValid) this.dataValidation()
            }}
          />
          <input
            type='hidden'
            name={row}
            value={data[row]}
            readOnly
          />
        </div>
      )
    } else
    if (colParams[row].type === 'date') {
      const { dateFocus } = this.state

      const isOutsideRange = (date, input) => {
        if (input === 'end' && data.start !== '') {
          if (slug !== 'add') {
            return date < moment(data.start) || date < moment().date(1).hour(1)
          } else {
            return date < moment(data.start)
          }
        } else {
          return date < moment('2019-01-01')
        }
      }

      return (
        <div>
          <SingleDatePicker
            date={data[row] && data[row] !== 'Invalid date' ? moment(data[row]) : null}
            onDateChange={date => {
              data[row] = moment(date).format('YYYY-MM-DD')
              this.setState({ jobs: [data] })
              if (!formValid) this.dataValidation()
            }}
            focused={this.state.dateFocus[row]}
            onFocusChange={({ focused }) => {
              dateFocus[row] = focused
              return (this.setState({ dateFocus }))
            }}
            id={row}
            placeholder={null}
            displayFormat='YYYY-MM-DD'
            disabled={!isFinance && (readOnlyInputs.includes(row) || disabled)}
            showClearDate
            small
            readOnly={!isFinance}
            showDefaultInputIcon
            isOutsideRange={(date) => isOutsideRange(date, row)}
            numberOfMonths={1}
          />
          <div
            style={{ width: 178 }}
            className={this.checkInvalidInput(data[row] || '', row)}
          />
        </div>
      )
    } else {
      return (
        <FormControl
          type='text'
          name={row}
          value={data[row]}
          className={this.checkInvalidInput(data[row] || '', row)}
          readOnly={!isFinance && (readOnlyInputs.includes(row)) ? 'readonly' : ''}
          onChange={(e) => {
            data[row] = e.target.value
            this.setState(() => ({ jobs: [data] }), (!formValid ? this.dataValidation() : ''))
          }}
        />
      )
    }
  }

  renderOrdersTable () {
    const { orders = [], jobs = [], mainMap } = this.state

    return orders.map((el, key) =>
      <tr key={key}>
        <td>{key + 1}</td>
        <td>{el.order_id}</td>
        <td>{el.order_name}</td>
        <td>{getMapLabel(mainMap.companies, el.company)}</td>
        <td>{el.client}</td>
        <td><input
          type='button'
          className='btn btn-default btn-sm'
          value='Nowe podzlecenie'
          onClick={() => {
            jobs[0].order_id = el.order_id
            jobs[0].main_person = el.responsible_person
            jobs[0].main_depart = el.departments.filter(el2 => allowedFromDepartments.includes(el2))[0] + '' || ''
            jobs[0].main_depart_arr = el.departments || []
            jobs[0].from_depart = el.departments.filter(el2 => allowedFromDepartments.includes(el2))[0] + '' || ''
            if (this.state.jobsWhereUserIsResponsiblePerson === 1) {
              jobs[0].from_depart = el.departments.filter(el2 => el2 === (this.state.loggedUser.scDepartment || [])[0] + '' || '') || ''
            }
            jobs[0].from_depart_arr = el.departments || []
            this.setState({
              jobs,
              job_order_id: el.order_id
            })
          }}
            />
        </td>
      </tr>
    )
  }

  filterOrderOwner () {
    const options = [
      { value: 0, label: 'Aktywne zlecenia - jestem opiekunem' },
      { value: 1, label: 'Aktywne zlecenia - które realizuję' }
    ]

    return (
      <Select
        options={options}
        name='jobsWhereUserIsResponsiblePerson'
        value={options[this.state.jobsWhereUserIsResponsiblePerson]}
        styles={{ container: styles => ({ ...styles, width: 300, fontSize: 14 }) }}
        onChange={(e) => {
          this.setState(() => ({ jobsWhereUserIsResponsiblePerson: e.value, loader: true }), () => this.fetchOrders())
        }}
      />
    )
  }

  handleSubmit (e) {
    const { slug, formValid } = this.state
    e.preventDefault()
    e.stopPropagation()

    this.dataValidation()
    if (formValid) {
      this.showNotification('info', 'Wysyłanie danych')
      const params = new FormData(e.target)
      const inputValues = {}

      let pair
      for (pair of params.entries()) {
        inputValues._id = slug === 'add' ? null : slug
        inputValues[pair[0]] = pair[1]
      }

      fetch(`${API_SERVER}/api/save-job`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(inputValues)
      })
        .then(res => res.json())
        .catch(error => {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', error)
        })
        .then(response => {
          if (response && response.success) {
            this.showNotification('success', 'Dane zostały zapisane', 6)
            if (slug === 'add') {
              this.setState(() => ({ redirect: true }))
            }
          } else {
            this.showNotification('error', response.error || 'Wystąpił błąd.')
            console.error('Error:', response)
          }
        })
    } else {
      this.showNotification('error', 'Formularz zawiera błędy')
    }
  }

  render () {
    const { slug, loader, job_order_id } = this.state
    if (this.state.redirect) { return <Redirect to='/settlements/jobs' /> }
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Loader display={loader} />
        <form className='form-inline' onSubmit={this.handleSubmit}>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  content={
                    <div className='breadcrumb'>
                      <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                        to='/settlements/jobs'
                                                                       >Lista podzleceń
                      </Link> » <Link
                                                                         to={this.props.location.pathname}
                                                                                 >Szczegóły podzlecenia
                                                                                 </Link>
                    </div>
                  }
                />
              </Col>
            </Row>
            <div className={loader ? 'noEvents' : ''}>
              <Row>
                <Col md={12}>
                  <nav className='navbar navbar-light bg-light'>
                    <div
                      className='btn-group'
                      role='group'
                      aria-label='Basic example'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {
                        (slug !== 'add' || job_order_id)
                          ? <input
                              type='submit'
                              className='btn btn-primary'
                              value='zapisz'
                              onClick={() => {
                                this.dataValidation()
                              }}
                            />
                          : ''
                      }
                      {slug === 'add' && !job_order_id ? this.filterOrderOwner() : null}
                    </div>
                  </nav>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {
                    (slug === 'add' && !job_order_id)
                      ? <Card
                          title='Dodaj podzlecenie - Krok 1'
                          ctTableFullWidth
                          ctTableResponsive
                          content={
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th>Lp</th>
                                  <th>ID zlecenia</th>
                                  <th>Nazwa zlecenia</th>
                                  <th>Zleceniodawca</th>
                                  <th>Klient</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.renderOrdersTable()}
                              </tbody>
                            </Table>
                        }
                        />
                      : <Card
                          title={slug === 'add' ? 'Dodaj podzlecenie - Krok 2' : 'Szczegóły podzlecenia'}
                          content={
                            <div>
                              {this.renderOrderDetailsTable()}
                              <Table striped hover>
                                <tbody>
                                  {this.renderJobDetailsTable()}
                                </tbody>
                              </Table>
                            </div>
                        }
                        />
                  }
                </Col>
              </Row>
            </div>
          </Grid>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main
  }
}

export default connect(mapStateToProps)(JobDetails)
