import React from 'react'

export default function ThankYouPage ({ summary }) {
  const { results, totalPoints } = summary
  const sortedResults = Object.values(results).sort((a, b) => b.points - a.points)

  return (
    <>
      <h2 className='title'>Gratulacje! 🎉</h2>
      <p className='text'>Twoje wyniki są gotowe. Zobacz, do jakiego profilu Buyer Person przypisałam Twojego Klienta i zapoznaj się z ich  szczegółowym opisem, aby lepiej zrozumieć ich potrzeby i preferencje.</p>
      <p className='text'>Dziękuję Ci za udział w quizie i życzę sukcesów w wykorzystaniu zdobytej wiedzy w pracy! 🚀</p>
      <p className='text'>Twój wynik to:</p>
      {sortedResults.map(item => (<p className='text' key={item.name}><b>{item.name} : {item.points} / {totalPoints}</b></p>))}
      <p className='text'>Szczegółowy opis możesz sprawdzić tu: <a target='_blank' href='https://docs.google.com/presentation/d/1WaFwLO5DNWQNdTHNG3z-Q4WJ61PC2rbl9JMmmrv5Zlw/edit' rel='noreferrer'>Buyer Persony dla agencji digital</a></p>
    </>
  )
}
