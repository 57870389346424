import React from 'react'
import { checkAuthIdUser, checkIsUPUser, checkUserType } from '../../authData'
import { Tab, Tabs } from 'react-bootstrap'
import UPReport from './UPReport'

const tabs = [
  { name: 'default', label: 'Raport miesięczny', component: UPReport }
  // {name: 'users', label: 'Raport pracowników', component: UPUsers},
]

export default ({
  loggedUser,
  setLoader,
  setInfo,
  date,
  setData,
  data,
  generateHandle,
  setGenerateHandle,
  setConfirmAllowed
}) => {
  /* auth */
  const isFinance = checkUserType(loggedUser, 'finance')
  const isDev = checkUserType(loggedUser, 'dev')
  const isUP = checkIsUPUser(loggedUser, date)
  const isAuthIdUser = checkAuthIdUser(loggedUser,
    ['200'] // Cezary Śledziak
  )

  return (isFinance || isDev || isAuthIdUser || isUP)
    ? <Tabs defaultActiveKey='default' id='tabs' style={{ marginTop: 10 }}>
      {
        tabs.map((tab, keyT) =>
          <Tab eventKey={tab.name} title={tab.label} key={keyT}>
            <tab.component
              loggedUser={loggedUser}
              setLoader={setLoader}
              setInfo={setInfo}
              date={date}
              setData={setData}
              data={data}
              generateHandle={generateHandle}
              setGenerateHandle={setGenerateHandle}
              setConfirmAllowed={setConfirmAllowed}
            />
          </Tab>)
      }
      </Tabs>
    : <div className='content SalaryGrid'>
      <div>Brak dostępu</div>
      </div>
}
