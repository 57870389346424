module.exports = {
  ACTIVE: { pl: 'Stan', width: '2%', tooltip: 'Status konta użytkownika Bitrix' },
  ID: { pl: 'ID', width: '3%', tooltip: 'Identyfikator użytkownika Bitrix' },
  NAME: { pl: 'nazwisko i imię', width: '8%', type: 'text', req: true, tooltip: 'Nazwisko i imię' },
  scDepartment: { pl: 'Dział', width: '5%', tooltip: 'Dział danej osoby. W przypadku osób z Top Managementu, dział Top Management musi być wybrany jako pierwszy' },
  scHead: { pl: 'podległe osoby', width: '26%', type: 'select', tooltip: 'Osoby formalnie podległe pod osobę, co umożliwia dostęp do alertów, rozliczeń i raportów rentowności tych osób ' },
  scSalary: { pl: 'poz. stan.', width: '3%', type: 'salary', tooltip: 'Poziom stanowiska w strukturze firmy' },
  scWage: { pl: 'Poz. płac', width: '3%', tooltip: 'Poziom wynagrodzenia w strukturze firmy' },
  scMatrix: { pl: 'Mat ryca', width: '3%', tooltip: '' },
  scTeamAlerts: { pl: 'ALERT', width: '3%', type: 'checkbox', tooltip: 'Włączanie otrzymywania kopii alertów wysyłanych mailem do podległych osób' },
  scEstimactionNull: { pl: 'PUS. EST.', width: '2%', type: 'checkbox', tooltip: 'Puste estymacje klientów, którzy byli zaplanowani w budżecie, ale współpraca w danym roku budżetowym zakończyłą się' },
  scExcluded: { pl: 'wykl.', width: '3%', type: 'checkbox', tooltip: 'Wykluczanie błędnych, testowych i tymczasowych kont użytkowników Bitrix z raportów i skryptów ' },
  customWorktime: { pl: 'zm. wym.', width: '3%', type: 'checkbox', tooltip: 'Zmienny (nierównomierny) dzienny wymiar czasu pracy. Po włączeniu wymiar dzienny dla takiej osoby pobierany jest z Arkusza Google z Urlopami' },
  startDate: { pl: 'Data start', width: '5%', type: 'date', tooltip: 'Data rozpoczęcia pracy' },
  endDate: { pl: 'Data stop', width: '5%', type: 'date', tooltip: 'Data zakończenia pracy' },
  status: { pl: 'status', width: '5%', type: 'statusSelect', tooltip: 'Status zatrudnienia' },
  importance: { pl: 'Ważność', width: '5%', type: 'importanceSelect', tooltip: 'Ważność osoby dla całej organizacji' },
  contractType: { pl: 'forma', width: '4%', tooltip: 'Forma zatrudnienia danej osoby' },
  mode: { pl: 'tryb', width: '5%', type: 'modeSelect', tooltip: 'Tryb pracy' },
  birthday: { pl: 'urodziny', width: '5%', type: 'date', tooltip: 'Data urodzenia' },
  save: { width: '5%' }
}
