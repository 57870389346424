const moment = require('moment')
const format2d = number => (`0${number}`).slice(-2)

const toHours = (seconds, long = false) => {
  let result = ''

  seconds = Math.abs(seconds)
  const hh = parseInt(seconds / 3600, 10)
  const mm = Math.ceil((seconds - (hh * 3600)) / 60)
  if (long && hh < 10) {
    result += '0'
  }
  result += hh
  result += ':' + format2d(mm)
  if (long) {
    result += ':00'
  }
  return seconds !== undefined ? result : ''
}

const toShortHours = time => {
  let result = ''

  const seconds = toSeconds(time || '')
  let hh = parseInt(seconds / 3600, 10)
  let mm = Math.ceil((seconds - (hh * 3600)) / 60)
  if (mm === 60) {
    hh += 1
    mm = 0
  }
  result += hh
  result += ':' + format2d(mm)
  return result
}

const toSeconds = hours => {
  const hourSplit = (hours && hours.toString().split(':')) || []
  const hour = parseInt(hourSplit[0] || '0', 10) || 0
  const minutes = parseInt(hourSplit[1] || '0', 10) || 0
  const seconds = parseInt(hourSplit[2] || '0', 10) || 0
  const multiplier = (hourSplit[0] && hourSplit[0].charAt(0) === '-') ? -1 : 1
  return (((Math.abs(hour) * 60) + minutes) * 60 + seconds) * multiplier
}

const toShortHoursAndSeconds = hours => {
  const a = toShortHours((hours && hours.toString()) || '').split(':') || []
  return (+a[0]) * 60 * 60 + (+a[1]) * 60
}

const momentToShortHours = hours => hours ? moment(hours).format('HH:mm') : ''

const isFriday = day => (moment(day) >= moment('2021-10-06') && (moment(day).day() === 5))

module.exports = {
  toHours,
  toShortHours,
  toSeconds,
  toShortHoursAndSeconds,
  momentToShortHours,
  isFriday
}
