import React, { Component } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import Card from '../../../components/Card/Card.jsx'
import NotificationSystem from 'react-notification-system'
import connect from 'react-redux/es/connect/connect'
import { style } from '../../../variables/Variables'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import ordersExample from './orders_example.csv'
import jobsExample from './jobs_example.csv'
import settlementsExample from './settlements_example.csv'
import { API_SERVER, checkUserType } from '../authData'

class UploadCSV extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      select: { value: '', label: '' },
      loggedUser: props.user
    }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    this.setState({ isFinance })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'tr',
      autoDismiss
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    e.stopPropagation()

    this.showNotification('info', 'Wysyłanie danych')
    const params = new FormData(e.target)

    fetch(`${API_SERVER}/api/upload-csv`, {
      method: 'POST',
      body: params
    }).then(response => response.json())
      .catch((error) => {
        this.showNotification('error', `Wystąpił błąd: ${JSON.stringify(error)}`)
        console.error('Error:', error)
      })
      .then((response) => {
        if (response && response.error) {
          this.showNotification('error', `Wystąpił błąd: ${JSON.stringify(response.error)}`)
          console.log('Error:', response)
        } else if (response && response.success) {
          this.showNotification('success', `Zapisane: ${response.success} pozycji`, 6)
        }
      })
  }

  renderSelect () {
    const options = [
      { value: 'Orders', label: 'Zlecenia' },
      { value: 'Jobs', label: 'Podzlecenia' },
      { value: 'Settlements', label: 'Rozliczenia' }
    ]
    return (
      <div style={{ width: 200 }}>
        <Select
          options={options}
          name='db'
          isClearable
          placeholder='Wybierz bazę...'
          onChange={(e) => {
            this.setState(() => ({
              select: {
                value: e ? e.value : null,
                label: e ? e.label : null
              }
            }))
          }}
        />
      </div>
    )
  }

  renderFileInput () {
    return (
      <label className='form-control btn' style={{ width: '120px', margin: 5 }}>
        Wybierz plik
        <input
          type='file'
          name='file'
          id='file'
          style={{ display: 'none' }}
        />
      </label>
    )
  }

  renderSaveButton () {
    return (
      <input
        type='submit'
        className='btn btn-primary btn-sm'
        style={{ fontSize: 14 }}
        value='Wyślij bezpośrednio do bazy'
        name='save'
      />

    )
  }

  render () {
    const { isFinance } = this.state
    if (isFinance || ['360'].includes(this.props.user.ID)) {
      return (
        <div className='content UploadCSV'>
          <NotificationSystem ref='notificationSystem' style={style} />
          <form className='form-inline' onSubmit={this.handleSubmit}>
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Card
                    content={
                      <div className='breadcrumb'>
                        <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                          to='/settlements/office-clients'
                                                                         >UploadCSV
                        </Link>
                      </div>
                     }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Card
                    title='Upload CSV'
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <div style={{ padding: 20 }}>
                        <p style={{ fontSize: 13 }}>
                          Dodawaj i aktualizuj rekordy bezpośrednio do bazy.<br />
                          <br />
                          Wybierz bazę, która aktualizujesz.<br />
                          Prześlij sformatowany plik CSV z nagłówkami, dane oddzielane średnikami, wg wzorów poniżej:<br />
                          <a href={ordersExample}>Przykład csv Zlecenia</a><br />
                          <a href={jobsExample}>Przykład csv Podzlecenia</a><br />
                          <a href={settlementsExample}>Przykład csv Rozliczenia</a> <span style={{ color: 'red', fontWeight: 'bold' }}>UWAGA! od 04.11 nowy wzór</span><br />
                          <br />
                          <span style={{ color: 'red' }}>UWAGA! Pracujesz bezpośrednio na bazie. Wysłanych zmian nie można cofnąć!</span>
                        </p>
                        <br />
                        {this.renderSelect()}
                        {this.renderFileInput()}
                        {this.renderSaveButton()}
                        <div>
                          <br />
                          <br />
                          <span style={{ fontSize: 13 }}>Linki CSV:</span><br />
                          <p style={{ fontSize: 13 }}>kodowanie UTF-8 oddzielone średnikiem</p>
                          <a href='../api/settlements/orders.csv' target='_blank'>CSV Zlecenia</a><br />
                          <a href='../api/settlements/jobs.csv' target='_blank'>CSV Podlecenia</a><br />
                          <a href='../api/settlements/settlements.csv' target='_blank'>CSV Rozliczenia</a><br />
                          <a href='../api/settlements/invoices.csv' target='_blank'>CSV Faktury</a><br />
                          <a href='../api/settlements/invoices-det.csv' target='_blank'>CSV Faktury szczegóły</a><br />
                          <a href='../api/settlements/estimations.csv' target='_blank'>CSV Estymacje</a><br />
                          <br />
                          <a href='../api/settlements/companies.csv' target='_blank'>CSV Klienci</a><br />
                          <a href='../api/settlements/users.csv' target='_blank'>CSV Użytkownicy</a><br />
                          <a href='../api/settlements/departments.csv' target='_blank'>CSV Działy</a><br />
                          <br />
                          <br />
                          <p style={{ fontSize: 13 }}>NOWOŚĆ - CSV Podlecenia z dodatkową kolumną "Rozliczonych" (w url podaj za jaki miesiąc)</p>
                          <a href='../api/settlements/jobs.csv?month=2021-01' target='_blank'>CSV Podlecenia 1 miesiąc</a><br />
                        </div>
                      </div>
                         }
                  />
                </Col>
              </Row>
            </Grid>
          </form>
        </div>
      )
    } else {
      return (
        <div>Brak dostępu</div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(UploadCSV)
