export default () => ({
  labels: {
    company: 'firma',
    firstName: 'imię',
    lastName: 'nazwisko',
    email: 'email',
    address: 'adres',
    city: 'miasto',
    zipCode: 'kod pocztowy',
    countryCode: 'kod kraju',
    languageCode: 'kod języka',
    currencyCode: 'kod waluty',
    fax: 'fax',
    phone: 'telefon',
    skypeName: 'nazwa skype',
    labels: 'etykiety',
    newsletterSettings: 'ustawienia newslettera',
    status: 'status',
    agreement1: 'zgoda 1',
    agreement2: 'zgoda 2',
    agreement3: 'zgoda 3',
    SMID: 'SMID',
    NSID: 'NSID',
    ITID: 'ITID',
    comment: 'komentarz',
    partner: 'partner',
    polecajacy: 'polecajacy',
    hash: 'hash',
    codesVerification: 'weryfikacja kodów',
    codesVerificationComment: 'weryfikacja kodów - komentarz',
    contractsTypes: 'rodzaje umów',
    contractsInfo: 'szczegóły umów'
  },
  data: {
    company: '',
    firstName: 'req',
    lastName: 'req',
    email: 'req',
    address: '',
    city: 'req',
    zipCode: 'req',
    countryCode: 'req',
    languageCode: 'req',
    currencyCode: 'req',
    fax: '',
    phone: '',
    skypeName: '',
    labels: '',
    newsletterSettings: '',
    status: '',
    agreement1: '',
    agreement2: '',
    agreement3: '',
    SMID: '',
    NSID: '',
    ITID: '',
    comment: '',
    partner: '',
    polecajacy: '',
    hash: '',
    codesVerification: '',
    codesVerificationComment: '',
    contractsTypes: '',
    contractsInfo: ''
  },
  adSpace: {
    categoryId: 'req',
    subcategories: '',
    description: '',
    name: 'req',
    websiteUrl: 'req'
  }
})
