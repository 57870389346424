import React from 'react'
import '../../style.css'

export default (prop) => (
  <div
    className='lds-dual-ring'
    id='spinner'
    style={{
      display: `${prop.display ? 'block' : 'none'}`,
      zIndex: 99999
    }}
  />
)
