import React from 'react'
import './ToggleSwitch.css'

const ToggleSwitch = (props) => {
  const { checked, name, label, className = '', style = {}, title = '' } = props
  return (
    <div
      className={`switch-container ${className}`}
      style={style}
      onClick={(e) => {
        e.preventDefault()
        props.toggleSwitch()
      }}
    >
      <label title={title}>
        <div className='switch'>
          <input
            type='checkbox'
            name={name}
            checked={checked || false}
            onChange={() => {}}
          />
          <span className='slider round' />
        </div>
        {label}
      </label>
    </div>
  )
}

export default ToggleSwitch
