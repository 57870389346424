import 'react-day-picker/lib/style.css'

import _ from 'lodash/fp'
import moment from 'moment'
import React, { Component } from 'react'
import { Button, Col, Grid, Row, Table } from 'react-bootstrap'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import NotificationSystem from 'react-notification-system'
import { connect } from 'react-redux'
import Select from 'react-select'

import { style } from '../../variables/Variables'
import Card from '../../components/Card/Card.jsx'
import officesSetUp from './officesSetUp'
import Warsaw from '../../assets/img/biurka_warszawa.png'
import Gdansk from '../../assets/img/biurka_gdansk.png'

const img = { Warsaw, Gdansk }

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'

class DesksBooking extends Component {
  constructor (props) {
    super(props)
    this.showNotification = this.showNotification.bind(this)
    this.state = {
      loader: true,
      chosenCity: props.user.defaultOffice || 'Warsaw',
      officesSetUp
    }
  }

  componentDidMount () {
    this.getOfficeOccupancy()
  }

  getWeekDays (chosenDay) {
    const startOfWeek = moment(chosenDay).startOf('week')
    const endOfWeek = moment(chosenDay).endOf('week')

    const days = []
    let day = startOfWeek

    while (day <= endOfWeek) {
      days.push(moment(day).format('YYYY-MM-DD'))
      day = moment(day).clone().add(1, 'd')
    }

    return { weekDays: days, weekStart: days[0], weekEnd: days[6] }
  }

  getOfficeOccupancy (chosenDay = this.state.weekStart) {
    const days = this.getWeekDays(chosenDay)
    const query = {
      where: {
        and: [
          { date: { in: days.weekDays } },
          { office: this.state.chosenCity }
        ]
      }
    }

    fetch(`${API_SERVER}/db/api/offices_occupancy?filter=${JSON.stringify(query)}`)
      .then(res => res.json())
      .then(response => {
        this.setState({ occupancy: response })
      })
      .catch(err => {
        console.error(err)
        this.showNotification('error', 'Wystąpił błąd')
      })
      .finally(() => {
        this.setState({ loader: false, ...days })
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      warning: 'pe-7s-info'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  changeCity (city) {
    this.setState({ loader: true })
    const { user } = this.props
    const changes = {
      ID: user.ID,
      defaultOffice: city
    }

    fetch(`${API_SERVER}/api/change-city`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ changes })
    })
      .then(res => res.json())
      .then(response => {
        if (!response || response.error) {
          console.error('Error:', response.error)
          this.showNotification('error', 'Wystąpił błąd')
        }
        if (response.success) {
          console.log('Success:', response)
        }
      })
      .catch(error => {
        console.error('Error:', error)
        this.showNotification('error', 'Wystąpił błąd')
      })
      .finally(() => {
        this.setState({ chosenCity: city }, () => {
          this.getOfficeOccupancy()
        })
      })
  }

  bookDesk (date, user, office, roomName, desk) {
    fetch(`${API_SERVER}/api/book-desk`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ date, user, office, roomName, desk })
    })
      .then(res => res.json())
      .then(response => {
        if (!response || response.error) {
          console.error('Error:', response.error)
          this.showNotification('error', response.error ? response.error : 'Wystąpił błąd')
        }
        if (response.success) {
          console.log('Success:', response)
          this.showNotification('success', response.message)
        } else if (response.taken) {
          console.log('Taken:', response)
          this.showNotification('warning', response.message)
        }
      })
      .catch(error => {
        console.error('Error:', error)
        this.showNotification('error', 'Wystąpił błąd')
      })
      .finally(() => {
        this.getOfficeOccupancy()
      })
  }

  cancelDeskBooking (date, office, roomName, desk) {
    fetch(`${API_SERVER}/api/cancel-desk-booking`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ date, office, roomName, desk })
    })
      .then(res => res.json())
      .then(response => {
        if (!response || response.error) {
          console.error('Error:', response.error)
          this.showNotification('error', 'Wystąpił błąd')
        }
        if (response.success) {
          console.log('Success:', response)
          this.showNotification('success', response.message)
        }
      })
      .catch(error => {
        console.error('Error:', error)
        this.showNotification('error', 'Wystąpił błąd')
      })
      .finally(() => {
        this.getOfficeOccupancy()
      })
  }

  renderCellContent (date, desk, roomName) {
    const { user } = this.props
    const { occupancy, chosenCity } = this.state

    const currentDate = moment(date)
    const today = moment().startOf('day')

    const userName = user.LAST_NAME
      ? `${user.LAST_NAME} ${user.NAME}`
      : user.NAME
    const bookedBy = _.get(
      ['rooms', roomName, desk],
      occupancy.find((days) => days.date === date)
    )
    const isBookedByUser = userName === bookedBy

    switch (true) {
      case currentDate >= today && !bookedBy:
        return (
          <input
            type='button'
            className='btn btn-secondary btn-xs hover-button'
            value='Rezerwuj'
            title='Rezerwuj'
            onClick={() => {
              this.setState({ loader: true })
              this.bookDesk(date, userName, chosenCity, roomName, desk)
            }}
          />
        )

      case currentDate >= today && isBookedByUser:
        return (
          <input
            type='button'
            className='btn btn-success btn-xs hover-button'
            value={bookedBy}
            title='Kliknij żeby odwołać rezerwację'
            onClick={() => {
              this.setState({ loader: true })
              this.cancelDeskBooking(date, chosenCity, roomName, desk)
            }}
          />
        )

      case !!bookedBy:
        return bookedBy

      default:
        return ''
    }
  }

  renderCols (date, desk, roomName) {
    const weekEnd = this.state.weekDays.slice(-2)

    return (
      <td
        key={`${date}-${desk}-${roomName}`}
        style={{ width: '12.5%', borderLeft: '1px solid #ddd' }}
        className={weekEnd.includes(date) ? 'bg-grey' : ''}
      >
        {!weekEnd.includes(date) ? this.renderCellContent(date, desk, roomName) : ''}
      </td>
    )
  }

  renderRow (dates, roomName, room) {
    const desks = room.desks
    return desks.map((desk) => (
      <tr key={`${roomName}-${desk}`}>
        <td style={{ width: '12.5%' }}>
          Biurko {desk}
        </td>
        {dates.map((date) => this.renderCols(date, desk, roomName))}
      </tr>
    ))
  }

  renderHeadRow (dates) {
    return (
      <tr className='bg-grey'>
        <td style={{ width: '12.5%' }}>
          <b>Biurka</b>
        </td>
        {dates.map((date) => <td key={date} style={{ width: '12.5%' }}><b>{moment(date).format('dddd').toUpperCase()}<br />{date}</b></td>)}
      </tr>
    )
  }

  renderSingleTable (officeSetUp, roomName) {
    const { weekDays = [] } = this.state
    const room = officeSetUp.rooms[roomName]

    return (
      <div key={roomName} style={{ marginTop: 25 }}>
        <div>
          <h5 style={{ margin: '25px 0' }}>
            Pokój: <b>{room.displayName}</b>
          </h5>
        </div>
        <Table hover className='text-center' style={{ border: '1px solid #ddd' }}>
          <thead>
            {this.renderHeadRow(weekDays)}
          </thead>
          <tbody>
            {this.renderRow(weekDays, roomName, room)}
          </tbody>
        </Table>
      </div>
    )
  }

  renderTables () {
    const { officesSetUp, chosenCity } = this.state
    const officeSetUp = officesSetUp[chosenCity]

    return Object.keys(officeSetUp.rooms).map((roomName) => (
      this.renderSingleTable(officeSetUp, roomName)
    ))
  }

  render () {
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Grid fluid>
          <Row className='desksBookingControls'>
            <Col xs={12} lg={4}>
              <Row>
                <Col xs={12} style={{ marginBottom: '15px' }}>
                  <Select
                    options={[{ value: 'Warsaw', label: 'Warszawa' }, { value: 'Gdansk', label: 'Gdańsk' }]}
                    defaultValue={{ value: this.state.chosenCity, label: this.state.officesSetUp[this.state.chosenCity].displayName }}
                    styles={{ control: styles => ({ ...styles, width: 265, fontSize: 14 }) }}
                    onChange={(e) => {
                      this.changeCity(e.value)
                    }}
                  />
                </Col>
                <Col xs={12} style={{ marginBottom: '15px' }}>
                  <Button
                    className='btn btn-sm btn-secondary'
                    onClick={() => {
                      this.setState({ loader: true }, () => this.getOfficeOccupancy(moment(this.state.weekStart).subtract(7, 'd')))
                    }}
                  >
                    <i class='fas fa-angle-double-left' />
                  </Button>
                  <DayPickerInput
                    inputProps={{
                      id: 'desksDateSelect',
                      name: 'dateSelect'
                    }}
                    value={`${this.state.weekStart} - ${this.state.weekEnd}`}
                    formatDate={date => moment(date).format('YYYY-MM-DD')}
                    dayPickerProps={
                      {
                        firstDayOfWeek: 1,
                        disabledDays: day => {
                          return day >= moment(this.state.weekStart) && day <= moment(this.state.weekEnd).add(1, 'd')
                        }
                      }
                    }
                    onDayChange={date => {
                      this.getOfficeOccupancy(date)
                    }}
                  />
                  <Button
                    className='btn btn-sm btn-secondary'
                    onClick={() => {
                      this.setState({ loader: true }, () => this.getOfficeOccupancy(moment(this.state.weekStart).add(7, 'd')))
                    }}
                  >
                    <i class='fas fa-angle-double-right' />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={8}>
              <Card
                title={`Mapa ${this.state.officesSetUp[this.state.chosenCity].displayName}`}
                content={<img src={img[this.state.chosenCity]} alt='biurka' style={{ maxWidth: '100%' }} />}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title={this.state.officesSetUp[this.state.chosenCity].displayName}
                content={
                  <div className='table-responsive'>
                    {this.renderTables()}
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        <div id='own-preloader' className={`${this.state.loader === true ? 'show' : ''}`}>
          <div id='own-loader' />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(DesksBooking)
