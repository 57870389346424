import React, { useState } from 'react'

export default function MultiChoiceQuestion ({ questionData, currentStep, answers = [], setAnswer, validate }) {
  const choices = questionData.choices.reduce((acc, choice) => {
    choice.profiles.forEach(profile => {
      if (!acc[choice.answer]) {
        acc[choice.answer] = []
      }
      acc[choice.answer].push(profile)
    })
    return acc
  }, {})

  const [selectedChoices, setSelectedChoices] = useState(answers.map(answer => answer.answer))

  const handleChange = (answer) => {
    const isSelected = selectedChoices.includes(answer)

    if (!isSelected && selectedChoices.length >= 4) {
      validate()
    } else {
      const newSelectedChoices = isSelected
        ? selectedChoices.filter(c => c !== answer)
        : [...selectedChoices, answer]

      setSelectedChoices(newSelectedChoices)

      const newAnswers = newSelectedChoices.map(answer => ({
        answer,
        profiles: choices[answer]
      }))

      setAnswer(currentStep, newAnswers)
    }
  }

  return (
    <div className='multi-choice'>
      {Object.keys(choices).map((answer, index) => (
        <div
          className={`multi-choice--card ${selectedChoices.includes(answer) ? 'selected' : ''}`}
          key={index}
        >
          <input
            type='checkbox'
            id={`choice-${index}`}
            name='choice'
            value={answer}
            checked={selectedChoices.includes(answer)}
            onChange={() => handleChange(answer)}
            data-profiles={choices[answer].join(',')}
          />
          <label htmlFor={`choice-${index}`}>{answer}</label>
        </div>
      ))}
    </div>
  )
}
