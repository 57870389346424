import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import NotificationSystem from 'react-notification-system'
import { style } from '../../variables/Variables'
import connect from 'react-redux/es/connect/connect'
import { getMainBtx } from './common'
import './Settlements.css'

import SettlementsDashboard from './SettlementsDashboard'
import SettlementList from './Settlements/SettlementList'
import SettlementDetails from './Settlements/SettlementDetails'
import OrderList from './Orders/OrderList'
import OrderDetails from './Orders/OrderDetails'
import JobList from './Jobs/JobList'
import JobDetails from './Jobs/JobDetails'
import OfficeClients from './OfficeClients/OfficeClients'
import InvoiceList from './Invoices/InvoiceList'
import InvoiceDetails from './Invoices/InvoiceDetails'
import UploadCSV from './UploadCSV/UploadCSV'
import Users from './Users/Users.jsx'
import SalaryGrid from './Users/SalaryGrid'
import HcvaList from './Hcva/HcvaList'
import GetCsv from './GetCsv/GetCsv'
import WorktimeReport from './WorktimeReport/WorktimeReport'
import B2BSettings from './WorktimeReport/B2BReport/B2BSettings'
import COO from './COO/COO'
import { default as EstimationList } from './Estimations/EstimationList'

class SettlementMain extends Component {
  constructor (props) {
    super(props)
    this.setBtxMain = props.setBtxMain
    this.setFakeUser = props.setFakeUser
  }

  componentDidMount () {
    getMainBtx()
      .catch((err) => this.showNotification('error', `Wystąpił błąd: ${err}`))
      .then(response => this.setBtxMain(response || []))
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }
    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  render () {
    const { fakeUser } = this.props
    return (
      <div className='content Settlements'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Router>
          <Route render={({ location }) => (
            <Switch location={location}>
              <Route exact path='/settlements/orders' component={OrderList} />
              <Route exact path='/settlements/order/:slug' component={OrderDetails} />
              <Route exact path='/settlements/jobs' component={JobList} />
              <Route exact path='/settlements/job/:slug' component={JobDetails} />
              <Route exact path='/settlements/list' component={SettlementList} />
              <Route exact path='/settlements/settlement/:slug' component={SettlementDetails} />
              <Route exact path='/settlements/office-clients' component={OfficeClients} />
              <Route exact path='/settlements/invoices' component={InvoiceList} />
              <Route exact path='/settlements/invoice/:slug' component={InvoiceDetails} />
              <Route exact path='/settlements/upload' component={UploadCSV} />
              <Route exact path='/settlements/users' component={Users} />
              <Route exact path='/settlements/salary-grid' component={SalaryGrid} />
              <Route exact path='/settlements/estimations' component={EstimationList} />
              <Route exact path='/settlements/hcva' component={HcvaList} />
              <Route exact path='/settlements/getcsv' component={GetCsv} />
              <Route exact path='/settlements/worktime-report/:type' component={WorktimeReport} />
              <Route exact path='/settlements/worktime-settings/:type' component={B2BSettings} />
              <Route exact path='/settlements/coo' component={COO} />
              <Route component={SettlementsDashboard} />
            </Switch>
          )}
          />
        </Router>
        {
          fakeUser.ID
            ? <div className='fakeInfo'>
              <div>Zalogowany jako: <b>{fakeUser.userName}</b> ({fakeUser.departName}) </div>
              <div onClick={() => this.setFakeUser({})} style={{ cursor: 'pointer' }}>RESETUJ</div>
            </div>
            : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    orders: state.orders,
    fakeUser: state.fakeUser,
    user: state.user,
    main: state.main
  }
}

const mapDispatchToProps = {
  setBtxMain: mainBtx => ({
    type: 'MAIN_BTX',
    mainBtx
  }),
  setFakeUser: user => ({
    type: 'SET_FAKE_USER',
    user
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementMain)
