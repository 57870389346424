import React, { Component } from 'react'
import Picker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css'
import './ReactMonthPicker.css'
import { FormControl } from 'react-bootstrap'

class MonthBox extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      value: this.props.value || ''
    }
    this._handleClick = this._handleClick.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState({
        value: this.props.value || ''
      })
    }
  }

  render () {
    return (
      <div className='box' onClick={this._handleClick}>
        <label>{this.state.value}</label>
      </div>
    )
  }

  _handleClick (e) {
    this.props.onClick && this.props.onClick(e)
  }
}

const pickerLang = {
  months: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
  monthsFull: ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'paździenik', 'listopad', 'grudzień'],
  from: 'Od',
  to: 'Do'
}

class ReactMonthPicker extends Component {
  constructor (props) {
    super(props)
    this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
    this.handleAMonthChange = this.handleAMonthChange.bind(this)
    this.handleAMonthDismiss = this.handleAMonthDismiss.bind(this)

    this.handleClickRangeBox = this.handleClickRangeBox.bind(this)
    this.handleRangeChange = this.handleRangeChange.bind(this)
    this.handleRangeDismiss = this.handleRangeDismiss.bind(this)
    this.state = {
      date: props.date,
      monthRangePicker: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(prevProps.date) !== JSON.stringify(this.props.date)) {
      this.setState({ date: this.props.date })
    }
    if (prevState.monthRangePicker !== this.state.monthRangePicker && prevState.monthRangePicker) {
      const date = { ...this.state.date, from: this.state.date.to }
      this.props.onChangeMonth(date)
    }
  }

  makeText (m) {
    if (m && m.year && m.month) return (pickerLang.monthsFull[m.month - 1] + ' ' + m.year)
    return ''
  }

  render () {
    const { date, monthRangePicker } = this.state
    return (
      <div className='react-month-picker'>
        {
          !monthRangePicker
            ? <div>
              <div className='form-control edit' style={{ width: 200 }}>
                <Picker
                  ref='pickAMonth'
                  years={{ min: this.props.min || 2019, max: this.props.max || (2020 + 10) }}
                  value={date.to}
                  lang={pickerLang.months}
                  onChange={this.handleAMonthChange}
                  onDismiss={this.handleAMonthDismiss}
                >
                  <MonthBox value={this.makeText(date.to)} onClick={this.handleClickMonthBox} />
                </Picker>
              </div>
              {
                this.props.showDel !== false
                  ? <div
                      className='del'
                      style={{ margin: 0 }}
                      title='Usuń'
                      onClick={() => {
                        this.props.onChangeMonth('')
                      }}
                    >x
                    </div>
                  : null
              }
              </div>
            : <div>
              <div className='form-control edit' style={{ width: 200 }}>
                <Picker
                  ref='pickRange'
                  years={{ min: 2019, max: 2020 + 10 }}
                  range={date}
                  lang={pickerLang}
                  onChange={this.handleRangeChange}
                  onDismiss={this.handleRangeDismiss}
                >
                  <MonthBox value={this.makeText(date.from) + ' - ' + this.makeText(date.to)} onClick={this.handleClickRangeBox} />
                </Picker>
              </div>
              </div>
        }
        {
          this.props.monthRangeCheckbox
            ? <label className='finance-data'>
              <FormControl
                style={{ margin: 0, height: 20 }}
                type='checkbox'
                checked={monthRangePicker}
                onChange={() => {
                  this.setState({ monthRangePicker: !monthRangePicker })
                }}
              /> kilka miesięcy
              </label>
            : null
        }
      </div>
    )
  }

  handleClickMonthBox (e) {
    this.refs.pickAMonth.show()
  }

  handleAMonthChange (year, month) {
    this.props.onChangeMonth({
      from: { year, month },
      to: { year, month }
    })
  }

  handleAMonthDismiss ({ year, month }) {
    this.props.onChangeMonth({
      from: { year, month },
      to: { year, month }
    })
  }

  handleClickRangeBox (e) {
    this.refs.pickRange.show()
  }

  handleRangeChange (year, month, idx) {
    if (idx === 1) {
      this.refs.pickRange.dismiss()
    }
  }

  handleRangeDismiss (value) {
    this.props.onChangeMonth(value)
  }
}

export default ReactMonthPicker
