import React, { Component } from 'react'

export class Card extends Component {
  render () {
    return (
      <div className={'card' + (this.props.plain ? ' card-plain' : '')} style={this.props.style}>
        {
                (this.props.header || this.props.title || this.props.category || this.props.hCenter) &&
                  <div className={'header' +
                  (this.props.hCenter ? ' text-center' : '')}
                  >
                    <h4 className='title'>{this.props.title}</h4>
                    <div className='category'>{this.props.category}</div>
                    <p style={{ display: 'none' }} />
                  </div>
              }
        <div
          className={'content' +
                (this.props.ctAllIcons ? ' all-icons' : '') +
                (this.props.ctTableFullWidth ? ' table-full-width' : '') +
                (this.props.ctTableResponsive ? ' table-responsive' : '') +
                (this.props.ctTableUpgrade ? ' table-upgrade' : '')}
          style={this.props.contentStyle}
        >
          {this.props.content}
        </div>
        <div className={`footer ${this.props.footer || ''}`}>
          {this.props.legend}
          {this.props.stats != null ? <hr /> : ''}
          <div className='stats'>
            <i className={this.props.statsIcon} /> {this.props.stats}
          </div>
        </div>
      </div>
    )
  }
}

export default Card
