import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Modal extends Component {
  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.show === true) {
      document.body.classList.add('modal-open')
    }
  }

  render () {
    return (
      <div className={`${this.props.show ? 'show' : 'hide'}`}>
        <div className={`modal show fade ${this.props.show ? 'in' : ''}`}>
          <div className='modal-dialog modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  type='button' className='close' onClick={() => {
                    document.body.classList.remove('modal-open')
                    this.props.handleClosing()
                  }}
                ><span>×</span>
                </button>
                <h4 className='modal-title'>{this.props.header}</h4>
              </div>
              <div className='modal-body'>{this.props.content}</div>
              {this.props.footer &&
                <div className='modal-footer'>{this.props.footer}</div>}
            </div>
          </div>
        </div>
        <div className={`modal-backdrop show fade ${this.props.show ? 'in' : ''}`} />
      </div>
    )
  }
}

Modal.defaultProps = {
  show: false,
  header: 'Super Cube'
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClosing: PropTypes.func.isRequired,
  footer: PropTypes.string
  // content: PropTypes.string
}

export default Modal
