import React, { useState, useEffect, useRef } from 'react'
import FirstPage from './components/FirstPage'
import ThankYouPage from './components/ThankYouPage'
import StepsNavigation from './components/StepsNavigation'
import MultiChoiceQuestion from './components/MultiChoiceQuestion'

import defaultImage from './img/intro.jpg'
import { questions, defaultSummary } from './config'

import './Survey.scss'

export default function Survey () {
  const [currentStep, setCurrentStep] = useState(-1)
  const [answers, setAnswers] = useState(questions.map(() => ([])))
  const [summary, setSummary] = useState(defaultSummary)

  const validateAnswers = () => {
    window.alert('W każdym pytaniu muszą być zaznaczone 4 odpowiedzi')
  }

  const isValid = (step = currentStep) => answers[step].length === 4

  const changeStep = (newStep = currentStep + 1) => {
    if (isValid()) {
      setCurrentStep(newStep)
    } else {
      validateAnswers()
    }
  }

  const countAnswers = () => {
    const newSummary = { ...summary }
    answers.forEach(answerArray => {
      answerArray.forEach(answer => {
        answer.profiles.forEach(profile => {
          newSummary.results[profile].points += 1
        })
      })
    })
    setSummary(newSummary)
  }

  const finishSurvey = () => {
    if (answers.every((answer, index) => isValid(index))) {
      countAnswers()
      changeStep()
    } else {
      validateAnswers()
    }
  }

  const setAnswer = (questionIndex, answers) => {
    setAnswers(prevAnswers => {
      const newAnswers = [...prevAnswers]
      newAnswers[questionIndex] = answers
      return newAnswers
    })
  }

  const getUniqueAnswers = (choices) => {
    const uniqueAnswers = {}
    choices.forEach(choice => {
      choice.answers.forEach(answer => {
        if (!uniqueAnswers[answer]) {
          uniqueAnswers[answer] = []
        }
        uniqueAnswers[answer].push(choice.name)
      })
    })

    return uniqueAnswers
  }

  const renderQuestion = (questionData, currentStep) => {
    const currentAnswers = answers[currentStep] || {}
    const uniqueAnswers = getUniqueAnswers(questionData.choices)
    return (
      <div className='fadeIn' key={`question-container-${currentStep + 1}`}>
        <span className='step-nr'>{`Krok ${currentStep + 1}`}</span>
        <h2 className='question'>{questionData.question}</h2>
        <MultiChoiceQuestion
          key={`question-${currentStep}`}
          questionData={{ ...questionData, choices: Object.keys(uniqueAnswers).map(answer => ({ answer, profiles: uniqueAnswers[answer] })) }}
          currentStep={currentStep}
          answers={currentAnswers || []}
          setAnswer={setAnswer}
          validate={validateAnswers}
        />
      </div>
    )
  }

  const bannerImage = questions[currentStep] ? questions[currentStep].image : defaultImage

  return (
    <div className='cg-survey'>
      <div className='survey-header' style={{ backgroundImage: `url(${bannerImage})` }}>
        {currentStep >= 0 && currentStep < questions.length && <StepsNavigation totalSteps={questions.length} currentStep={currentStep} setStep={changeStep} />}
      </div>
      <div className='survey-body'>
        {currentStep === -1 && <FirstPage />}
        {questions[currentStep] && renderQuestion(questions[currentStep], currentStep)}
        {currentStep === questions.length && <ThankYouPage summary={summary} />}
        {currentStep === -1 && <button className='r' onClick={() => setCurrentStep(currentStep + 1)}>Rozpocznij</button>}
        {currentStep >= 0 && currentStep < questions.length - 1 && <button className='r' onClick={() => changeStep()}>Następne</button>}
        {currentStep === questions.length - 1 && <button className='r' onClick={() => finishSurvey()}>Zakończ</button>}
      </div>
    </div>
  )
}
