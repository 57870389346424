const user = (state = {
  data: [],
  filter: {
    status: 'placeholder',
    agreement3: 'placeholder',
    confirmed: 'placeholder',
    confirm: false,
    query: '',
    page: 0,
    adspaces: [],
    clients: [],
    codesVerificationComment: 'placeholder',
    codesVerification: 'placeholder'
  }
}, action) => {
  switch (action.type) {
    case 'SET_PARTNERS':
      return Object.assign({}, state, {
        data: action.partners
      })
    case 'SET_AGREEMENT3':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          agreement3: action.agreement3,
          page: 0
        }
      })
    case 'SET_CONFIRMED':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          confirmed: action.confirmed,
          page: 0
        }
      })
    case 'SET_PARTNER':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          partner: action.partner,
          page: 0
        }
      })
    case 'SET_CODES_VERIFICATION':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          codesVerification: action.codesVerification,
          page: 0
        }
      })
    case 'SET_CODES_VERIFICATION_COMMENT':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          codesVerificationComment: action.codesVerificationComment,
          page: 0
        }
      })
    case 'SET_STATUS':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          status: action.status,
          page: 0
        }
      })
    case 'SET_ADSPACES':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          adspaces: action.adspaces,
          clients: [],
          page: 0,
          status: 'placeholder',
          query: '',
          agreement3: 'placeholder',
          confirmed: 'placeholder',
          confirm: 'placeholder'
        }
      })
    case 'SET_NSM_ADSPACE_CATEGORIES':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          NSMAdSpaceCategories: action.NSMAdSpaceCategories,
          page: 0
        }
      })
    case 'SET_QUERY':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          query: action.query,
          page: 0
        }
      })
    case 'SET_PAGE':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          page: action.page
        }
      })
    case 'SET_CHECKBOXES':
      const filter = {
        ...state.filter,
        [action.id]: action.checkbox,
        page: 0
      }
      return Object.assign({}, state, {
        filter
      })
    case 'SET_CLIENTS':
      return Object.assign({}, state, {
        filter: {
          ...state.filter,
          clients: action.clients,
          adspaces: [],
          page: 0,
          status: 'placeholder',
          query: '',
          agreement3: 'placeholder',
          confirmed: 'placeholder',
          confirm: false
        }
      })
    default:
      return state
  }
}

export default user
