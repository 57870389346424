export default {
  shorterDaySwitch: {
    title: 'KRÓTSZY DZIEŃ PRACY',
    info: 'Przełącznik odkrywający pole umożliwiające wpisanie godzin wolnych od pracy, które skracają standardowy dzienny wymiar czasu pracy.'
  },
  freeHoursInfo: {
    title: 'KOMENTARZ DO WOLNYCH GODZIN',
    info: 'Pole wymaganego komentarza do okolicznościowych, płatnych godziny wolnych od pracy, udzielanych przez Cube Group (np. krótszy czas pracy w piątki, dodatkowy dzień wolny na urodziny) lub innych dotyczących Pracowników (m.in. ślub, narodziny dziecka, pogrzeb bliskiego, wezwania przez organ państwowy, poszukiwanie pracy, badań kontrolne i okresowe).'
  },
  lackOfDayTimeInfo: {
    title: 'KOMENTARZ DO NIEDOBORU CZASU',
    info: 'Pole wymaganego komentarza w przypadku niedoboru czasu pracy względem czasu, który powinieneś przepracować.'
  },
  overTimeInfo: {
    title: 'KOMENTARZ DO NADGODZIN',
    info: 'Pole wymaganego komentarza w przypadku pracy ponad czas, który powinieneś przepracować. Pamiętaj, że na pracę w nadgodzinach powinieneś uzyskać zgodę od przełożonego.'
  },
  prevDayOverTime: {
    title: 'Z POPRZEDNICH DNI',
    info: 'Sumaryczny czas nadgodzin lub zbyt krótkiego czasu pracy z wszystkich poprzednich dni. Wartość dodatnia to nadgodziny, ujemna to niedobór czasu.'
  },
  nextDayOverTime: {
    title: 'NA KOLEJNY DZIEŃ',
    info: 'Sumaryczny czas nadgodzin lub zbyt krótkiego czasu pracy przechodzący na kolejny dzień, po uwzględnieniu wybranego dnia w raporcie. Wartość dodatnia to nadgodziny, ujemna to niedobór czasu.'
  },
  workTime: {
    title: 'WYMIAR CZASU PRACY',
    info: 'Standardowy dzienny wymiar czasu pracy wynikający z umowy.'
  },
  freeHours: {
    title: 'WOLNE GODZINY',
    info: 'Okolicznościowe, płatne godziny wolne od pracy udzielane przez Cube Group (np. krótszy czas pracy w piątki, dodatkowy dzień wolny) lub inne dotyczące Pracowników (m.in. ślub, narodziny dziecka, pogrzeb bliskiego, wezwanie przez organ państwowy, poszukiwanie pracy, badania kontrolne i okresowe).'
  },
  correctedWorkTime: {
    title: 'SKORYGOWANY WYMIAR',
    info: 'Czas do przepracowania po odjęciu wolnych godzin.'
  },
  duration: {
    title: 'CZAS BITRIX',
    info: 'Twój czas zalogowania do pracy w Bitrix.'
  },
  totalTime: {
    title: 'ŁĄCZNY CZAS PRACY',
    info: 'Twój raportowany łączny czas pracy. Możesz go skorygować wedle potrzeby. Wstępnie wpisany jako Czas Bitrix lub czas do przepracowania (wymiar lub skorygowany wymiar), jeśli Czas Bitrix jest nie krótszy więcej niż 5 minut lub nie dłuższy więcej niż 10 min od czasu do przepracowania. Np. Czas Bitrix 7:57, a wymiar wynosi 8:00, to łączny czas pracy 8:00.'
  },
  baseTime: {
    title: 'CZAS PODSTAWOWY',
    info: 'Twój czas pracy nieprzekraczający czasu, który powinieneś przepracować. Np. gdy pracowałeś 8:30, a wymiar wynosi 8:00, czas podstawowy wynosi 8:00. Pozostały czas 0:30 to nadgodziny.'
  },
  lackOfDayTime: {
    title: 'NIEDOBÓR CZASU',
    info: 'Czas brakujący do wymiaru, który powinieneś przepracować. Np. gdy pracowałeś 7:30, a wymiar wynosi 8:00, niedobór czasu to 0:30.'
  },
  overTime: {
    title: 'NADGODZINY',
    info: 'Twój czas pracy ponad czas, który powinieneś przepracować. Np. gdy pracowałeś 8:30, a wymiar wynosi 8:00, nadgodziny to 0:30.'
  },
  scStart: {
    title: 'GODZINA STARTU',
    info: 'Godzina Twojej rejestracji do pracy w Bitrix. W razie potrzeby możesz ją skorygować.'
  },
  scFinish: {
    title: 'GODZINA STOPU',
    info: 'Godzina zakończenia pracy wynikająca z godziny startu w Bitrix i łącznego czasu pracy.'
  },
  overTimeAgree: {
    title: 'mam zgodę od przełożonego',
    info: 'posiadam zgodę od przełożonego na nadgodziny ≥30min'
  }
}
