const fakeUser = (state = {
}, action) => {
  switch (action.type) {
    case 'SET_FAKE_USER':
      return Object.assign({}, '', {
        ...action.user
      })
    default:
      return state
  }
}

export default fakeUser
