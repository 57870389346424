import React from 'react'
import ReactDOM from 'react-dom'

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
import persistState from 'redux-sessionstorage'
import { createStore, applyMiddleware } from 'redux'
import { sessionService } from 'redux-react-session'
import thunk from 'redux-thunk'

import reducer from './reducers/index'

import App from './containers/App/App'

import './assets/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/sass/light-bootstrap-dashboard.css'
import './assets/css/demo.css'
import './assets/css/pe-icon-7-stroke.css'

const createPersistentStore = composeWithDevTools(
  persistState(),
  applyMiddleware(thunk)
)(createStore)

const store = createPersistentStore(reducer)

// Init the session service
sessionService.initSessionService(store)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path='/' name='Home' component={App} />
      </Switch>
    </Router>
  </Provider>, document.getElementById('root')
)
