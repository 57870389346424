import React from 'react'
import { FormControl } from 'react-bootstrap'

export const DefaultButton = ({ value = '', style = {}, onClick = () => { } }) => <button
  className='btn'
  type='button'
  style={style}
  onClick={onClick}
>{value}
</button>

export const MediaFolder = ({ state, setState }) => <>
  <a href={state.deeplinkHtmlEnabled ? `../api/client/get-deeplink-html/${state.data._id}` : '#'} className={state.deeplinkHtmlEnabled ? 'btn' : 'btn btn-light disabled'}>
    Deeplink HTML
  </a>
  <a
    href={`https://console.cloud.google.com/storage/browser/nsmfiles/${state.data.it ? state.data.it.alias : state.data.alias}/?project=netsalesmedia`}
    className='btn'
    rel='noopener noreferrer'
    target='_blank'
  >
    <i className='pe-7s-cloud-upload' /> Dysk
  </a>
  <FormControl
    componentClass='select'
    name='mediaFoldersList'
    type='select'
    value={state.selectedMediaFolder}
    style={{ width: 300, display: 'inline-block' }}
    onChange={(e) => {
      const mediaCsvBtn = document.getElementById('getMediaCsvBtn')
      if (mediaCsvBtn) {
        mediaCsvBtn.href = `../api/client/media-csv/${state.data.ITID}/${encodeURIComponent(e.target.value)}`
      } else {
        const mediaCsvBtn = document.createElement('a')
        mediaCsvBtn.href = `../api/client/media-csv/${state.data.ITID}/${encodeURIComponent(e.target.value)}`
        mediaCsvBtn.className = 'btn btn-info'
        mediaCsvBtn.textContent = 'Pobierz media'
        mediaCsvBtn.id = 'getMediaCsvBtn'
        e.target.parentNode.insertBefore(mediaCsvBtn, e.target.nextSibling)
      }
      setState({ selectedMediaFolder: e.target.value })
    }}
  >
    <option disabled value={-1} key={-1}>
      Wybierz folder mediów
    </option>
    {state.mediaFolders.length > 0
      ? state.mediaFolders.map((val, key) => (
        <option key={key} value={val}>
          {val === '' ? 'wszystkie' : val}
        </option>
      ))
      : ''}
  </FormControl>
</>
