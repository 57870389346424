import React, { Component } from 'react'
import { allowedFromDepartments, allowedTimeReportsDepartments } from '../authData'
import { Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row } from 'react-bootstrap'
import Select from 'react-select'
import NumberFormat from 'react-number-format'

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 1, width: 13 }),
  control: style => ({ ...style, margin: 1, padding: 0, minHeight: 30, fontSize: 12, width: 200 }),
  option: style => ({ ...style, padding: 6, textAlign: 'left', fontSize: 12 }),
  indicatorSeparator: style => ({ ...style, display: 'none' }),
  input: style => ({ ...style, margin: 0, padding: 0 })
}

class HcvaAddForm extends Component {
  constructor (props) {
    super(props)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.state = {}
  }

  componentDidMount () {
    this.getOptions()
  }

  getOptions () {
    const clientsOptions = this.getClientsOptions()
    const departmentsOptions = this.getDepartmentsOptions()
    this.setState({ clientsOptions, departmentsOptions })
  }

  getClientsOptions () {
    const { companies = [] } = this.props.main || {}
    return (companies.length && companies
      .filter(item => Number(item.ID) < 90000) // bez klientow office
      .map(el => ({
        value: el.ID,
        label: el.TITLE
      })).sort((a, b) => a.label > b.label ? 1 : -1)) || []
  }

  getDepartmentsOptions () {
    const { departments = [] } = this.props.main || {}
    const arr = (departments.length && departments
      .filter(el => allowedFromDepartments
        .concat(allowedTimeReportsDepartments.map(el => `${el}`))
        .includes(el.ID))
      .map(el => ({
        value: el.ID,
        label: el.NAME
      }))) || []
    arr.unshift({
      value: 'all',
      label: 'WSZYSTKIE DZIAŁY'
    }, {
      value: '1',
      label: 'SUMA DZIAŁÓW'
    })
    return arr
  }

  onFieldChange (name, value) {
    const { formBody = {} } = this.state
    formBody[name] = value
    this.setState({ formBody })
  }

  renderAddForm () {
    const { clientsOptions = [], departmentsOptions = [], errors = {}, formBody = {} } = this.state
    return (
      <Row>
        <Col md={3}>
          <FormGroup controlId='client' validationState={errors.client ? 'error' : null}>
            <ControlLabel>Klient</ControlLabel>
            <Select
              name='client'
              options={clientsOptions}
              styles={selectStyles}
              onChange={e => this.onFieldChange('client', e ? e.value : '')}
              value={clientsOptions.find(item => item.value === formBody.client)}
            />
            {errors.client && <HelpBlock>{errors.client[0]}</HelpBlock>}
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup controlId='department' validationState={errors.department ? 'error' : null}>
            <ControlLabel>Dział</ControlLabel>
            <Select
              name='department'
              options={departmentsOptions}
              styles={selectStyles}
              onChange={e => this.onFieldChange('department', e ? e.value : '')}
              value={departmentsOptions.find(item => item.value === formBody.department)}
            />
            {errors.department && <HelpBlock>{errors.department[0]}</HelpBlock>}
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup controlId='hcva' validationState={errors.hcva ? 'error' : null}>
            <ControlLabel>Min. HCVA</ControlLabel>
            <NumberFormat
              name='hcva'
              value={formBody.hcva || ''}
              customInput={FormControl}
              thousandSeparator={' '}
              decimalSeparator=','
              allowedDecimalSeparators={[',', '.']}
              fixedDecimalScale
              decimalScale={1}
              isNumericString
              onValueChange={(values) => {
                const value = values.value
                this.onFieldChange('hcva', value)
              }}
            />
            {errors.hcva && <HelpBlock>{errors.hcva[0]}</HelpBlock>}
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup controlId='hidden'>
            <ControlLabel>Ukryte w Estymacjach</ControlLabel>
            <FormControl
              type='date'
              name='hidden'
              onChange={(e) => this.onFieldChange('hidden', e.target.value)}
              value={formBody.hidden || ''}
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <ControlLabel />
            <br />
            <Button onClick={() => this.props.addItem(formBody)}>Zapisz</Button>
          </FormGroup>
        </Col>
      </Row>
    )
  }

  render () {
    return this.renderAddForm()
  }
}

export default HcvaAddForm
