import Dashboard from '../views/Dashboard/Dashboard'
import UserProfile from '../views/UserProfile/UserProfile'
import Login from '../views/Login/Login'
import PublisherList from '../views/Publishers/List'
import PublisherDetails from '../views/Publishers/Details/PublishersDetail'
import ClientList from '../views/Clients/List'
import ClientsEmailList from '../views/Clients/ReportsAccessTableSummary'
import ClientDetails from '../views/Clients/ClientDetails'
import ClicksValidation from '../views/Clients/ClicksValidation'
import ClicksValidationReport from '../views/Clients/ClicksValidationReport'
import ClicksAddHistory from '../views/Clients/ClicksAddHistory'
import SettlementsMain from '../views/Settlements/SettlementsMain'
import InfluencerList from '../views/Influencers/List'
import InfluencerAddNew from '../views/Influencers/Add'
import InfluencerEdit from '../views/Influencers/Edit'
import TimeReportEdit from '../views/TimeReport/TimeReportEdit'
import TimeReport from '../views/TimeReport/TimeReport'
import ClientNewsletter from '../views/Clients/ClientNewsletter'
import SentRaports from '../views/SentRaports/SentRaports'
import NewsletterList from '../views/Clients/NewsletterList'
import AutoEditorTool from '../views/Creatives/AutoEditorTool'
import DesksBooking from '../views/DesksBooking/DesksBooking'
import Chat from '../views/Chat/Chat'
import BuyerPersonSurvey from '../views/BuyerPersonSurvey/Survey'

const appRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'pe-7s-drawer',
    component: Dashboard
  },
  {
    path: '/chat',
    name: 'SuperChat',
    component: Chat,
    icon: 'pe-7s-science',
    userIds: ['168', '200', '1', '536', '44', '32', '54', '208', '252', '290', '748', '932', '46', '160', '846', '576', '934', '722', '200', '144', '262', '688', '34', '798', '364', '574']
  },
  {
    path: '/buyer-person-quiz',
    name: 'Buyer Person Quiz',
    component: BuyerPersonSurvey,
    icon: 'pe-7s-target',
    userIds: ['536', '168', '932', '32']
  },
  {
    path: '/publishers',
    name: 'Wydawcy',
    icon: 'pe-7s-news-paper',
    component: PublisherList
  },
  {
    path: '/clients/email-list',
    name: 'Klienci',
    icon: 'pe-7s-note2',
    component: ClientsEmailList,
    hidden: true
  },
  {
    path: '/clients',
    name: 'Klienci',
    icon: 'pe-7s-note2',
    component: ClientList
  },
  {
    path: '/influencers',
    name: 'Influencerzy',
    icon: 'pe-7s-users',
    component: InfluencerList,
    auth: [7, 338, 354, 256, 464]
  },
  {
    path: '/validation-reports-sent',
    name: 'Wysłane raporty',
    icon: 'pe-7s-way',
    component: SentRaports,
    auth: [7, 44, 254, 338, 354, 256, 464]
  },
  {
    path: '/creative/autoeditor',
    name: 'Creative Tool',
    component: AutoEditorTool,
    icon: 'pe-7s-magic-wand',
    auth: [7, 254, 338]
  },
  {
    path: '/newsletter-list',
    name: 'Lista newsletterów',
    icon: 'pe-7s-rocket',
    component: NewsletterList,
    auth: [7, 338, 354, 256, 464],
    userIds: ['386', '200']
  },
  {
    path: '/clicks-validation/:month',
    name: 'Walidacja Klików',
    icon: 'pe-7s-piggy',
    component: ClicksValidation
  },
  {
    path: '/clicks-validation-report',
    icon: 'pe-7s-graph2',
    name: 'Raport klików',
    component: ClicksValidationReport
  },
  {
    path: '/clicks-add-history',
    name: 'Raport zmian',
    component: ClicksAddHistory,
    hidden: true
  },
  {
    path: '/settlements',
    name: 'Rozliczenia',
    icon: 'pe-7s-calculator',
    component: SettlementsMain
  },
  {
    path: '/timereport',
    name: 'Raport czasu pracy',
    icon: 'pe-7s-stopwatch',
    component: TimeReport
  },
  {
    path: '/desks-booking',
    name: 'Rezerwacja biurek',
    icon: 'pe-7s-date',
    component: DesksBooking
  },
  {
    path: '/publisher/:id',
    name: 'Details',
    component: PublisherDetails,
    auth: [7, 338, 354, 256, 464],
    userIds: ['386', '200'],
    hidden: true
  },
  {
    path: '/client/newsletter/:id',
    name: 'Newsletter',
    component: ClientNewsletter,
    auth: [7, 338, 354, 256, 464],
    hidden: true
  },
  {
    path: '/client/:id',
    name: 'Details',
    component: ClientDetails,
    auth: [7, 338, 354, 256, 464],
    hidden: true
  },
  {
    path: '/influencer/add',
    name: 'Dodaj influencera',
    component: InfluencerAddNew,
    auth: [7, 338, 354, 256, 464],
    hidden: true
  },
  {
    path: '/influencer/:id',
    name: 'Edycja influencera',
    component: InfluencerEdit,
    auth: [7, 338, 354, 256, 464],
    hidden: true
  },
  {
    path: '/user',
    name: 'Stopka',
    icon: 'pe-7s-user',
    component: UserProfile
  },
  {
    path: '/timereportedit/:date?',
    name: 'Raport czasu pracy',
    component: TimeReportEdit,
    hidden: true
  },
  {
    path: '/login/:redirect',
    component: Login,
    name: 'Login',
    hidden: true
  },
  {
    redirect: true,
    path: '/',
    to: '/dashboard',
    name: 'Dashboard'
  }
]

export default appRoutes
