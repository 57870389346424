import React from 'react'
import { useNotification } from '../../useNotification'

export default ({ data, basicValues, validationFields }) => {
  if (Object.keys(data).length === 0) {
    return useNotification('Brak danych!')
  }
  if (!data.company) {
    return useNotification(<p className='mb-0'>Pole company musi być wypełnione</p>, 'error', 10)
  }
  if (!data.alias || !data.alias.match(/^[a-zA-Z][0-9a-zA-Z]*$/)) {
    return useNotification(<p className='mb-0'>Pole alias:<br />1) może zawierać tylko cyfry i litery oraz nie może zaczynać się od cyfry.</p>, 'error', 10)
  }
  if (!data.website || !data.website.match(/^http[s]?:\/\/|^sc-domain:/)) {
    return useNotification(<p className='mb-0'>Pole website:<br />1) w przypadku Usług Domeny GSC musi zaczynać się od "sc-domain:" + nazwa domeny np.allegro.pl<br />2) w pozostałych przypadkach od "http://" lub "https://" + nazwa domeny np.allegro.pl</p>, 'error', 10)
  }
  /* Check if validation data are correct */
  if (basicValues.reportType && basicValues.reportType !== '') {
    const errArr = []
    let field
    for (field of validationFields) {
      if (field !== 'Walidacja') {
        if (!basicValues[field]) {
          errArr.push(field)
        }
      }
    }
    if (errArr.length) {
      console.error(`Error: Pola: ${errArr.join(', ')} muszą być wypełnione`)
      return useNotification(`Pola: ${errArr.join(', ')} muszą być wypełnione`)
    }
  }
  /* Email check */
  if (basicValues.email && basicValues.email !== '') {
    const re = /^(([^<>()\],;:\s@]+(\.[^<>()\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i
    if (!re.test(String(data.email).toLowerCase())) {
      console.error('Error: Niepoprawny email')
      return useNotification('Niepoprawny adres e-mail')
    }
  }
  return true
}
