import React, { Component } from 'react'
import SCModal from '../Settlements/Components/Modal'
import { connect } from 'react-redux'
import { Line } from 'react-chartjs-2'

const colors = [
  '255,111,85', // time
  '188,29,0', // cost
  '95,218,255', // markup
  '82,205,144', // profit
  '76,92,255' // hcva
]

const pl = {
  time: 'CZAS',
  cost: 'KOSZT RBH',
  markup: 'MARŻA',
  hcva: 'HCVA',
  profit: 'WYNIK'
}

const options = {
  responsive: true,
  scales: {
    yAxes: [
      {
        position: 'left',
        id: 'y-axis-1',
        // stacked: true,
        scaleLabel: {
          display: true,
          labelString: 'CZAS [h], KWOTY [zł]'
        },
        ticks: {
          callback (value) {
            return Number(value).toLocaleString()
          }
        }
      },
      {
        position: 'right',
        id: 'y-axis-2',
        scaleLabel: {
          display: true,
          labelString: 'HCVA'
        },
        ticks: {
          callback (value) {
            return Number(value).toFixed(1)
          }
        }
      }
    ]
  },
  tooltips: {
    mode: 'label',
    callbacks: {
      label (tooltipItem, data2) {
        return `${data2.datasets[tooltipItem.datasetIndex].label} ${data2.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString()}`
      }
    }
  }
}

class ChartModal extends Component {
  constructor (props) {
    super(props)
    this.setModalParams = this.setModalParams.bind(this)
    const { user } = this.props
    const { tasksFakeUser } = this.props.tasks
    this.state = {
      loggedUser: tasksFakeUser.ID ? tasksFakeUser : user
    }
  }

  componentDidMount () {
    this.setModalParams(true, this.props.modalParams.clientName, '', () => {})
    this.prepareData()
  }

  prepareData () {
    const { data, multiSelect = {} } = this.props.modalParams
    const sortedDates = Object.keys(data).sort()
    const labels = ['time', 'cost', 'markup', 'profit']
    const hidden = ['cost', 'markup', 'profit']

    const sum = sortedDates.map(m => {
      return Object.entries(data[m])
        .filter(el => (multiSelect.departments || []).length ? multiSelect.departments.includes(el[0]) : el)
        .map(el => el[1])
        .reduce((a, b) => {
          const arr = []
          a.forEach((ele, i) => arr.push(a[i] + b[i]))
          return arr
        })
    })

    const forChart = {
      labels: sortedDates,
      datasets: labels.map((label, key) => ({
        label: pl[label],
        data: label === 'time' ? sum.map(el => Number((el[key] / 3600).toFixed(1))) : sum.map(el => el[key]),
        borderColor: `rgba(${colors[key]},1)`,
        hidden: hidden.includes(label),
        yAxisID: 'y-axis-1',
        fill: false,
        pointHitRadius: 20
      }))
    }
    this.addHcva(forChart)
    this.setState({ data: forChart })
  }

  countHcva (costArr, markupArr) {
    return markupArr.map((markup, key) => {
      const cost = costArr[key]
      return markup && cost ? Number((markup / cost).toFixed(1)) : (markup > 0 ? null : null)
    })
  }

  addHcva (obj = {}) {
    const hcva = this.countHcva((obj.datasets[1] || []).data, (obj.datasets[2] || []).data)
    obj.datasets.splice(3, 0, {
      label: pl.hcva,
      data: hcva,
      borderColor: `rgba(${colors[4]},1)`,
      yAxisID: 'y-axis-2',
      fill: false,
      autoSkip: false,
      spanGaps: false,
      pointHitRadius: 20
    })
  }

  modalContent () {
    const { data = {} } = this.state
    const { dayStart, dayEnd, multiSelect = {}, departmentsNames } = this.props.modalParams

    return (
      <div className='chart-modal'>
        <div>
          <div><label>Dane z zakresu: </label> od {dayStart} do {dayEnd}</div>
          <div style={{ marginBottom: 40 }}><label>Uwzględnione działy: </label> {(multiSelect.departments || []).length
            ? multiSelect.departments.map(el => departmentsNames[el]).join(', ')
            : 'wszystkie'}
          </div>
          <Line
            data={data}
            options={options}
                // height={200}
          />
        </div>
      </div>
    )
  }

  setModalParams (show = false, title = '', content = '', method = null) {
    this.setState({
      modalShow: show,
      modalTitle: title,
      modalContent: content,
      modalMethod: method
    }, () => method === null ? this.props.setChartModalParams({}) : null)
  }

  render () {
    const { modalShow, modalTitle, modalMethod } = this.state
    const modalContent = this.modalContent()

    return (
      <SCModal
        modalShow={modalShow}
        modalTitle={modalTitle}
        modalContent={modalContent}
        setModalParams={this.setModalParams}
        propMethod={modalMethod}
        setHeight
        agreeButton={false}
        closeText='Zamknij'
        size='large'
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    main: state.main,
    tasks: state.tasks
  }
}

export default connect(mapStateToProps)(ChartModal)
