import React, { Component } from 'react'
import { Col, FormControl, Grid, Row, Table } from 'react-bootstrap'
import moment from 'moment'
import NotificationSystem from 'react-notification-system'
import { connect } from 'react-redux'
import { style } from '../../../variables/Variables'
import { Card } from '../../../components/Card/Card'
import Select from 'react-select'
import FORM_MODEL from './orders'
import { Link, Redirect } from 'react-router-dom'
import { getMainMap, getMapLabel, onlyActiveUsers } from '../common'
import { API_SERVER, checkUserType } from '../authData'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import Loader from '../Components/Loader'

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 1 }),
  control: style => ({ ...style, minHeight: 30 })
}

class OrderDetails extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.saveFile = this.saveFile.bind(this)
    this.state = {
      loader: false,
      colNames: Object.keys(FORM_MODEL),
      colParams: FORM_MODEL,
      today: moment().format('YYYY-MM-DD'),
      formValid: true,
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      slug: props.match.params.slug,
      mainMap: getMainMap(props.main)
    }
  }

  componentDidMount () {
    const { slug } = this.state
    if (slug !== 'add') {
      this.getOrder()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.main) !== JSON.stringify(this.props.main)) {
      const mainMap = getMainMap(this.props.main)
      this.setState({ mainMap })
    }
  }

  getOrder () {
    const { loggedUser, slug } = this.state
    fetch(`${API_SERVER}/api/get-orders`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: slug,
        person: loggedUser
      })
    })
      .then(res => res.json())
      .then(response => {
        if (response && !response.error) {
          const data = (response.success || [])[0] || {}
          this.getListFiles(`${data.gen_yy}/${data.order_id}`, (files) => {
            this.setState({ data, files, loader: false })
          })
        } else {
          console.error('Error:', response.error)
          this.showNotification(response.error || 'Wystąpił błąd')
        }
      })
      .catch(error => {
        this.showNotification('Wystąpił błąd')
        console.error('Error:', error)
      })
  }

  generateOrderId () {
    const { data = {} } = this.state
    return (
      <div className='d-flex gen-id'>
        <FormControl
          componentClass='select'
          type='select'
          name='gen_sel'
          className='btn btn-default'
          style={{ width: 70 }}
          onChange={(e) => {
            data.order_id = e.target.value
            this.setState({
              data,
              gen_sel: e.target.value
            })
            this.dataValidation()
          }}
        >
          <option value=''>&nbsp;</option>
          {['CG', 'MS', 'PPC', 'SEO', 'CM', 'BL', 'MP', 'PRO'].map(item => <option key={item} value={item}>{item}</option>)}
        </FormControl>
      </div>
    )
  }

  renderMonthInput (data, col, isFinance) {
    const { formValid } = this.state
    return (
      data.status === 'w toku warunkowo'
        ? <div>
          <SingleDatePicker
            date={!!data[col] && data[col] !== '' ? moment(data[col]) : null}
            onDateChange={date => {
              data[col] = date ? moment(date).format('YYYY-MM-DD') : null
              this.setState(() => ({ data }))
              if (!formValid) this.dataValidation()
            }}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            id={col}
            placeholder={null}
            displayFormat='YYYY-MM-DD'
            showClearDate
            small
            readOnly={!isFinance}
            showDefaultInputIcon
            isOutsideRange={(date) => date < moment()}
            numberOfMonths={1}
          />
          <div
            style={{ width: 178 }}
            className={this.checkInvalidInput(data[col] || '', col)}
          />
        </div>
        : <FormControl
            readOnly='readonly'
            type='text'
            value='nie dotyczy'
          />
    )
  }

  getColValues (col, slug, today) {
    const { data = {}, files = [], loggedUser, mainMap, formValid } = this.state
    const { companies = [], users = [] } = this.props.main
    const isFinance = checkUserType(loggedUser, 'finance')
    const isOffice = checkUserType(loggedUser, 'office')

    const optionsValues = {
      status: ['nowe', ' w akceptacji', 'w toku', 'w toku warunkowo', 'odrzucone', 'zamknięte', 'anulowane'],
      company: companies.filter(el => el.UF_CRM_1542970468 === '1'),
      responsible_person: onlyActiveUsers(users)
    }

    // warunek zmiany statusu
    //
    let active = optionsValues.status
    if (!(isFinance || isOffice)) {
      active = slug === 'add'
        ? ['nowe']
        : slug !== 'add' && data.status === 'nowe'
          ? ['anulowane']
          : slug !== 'add' && data.status !== 'anulowane' ? ['zamknięte'] : []
    }

    const selectInputs = ['status', 'company', 'responsible_person']

    if (selectInputs.includes(col)) {
      const options = optionsValues[col].map((item) => {
        if (col === 'status') {
          return ({
            value: item,
            label: item,
            isDisabled: !active.includes(item)
          })
        }

        const label_name = item.TITLE || item.NAME
        const label_nip = item.UF_CRM_1530178687 && col === 'company' ? `, NIP ${item.UF_CRM_1530178687}` : ''
        return ({
          value: item.ID,
          label: `${label_name} ${label_nip}`
        })
      })

      const initValue = { value: data[col], label: data[col] }

      initValue.value =
      (slug === 'add' && col === 'responsible_person' && !data.responsible_person)
        ? (data.responsible_person = loggedUser.ID, loggedUser.ID)
        : initValue.value

      initValue.value =
        (slug === 'add' && col === 'status')
          ? (data.status = optionsValues.status[0],
            initValue.label = optionsValues.status[0],
            optionsValues.status[0])
          : initValue.value

      initValue.label =
        col === 'company' && data[col]
          ? `${getMapLabel(mainMap.companies, data[col])}, NIP ${getMapLabel(mainMap.companies, data[col], 'nip')}`
          : col === 'responsible_person'
            ? getMapLabel(mainMap.users, data[col])
            : initValue.label

      return (
        <Select
          options={options}
          isDisabled={col === 'company' && slug !== 'add' && (!(isFinance || isOffice))}
          name={col}
          value={initValue}
          className={this.checkInvalidInput(data[col] || '', col)}
          styles={selectStyles}
          onChange={(e) => {
            data[col] = e.value
            data.client = col === 'company' ? getMapLabel(mainMap.companies, data[col]) : data.client
            this.setState(() => ({ data }))
            if (!formValid) this.dataValidation()
          }}
        />
      )
    }

    if (slug !== 'add' && col === 'file') {
      const allowDelete = ['nowe', 'odrzucone'].includes(data.status)
      return this.renderFileInput(data, col, files, allowDelete)
    } else if (slug === 'add' && col === 'file') {
      return
    }

    if (slug === 'add' && col === 'order_id') {
      return this.generateOrderId()
    }

    if (col === 'endDate') {
      return this.renderMonthInput(data, col, (isFinance || isOffice))
    }

    const readOnlyInputs = ['created_on', 'created_by', 'order_id']

    return (
      <FormControl
        readOnly={readOnlyInputs.includes(col) ? 'readonly' : ''}
        type='text'
        name={col}
        className={col === 'link' ? this.checkInvalidLink(data[col] || '') : this.checkInvalidInput(data[col] || '', col)}
        value={
          (col === 'created_on' && slug === 'add')
            ? today
            : (col === 'created_by' && slug === 'add')
                ? getMapLabel(mainMap.users, loggedUser.ID)
                : (col === 'endDate' && !data[col]) ? '' : data[col]
        }
        onChange={(e) => {
          data[col] = e.target.value
          this.setState({ data })
          if (!formValid) this.dataValidation()
        }}
      />
    )
  }

  renderMainTableCols () {
    const { colNames, slug, today, colParams } = this.state
    const dontShow = ['_id', 'actions']
    const style = col => ({ display: dontShow.includes(col) ? 'none' : '' })

    return colNames.map((col, colKey) => (
      <tr key={colKey} style={style(col)}>
        <td style={{ width: '30%' }}><label>{colParams[col]
          ? (slug === 'add' && col === 'order_id' ? 'Przedrostek' : colParams[col].pl)
          : col}{colParams[col].req && '*'}
        </label>
        </td>
        <td>{this.getColValues(col, slug, today)}</td>
      </tr>
    ))
  }

  dataValidation () {
    const { data = {} } = this.state
    const allData = FORM_MODEL
    const requiredInputs = Object.keys(allData).filter(prop => allData[prop].req === true)
    if (data.status === 'w toku warunkowo') requiredInputs.push('endDate')
    const validLink = data.link ? data.link !== '' && data.link.includes('https://baseline.cubegroup.pl/') : true
    const formValid =
      Object.keys(data).length &&
      requiredInputs.every(prop => data[prop] && (data[prop] !== 'undefined' || data[prop] !== '' || data[prop].length > 0)) &&
      validLink
    this.setState({ formValid, requiredInputs })
  }

  checkInvalidInput (val, col) {
    const { requiredInputs = [], formValid } = this.state
    if (!formValid && requiredInputs.includes(col) && (!val)) {
      return 'error'
    }
  }

  checkInvalidLink (val) {
    const { formValid } = this.state
    return !formValid && !val.includes('https://baseline.cubegroup.pl/') ? 'error' : ''
  }

  showNotification (message, level = 'error', autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  handleSubmit (e) {
    const { slug, formValid } = this.state
    e.preventDefault()
    e.stopPropagation()

    this.dataValidation()
    if (formValid) {
      this.setState({ loader: true })
      this.showNotification('Wysyłanie danych', 'info')
      const params = new FormData(e.target)

      if (params.get('status') !== 'w toku warunkowo') {
        params.append('endDate', '')
      }
      params.delete('file')
      const inputValues = {}

      let pair
      for (pair of params.entries()) {
        inputValues.order_id = slug === 'add' ? null : slug
        inputValues[pair[0]] = pair[1]
      }

      fetch(`${API_SERVER}/api/save-order`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(inputValues)
      })
        .then(res => res.json())
        .catch(error => {
          this.showNotification('Wystąpił błąd')
          console.error('Error:', error)
        })
        .then((response) => {
          if (response && response.success) {
            this.showNotification('Dane zostały zapisane', 'success', 6)
            if (slug === 'add') {
              this.setState(() => ({ redirect: true }))
            }
          } else {
            this.showNotification('Wystąpił błąd')
            console.error('Error:', response)
          }
          this.setState({ loader: false })
        })
    } else {
      this.showNotification('Formularz zawiera błędy')
    }
  }

  /** FILES **/

  renderFileInput (data, col, files = [], allowDelete) {
    const orderId = data.order_id
    const orderYear = orderId ? orderId.slice(-4) : ''
    const folderPath = `${orderYear}/${orderId}`

    return (
      <div className='btn-group' style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
          <div style={{ height: 24, marginTop: 5 }}>Pliki:</div>
          {
            Array.isArray(files) && files.map((file, key) => {
              return (
                <div key={key} style={{ height: 24 }}>
                  {allowDelete
                    ? <span
                        className='del'
                        style={{ marginRight: 10 }}
                        onClick={() => this.removeFile(folderPath, file)}
                      >x
                    </span>
                    : null}
                  <a
                    href={`${API_SERVER}/api/orders/file/${folderPath}/${file}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >{file}
                  </a>
                </div>
              )
            })
          }
        </div>
        <div>
          <label
            className='form-control btn'
            style={{
              width: '120px',
              marginRight: 20,
              display: ['anulowane', 'zamknięte'].includes(data.status) ? 'none' : 'inline-block'
            }}
          >
            Prześlij plik
            <input
              type='file'
              name='file'
              style={{ display: 'none' }}
              onChange={e => this.saveFile(e)}
            />
          </label>
        </div>
      </div>
    )
  }

  getListFiles (folderPath, cb) {
    fetch(`${API_SERVER}/api/orders/files/${folderPath}`)
      .then(res => res.json())
      .catch(error => {
        console.error('Error:', error)
        this.showNotification('Wystąpił błąd')
      })
      .then(response => {
        cb(response)
      })
  }

  removeFile (folderPath, file) {
    fetch(`${API_SERVER}/api/orders/remove-file/${folderPath}/${file}`)
      .then(res => res.json())
      .catch(error => {
        console.error('Error:', error)
        this.showNotification('Wystąpił błąd')
      })
      .then(response => {
        if (response && response.error) {
          console.error('Error:', response.error)
          this.showNotification('Wystąpił błąd')
        } else {
          this.showNotification(response.message, 'success')
          this.getListFiles(folderPath, files => this.setState({ files }))
        }
      })
  }

  saveFile (e) {
    const { slug, data = {} } = this.state
    e.preventDefault()
    e.stopPropagation()

    const formData = new FormData()
    formData.append('file', e.target.files[0], e.target.files[0].name)
    formData.append('orderId', data.order_id)
    const file = formData.get('file')

    if (slug !== 'add' && file.name !== '') {
      this.setState({ loader: true })
      fetch(`${API_SERVER}/api/orders/save-file`, {
        method: 'POST',
        body: formData
      }).catch(error => {
        this.showNotification(`Wystąpił błąd: ${error}`)
        console.error('Error:', error)
      })
        .then(res => res.json())
        .then(results => {
          if (results.error) {
            this.showNotification(`Wystąpił błąd: ${results.error}`)
            console.error('error', results)
          } else {
            this.showNotification(`${results.success}`, 'success')
            console.log('success', results)
            this.getOrder()
          }
        })
    }
  }

  render () {
    const { slug, loader, redirect } = this.state

    if (redirect) { return <Redirect to='/settlements/orders' /> }
    return (
      <div className='content Orders'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Loader display={loader} />
        <form className='form-inline' onSubmit={this.handleSubmit}>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  content={
                    <div className='breadcrumb'>
                      <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                        to='/settlements/orders'
                                                                       >Lista zleceń
                                                                       </Link> » <Link
                        to={this.props.location.pathname}
                                >Szczegóły zlecenia
                                </Link>
                    </div>
                }
                />
              </Col>
            </Row>
            <div className={loader ? 'noEvents' : ''}>
              <Row>
                <Col md={12}>
                  <nav className='navbar navbar-light bg-light'>
                    <div className='btn-group' role='group' aria-label='Basic example' style={{ display: 'flex' }}>
                      <input
                        type='submit'
                        className='btn btn-primary'
                        value='zapisz'
                        onClick={() => {
                          this.dataValidation()
                        }}
                      />
                    </div>
                  </nav>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <Card
                    title={slug === 'add' ? 'Nowe zlecenie' : `Szczegóły zlecenia ${slug}`}
                    content={
                      <Table striped hover>
                        <tbody>
                          {this.renderMainTableCols()}
                        </tbody>
                      </Table>
                  }
                  />
                </Col>
              </Row>
            </div>
          </Grid>
        </form>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main
  }
}

export default connect(mapStateToProps)(OrderDetails)
