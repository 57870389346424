import React, { Component } from 'react'
import { Grid, Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../components/Card/Card.jsx'
import { connect } from 'react-redux'
import NotificationSystem from 'react-notification-system'
import { style } from '../../variables/Variables'

const API_SERVER = process.env.REACT_APP_API_SERVER || '..'

class TableList extends Component {
  constructor (props) {
    super(props)
    this.onRequest = props.onRequest
    this.onResponse = props.onResponse
    this.onClickPrev = this.onClickPrev.bind(this)
    this.onClickNext = this.onClickNext.bind(this)
    this.onKeyPressSearch = this.onKeyPressSearch.bind(this)
    this.onClickSearch = this.onClickSearch.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.loadNext(0)
    this.page = 0
    this.query = ''
    this.state = {
      PrevButton: true
    }
  }

  loadNext (next) {
    const onResponse = this.onResponse
    this.onRequest()
    let query = ''
    if (this.query) {
      query = `filter[where][or][0][name][like]=${this.query}&filter[where][or][1][surname][like]=${this.query}
        &filter[where][or][2][blogName][like]=${this.query}&filter[where][or][3][blogUrl][like]=${this.query}&filter[where][or][4][email][like]=${this.query}
        &filter[where][or][5][id][like]=${this.query}`
    } else {
      query = `filter[limit]=15&filter[skip]=${next}`
    }
    fetch(`${API_SERVER}/db/api/influencers?${query}`)
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then((response) => {
        onResponse(response)
      })
    this.checkPrevButton()
  }

  onClickPrev () {
    this.page--
    this.loadNext(15 * this.page)
  }

  onClickNext () {
    this.page++
    this.loadNext(15 * this.page)
  }

  onClickSearch () {
    this.page = 0
    this.query = document.getElementById('query').value
    this.loadNext(15 * this.page)
  }

  onKeyPressSearch (e) {
    if (e.key === 'Enter') {
      this.onClickSearch()
    }
  }

  checkPrevButton () {
    return this.page === 0 ? this.setState({ PrevButton: true }) : this.setState({ PrevButton: false })
  }

  checkNextButton (data) {
    return data.length < 15
  }

  handleDelete (event, id) {
    fetch(`${API_SERVER}/db/api/influencers/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    })
      .then(response => response.json())
      .then((response) => {
        this.refs.notificationSystem.addNotification({
          title: (<span data-notify='icon' className='pe-7s-check' />),
          message: (
            <div>Rekord usunięty</div>
          ),
          level: 'success',
          position: 'br',
          autoDismiss: 6
        })
        this.loadNext(0)
      })
      .catch((error) => {
        console.error('Error:', error)
        this.refs.notificationSystem.addNotification({
          title: (<span data-notify='icon' className='pe-7s-bandaid' />),
          message: (
            <div>Wystąpił błąd</div>
          ),
          level: 'error',
          position: 'br'
        })
      })
    event.preventDefault()
    event.stopPropagation()
  }

  render () {
    let data = []
    if (this.props.influencers) {
      data = this.props.influencers
    }
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <nav className='navbar navbar-light bg-light'>
                <div className='btn-group' role='group' aria-label='Basic example'>
                  <form className='form-inline my-2 my-lg-0'>
                    <button type='button' className='btn btn-secondary' onClick={this.onClickPrev} disabled={this.state.PrevButton}>« poprzednia</button>
                    <button type='button' className='btn btn-secondary' onClick={this.onClickNext} disabled={this.checkNextButton(data)}>następna »</button>
                    <input type='text' id='query' className='form-control' onKeyPress={this.onKeyPressSearch} />
                    <button type='button' className='btn btn-secondary' onClick={this.onClickSearch}>szukaj</button>
                    <Link to='/influencer/add' className='btn btn-success' role='button'>Dodaj influencera</Link>
                  </form>
                </div>
              </nav>
            </Col>
            <Col md={12}>
              <Card
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <td>ID</td>
                        <td>Imie i nazwisko</td>
                        <td>Nazwa bloga</td>
                        <td>Adres bloga</td>
                        <td>Email</td>
                        <td />
                      </tr>
                    </thead>
                    <tbody>
                      {
                      data.map((prop, key) => (
                        <tr key={key}>
                          <td>{prop.id}</td>
                          <td>{prop.name} {prop.surname}</td>
                          <td>{prop.blogName}</td>
                          <td>{prop.blogUrl}</td>
                          <td>{prop.email}</td>
                          <td>
                            <button
                              type='button' className='btn btn-xs btn-danger' onClick={(e) => {
                                this.handleDelete(e, prop.id)
                              }}
                            >usuń
                            </button>
                            <button
                              type='button' className='btn btn-xs btn-info' onClick={() => {
                                this.props.history.push({
                                  pathname: `/influencer/${prop.id}`,
                                  influencer: { data: prop }
                                })
                              }} style={{ marginLeft: '5px' }}
                            >szczegóły
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  influencers: state.influencers
})

const mapDispatchToProps = {
  onRequest: id => ({
    type: 'REQUEST_INFLUENCER'
  }),
  onResponse: influencers => ({
    type: 'SET_INFLUENCERS',
    influencers
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(TableList)
