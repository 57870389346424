const toDot = (value) => {
  if (value) {
    const _value = value.toString().replace(/ /g, '')
    const dotValue = parseFloat(_value.toString().replace(',', '.'))
    if (isNaN(dotValue)) {
      return ''
    }
    return dotValue
  }
}

const toFixed = (value, digits = 2) => {
  const dotValue = toDot(value)
  if (dotValue === '' || isNaN(dotValue)) {
    return ''
  }
  return (Math.round(dotValue * 100) / 100).toFixed(digits)
}

const thousandSeparator = (value) => {
  if (value === '') {
    return ''
  }
  const parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

const currencyFormatter = (val, prevSign = '', digits) => {
  if (!val) {
    return ''
  }
  const value = toFixed(val, digits)
  return prevSign + thousandSeparator(value)
}

module.exports = {
  currencyFormatter,
  toFixed
}
