import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, FormControl, Grid, Row, Table } from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'
import { style } from '../../../variables/Variables'
import { Card } from '../../../components/Card/Card'
import Loader from '../Components/Loader'
import { getMainBtx } from '../common'
import { Link } from 'react-router-dom'
import FORM_MODEL from './users'
import ScMultiselect from '../../../components/ScMultiselect/SCMultiselect'
import {
  allowedFromDepartments,
  allowedTimeReportsDepartments,
  API_SERVER,
  checkAuthIdUser,
  checkUserType,
  supportDepartments
} from '../authData'
import SCModal from '../Components/Modal'
import moment from 'moment'
import Select from 'react-select'
import ToggleSwitch from '../Components/ToggleSwitch'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import contractType from './contractType'
import Tooltip from '../../../components/Tooltip/Tooltip'

const selectStyles = {
  container: style => ({ ...style, display: 'inline-block' }),
  dropdownIndicator: style => ({ ...style, padding: 1, width: 13 }),
  control: style => ({ ...style, margin: 1, padding: 0, minHeight: 15, fontSize: 11, width: 80 }),
  option: style => ({ ...style, padding: 1, textAlign: 'left', fontSize: 11 }),
  indicatorSeparator: style => ({ ...style, display: 'none' }),
  input: style => ({ ...style, margin: 0, padding: 0 })
}

const simpleSelectOptions = {
  modeSelect: ['(Tryb...)', 'stacjonarny', 'zdalny'],
  importanceSelect: ['(Ważność...)', 'normalna', 'sukcesor', 'krytyczna']
}

class Users extends Component {
  constructor (props) {
    super(props)
    this.setBtxMain = props.setBtxMain
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setModalParams = this.setModalParams.bind(this)
    this.changeSalary = this.changeSalary.bind(this)
    this.state = {
      colParams: FORM_MODEL,
      isShowActive: true,
      isShowExcluded: false,
      searchNameInput: '',
      searchStatus: undefined,
      searchDepartment: undefined,
      editableRow: {},
      loader: true,
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      generateFrom: '2019-05',
      thisMonth: moment().format('YYYY-MM'),
      nextMonth: moment().add(1, 'M').format('YYYY-MM'),
      salaries: {},
      months: [],
      allowToSubmit: true
    }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    const isDev = checkUserType(loggedUser, 'dev')
    const isHR = checkUserType(loggedUser, 'hr')
    const isAuthIdUser = checkAuthIdUser(loggedUser,
      ['200'] // Cezary Śledziak
    )
    this.setState({
      isFinance,
      isDev,
      isHR,
      isAuthIdUser,
      months: this.generateMonths()
    })
    this.getData()
    this.getSalaries()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.isShowActive !== this.state.isShowActive) {
      const options = this.getOptions()
      this.setState({ ...options, editableRow: {} })
    }
    if (prevState.isShowExcluded !== this.state.isShowExcluded) {
      const options = this.getOptions()
      this.setState({ ...options })
    }
  }

  getSalaries () {
    const { loggedUser } = this.state
    fetch(`${API_SERVER}/api/get-salary`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        person: loggedUser
      })
    })
      .then(res => res.json())
      .then(response => {
        if (!response || response.error) {
          console.error('Error:', (response || {}).error)
          this.showNotification('error', `Wystąpił błąd: ${(response || {}).error}`)
        } else {
          this.setState({
            salaries: response.success
          })
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  getData () {
    getMainBtx()
      .then(response => {
        this.setBtxMain(response || [])
        const options = this.getOptions()
        this.setState({
          loader: false,
          ...options,
          editableRow: {}
        })
      })
      .catch(() => this.showNotification('error', 'Wystąpił błąd'))
  }

  getOptions () {
    const usersOptions = this.getUsersOptions()
    const departmentsOptions = this.getDepartmentsOptions()
    return { usersOptions, departmentsOptions }
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs && this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  renderTableHeader () {
    const { colParams } = this.state
    const style = col => ({
      width: colParams[col] ? colParams[col].width : ''
    })

    return (
      Object.keys(colParams).map((col, key) => (
        <th key={key} style={style(col)}>
          <>
            {colParams[col].tooltip && <Tooltip className={key < 3 && 'toRight'}>{colParams[col].tooltip || ''}</Tooltip>}
            <br />
            {colParams[col].pl || ''}
          </>
        </th>
      ))
    )
  }

  getArrayLabel (value) {
    return (Array.isArray(value) && value.map(el => (el || {}).name)
      .join(', ')) || value
  }

  sort (obj = {}) {
    return Object.entries(obj).sort((a, b) => a[0] < b[0] ? 1 : -1)
  }

  getLastMonthValue (obj, col) {
    const sorted = this.sort(obj)
    return (sorted.length && sorted[0][1][col]) || ''
  }

  getDepartmentName (arr = [], departments = []) {
    return arr.length
      ? (departments.find(el => el.ID === arr[0]) || {}).NAME
      : 'bez działu'
  }

  getUsersOptions () {
    const { users = [], departments = [] } = this.props.main || {}

    return users.length && users.map(el => ({
      id: el.ID,
      name: el.NAME,
      scDepartment: this.getDepartmentName(el.scDepartment || [], departments)
    })).sort((a, b) => {
      if (a.scDepartment === b.scDepartment) {
        return a.name < b.name ? -1 : 1
      }
      return a.scDepartment < b.scDepartment ? -1 : 1
    })
  }

  getDepartmentsOptions () {
    const { departments = [] } = this.props.main || {}
    return departments
      .filter(el => allowedFromDepartments
        .concat(allowedTimeReportsDepartments.map(el => `${el}`))
        .concat(supportDepartments.map(el => `${el}`))
        .includes(el.ID))
      .map(el => ({
        id: el.ID,
        name: el.NAME
      }))
  }

  getMultiselectValues (val, elem) {
    const { editableRow, thisMonth } = this.state
    editableRow[elem] = val
    if (elem === 'scDepartment') {
      if (!editableRow.monthly) {
        editableRow.monthly = { [thisMonth]: { scDepartment: val } }
      } else {
        (editableRow.monthly[thisMonth] || {}).scDepartment = val
      }
    }
    if (elem === 'scExcluded') {
      this.setState({ isShowExcluded: true })
    }
    this.setState({ editableRow })
  }

  setDate (date, fieldName, editableRow, idx) {
    if (typeof idx === 'undefined') {
      editableRow[fieldName] = date
    } else {
      editableRow.scWorkParams[idx][fieldName] = date
    }
    this.setState({ editableRow })
  }

  b2bSelect (value, month) {
    const { editableRow } = this.state
    const b2bOptions = [
      { value: '', label: '(brak)' },
      { value: 'cube', label: 'cube' },
      { value: 'hog', label: 'hog/ut' },
      { value: 'uz', label: 'uz cube' }
    ]
    const initValue = b2bOptions.filter(el => el.value === value)
    return (
      <Select
        options={b2bOptions}
        value={initValue}
        styles={{
          ...selectStyles,
          control: style => ({ ...style, margin: 1, padding: 0, minHeight: 15, fontSize: 11, width: 80 }),
          menu: style => ({ ...style, width: 80 })
        }}
        placeholder='wybierz'
        onChange={(e) => {
          editableRow.monthly[month].b2bReport = e.value
          this.setState({ editableRow }, () => this.checkAllowToSumbit(month))
        }}
      />
    )
  }

  checkAllowToSumbit (month) {
    const { editableRow } = this.state
    const m = editableRow.monthly && editableRow.monthly[month] ? editableRow.monthly[month] : {}
    const allowToSubmit = !!m.workPosition &&
      !!m.scWage &&
      (m.scDepartment && m.scDepartment.length) &&
      (m.upReport || m.kmReport || m.b2bReport) &&
      (m.b2bReport ? m.b2bName !== '' : true)
    this.setState({ allowToSubmit })
  }

  b2bName (value, month) {
    const { editableRow } = this.state
    return (
      <input
        type='text'
        value={value || ''}
        placeholder='wpisz nazwę firmy'
        style={{ width: 125 }}
        className={editableRow.monthly[month].b2bReport && editableRow.monthly[month].b2bName === '' ? 'input-error' : ''}
        onChange={(e) => {
          editableRow.monthly[month].b2bName = e.target.value
          this.setState({ editableRow }, () => this.checkAllowToSumbit(month))
        }}
      />
    )
  }

  statusSelect (value, idx) {
    const { editableRow } = this.state
    const options = ['(Status...)', 'zatrudniony', 'odszedł', 'zwolniony'].map((el, key) => ({ value: key === 0 ? '' : el, label: el }))
    const initValue = options.filter(el => el.value === value)
    return (
      <Select
        options={options}
        value={initValue}
        key={`s${idx}`}
        styles={{
          ...selectStyles,
          control: style => ({ ...style, margin: 1, padding: 0, minHeight: 15, fontSize: 11, width: typeof idx === 'undefined' ? 120 : 80 }),
          menu: style => ({ ...style, width: 80 })
        }}
        placeholder='Status...'
        onChange={(e) => {
          if (typeof idx === 'undefined') {
            this.setState({ searchStatus: e.value })
          } else {
            editableRow.scWorkParams[idx].status = e.value
            this.setState({ editableRow })
          }
        }}
      />
    )
  }

  simpleSelect (value, type, colName) {
    const { editableRow } = this.state
    const options = simpleSelectOptions[type].map((el, key) => ({ value: key === 0 ? '' : el, label: el }))
    const initValue = options.filter(el => el.value === value)
    return (
      <Select
        options={options}
        value={initValue}
        styles={{
          ...selectStyles,
          control: style => ({ ...style, margin: 1, padding: 0, minHeight: 15, fontSize: 11, width: 80 }),
          menu: style => ({ ...style, width: 80 })
        }}
        placeholder={options[0].label}
        onChange={(e) => {
          editableRow[colName] = e.value
          this.setState({ editableRow })
        }}
      />
    )
  }

  datePicker (col, key, users, editableRow, value, idx) {
    return (
      <DayPickerInput
        inputProps={{
          id: `${col}${idx}`,
          name: col,
          style: { width: 80 }
        }}
        value={value || ''}
        key={`d${idx}`}
        formatDate={date => moment(date).format('YYYY-MM-DD')}
        placeholder=''
        onDayChange={(value) => {
          const date = value ? moment(value).format('YYYY-MM-DD') : ''
          this.setDate(date, col, editableRow, idx)
        }}
        dayPickerProps={{ firstDayOfWeek: 1 }}
      />
    )
  }

  getRowValues (col, key, colParams, users) {
    const { usersOptions = [], departmentsOptions = [], editableRow } = this.state
    const options = col === 'scHead' ? usersOptions : departmentsOptions
    const groupBy = col === 'scHead' ? 'scDepartment' : null
    const isEditable = users[key].ID === editableRow.ID

    let value = users[key][col]
    if (Array.isArray(users[key][col]) && col !== 'scSalary') {
      value = users[key][col].map(el => options.find(el2 => el2.id === el))
    }
    if (['startDate', 'endDate', 'status'].includes(col)) {
      value = users[key].scWorkParams || []
    }
    if (col === 'customWorktime' && isEditable) {
      return <input type='checkbox' name={col} checked={value || ''} value={1} disabled={!isEditable || value === true} onChange={() => this.getMultiselectValues(!value, col)} />
    }
    if (colParams[col].type === 'checkbox') {
      return <input type='checkbox' name={col} checked={value || ''} value={1} disabled={!isEditable} onChange={() => this.getMultiselectValues(!value, col)} />
    }
    if (col === 'ACTIVE') {
      return value && <span className='p-3'><i className='pe-7s-check accept-cell-icon' /></span>
    }
    if (colParams[col].type === 'select' && col === 'scHead') {
      const output1 = isEditable
        ? (
          <ScMultiselect
            name={col}
            options={options}
            value={value}
            readOnly
            getValues={this.getMultiselectValues.bind(this)}
            valueField='id'
            textField='name'
            groupBy={groupBy}
          />
          )
        : this.getArrayLabel(value)

      const accessToSettlementsIds = users[key].accessToSettlementsIds

      const output2 = (accessToSettlementsIds && accessToSettlementsIds.length) || isEditable
        ? <><br /><label>dostęp do rozliczeń</label><Tooltip>Niestandardowy dostęp do rozliczeń osób innych niż podległe (np. podczas zastępstwa)</Tooltip><br /></>
        : null

      const accessToSettlementsValue = (accessToSettlementsIds || []).map(el => options.find(el2 => el2.id === el))
      const output3 = isEditable
        ? (
          <ScMultiselect
            name='accessToSettlementsIds'
            options={options}
            value={accessToSettlementsValue}
            readOnly
            getValues={this.getMultiselectValues.bind(this)}
            valueField='id'
            textField='name'
            groupBy={groupBy}
          />
          )
        : this.getArrayLabel(accessToSettlementsValue)

      return <>{output1}{output2}{output3}</>
    }
    if (['startDate', 'endDate', 'status'].includes(col)) {
      return value && value.map((el, idx) => {
        if (isEditable) {
          if (colParams[col].type === 'date') {
            return this.datePicker(col, key, users, editableRow, el[col], idx)
          }
          return this.statusSelect(el[col], idx)
        }
        return el[col]
      })
        .reduce((prev, curr) => [prev, curr, <br key={key} />], [])
        .concat(isEditable && (col === 'startDate'
          ? <Link
              to='' className='w-100 mt-1' key={`l${key}`} title='Dodaj kolejny okres zatrudnienia' onClick={(e) => {
              e.preventDefault()
              editableRow.scWorkParams = [...(editableRow.scWorkParams || []), {}]
              this.setState({ editableRow })
            }}
            >+ dodaj
            </Link>
          : <br key={`b${key}`} />))
    }
    if ((colParams[col].type === 'modeSelect' || colParams[col].type === 'importanceSelect') && isEditable) {
      return this.simpleSelect(value, colParams[col].type, col)
    }
    if (colParams[col].type === 'date' && isEditable) {
      return this.datePicker(col, key, users, editableRow, value)
    } else {
      if (['scSalary', 'scWage', 'scMatrix'].includes(col)) {
        return this.getLastMonthValue(users[key].monthly, col)
      }
      if (col === 'contractType') {
        return contractType(users[key].monthly, this.state.thisMonth, this.state.nextMonth)
      }
      if (col === 'scDepartment') {
        return value && value.map(el => el && el.name).join(', ')
      }
      return colParams[col].type === 'select'
        ? this.getArrayLabel(value)
        : value
    }
  }

  startDateExists ({ scWorkParams = [] }) {
    return scWorkParams.length && scWorkParams.every((param) => param.startDate)
  }

  renderTableContent () {
    const { colParams, isShowActive, isShowExcluded, searchNameInput, searchStatus, searchDepartment, editableRow } = this.state
    let { users = [] } = this.props.main || {}

    const dontShow = 'save'

    if (isShowActive) {
      users = users.filter(el => el.ACTIVE)
    }

    if (!isShowExcluded) {
      users = users.filter(el => !el.scExcluded)
    }

    if (searchNameInput) {
      users = users.filter(el => el.NAME.toLowerCase().includes(searchNameInput.toLowerCase()))
    }

    if (searchStatus) {
      users = users.filter(el => el.scWorkParams && el.scWorkParams.some(el2 => el2.status === searchStatus))
    }

    if (searchDepartment) {
      users = users.filter(el => (el.scDepartment || []).includes(searchDepartment))
    }

    users.sort((a, b) => a.NAME > b.NAME ? 1 : -1)
    return users.length
      ? users.map((user, key) => (
        <tr key={key}>
          {
            Object.keys(colParams)
              .filter(el => el !== dontShow)
              .map((col, key2) => (
                <td key={key2}>
                  {this.getRowValues(col, key, colParams, users)}
                </td>
              ))
          }
          <td style={{ display: 'flex', flexWrap: 'wrap', minHeight: 29 }}>
            <input
              type='button'
              className='btn btn-primary btn-sm hover-button'
              value={users[key].ID === editableRow.ID ? 'Zapisz' : 'Edytuj'}
              style={{ display: editableRow.ID ? (users[key].ID !== editableRow.ID ? 'none' : 'block') : null }}
              onClick={(e) => {
                if (users[key].ID === editableRow.ID) {
                  this.handleSubmit(e)
                } else {
                  this.setState({ editableRow: users[key] })
                }
              }}
            />
            {
              users[key].ID === editableRow.ID
                ? <>
                  <br />
                  <input
                    type='button'
                    className='btn btn-secondary btn-sm mt-1'
                    value='Anuluj'
                    onClick={() => {
                      this.getData()
                    }}
                  />
                  <br />
                  <Link
                    to='' className='w-100 mt-1' onClick={(e) => {
                      e.preventDefault()
                      if (!this.startDateExists(editableRow)) {
                        return this.showNotification('error', 'Uzupełnij datę startu')
                      }
                      this.setModalParams(true, 'Zarządzaj miesiącami', '', this.changeSalary)
                    }}
                  >Zarządzaj miesiącami
                  </Link>
                  </>
                : null
            }
          </td>
        </tr>
      ))
      : null
  }

  renderActiveSwitch () {
    const { isShowActive } = this.state
    return (
      <ToggleSwitch
        checked={isShowActive}
        name='isShowActive'
        label='tylko aktywni'
        toggleSwitch={() => this.setState({ isShowActive: !isShowActive })}
      />
    )
  }

  renderExcludedSwitch () {
    const { isShowExcluded } = this.state
    return (
      <ToggleSwitch
        checked={isShowExcluded}
        name='isShowExcluded'
        label='+wykluczeni'
        toggleSwitch={() => this.setState((old) => ({ isShowExcluded: !old.isShowExcluded }))}
        style={{ marginLeft: 20 }}
      />
    )
  }

  renderSearchNameInput () {
    const { searchNameInput } = this.state
    return (
      <FormControl
        type='text'
        id='searchNameInput'
        placeholder='Nazwisko...'
        value={searchNameInput}
        style={{ marginRight: 20, height: 25, fontSize: 11, width: 120, borderColor: 'hsl(0, 0%, 80%)' }}
        onChange={(e) => this.setState({ searchNameInput: e.target.value })}
      />
    )
  }

  renderSearchDepartmentSelect () {
    const options = ['(Dział...)'].concat(this.state.departmentsOptions || []).map((el, key) => ({ value: key === 0 ? '' : el.id, label: el.name || el }))
    const initValue = (this.state.departmentsOptions || []).filter(el => el.id === this.state.searchDepartment)
    return (
      <Select
        options={options}
        value={initValue.value}
        styles={{
          ...selectStyles,
          control: style => ({ ...style, margin: 1, marginLeft: 20, marginRight: 120, padding: 0, minHeight: 15, fontSize: 11, width: 120 }),
          menu: style => ({ ...style, width: 120 })
        }}
        placeholder='Dział...'
        onChange={(e) => {
          this.setState({ searchDepartment: e.value })
        }}
      />
    )
  }

  renderSearchStatusSelect () {
    return this.statusSelect(this.state.searchStatus)
  }

  handleSubmit () {
    const { editableRow } = this.state

    this.showNotification('info', 'Wysyłanie danych')

    fetch(`${API_SERVER}/api/manage-users`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ changes: editableRow })
    })
      .then(res => res.json())
      .then(response => {
        if (!response || response.error) {
          this.showNotification('error', `Wystąpił błąd: ${(response && response.error) || ''}`)
          console.error('Error:', response.error)
        }
        if (response.success) {
          this.showNotification('success', response.message || '', 6)
          console.log('Success:', response)
          this.getData()
        }
      })
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
  }

  /** modal */

  setModalParams (show = false, title = '', content = '', method = null) {
    this.setState({
      modalShow: show,
      modalTitle: title,
      modalContent: content,
      modalMethod: method
    })
  }

  changeSalary () {
    this.handleSubmit()
  }

  getRangeOfDates (start, end, key, arr = [start.startOf(key)]) {
    const next = moment(start).add(1, key).startOf(key)
    if (next.isAfter(end, key)) return arr
    return this.getRangeOfDates(next, end, key, arr.concat(next))
  }

  generateMonths () {
    const { generateFrom, thisMonth } = this.state
    const rangeOfDates = this.getRangeOfDates(moment(generateFrom), moment(thisMonth), 'month')
    return rangeOfDates.map(el => moment(el).format('YYYY-MM')).reverse() || []
  }

  modalContent (editableRow = {}) {
    const { monthly = {}, scDepartment, WORK_POSITION: workPosition, scWorkParams = [] } = editableRow
    const { salaries = {}, departmentsOptions = [], months, thisMonth, nextMonth } = this.state
    const monthArr = Object.keys(monthly).sort((a, b) => (a > b ? -1 : 1)) || months
    const currentWorkParams = scWorkParams.find(param => !param.endDate)
    const firstMonthForUser = currentWorkParams && !Object.keys(monthly).length
      ? moment(currentWorkParams.startDate).format('YYYY-MM')
      : null
    const biggestMonthForUser = Object.keys(monthly).reduce((a, b) => a > b ? a : b, 0)
    const nextMonthForUser = moment(biggestMonthForUser).add(1, 'M').format('YYYY-MM')
    return (
      <div>
        {
          !monthly[nextMonth] &&
            <button
            className='btn btn-sm btn-info'
            // disabled={Object.keys(monthly).length && !monthly[thisMonth]}
            onClick={() => {
              this.setState((old) => {
                old.months.unshift(nextMonthForUser)
                const thisMonthly = (old.editableRow.monthly || {})[biggestMonthForUser]
                if (thisMonthly) {
                  old.editableRow.monthly[nextMonthForUser] = { ...thisMonthly }
                } else {
                  old.editableRow.monthly = { [firstMonthForUser]: { scDepartment, workPosition } }
                }
                return old
              }, () => this.checkAllowToSumbit(thisMonth))
            }}
          >+ dodaj miesiąc
          </button>
        }
        <Table striped hover className='users-modal-table'>
          <thead>
            <tr>
              <th style={{ width: '5%' }}>MIESIĄC</th>
              <th style={{ width: '10%' }}>STANOWISKO</th>
              <th style={{ width: '10%' }}>POZIOM STANOWISKOWY</th>
              <th style={{ width: '5%' }}>POZIOM PŁAC</th>
              <th style={{ width: '5%' }}>MATRYCA</th>
              <th style={{ width: '15%' }}>DZIAŁ</th>
              <th style={{ width: '10%' }}>UMOWA UP</th>
              <th style={{ width: '10%' }}>UMOWA KM</th>
              <th style={{ width: '25%' }}>UMOWA B2B/UZ</th>
            </tr>
          </thead>
          <tbody>
            {
              monthArr.map((month, key) => {
                const thisMonthly = monthly[month] || {}
                const options = (salaries[month] || [])
                  .map(el => ({ value: el.step, label: `${el.step} - ${el.hourly}` }))
                const step = thisMonthly.scSalary

                const selected = (salaries[month] || []).find(el => el.step === step) || {}
                const initValue = { value: '', label: '' }
                initValue.value = selected.step
                initValue.label = `${selected.step || ''} - ${selected.hourly || ''}`

                const scDepartmentValue = (thisMonthly.scDepartment || []).map(el => departmentsOptions.find(el2 => el2.id === el))

                const scWageOpts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(el => ({ value: el, label: `${el}` }))
                const scMatrixOpts = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(el => ({ value: el, label: `${el}` }))
                const workTimeOpts = [8, 7, 6, 5, 4, 3, 2, 1].map(el => ({ value: el, label: `${el}` }))
                const workTimeUOPValue = { value: thisMonthly.workTimesUP, label: thisMonthly.workTimesUP }
                const workTimeB2BValue = { value: thisMonthly.workTimesB2B, label: thisMonthly.workTimesB2B }
                const workTimeKMValue = { value: thisMonthly.workTimesKM, label: thisMonthly.workTimesKM }
                const scWageValue = { value: thisMonthly.scWage, label: thisMonthly.scWage }
                const scMatrixValue = { value: thisMonthly.scMatrix, label: thisMonthly.scMatrix }
                const workPosition = thisMonthly.workPosition || ''

                return (
                  <tr key={key}>
                    <td style={{ paddingRight: 20, whiteSpace: 'nowrap' }}>{month}</td>
                    <td>
                      <input
                        type='text'
                        value={workPosition}
                        placeholder='Stanowisko...'
                        onChange={(e) => {
                          editableRow.monthly[month].workPosition = e.target.value
                          this.setState({ editableRow }, () => this.checkAllowToSumbit(month))
                        }}
                        className={!editableRow.monthly[month].workPosition ? 'input-error' : ''}
                      />
                    </td>
                    <td>
                      <Select
                        options={options}
                        value={initValue}
                        styles={selectStyles}
                        onChange={(e) => {
                          thisMonthly.scSalary = e.value
                          this.setState({ editableRow })
                        }}
                      />
                    </td>
                    <td>
                      <Select
                        options={scWageOpts}
                        value={scWageValue}
                        styles={selectStyles}
                        onChange={(e) => {
                          thisMonthly.scWage = e.value
                          this.setState({ editableRow }, () => this.checkAllowToSumbit(month))
                        }}
                      />
                    </td>
                    <td>
                      <Select
                        options={scMatrixOpts}
                        value={scMatrixValue}
                        styles={selectStyles}
                        onChange={(e) => {
                          thisMonthly.scMatrix = e.value
                          this.setState({ editableRow })
                        }}
                      />
                    </td>
                    <td>
                      <ScMultiselect
                        name='scDepartment'
                        options={this.state.departmentsOptions}
                        value={scDepartmentValue}
                        getValues={(val) => {
                          thisMonthly.scDepartment = val
                          if (month === thisMonth) {
                            editableRow.scDepartment = val
                          }
                          this.setState({ editableRow }, () => this.checkAllowToSumbit(month))
                        }}
                        valueField='id'
                        textField='name'
                      />
                    </td>
                    <td>
                      <input
                        type='checkbox' checked={thisMonthly.upReport || ''} value={1} onChange={() => {
                          this.setState((prev) => thisMonthly.upReport = !prev.editableRow.monthly[month].upReport, () => this.checkAllowToSumbit(month))
                        }}
                      /> <label style={{ fontSize: 12 }}>Raport UP</label>
                      <Select
                        options={workTimeOpts}
                        value={workTimeUOPValue}
                        styles={selectStyles}
                        onChange={(e) => {
                          thisMonthly.workTimesUP = e.value
                          this.setState({ editableRow })
                        }}
                      />
                    </td>
                    <td style={{ verticalAlign: 'top' }}>
                      <input
                        type='checkbox' checked={thisMonthly.kmReport || ''} value={1} onChange={() => {
                          this.setState((prev) => thisMonthly.kmReport = !prev.editableRow.monthly[month].kmReport, () => this.checkAllowToSumbit(month))
                        }}
                      /> <label style={{ fontSize: 12 }}>Raport KM</label>
                      {
                        thisMonthly.kmReport &&
                          <Select
                          options={workTimeOpts}
                          value={workTimeKMValue}
                          styles={selectStyles}
                          onChange={(e) => {
                            thisMonthly.workTimesKM = e.value
                            this.setState({ editableRow })
                          }}
                        />
                      }
                    </td>
                    <td style={{ verticalAlign: 'top' }}>
                      <>{this.b2bSelect(thisMonthly.b2bReport, month)} {editableRow.monthly[month].b2bReport && this.b2bName(thisMonthly.b2bName, month)}</>
                      {
                        thisMonthly.b2bReport &&
                          <Select
                          options={workTimeOpts}
                          value={workTimeB2BValue}
                          styles={selectStyles}
                          onChange={(e) => {
                            thisMonthly.workTimesB2B = e.value
                            this.setState({ editableRow })
                          }}
                        />
                      }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }

  renderModal () {
    const { modalShow, modalTitle, modalMethod, editableRow = {}, allowToSubmit } = this.state
    return (
      <SCModal
        modalShow={modalShow}
        modalTitle={modalTitle}
        modalContent={this.modalContent(editableRow)}
        setModalParams={this.setModalParams}
        propMethod={modalMethod}
        size='large'
        dialogClassName='users-modal-dialog'
        allowToSubmit={allowToSubmit}
      />
    )
  }

  render () {
    const { loader, isFinance, isDev, isAuthIdUser, isHR } = this.state
    if (isFinance || isDev || isAuthIdUser || isHR) {
      return (
        <div className='content Users blue-tooltip'>
          <NotificationSystem ref='notificationSystem' style={style} />
          <Loader display={loader} />
          <form className='form-inline' onSubmit={this.handleSubmit}>
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Card
                    content={
                      <div>
                        <div className='breadcrumb'>
                          <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                            to='/settlements/users'
                                                                           >Pracownicy
                                                                           </Link>
                        </div>

                      </div>
                    }
                  />
                </Col>
              </Row>
              <div className={loader ? 'noEvents' : ''}>
                <Row>
                  <Col md={12}>
                    <div>
                      <div style={{ textAlign: 'center', marginBottom: 10 }}>
                        {this.renderSearchNameInput()}
                        {this.renderSearchStatusSelect()}
                        {this.renderSearchDepartmentSelect()}
                        {this.renderActiveSwitch()}
                        {this.renderExcludedSwitch()}
                        <a key='1' className='header-link' target='_blank' rel='noopener noreferrer' href='https://docs.google.com/spreadsheets/d/14p6wIS8-zrXX7fI_FEXMfmEXYE-7SH2eVA46rS5sCZo/edit#gid=0' style={{ fontSize: 16, textDecoration: 'underline' }}>DRABINKA STANOWISK</a>
                      </div>
                      <Card
                        title='Pracownicy'
                        ctTableFullWidth
                        ctTableResponsive
                        content={
                          <div>
                            <Table striped hover>
                              <thead>
                                <tr>
                                  {this.renderTableHeader()}
                                </tr>
                              </thead>
                              <tbody>
                                {this.renderTableContent()}
                              </tbody>
                            </Table>
                          </div>
                        }
                      />
                    </div>
                    {this.renderModal()}
                  </Col>
                </Row>
              </div>
            </Grid>
          </form>
        </div>
      )
    } else {
      return (
        <div className='content SalaryGrid'>
          <NotificationSystem ref='notificationSystem' style={style} />
          <div>Brak dostępu</div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main
  }
}

const mapDispatchToProps = {
  setBtxMain: mainBtx => ({
    type: 'MAIN_BTX',
    mainBtx
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
