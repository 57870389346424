import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { StatsCard } from '../../components/StatsCard/StatsCard.jsx'

const rooms = {
  Front: 'qhg-khfi-rpm',
  'E-mail marketing': 'nxc-uvej-wfm',
  Programmatic: 'xjs-jrdx-axw',
  Performance: 'cek-dgvf-dmj',
  Creative: 'gkf-ogxn-edw',
  'SEM & Social': 'tfh-tdwr-zei',
  Analityka: 'xbc-uhja-dxv',
  Development: 'gku-xyax-gyz',
  Finanse: 'rhs-prnn-ofg',
  Traffic: 'uws-youj-duq',
  Content: 'xwd-ohqn-gri',
  SEO: 'gsa-kzmc-zkg',
  HR: 'kdb-davm-svs',
  'PR&Marketing': 'yev-ckod-bne',
  Strategia: 'noy-riim-uxd',
  Office: 'sns-dsfs-emr',
  Zarząd: 'bfq-beco-zhu',
  COO: 'vrf-rnzy-hve'
}

export default () => {
  const [visible, setVisible] = useState(false)

  return (
    <div className='virtual-rooms'>
      <h4>
        Wirtualne pokoje
        <button className='btn btn-sm btn-info' style={{ marginLeft: '10px' }} onClick={() => setVisible(!visible)}>{visible ? 'ukryj' : 'pokaż'}</button>
      </h4>
      <Row className={`virtual-rooms ${!visible ? 'hidden' : ''}`}>
        {Object.keys(rooms).map((room, key) => (
          <Col lg={3} sm={12} key={key}>
            <a href={`https://meet.google.com/${rooms[room]}`} target='_blank' rel='noopener noreferrer'>
              <StatsCard bigIcon={<i className='pe-7s-users text-success' />} statsText='' statsValue={room} />
            </a>
          </Col>
        ))}
      </Row>
    </div>
  )
}
