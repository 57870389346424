import React, { Component } from 'react'
import {
  Grid, Row, Col
} from 'react-bootstrap'
import { connect } from 'react-redux'
import NotificationSystem from 'react-notification-system'
import { style } from '../../variables/Variables'
import bitrixConfig from '../../bitrixConfig.json'

const env = process.env.REACT_APP_NODE_ENV || 'development'

class Login extends Component {
  constructor(props) {
    super(props)
    this.onLogin = props.onLogin.bind(this)
  }

  renderButtons() {
    const sessionRedirect = window.sessionStorage.getItem('redirectUrl') || '/'
    return (
      <div>
        <a className='btn-block btn btn-lg btn-default' href={`https://cube.bitrix24.pl/oauth/authorize?response_type=code&client_id=${bitrixConfig[env].client_id}&redirect_uri=${bitrixConfig[env].redirect_uri}/api/oauth2/bitrix?url=${sessionRedirect}`}>Logowanie przez konto Bitrix</a>
      </div>
    )
  }

  render() {
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Grid fluid>
          <Row>
            <Col md={8}>
              {this.renderButtons()}
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = {
  onLoginSubmit: id => ({
    type: 'INIT_LOGIN'
  }),
  onLogin: user => ({
    type: 'SET_USER',
    user
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
