import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Grid, Row } from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'
import { style } from '../../../variables/Variables'
import { Card } from '../../../components/Card/Card'
import Loader from '../Components/Loader'
import { getMainMap, getMapLabel } from '../common'
import { Link } from 'react-router-dom'
import hcvaForm from './hcva'
import { API_SERVER, checkUserType, checkAuthIdUser } from '../authData'
import AgGrid from '../Components/AgGrid'
import HcvaAddForm from './HcvaAddForm'
import { currencyFormatter } from '../Components/currencyFormatter'

class HcvaList extends Component {
  constructor (props) {
    super(props)
    this.addItem = this.addItem.bind(this)
    this.editValueCell = this.editValueCell.bind(this)

    this.setFilters = props.setFilters
    this.state = {
      colParams: hcvaForm,
      loader: true,
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      mainMap: getMainMap(props.main)
    }
    this.state.mainMap.departments.all = { name: 'WSZYSTKIE DZIAŁY' }
    this.state.mainMap.departments['1'] = { name: 'SUMA DZIAŁÓW' }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    const isDev = checkUserType(loggedUser, 'dev')
    const isAuthIdUser = checkAuthIdUser(loggedUser,
      ['200'] // Cezary Śledziak
    )
    this.setState({
      isFinance,
      isDev,
      isAuthIdUser
    })
    this.getData()
  }

  getData () {
    fetch(`${API_SERVER}/db/api/hcva`)
      .then(res => res.json())
      .then(response => {
        if (!response || response.error) {
          console.error('Error:', (response || {}).error)
          this.setState({ loader: false, formBody: {}, errors: {} })
          this.showNotification('error', `Wystąpił błąd: ${(response || {}).error || ''}`)
        } else {
          this.setState({ data: response, loader: false, formBody: {}, errors: {} }, () => {
            this.prepareCols()
            this.prepareRows()
          })
        }
      })
      .catch(error => {
        console.error('Error:', error)
        this.showNotification('error', 'Wystąpił błąd')
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  addItem (formBody) {
    this.setState({ loader: true }, () => {
      fetch(`${API_SERVER}/db/api/hcva`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: Object.keys(formBody)
          .map(k => (!/^\s*$/.test(formBody[k]))
            ? encodeURIComponent(k) + '=' + encodeURIComponent(formBody[k])
            : undefined)
          .filter(k => k !== undefined)
          .join('&')
      })
        .then(response => response.json())
        .then((res) => {
          if (!res || res.error) {
            this.setState({
              errors: (res.error.details && res.error.details.messages) ? res.error.details.messages : res.error,
              loader: false
            }, () => this.showNotification('error', `W formularzu wystąpiły błędy ${res.error && res.error.message}`, 10))
          } else {
            this.setState({ loader: false, showAddForm: false }, () => {
              this.showNotification('success', 'Zmiany zostały zapisane')
              this.getData()
            })
          }
        })
        .catch((error) => {
          console.error(error)
          window.alert('Wystąpił błąd')
          this.setState({ loader: false })
        })
    })
  }

  editValueCell (e) {
    if (e.oldValue + '' !== e.newValue) {
      const newValue = e.column.colId === 'hcva' ? currencyFormatter(e.newValue.toString().replace(/\s/g, '').replace(',', '.'), '', 1) : e.newValue
      const data = { [e.column.colId]: newValue }
      fetch(`${API_SERVER}/db/api/hcva/${e.data._id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(response => {
          if (!response || response.error) {
            console.error(response.error)
            this.showNotification('error', `Wystąpił błąd  ${response.error && response.error.message}`)
          } else {
            this.showNotification('success', 'Zmiany zostały zapisane')
          }
        })
        .catch(err => {
          console.error(err)
          this.showNotification('error', 'Wystąpił błąd krytyczny')
        })
    }
  }

  removeItem (id) {
    this.setState({ loader: false }, () => {
      fetch(`${API_SERVER}/db/api/hcva/${id}`, {
        method: 'DELETE'
      })
        .then(response => response.json())
        .then(() => {
          this.getData()
        })
        .catch((error) => {
          console.error(error)
          window.alert('Wystąpił błąd')
          this.setState({ loader: false })
        })
    })
  }

  prepareRows () {
    const { data = [], colParams, mainMap } = this.state
    const rows = data.length
      ? data.map((elem) => {
        let row = {}
        Object.entries(colParams).forEach(([col, params]) => {
          const value = params.hasOwnProperty('source')
            ? getMapLabel(mainMap[params.source], elem[col])
            : elem[col]
          row = { ...row, [col]: value || '' }
        })
        return row
      })
      : []
    this.setState({ preparedRows: rows })
  }

  prepareCols () {
    const { colParams } = this.state
    const dontShow = ['_id', 'hidden', 'hcva']

    const columns = Object.entries(colParams)
      .filter(([col]) => !dontShow.includes(col))
      .map(([col, params]) => ({
        field: col,
        colId: col,
        headerName: params.pl,
        editable: params.editable,
        width: parseInt(params.width, 10) || null,
        filter: true
      }))
    columns.push({
      field: 'hcva',
      headerName: 'Min. HCVA',
      filter: true,
      width: 150,
      editable: true,
      cellClass: () => 'editable-cell text',
      cellRenderer: (params) => currencyFormatter(params.value, '', 1)
    })
    columns.push({
      field: 'hidden',
      headerName: 'Ukryte w Estymacjach',
      filter: false,
      width: 150,
      editable: true,
      cellClass: () => 'editable-cell text',
      cellEditor: 'datePicker'
    })

    columns.push({
      field: 'remove',
      headerName: 'Usuń',
      width: 150,
      filter: false,
      cellRenderer: params => {
        const button = document.createElement('button')
        button.innerText = 'Usuń'
        button.classList.value = 'btn btn-sm accept-btn btn-dark'
        button.addEventListener('click', (e) => {
          e.preventDefault()
          this.removeItem(params.data._id)
        })
        return button
      }
    })

    this.setState({ preparedCols: columns })
  }

  renderAgGrid () {
    const { preparedRows = [], preparedCols = [] } = this.state
    return (
      preparedCols.length && preparedRows.length
        ? <AgGrid
            columns={preparedCols}
            rows={preparedRows}
            amountCols={['-']}
            table='hcva'
            pinnedBottomRowData={false}
            cellValueChanged={this.editValueCell}
          />
        : null
    )
  }

  render () {
    const { loader, showAddForm = false, isFinance, isDev, isAuthIdUser } = this.state
    if (isFinance || isDev || isAuthIdUser) {
      return (
        <div className='content'>
          <NotificationSystem ref='notificationSystem' style={style} />
          <Loader display={loader} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  content={
                    <div>
                      <div className='breadcrumb'>
                        <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                          to='/settlements/hcva'
                                                                         >hcva
                        </Link>
                      </div>

                    </div>
                  }
                />
              </Col>
            </Row>
            <div className={loader ? 'noEvents' : ''}>
              <Row>
                <Col md={12}>
                  <Card
                    title={<div>Hcva
                      {
                        showAddForm
                          ? <HcvaAddForm main={this.props.main} addItem={this.addItem} />
                          : <button
                              className='btn btn-primary btn-sm'
                              style={{ marginLeft: '3rem', marginBottom: '0.5rem' }}
                              onClick={(e) => {
                                e.preventDefault()
                                this.setState({ showAddForm: true })
                              }}
                            >+ dodaj pozycję
                          </button>
                      }
                    </div>}
                    ctTableFullWidth
                    ctTableResponsive
                    content={this.renderAgGrid()}
                  />
                </Col>
              </Row>
            </div>
          </Grid>
        </div>
      )
    } else {
      return (
        <div className='content'>
          <NotificationSystem ref='notificationSystem' style={style} />
          <div>Brak dostępu</div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main,
    filters: state.settlements.filters
  }
}

const mapDispatchToProps = {
  setFilters: data => ({
    type: 'SETTLEMENTS_FILTERS',
    filters: data
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(HcvaList)
