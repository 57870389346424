import React, { Component } from 'react'
import { Grid, Col, Table, Row, FormGroup, FormControl } from 'react-bootstrap'
import _ from 'lodash'
import NotificationSystem from 'react-notification-system'
import { connect } from 'react-redux'
import SimpleDataTable from '../../../components/Table/SimpleDataTable'
import Card from '../../../components/Card/Card'
import { style } from '../../../variables/Variables'
import formModel from './form_data'
import adspaceSubcategories from './adspaceSubcategories'
import moment from 'moment'

const md5 = require('md5')

const API_SERVER = process.env.REACT_APP_API_SERVER || '..'
const API_INGENIOUS = API_SERVER + '/ig/masterdata'
const FORM_MODEL = formModel()
const ADSPACE_SUBCATEGORIES = adspaceSubcategories()
const AD_SPACES = Object.keys(FORM_MODEL.adSpace)
const EXCLUDED_PROPS = ['users', 'sm_users', 'websites', 'ns_users']
const ALL_PROPS = Object.keys(FORM_MODEL.data)
const AUTH_DEPARTMENTS = [7, 338, 354, 256, 464]
const USER_IDS = ['386', '200']

// TODO: cały komponent wymaga gruntownego refactoringu i wydzielenia na mniejsze komponenty

class PublishersDetail extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSubmitAdSpace = this.handleSubmitAdSpace.bind(this)
    this.renderSelects = this.renderSelects.bind(this)
    this.removeAdSpace = this.removeAdSpace.bind(this)
    this.addAdSpace = this.addAdSpace.bind(this)
    // this.showMore = this.showMore.bind(this);
    this.state = {
      columnNames: [],
      rowNames: [],
      additionalPropNames: [],
      basicValues: {},
      adSpaces: [],
      formValid: false,
      adSpaceValid: false,
      adSpaceCategories: [],
      adSpaceTranckingChannels: [],
      data: [],
      details: true
    }
  }

  componentDidMount() {
    const publisherId = this.props.match.params.id
    const query = `filter[where][_id]=${publisherId}`
    fetch(`${API_SERVER}/db/api/partners?${query}`)
      .then((res) => res.json())
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (this.props.match.params.id !== 'add' && response[0].adSpaces && !Array.isArray(response[0].adSpaces)) {
          response[0].adSpaces = Object.values(response[0].adSpaces)
        }
        this.setState({ data: response[0] })

        const data = this.props.match.params.id === 'add' ? {} : this.state.data ? this.state.data : this.props.location.client.data
        let { adSpaces } = data

        adSpaces = (adSpaces || []).map((item) => {
          if (item.adSpaceData) {
            item.websiteUrl = `https://${item.adSpaceData.www}`
            item.name = `https://${item.adSpaceData.www}`
            item.description = item.adSpaceData.descr
          }
          if (!item.name && item.websiteUr) {
            item.name = item.websiteUrl.replace(/^http[s]?:\/\//, '')
          }
          return item
        })

        if (!data.countryCode) {
          data.countryCode = 'POL'
        }
        if (!data.phone) {
          data.phone = data.user_phone
        }
        if (!data.email) {
          data.email = data.user_email
        }
        if (!data.name) {
          data.name = data.user_name
        }
        if (!data.firstName) {
          data.firstName = data.user_first_name
        }
        if (!data.lastName) {
          data.lastName = data.user_last_name
        }
        if (!data.currencyCode) {
          data.currencyCode = data.currency
        }
        if (!data.zipCode) {
          data.zipCode = data.zipcode
        }
        if (!data.languageCode) {
          data.languageCode = data.language
        }
        //
        const filteredPropNames = Object.keys(data).filter((key) => typeof data[key] === 'object' && !EXCLUDED_PROPS.includes(key))
        const nonEmptyFilteredPropNames = filteredPropNames.filter(
          (prop) => data[prop] && prop !== 'ingenious' && Object.keys(data[prop]).length && prop.toLowerCase().indexOf('adspaces') !== 0 && prop.toLowerCase().indexOf('user') !== 0
        )
        const basicValues = data
        window.scrollTo(0, 0)

        this.setState({
          columnNames: ['basic', ...nonEmptyFilteredPropNames],
          rowNames: [...ALL_PROPS],
          additionalPropNames: nonEmptyFilteredPropNames,
          basicValues,
          adSpaces
        })

        this.publisherDataValidation(this.getPublisherData())
        this.adSpaceValidation()
        this.fetchAdSpaceData('category/adspace', 'adSpaceCategories')
        this.fetchAdSpaceData('general/tracking/channel', 'adSpaceTranckingChannels')
      })
  }

  showNotification(level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: <span data-notify='icon' className={icons[level]} />,
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  addAdSpace() {
    const adSpaces = [...this.state.adSpaces, {}]
    this.setState({
      adSpaces
    })
  }

  getPublisherData() {
    const { data } = this.state
    return { ..._.pick(data, this.state.rowNames), ..._.pick(this.state.basicValues, AD_SPACES) }
  }

  fetchAdSpaceData(url, stateData) {
    fetch(`${API_INGENIOUS}/${url}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET'
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error('Error:', error)
      })
      .then((response) => {
        this.setState({ [stateData]: response })
      })
  }

  handleSubmit(event) {
    const data = this.props.match.params.id === 'add' ? this.state.basicValues : this.state.data
    const id = data._id
    const params = new FormData(event.target)
    const searchParams = []
    this.showNotification('info', 'Wysyłanie danych')

    let pair
    for (pair of params.entries()) {
      searchParams.push(`${encodeURIComponent(pair[0])}=${encodeURIComponent(pair[1])}`)
    }

    const newHash = data.email ? md5(data.email + Date.now()) : data.hash
    searchParams.push(`hash=${newHash}`)

    fetch(`${API_SERVER}/db/api/partners${this.props.match.params.id === 'add' ? '' : `/${id}`}`, {
      method: this.props.match.params.id === 'add' ? 'POST' : 'PATCH',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: searchParams.join('&')
    })
      .then((response) => response.json())
      .catch((error) => {
        this.showNotification('error', `Wystąpił błąd: ${JSON.stringify(error)}`)
        console.error('Error:', error)
      })
      .then((response) => {
        if (response.error) {
          this.showNotification('error', `Wystąpił błąd: ${JSON.stringify(response.error.message)}`)
          console.log('Error:', response)
        } else {
          this.showNotification('success', 'Dane zostały zapisane', 6)
          console.log('Success:', response)
        }
      })
    event.preventDefault()
    event.stopPropagation()
  }

  handleDelete(event) {
    if (window.confirm('Czy jesteś pewien, że chcesz usunąć tego wydawcę? Ta operacja jest nieodwracalna.')) {
      this.showNotification('info', 'Wysyłanie danych')
      const id = this.state.data._id

      fetch(`${API_SERVER}/db/api/partners/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: ''
      })
        .then((response) => response.json())
        .catch((error) => {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', error)
        })
        .then((response) => {
          this.showNotification('success', 'Dane zostały zapisane', 6)
          console.log('Success:', response)
        })
    }
    event.preventDefault()
    event.stopPropagation()
  }

  getRowValues(col, row) {
    const data = this.state.basicValues
    const isNestingExist = data.hasOwnProperty(col)
    const basicValue = data[row]
    const isNestedValueExist = data.hasOwnProperty(col) && data[col].hasOwnProperty(row)
    const value = isNestingExist ? (isNestedValueExist ? data[col][row] : '-') : basicValue

    if (col === 'basic') {
      const error = FORM_MODEL.data[row] === 'req' && (this.state.basicValues[row] === undefined || this.state.basicValues[row] === '')

      if (row === 'status' || row === 'codesVerification') {
        return (
          <FormControl
            componentClass='select'
            placeholder=''
            type='select'
            name={row}
            style={{ borderColor: error ? 'red' : '' }}
            value={data[row]}
            onChange={(event) => {
              data[row] = event.target.value
              this.setState({ basicValues: data })
              this.publisherDataValidation(this.getPublisherData())
              this.adSpaceValidation()
            }}
          >
            {row === 'status'
              ? ['pozyskany', 'oczekuje', 'odrzucony', 'zaakceptowany', 'duplikat', 'w trakcie wyjaśniania', 'fraud', 'zamknięty'].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))
              : ['niezweryfikowane', 'akceptacja', 'akceptacja warunkowa', 'odmowa'].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
          </FormControl>
        )
      }
      if (row === 'codesVerification') {
        return (
          <FormControl
            componentClass='select'
            placeholder=''
            type='select'
            name={row}
            style={{ borderColor: error ? 'red' : '' }}
            value={data[row]}
            onChange={(event) => {
              data[row] = event.target.value
              this.setState({ basicValues: data })
              this.publisherDataValidation(this.getPublisherData())
              this.adSpaceValidation()
            }}
          >
            {['niezweryfikowane', 'akceptacja', 'akceptacja warunkowa', 'odmowa'].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </FormControl>
        )
      }
      if (row.indexOf('agreement') === 0) {
        return data[row] ? 'T' : 'N'
      }
      if (row === 'comment' || row === 'codesVerificationComment') {
        return (
          <FormControl
            componentClass='textarea'
            placeholder='komentarz'
            name={row}
            style={{ height: 100 }}
            value={data[row]}
            onChange={(event) => {
              data[row] = event.target.value
              this.setState({ basicValues: data })
              this.publisherDataValidation(this.getPublisherData())
            }}
          />
        )
      }
      if (row === 'codesVerificationComment') {
        return (
          <FormControl
            componentClass='textarea'
            placeholder='komentarz'
            name={row}
            style={{ height: 100 }}
            value={data[row]}
            onChange={(event) => {
              data[row] = event.target.value
              this.setState({ basicValues: data })
              this.publisherDataValidation(this.getPublisherData())
            }}
          />
        )
      }
      if (row === 'contractsTypes') {
        return (
          <div>
            <input type='hidden' name='contractsTypes' />
            {['cashback/loyalty', 'display', 'mailing', 'subnetwork', 'inne'].map((contractType, i) => (
              <label key={i} className='pub-form-checkbox'>
                <input
                  id={contractType}
                  type='checkbox'
                  name='contractsTypes'
                  checked={data.contractsTypes ? (!!data.contractsTypes.includes(contractType)) : false}
                  value={contractType}
                  onChange={(event) => {
                    !data.contractsTypes && (data.contractsTypes = [])
                    !data.contractsTypes.includes(contractType) ? data.contractsTypes.push(contractType) : (data.contractsTypes = data.contractsTypes.filter((x) => x !== contractType))
                    this.setState({ basicValues: data })
                    this.publisherDataValidation(this.getPublisherData())
                  }}
                />{' '}
                {contractType}
              </label>
            ))}
          </div>
        )
      }
      if (row === 'contractsInfo') {
        return (
          <FormControl
            componentClass='textarea'
            placeholder='Informacje dodatkowe'
            name={row}
            style={{ height: 100 }}
            value={data[row]}
            onChange={(event) => {
              data[row] = event.target.value
              this.setState({ basicValues: data })
              this.publisherDataValidation(this.getPublisherData())
            }}
          />
        )
      }
      if (row === 'hash') {
        return data[row]
      }
      if (row === 'contractTypes') {
        return null
      }
      return (
        <FormControl
          style={{ borderColor: error ? 'red' : '' }}
          type='text'
          name={row}
          className='invalid'
          value={data[row]}
          onChange={(event) => {
            data[row] = event.target.value
            this.setState({ basicValues: data })
            this.publisherDataValidation(this.getPublisherData())
          }}
        />
      )
    }

    return (
      <span
        onClick={(event) => {
          data[row] = value
          this.setState({ basicValues: data })
        }}
      >
        {value}
      </span>
    )
  }

  sendAdSpaceData(reqParams) {
    // TODO: id do weryfikacji
    return fetch(API_INGENIOUS + `/partner/${this.state.basicValues.id}/adspace/`, reqParams)
      .then((res) => res.json())
      .catch((error) => {
        this.showNotification('error', 'error')
        console.error('Error:', error)
      })
      .then((response) => {
        // Problem z wychwytywaniem errora - komunikat o
        // błędach przychodzi w response... narazie roboczo go na podstawie typu odpowiedzi
        if (Array.isArray(response)) {
          this.showNotification('error', response.description, 0)
        } else {
          this.showNotification('success', `Został utworzony nowy ad space o id ${response.id}`, 0)
        }
      })
  }

  publisherDataValidation(publisherData) {
    const allData = { ...FORM_MODEL.data, ...FORM_MODEL.adSpace }
    const requiredProps = Object.keys(allData).filter((prop) => allData[prop] === 'req')
    const valid = requiredProps.every((prop) => publisherData[prop] && (publisherData[prop] !== undefined || publisherData[prop] !== ''))
    this.setState({ formValid: valid })
  }

  adSpaceValidation() {
    const requiredProps = Object.keys(FORM_MODEL.adSpace).filter((prop) => FORM_MODEL.adSpace[prop] === 'req')
    const valid = requiredProps.every((prop) => this.state.basicValues[prop] && (this.state.basicValues[prop] !== undefined || this.state.basicValues[prop] !== ''))
    this.setState({ adSpaceValid: valid })
  }

  renderMainTableColumnsNames() {
    return ['', ...this.state.columnNames].map((prop, key) => <th key={key}>{prop}</th>)
  }

  renderMainTableRows() {
    const { rowNames, columnNames } = this.state
    return rowNames.map((row, rowKey) => {
      // if details are not displayed, than move unnecessary fields by 10 000 px
      // const noDetailedDisplayValues = [
      //   'company',
      //   'firstName',
      //   'lastName',
      //   'email',
      //   'comment',
      //   'status'
      // ];
      // let style = {};
      // if (!this.state.details && noDetailedDisplayValues.indexOf(row) === -1){
      //   style = {position: 'absolute', left:'-10000px'};
      // }
      return (
        <tr
          key={rowKey}
        // style={style}
        >
          <td>
            <label>
              {FORM_MODEL.labels[row]}
              {FORM_MODEL.data[row] === 'req' && '*'}
            </label>
          </td>
          {columnNames.map((col, colKey) => (
            <td key={colKey}>{this.getRowValues(col, row)}</td>
          ))}
        </tr>
      )
    })
  }

  renderMainTable() {
    const data = this.props.match.params.id === 'add' ? {} : this.state.data ? this.state.data : this.props.location.client.data
    return (
      <Card
        title={data.company}
        category='Tabela z porównaniem różnic danych'
        ctTableFullWidth
        ctTableResponsive
        content={
          <Table striped hover>
            {data && data.it
              ? (
                <thead>
                  {data.partner
                    ? (
                      <tr>
                        <th colSpan={3}>
                          <b>Partner</b>: {data.partner}
                        </th>
                      </tr>
                    )
                    : null}
                  <tr>
                    <th colSpan={3}>
                      <b>Data rejestracji</b>: {moment(data.it.registrationDate).format('DD-MM-YYYY hh:mm')}
                    </th>
                  </tr>
                </thead>
              )
              : null}
            <thead>
              <tr>{this.renderMainTableColumnsNames()}</tr>
            </thead>
            <tbody>{this.renderMainTableRows()}</tbody>
          </Table>
        }
      />
    )
  }

  renderAdditionalTables() {
    const data = this.state.data && this.state.data.lenght > 0 ? (this.state.data ? this.state.data : this.props.location.client.data) : {}
    const { additionalPropNames } = this.state
    if (Object.keys(data).length) {
      return additionalPropNames.length && additionalPropNames.map((prop, propKey) => <SimpleDataTable data={data} prop={prop} key={propKey} />)
    }
  }

  handleSubmitAdSpace(e) {
    const { adSpaces, basicValues } = this.state
    e.preventDefault()
    this.setState({ adSpaces: [...adSpaces, _.pick(basicValues, AD_SPACES)] })
  }

  renderSelects(line, name, value, error, dataList) {
    return (
      <FormControl
        componentClass='select'
        placeholder='select'
        type='select'
        name={`adSpaces[${line}][${name}]`}
        style={{ borderColor: error ? 'red' : '' }}
        value={value}
        onChange={(event) => {
          const value2 = event.target.value
          this.setState(
            ({ adSpaces }) => {
              adSpaces[line][name] = value2
              return {
                adSpaces
              }
            },
            () => {
              this.publisherDataValidation(this.getPublisherData())
              this.adSpaceValidation()
            }
          )
        }}
      >
        <option value=''>wybierz z listy</option>
        {dataList.map((item) => {
          let name = item.description || item.name
          if (item.group) {
            name = `${item.group.name} \\ ${name}`
          }
          return (
            <option key={`adsps${item.id}`} value={item.id}>
              {name} ({item.id})
            </option>
          )
        })}
      </FormControl>
    )
  }

  renderInfo(key, value) {
    if (['www'].indexOf(key) > -1 || key.indexOf('subject-') === 0) {
      return ''
    }
    return (
      <div>
        {key}: {value}
      </div>
    )
  }

  renderAdSpaceInputs(space, line, name, val, categories, adSpaceTranckingChannels, error) {
    let input
    if (name === 'categoryId') {
      input = this.renderSelects(line, name, val, error, categories)
    } else if (name === 'subcategories') {
      input = (
        <div className='nsm-categories-box'>
          {Object.keys(ADSPACE_SUBCATEGORIES.adspaceSubcategories).map((category, i) => {
            return (
              <div key={i}>
                <label key={i} className='pub-form-checkbox'>
                  <input
                    id={category}
                    type='checkbox'
                    name={category}
                    checked={space.adSpaceData ? space.adSpaceData[category] === 'true' || space.adSpaceData[category] === true : false}
                    value={category}
                    onChange={(event) => {
                      const value = event.target.checked
                      this.setState(
                        ({ adSpaces }) => {
                          if (!adSpaces[line].adSpaceData) {
                            adSpaces[line].adSpaceData = {}
                          }
                          if (value) {
                            adSpaces[line].adSpaceData[category] = value
                          } else {
                            delete adSpaces[line].adSpaceData[category]
                          }
                          return {
                            adSpaces
                          }
                        },
                        () => {
                          this.publisherDataValidation(this.getPublisherData())
                          this.adSpaceValidation()
                        }
                      )
                    }}
                  />{' '}
                  {ADSPACE_SUBCATEGORIES.adspaceSubcategories[category]}
                </label>
              </div>
            )
          })}
        </div>
      )
    } else {
      input = (
        <FormControl
          type='text'
          name={`adSpaces[${line}][${name}]`}
          style={{ borderColor: error ? 'red' : '' }}
          value={val}
          onChange={(event) => {
            const value = event.target.value
            this.setState(
              ({ adSpaces }) => {
                adSpaces[line][name] = value
                return {
                  adSpaces
                }
              },
              () => {
                this.publisherDataValidation(this.getPublisherData())
                this.adSpaceValidation()
              }
            )
          }}
        />
      )
    }
    return input
  }

  renderAdSpaceData(space, spaceKey) {
    const result = []
    if (space.adSpaceData) {
      Object.keys(space.adSpaceData).forEach((key) => {
        result.push(
          <span key={`adsp${spaceKey}${key}`}>
            <input type='hidden' name={`adSpaces[${spaceKey}][adSpaceData][${key}]`} value={space.adSpaceData[key]} />
            {this.renderInfo(key, space.adSpaceData[key])}
          </span>
        )
      })
    }
    result.push(<input key={`adsph${spaceKey}`} type='hidden' name={`adSpaces[${spaceKey}][id]`} value={space.id} />)
    return result
  }

  removeAdSpace(e) {
    const target = parseInt(e.target.name, 10)
    const filteredAdSpaces = this.state.adSpaces.filter((el, idx) => idx !== target)
    this.setState({
      adSpaces: filteredAdSpaces
    })
  }

  renderAdSpaceForm() {
    const { adSpaces } = this.state
    return (
      <Card
        title='AD SPACES'
        category='Formularz tworzenia AD_SPACE'
        ctTableFullWidth
        ctTableResponsive
        content={
          <FormGroup controlId='formBasicText'>
            <Table striped hover>
              <thead>
                <tr>
                  {AD_SPACES.map((val, key) => (
                    <th key={key}>
                      <label>
                        {val}
                        {FORM_MODEL.adSpace[val] === 'req' && '*'}
                      </label>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {adSpaces.map((space, spaceKey) => (
                  <tr key={spaceKey}>
                    {AD_SPACES.map((val, key) => {
                      const error = FORM_MODEL.adSpace[val] === 'req' && (space[val] === undefined || space[val] === '')
                      return <td key={key}>{this.renderAdSpaceInputs(space, spaceKey, val, space[val], this.state.adSpaceCategories || [], this.state.adSpaceTranckingChannels || [], error)}</td>
                    })}
                    <td className='adspace-description-box'>{this.renderAdSpaceData(space, spaceKey)}</td>
                    <td>
                      <input className='btn btn-danger' type='button' value='usuń' name={spaceKey} onClick={this.removeAdSpace} />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan='5'>
                    <input className='btn btn-success' type='button' value='Dodaj nowy AdSpace' onClick={this.addAdSpace} />
                  </td>
                  <td>{this.renderButtonSave()}</td>
                </tr>
              </tbody>
            </Table>
          </FormGroup>
        }
      />
    )
  }

  renderButtonSave() {
    const user = this.props.user
    if (
      process.env.NODE_ENV === 'development' ||
      (user && user.UF_DEPARTMENT && (user.UF_DEPARTMENT.filter((department) => AUTH_DEPARTMENTS.indexOf(department) > -1).length || USER_IDS.includes(user.ID)))
    ) {
      return <input className='btn btn-save' type='submit' value='zapisz' />
    }
    return ''
  }

  renderButtonDelete() {
    const user = this.props.user
    if (
      process.env.NODE_ENV === 'development' ||
      (user && user.UF_DEPARTMENT && (user.UF_DEPARTMENT.filter((department) => AUTH_DEPARTMENTS.indexOf(department) > -1).length || USER_IDS.includes(user.ID)))
    ) {
      return <input className='btn' type='button' value='usuń' onClick={this.handleDelete} />
    }
    return ''
  }

  // renderButtonMore() {
  //   const user = this.props.user;
  //   if (process.env.NODE_ENV === 'development' || (user && user.UF_DEPARTMENT && (user.UF_DEPARTMENT.filter(department => AUTH_DEPARTMENTS.indexOf(department) > -1).length || USER_IDS.includes(user.ID)))) {
  //     return (<input
  //       className="btn"
  //       type="button"
  //       value={this.state.details ? 'ukryj' : 'szczegóły'}
  //       onClick={this.showMore}
  //     />);
  //   }
  //   return '';
  // }

  // showMore() {
  //   const detVal = this.state.details;
  //   if (!detVal) { this.setState({ details: true }); } else this.setState({ details: false });
  // }

  getConfirmLink(data) {
    return (
      <div>
        Link aktywacyjny: <a href={`https://netsalesmedia.pl/register/confirm/${data.confirm || ''}`}>{`https://netsalesmedia.pl/register/confirm/${data.confirm || ''}`}</a>
        <br />
        <br />
      </div>
    )
  }

  getActivationLink(data) {
    return (
      <div>
        Link migracyjny: <a href={`https://netsalesmedia.pl/register/${data.hash || ''}`}>{`https://netsalesmedia.pl/register/${data.hash || ''}`}</a>
        <br />
        <br />
      </div>
    )
  }

  renderMigration() {
    const data = this.props.match.params.id === 'add' ? {} : this.state.data ? this.state.data : this.props.location.client.data
    return (
      <Col>
        <Card
          title='Dane migracyjne'
          content={
            <Grid>
              <Row>
                <Col>
                  {data.confirm ? this.getConfirmLink(data) : ''}
                  {data.hash ? this.getActivationLink(data) : ''}
                  Login: {data.email}
                  <br />
                  Hasło: {data._id}
                </Col>
              </Row>
            </Grid>
          }
        />
      </Col>
    )
  }

  // TODO: większośc html zostanie wydzielona do osobnego modułu
  render() {
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <form onSubmit={this.handleSubmit}>
          <Grid fluid>
            <FormGroup controlId='formBasicText'>
              <Row>
                <Col md={12}>
                  <nav className='navbar navbar-light bg-light'>
                    <button
                      className='btn'
                      onClick={(e) => {
                        e.preventDefault()
                        this.props.history.push({ pathname: '/publishers' })
                      }}
                    >
                      &lt; powrót do listy wydawców
                    </button>
                    <span>
                      {this.renderButtonSave()}
                      {this.renderButtonDelete()}
                      {/* {this.renderButtonMore()} */}
                    </span>
                  </nav>
                </Col>
              </Row>
              <Row>
                <Col md={12}>{this.renderMainTable()}</Col>
              </Row>
            </FormGroup>
            <Row>
              <Col md={12}>{this.renderAdSpaceForm()}</Col>
            </Row>
            <Row>{this.state.details ? this.renderAdditionalTables() : ''}</Row>
            <Row>{this.state.details ? this.renderMigration() : ''}</Row>
          </Grid>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(PublishersDetail)
