const influencers = (state = [], action) => {
  switch (action.type) {
    case 'SET_INFLUENCERS':
      return action.influencers
    default:
      return state
  }
}

export default influencers
