import { useNotification } from './useNotification'

export const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : (process.env.REACT_APP_API_SERVER || '')

export default async (url, init) => {
  const response = await fetch(url, init)
  // Drut do obsługi walidacji loopback
  if (response.status === 422) {
    const { error: { details: { messages } = {} } = {} } = await response.json()
    return { errors: messages }
  } else if (!response.ok) {
    useNotification(`Wystąpił błąd ${response.statusText}`)
  } else {
    const json = await response.json()
    if (json.error && json.error.length) {
      console.error(json.error)
      useNotification(`Wystąpił błąd ${json.error || ''}`)
    } else {
      return json
    }
  }
}
