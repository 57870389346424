import React, { Component } from 'react'
import NotificationSystem from 'react-notification-system'
import { connect } from 'react-redux'
import { Card } from '../../components/Card/Card'
import { style } from '../../variables/Variables'
import '../style.css'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { withFixedColumnsScrollEvent } from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css'

const Table = withFixedColumnsScrollEvent(ReactTable)
const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'

class TimeReportProjects extends Component {
  constructor (props) {
    super(props)
    const departmentsNames = {};
    ((this.props.main || {}).departments || []).forEach(el => (departmentsNames[el.ID] = el.NAME))

    this.state = {
      dayStart: this.props.propDayStart,
      dayEnd: this.props.propDayEnd,
      date: props.date,
      user: props.user,
      departmentsNames
    }
  }

  componentDidMount () {
    this.loadNext()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.tasks) !== JSON.stringify(this.props.tasks) && this.props.load) {
      this.setState({
        dayStart: this.props.propDayStart,
        dayEnd: this.props.propDayEnd
      }, () => {
        this.loadNext()
      })
    }
  }

  loadNext () {
    if (this.props.updateLoader) this.props.updateLoader(true)
    this.setState({ data: {}, loader: true })
    const { params = {}, tasksFakeUser } = this.props.tasks
    const { user } = this.state
    const loggedUser = tasksFakeUser.ID ? tasksFakeUser : user
    const { dayStart, dayEnd } = this.state

    fetch(`${API_SERVER}/api/tasks/projectsreport`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        dayStart,
        dayEnd,
        email: loggedUser.EMAIL,
        showAll: params.isShowAll,
        reportType: params.reportType
      })
    })
      .then(res => res.json())
      .then(response => {
        if (!response || response.error) {
          console.error('Error:', response && response.error)
          this.setState({ data: {}, loader: false })
          this.showNotification('Wystąpił błąd: ' + ((response && response.error) || ''))
        } else {
          this.setState({ data: response, loader: false })
          if (this.props.updateLoader) this.props.updateLoader(false)
        }
      })
      .catch(error => {
        console.error('Error:', error)
        this.showNotification('Wystąpił błąd')
      })
  }

  showNotification (message, level = 'error', autoDismiss = 5) {
    this.refs.notificationSystem.addNotification({ message, level, position: 'br', autoDismiss })
  }

  toHours (seconds) {
    if (!seconds) return '0'
    let hh = parseInt(seconds / 3600, 10)
    if (hh < 10) {
      hh = '  ' + hh
    } else if (hh < 100) {
      hh = ' ' + hh
    }
    const mm = Math.floor((seconds - (hh * 3600)) / 60)
    const format2d = number => (`0${number}`).slice(-2)
    return hh + ':' + format2d(mm)
  }

  countSum (data, key) {
    return data
      .map(key2 => (key2[key] || []))
      .reduce((a, b) => typeof b === 'number' ? a + b : a, 0)
  }

  render () {
    const { data = {} } = this.state
    const { params: { reportType } } = this.props.tasks
    const tableTitle = {
      projectsViewUsers: ' imienny',
      projectsViewDepartments: ' w działach'
    }
    const prepared = {
      cols: {},
      rows: []
    }

    Object.entries(data).forEach(([project, obj]) => {
      const row = { Project: project }
      Object.entries(obj).forEach(([col, time]) => {
        row[col] = time
        if (prepared.cols[col]) {
          prepared.cols[col][project] = time
        } else {
          prepared.cols[col] = { [project]: time }
        }
      })
      row.Suma = Object.values(row).reduce((a, b) => typeof b === 'number' ? a + b : a, 0)
      prepared.rows.push(row)
      return prepared
    })

    const totalSum = prepared.rows.reduce((sum, { Suma }) => sum + Suma, 0)

    const columns = Object.keys(prepared.cols)
      .sort()
      .map((key) => ({
        Header: () => (
          <span>
            <strong>{key}</strong><i className='fa fa-sort' />
          </span>
        ),
        Cell: row => this.toHours(row.row[key]) || '',
        accessor: key,
        style: { textAlign: 'right', padding: 7 },
        headerStyle: { textAlign: 'right', paddingRight: 5 },
        sortMethod: (a = 0, b = 0) => a > b ? 1 : -1,
        Footer: row => <b>{this.toHours(this.countSum(row.data, key))}</b>
      }))

    columns.unshift({
      Header: () => (
        <span>
          <strong>Suma</strong><i className='fa fa-sort' />
        </span>
      ),
      Cell: row => this.toHours(row.row.Suma) || '',
      Footer: () => <b>{this.toHours(totalSum || 0)}</b>,
      accessor: 'Suma',
      fixed: 'left',
      width: 100,
      style: { padding: 7 },
      headerStyle: { textAlign: 'right' },
      sortMethod: (a = 0, b = 0) => a > b ? 1 : -1
    })

    columns.unshift({
      Header: () => (
        <span>
          <strong>Projekt</strong><i className='fa fa-sort' />
        </span>
      ),
      Footer: () => <b>PODSUMOWANIE</b>,
      accessor: 'Project',
      fixed: 'left',
      width: 300,
      style: { textAlign: 'left', padding: 7 },
      headerStyle: { textAlign: 'left' }
    })

    return (
      <div>
        <NotificationSystem ref='notificationSystem' style={style} />
        {this.state.loader ? <div className='lds-dual-ring' id='spinner' /> : null}
        <h5 className='print-dates'>{this.state.dayStart + ' - ' + this.state.dayEnd}</h5>
        <Card
          title={[`Raport czasu pracy w projektach ${tableTitle[reportType] || ''}`]}
          content={
            <div>
              <Table
                data={prepared.rows}
                columns={columns}
                showPagination={false}
                minRows={1}
                resizable={false}
                className='-striped -highlight timereport-clients timereport-projects'
                defaultPageSize={5000}
              />
            </div>
          }
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    main: state.main,
    tasks: state.tasks
  }
}

export default connect(mapStateToProps)(TimeReportProjects)
