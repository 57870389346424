import React, { Component } from 'react'
import { FormControl, Table } from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'
import { Card } from '../../components/Card/Card'
import { style } from '../../variables/Variables'
import ScDropdownList from '../../components/ScDropdownList/ScDropdownList'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'

class ReportsAccessTable extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
    }
  }

  componentDidMount () {
    this.getEmails()
  }

  getEmails () {
    const clientId = encodeURIComponent(this.props.clientId)
    fetch(`${API_SERVER}/api/client/get-emails?clientId=${clientId}`)
      .then(res => res.json())
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        this.setState({ ...response })
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  generatePassword (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  getValue (value, elem) {
    const { data = [], options = [] } = this.state
    data[elem].email = value
    if (!options.includes(value)) {
      data[elem].newPasswordCheckbox = true
      data[elem].password = this.generatePassword(10)
    } else {
      data[elem].password = 'dont change'
      data[elem].newPasswordCheckbox = false
    }
    this.setState({ data })
  }

  handleSubmit (e) {
    // e.preventDefault();
    // e.stopPropagation();
    const [email, password, del, newPass] = e.split(',')
    if (email && password) {
      this.showNotification('info', 'Wysyłanie danych')
      fetch(`${API_SERVER}/api/client/save-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          clientId: this.props.clientId,
          email,
          password,
          del,
          newPass
        })
      })
        .then(res => res.json())
        .catch((err) => {
          console.error(`Błąd: ${err}`)
        })
        .then((result) => {
          if (result.error) {
            this.showNotification('error', result.error, 10)
          } else {
            this.showNotification('success', result, 10)
            this.getEmails()
          }
        })
    } else {
      this.showNotification('error', 'Uzupełnij dane')
    }
  }

  selectCheckbox (name, key) {
    const activeCB = document.getElementById(name + key)
    const { data = [] } = this.state
    data.forEach(function (line) {
      line.deleteCheckbox = false
      line.newPasswordCheckbox = false
      line.password = 'dont change'
    })
    if (name === 'newPasswordCheckbox') {
      data[key].newPasswordCheckbox = activeCB.checked
      data[key].password = this.generatePassword(10)
    } else if (name === 'deleteCheckbox') {
      data[key].deleteCheckbox = activeCB.checked
    }
    this.setState({ data })
  }

  renderTableRows () {
    const { data = [], options = [], disabled = [] } = this.state

    return (
      data.map((el, key) => (
        <tr key={key}>
          <td><label>email</label></td>
          <td style={{ width: 300 }}>
            <ScDropdownList
              options={options}
              value={el.email}
              elem={key}
              disabled={disabled}
              getValue={this.getValue.bind(this)}
            />
          </td>
          <td><label>hasło</label></td>
          <td style={{ width: 300 }}><FormControl
            type='text'
            name='password'
            disabled={!el.newPasswordCheckbox}
            value={el.newPasswordCheckbox ? el.password : 'ukryte'}
                                     />
          </td>

          <td><input
            type='button'
            value='Zapisz zmiany'
            className='btn btn-default'
            onClick={() => {
              this.handleSubmit(`${el.email || ''},${el.password || ''}, ${el.deleteCheckbox || ''}, ${el.newPasswordCheckbox || ''}`)
              console.log(el)
            }}
              />
          </td>
          <td><label><input
            id={'newPasswordCheckbox' + key}
            type='checkbox'
            value='Nowe hasło'
            checked={el.newPasswordCheckbox}
            onChange={
                  () => this.selectCheckbox('newPasswordCheckbox', key)
              }
                     /> Nowe hasło
          </label>
            <br />
            <label><input
              id={'deleteCheckbox' + key}
              type='checkbox'
              value='Usuń'
              checked={el.deleteCheckbox}
              onChange={
                  () => this.selectCheckbox('deleteCheckbox', key)
                  }
                   /> Usuń
            </label>
          </td>
        </tr>
      )))
  }

  render () {
    const { data = [] } = this.state
    return (
      <Card
        title='Dostęp do raporty.cubegroup.pl'
        ctTableResponsive
        content={
          <div>
            <form onSubmit={this.handleSubmit}>
              <NotificationSystem ref='notificationSystem' style={style} />
              <Table striped hover>
                <tbody>
                  {this.renderTableRows()}
                </tbody>
              </Table>
              <input
                type='button'
                className='btn btn-default'
                value='Dodaj nowy'
                onClick={() => {
                  data.push({})
                  this.setState({ data })
                }}
              />
            </form>
            <a href='/clients/email-list'>Lista dostępów</a>
          </div>
        }
      />
    )
  }
}

export default ReportsAccessTable
