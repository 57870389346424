import React, { Component } from 'react'
import moment from 'moment'
import { Grid, Row, Col, Table, FormControl } from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import Card from '../../components/Card/Card'
import { style } from '../../variables/Variables'
import '../style.css'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'
const API_INGENIOUS = API_SERVER + '/ig/masterdata'

const noEvents = {
  pointerEvents: 'none',
  opacity: 0.5
}

class ClicksValidation extends Component {
  constructor (props) {
    super(props)
    this.onRequest = props.onRequest
    this.onResponse = props.onResponse
    this.handleSubmit = this.handleSubmit.bind(this)
    const month = moment().format('YYYY-MM')
    this.state = {
      month,
      page: 0,
      query: '',
      disabledPrevButton: true,
      disabledNextButton: false,
      advertisers: [],
      partners: [],
      adspaces: [],
      currency: 'PLN',
      filtered: false,
      empty: false,
      spinner: true,
      newCurrencyCode: 'PLN',
      newCommission: '0.00',
      newFee: '0.00'
    }
  }

  componentDidMount () {
    if (this.state.filtered) {
      this.fetchIGData('key-account-manager', 'accounts')
    }
    this.fetchAPI()
    this.loadNext()
  }

  fetchIGData (url, stateData) {
    fetch(`${API_INGENIOUS}/${url}`)
      .then(res => res.json())
      .catch((error) => {
        console.error('Error:', error)
      })
      .then((response) => {
        this.setState({ [stateData]: response })
      })
  }

  fetchAPI () {
    let query = ''
    if (this.state.filtered === true) {
      const { user } = this.props
      const { accounts } = this.state
      if (user && (user.UF_DEPARTMENT.indexOf(7) === -1 && user.UF_DEPARTMENT.indexOf(216) === -1)) {
        const loggedAccount = accounts
          ? accounts.filter(item =>
            item.firstName.toLowerCase() === user.NAME.toLowerCase() &&
          item.lastName.toLowerCase() === user.LAST_NAME.toLowerCase())
          : []
        query = loggedAccount[0] && loggedAccount[0].id ? `&filter[where][it.keyAccountManagerId]=${loggedAccount[0].id}` : ''
      }
    }
    fetch(`${API_SERVER}/db/api/clients?filter[where][ITID][exists]=true${query}`)
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then((advertisers) => {
        advertisers = advertisers.filter(advertiser => advertiser && advertiser.ITID && (advertiser.company || '').trim())
        advertisers.sort((a, b) => (a.company || '').toLowerCase() < (b.company || '').toLowerCase() ? -1 : 1)
        this.setState({ advertisers })
      })
    fetch(`${API_SERVER}/db/api/relationships`)
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then((relationships) => {
        this.setState({ relationships })
      })
    fetch(`${API_SERVER}/db/api/partners?filter[where][ITID][exists]=true&filter[fields][ITID]=true&filter[fields][adSpacesFromIT]=true&filter[fields][company]=true&filter[fields][name]=true`)
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then((partners) => {
        let adspaces = []
        const used = {}
        partners.forEach((partner) => {
          if (partner.adSpacesFromIT) {
            adspaces = adspaces.concat(partner.adSpacesFromIT.map((item) => {
              item.partnerId = partner.ITID
              return item
            }))
          }
        })
        partners = partners.filter(partner => partner && partner.ITID && (partner.company || '').trim()).filter((item) => {
          const result = !used[item.ITID]
          used[item.ITID] = 1
          return result
        })
        adspaces = adspaces.filter(adspace => adspace)
        partners.sort((a, b) => a.company.toLowerCase() < b.company.toLowerCase() ? -1 : 1)
        adspaces.sort((a, b) => a.name < b.name ? -1 : 1)
        this.setState({ fullPartners: partners, fullAdspaces: adspaces.slice() })
      })
  }

  loadNext () {
    this.setState({ spinner: true })
    this.onRequest()

    fetch(`${API_SERVER}/q3kzJGaGs9uBaWPJw57CdN4mbWw3Hmp4AyhemjqgeGc5vymdyn/api/clients/report.json?month=${this.state.month}&empty=${this.state.empty ? 1 : ''}`)
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then((response) => {
        // const today = moment().format('YYYY-MM-DD');

        const clicks = response
        let key
        for (key in clicks) {
          clicks[key].editable = false
        }

        this.setState({ spinner: false, clicks })
        // fetch(`${API_SERVER}/db/api/clicks?filter[where][time]=${today}`, {
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json',
        //   },
        //   method: 'GET',
        // })
        //   .then(res => res.json())
        //   .catch((error) => {
        //     console.error('Error:', error);
        //   })
        //   .then((res) => {
        //     const clicks = response;
        //     for (const key in clicks) {
        //       if (res.filter(item =>
        //         item.advertiserId === `${clicks[key].advertiserId}` &&
        //         item.partnerId === `${clicks[key].partnerId}` &&
        //         item.adspaceId === `${clicks[key].adspaceId}`).length > 0) {
        //         // tymczasowo wyłączone, jak mechanizm sie sprawdzi mozna usunąć
        //         // clicks[key].readonly = true;
        //       }
        //     }
        //     onResponse(response || []);
        //     this.setState({ spinner: false });
        //   });
      })
  }

  changeMonth (month) {
    if (month) {
      this.setState({
        month,
        clicks: []
      }, () => this.loadNext())
    }
  }

  handleSubmit (event) {
    const path = this.props.match.params.month
    const queryAdd = path === 'add' ? '&add=1' : ''

    this.setState({ spinner: true })
    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className='pe-7s-cloud-upload' />),
      message: (
        <div>Wysyłanie danych</div>
      ),
      level: 'info',
      position: 'br',
      autoDismiss: 15
    })
    //
    // const params = new FormData(event.target);
    //
    // const searchParams = [];
    // for (const pair of params.entries()) {
    //   searchParams.push(`${encodeURIComponent(pair[0])}=${encodeURIComponent(pair[1])}`);
    // }
    //
    fetch(`${API_SERVER}/q3kzJGaGs9uBaWPJw57CdN4mbWw3Hmp4AyhemjqgeGc5vymdyn/api/clients/report-save?month=${this.state.month}${queryAdd}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: event.target.name
      // body: searchParams.join('&'),
    })
      .then(response => response.json())
      .catch((error) => {
        this.refs.notificationSystem.addNotification({
          title: (<span data-notify='icon' className='pe-7s-bandaid' />),
          message: (
            <div>Wystąpił błąd</div>
          ),
          level: 'error',
          position: 'br',
          autoDismiss: 0
        })
        console.error('Error:', error)
        this.setState({ spinner: false })
      })
      .then((response) => {
        this.setState({ spinner: false })
        if (response.result && response.result.success && response.result.success.length) {
          this.refs.notificationSystem.addNotification({
            title: (<span data-notify='icon' className='pe-7s-check' />),
            message: (
              <div>Dane zostały zapisane</div>
            ),
            level: 'success',
            position: 'br',
            autoDismiss: 6
          })
          this.loadNext()
        } else {
          this.refs.notificationSystem.addNotification({
            title: (<span data-notify='icon' className='pe-7s-bandaid' />),
            message: (
              <div>{response.error
                ? response.error
                : response.result.ignored
                  ? response.result.ignored[0].message
                  : response.result.error ? response.result.error[0].message : ''}
              </div>
            ),
            level: 'error',
            position: 'br',
            autoDismiss: 0
          })
          console.error('Error:',
            response.error
              ? response.error
              : response.result.ignored
                ? response.result.ignored[0].message
                : response.result.error ? response.result.error[0].message : '')
        }
      })
    event.preventDefault()
    event.stopPropagation()
  }

  format (value) {
    const dotValue = value.replace(',', '.')
    return (Math.round(dotValue * 100) / 100).toFixed(2)
  }

  selectDate () {
    const startDate = moment('2018-04')
    const endDate = moment().subtract(1, 'month')

    const dates = []
    while (endDate > startDate) {
      dates.push(startDate.format('YYYY-MM'))
      startDate.add(1, 'month')
    }

    return (
      <FormControl
        componentClass='select'
        placeholder={moment().format('YYYY-MM')}
        type='select'
        className='btn btn-default'
        value={this.state.month}
        onChange={(e) => {
          this.setState({ month: e.target.value })
          this.changeMonth(e.target.value)
        }}
      >
        <option value={moment().format('YYYY-MM')}>{moment().format('YYYY-MM')}</option>
        {
          dates.reverse().map(item => <option value={item} key={item}>{item}</option>)
        }
      </FormControl>
    )
  }

  selectAdvertiser () {
    let { clicks = [] } = this.state
    clicks = clicks.filter((value, index, arr) => arr.map(mapObj => mapObj.advertiserId).indexOf(value.advertiserId) === index)
    const options = clicks.map(prop => ({
      value: prop.advertiserId,
      label: `${prop.advertiserName} (${prop.advertiserId})`
    }))

    return (
      <Select
        isClearable
        options={options}
        placeholder='Wybierz reklamodawcę'
        styles={{ control: styles => ({ ...styles, width: 300, marginRight: 5, marginTop: 15, fontSize: 14 }) }}
        onChange={(event) => {
          event ? this.setState({ selectedAdvertiser: event.value }) : this.setState({ selectedAdvertiser: '' })
        }}
      />
    )
  }

  selectPartner () {
    let { clicks = [] } = this.state
    clicks = clicks.filter((value, index, arr) => arr.map(mapObj => mapObj.partnerId).indexOf(value.partnerId) === index)
    const options = clicks.map(prop => ({
      value: prop.partnerId,
      label: `${prop.partnerName} (${prop.partnerId})`
    }))

    return (
      <Select
        isClearable
        options={options}
        placeholder='Wybierz partnera'
        styles={{ control: styles => ({ ...styles, width: 300, marginRight: 5, marginTop: 15, fontSize: 14 }) }}
        onChange={(event) => {
          event ? this.setState({ selectedPartner: event.value }) : this.setState({ selectedPartner: '' })
        }}
      />
    )
  }

  selectRange () {
    const options = [
      { value: false, label: 'Wszystkie' },
      { value: true, label: 'Tylko moje' }
    ]
    const { user } = this.props

    if ((user && ((user.UF_DEPARTMENT || []).indexOf(7) === -1 && (user.UF_DEPARTMENT || []).indexOf(162) === -1))) {
      return (
        <Select
          options={options}
          placeholder='Wszystkie'
          styles={{ control: styles => ({ ...styles, width: 150, marginRight: 5, marginTop: 15, fontSize: 14 }) }}
          onChange={() => {
            this.setState({ filtered: !this.state.filtered })
          }}
        />
      )
    }
    return ''
  }

  selectValues () {
    const options = [
      { value: false, label: 'Niezerowe' },
      { value: true, label: 'Wszystkie' }
    ]

    return (
      <Select
        options={options}
        placeholder='Niezerowe'
        styles={{ control: styles => ({ ...styles, width: 150, marginRight: 5, marginTop: 15, fontSize: 14 }) }}
        onChange={() => {
          this.setState({ empty: !this.state.empty }, () => this.changeMonth(this.state.month))
        }}
      />
    )
  }

  tableSum (data) {
    const commissionSum = data.reduce((a, b) => (
      { commission: Number(a.commission) + Number(b.commission) }), { commission: 0 })
    const feeSum = data.reduce((a, b) => (
      { fee: Number(a.fee) + Number(b.fee) }), { fee: 0 })

    return (
      <tr>
        <td colSpan={3}>&nbsp;</td>
        <td><b>Suma:</b></td>
        <td><input
          readOnly
          className='amount sum'
          style={{ opacity: 0.8 }}
          value={commissionSum.commission.toFixed(2)}
            />
        </td>
        <td><input
          readOnly
          className='amount sum'
          style={{ opacity: 0.8 }}
          value={feeSum.fee.toFixed(2)}
            />
        </td>
        <td colSpan={2}>&nbsp;</td>
      </tr>
    )
  }

  tableContent () {
    const path = this.props.match.params.month
    if (path !== 'add') {
      let { clicks: data = [] } = this.state

      if (this.state.filtered === true) {
        const { user } = this.props
        const advertisers = this.state.advertisers
        if ((user && (user.UF_DEPARTMENT.indexOf(7) === -1 && user.UF_DEPARTMENT.indexOf(162) === -1))) {
          const datafiltered = []
          if (advertisers && advertisers.length > 0) {
            advertisers.forEach((el) => {
              data.map(item => el.ITID === item.advertiserId ? datafiltered.push(item) : '')
            })
            data = datafiltered
          }
        }
      } else {
        console.log('unfiltered')
      }

      if (this.state.selectedAdvertiser) {
        data = data.filter(item => item.advertiserId === this.state.selectedAdvertiser)
      }

      if (this.state.selectedPartner) {
        data = data.filter(item => item.partnerId === this.state.selectedPartner)
      }

      return (
        <tbody>
          {
            data.map((prop, key) => (
              <tr key={key} className={prop.editable ? 'editable' : ''}>
                <td>{key + 1}</td>
                <td>{prop.advertiserName} <span className='sup'>{prop.advertiserId}</span></td>
                <td>{prop.partnerName} <span className='sup'>{prop.partnerId}</span></td>
                <td>{prop.adspaceName} <span className='sup'>{prop.adspaceId}</span></td>
                <td><input
                  readOnly={!prop.editable}
                  name={`values[${prop.advertiserId}-${prop.partnerId}-${prop.adspaceId}-commission]`}
                  value={prop.commission}
                  className='amount'
                  onClick={(event) => {
                    event.target.select()
                  }}
                  onBlur={(event) => {
                    const value = event.target.value
                    this.setState((data) => {
                      prop.commission = this.format(value)
                      return data
                    })
                  }}
                  onChange={(event) => {
                    const value = event.target.value
                    this.setState((data) => {
                      prop.commission = value
                      prop.editableSave = true
                      return data
                    })
                  }}
                    />
                </td>
                <td><input
                  readOnly={!prop.editable}
                  name={`values[${prop.advertiserId}-${prop.partnerId}-${prop.adspaceId}-fee]`}
                  value={prop.fee}
                  className='amount'
                  onClick={(event) => {
                    event.target.select()
                  }}
                  onBlur={(event) => {
                    const value = event.target.value
                    this.setState((data) => {
                      prop.fee = this.format(value)
                      return data
                    })
                  }}
                  onChange={(event) => {
                    const value = event.target.value
                    this.setState((data) => {
                      prop.fee = value
                      prop.editableSave = true
                      return data
                    })
                  }}
                    />
                </td>
                <td>{prop.currencyCode}</td>
                <td><button
                  className={prop.editableSave
                    ? 'editable-button edit hide'
                    : 'editable-button edit'} onClick={(e) => {
                      let key
                      for (key in data) {
                        data[key].editable = false
                        data[key].editableSave = false
                        prop.editable = true
                      }

                      this.setState({ clicks: data })
                      e.preventDefault()
                    }}
                    >edycja
                </button>
                  <button
                    className={prop.editableSave
                      ? 'editable-button save show'
                      : 'editable-button save'}
                    name={`values[${prop.advertiserId};${prop.partnerId};${prop.adspaceId};${prop.commission};${prop.fee}]`}
                    onClick={(e) => {
                      this.handleSubmit(e)
                      e.preventDefault()
                    }}
                  >zapisz
                  </button>
                </td>
              </tr>
            ))
            }
          {this.tableSum(data)}
        </tbody>
      )
    }
    return (
      <tbody>
        <tr>
          <td>&nbsp;</td>
          <td>
            <Select
              name='newAdvertiserId'
              style={{ maxWidth: 200 }}
              onChange={function (event) {
                const value = event.value
                const adSpaces = this.state.relationships.filter(item => item.advertiserId === value).map(item => item.adspaceId)
                const partnerIDs = this.state.fullAdspaces.filter(item => adSpaces.indexOf(item.id) !== -1).map(item => item.partnerId)
                this.setState({
                  newAdvertiserId: value,
                  partners: this.state.fullPartners.filter(item => partnerIDs.indexOf(item.ITID) !== -1)
                })
              }.bind(this)}
              options={this.state.advertisers.map(item => ({ value: item.ITID, label: `${item.company} (${item.ITID})` }))}
            />
          </td>
          <td>
            <Select
              style={{ maxWidth: 200 }}
              onChange={function (event) {
                const value = event.value
                const adSpaces = this.state.relationships.filter(item => item.advertiserId === this.state.newAdvertiserId).map(item => item.adspaceId)
                this.setState({
                  newPartnerId: value,
                  adspaces: this.state.fullAdspaces.filter(item => item.partnerId === value).filter(item => adSpaces.indexOf(item.id) !== -1)
                })
              }.bind(this)}
              name='newPartnerId'
              options={this.state.partners.map(item => ({ value: item.ITID, label: `${item.company} (${item.ITID})` }))}
            />
          </td>
          <td><Select
            style={{ maxWidth: 200 }}
            name='newAdspaceId'
            onChange={function (event) {
              const value = event.value
              this.setState({ newAdspaceId: value })
            }.bind(this)}
            options={this.state.adspaces.map(item => ({ value: item.id, label: `${item.name} (${item.id})` }))}
              />
          </td>
          <td><input
            name='newAdmediaId'
            onChange={function (event) {
              const value = event.target.value
              this.setState({ newAdmediaId: value })
            }.bind(this)}
              />
          </td>
          <td><input
            name='newCommission'
            value={this.state.newCommission}
            className='amount'
            onClick={(event) => {
              event.target.select()
            }}
            onBlur={(event) => {
              const value = event.target.value
              this.setState({ newCommission: this.format(value) })
            }}
            onChange={(event) => {
              const value = event.target.value
              this.setState({ newCommission: value })
            }}
              />
          </td>
          <td><input
            name='newFee'
            value={this.state.newFee}
            className='amount'
            onClick={(event) => {
              event.target.select()
            }}
            onBlur={(event) => {
              const value = event.target.value
              this.setState({ newFee: this.format(value) })
            }}
            onChange={(event) => {
              const value = event.target.value
              this.setState({ newFee: value })
            }}
              />
          </td>
          <td>
            <FormControl
              componentClass='select' name='newCurrencyCode' style={{ textAlign: 'right' }}
              onChange={(event) => {
                const value = event.target.value
                this.setState({ newCurrencyCode: value })
              }}
            >
              <option>PLN</option>
              <option>USD</option>
              <option>EUR</option>
            </FormControl>
          </td>
          <td>
            <button
              className='editable-button save show'
              name={`values[${this.state.newAdvertiserId};${this.state.newPartnerId};${this.state.newAdspaceId};${this.state.newAdmediaId};${this.state.newCommission};${this.state.newFee};${this.state.newCurrencyCode}]`}
              onClick={(e) => {
                this.handleSubmit(e)
                e.preventDefault()
              }}
            >zapisz
            </button>
          </td>
        </tr>
      </tbody>
    )
  }

  render () {
    const path = this.props.match.params.month
    return (
      <div className='content' style={this.state.spinner ? noEvents : {}}>
        <NotificationSystem ref='notificationSystem' style={style} />
        <form className='form-inline'>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <nav className='navbar navbar-light bg-light'>
                  {this.state.spinner ? <div className='lds-dual-ring' id='spinner' /> : ''}
                  <div role='group' aria-label='Basic example' style={{ display: 'flex' }}>
                    {path === 'add'
                      ? <button
                          className='btn'
                          onClick={() => {
                            this.props.history.push({ pathname: '/clicks-validation/:month' })
                          }}
                        >
                        &lt; powrót do listy
                        </button>
                      : ''}
                    {this.selectDate()}
                    {path !== 'add' ? this.selectAdvertiser() : ''}
                    {path !== 'add' ? this.selectPartner() : ''}
                    {path !== 'add' ? this.selectRange() : ''}
                    {path !== 'add' ? this.selectValues() : ''}
                    {path !== 'add' ? <Link to='/clicks-validation/add' className='btn btn-success' role='button'>Dodaj nowy</Link> : ''}
                    {path !== 'add' ? <Link to='/clicks-add-history' className='btn' role='button'>Raport zmian</Link> : ''}
                  </div>
                </nav>
              </Col>
              <Col md={12}>
                <Card
                  title={`Walidacja klików i odsłon ${this.state.month}`}
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          <th style={{ width: '5%' }}>#</th>
                          <th style={{ width: '15%' }}>Reklamodawca</th>
                          <th style={{ width: '15%' }}>Partner</th>
                          <th style={{ width: '15%' }}>Adspace</th>
                          {path === 'add' ? <th style={{ width: '15%' }}>Admedia</th> : null}
                          <th style={{ width: '20%' }}>Commision</th>
                          <th style={{ width: '20%' }}>Fee</th>
                          <th style={{ width: '5%' }}>Currency</th>
                          <th style={{ width: '5%' }}><p style={{ width: '60px' }} /></th>
                        </tr>
                      </thead>
                      {this.tableContent()}
                    </Table>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  clicks: state.clicks,
  user: state.user
})

const mapDispatchToProps = {
  onRequest: () => ({
    type: 'REQUEST_CLICKS'
  }),
  onResponse: clicks => ({
    type: 'SET_CLICKS',
    clicks
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(ClicksValidation)
