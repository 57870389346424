export const REQUIRED_PROPS = [
  'company',
  'alias',
  'address',
  'city',
  'country',
  'zipcode',
  'phone',
  'status',
  'website',
  'domain',
  'language',
  'bitrix',
  'SMID',
  'NSID',
  'ITID',
  'deeplink',
  'adformclient',
  'gaView',
  'gaUserId',
  'external_id',
  'trackingsubdomain_1',
  'trackingsubdomain_2',
  'trackingsubdomain_3',
  'deeplink_utm',
  'tracking_dcm',
  'gtm',
  'keywords',
  'raporty',
  'widocznosc_NSM'
]
export const RENDER_INPUT_TEXT_FIELDS = [
  'company',
  'alias',
  'address',
  'city',
  'country',
  'zipcode',
  'phone',
  'status',
  'website',
  'domain',
  'language',
  'bitrix',
  'SMID',
  'NSID',
  'ITID',
  'deeplink',
  'external_id',
  'trackingsubdomain_1',
  'trackingsubdomain_2',
  'trackingsubdomain_3',
  'deeplink_utm',
  'gtm',
  'email',
  'reportDayOfValidation',
  'shouldReportsBeSent',
  'reportUserValue1',
  'keywords',
  'raporty',
  'raporty_link',
  'widocznosc_NSM'
]

export const validationStyles = {
  top: {
    border: '2px #DCDCC6 dashed',
    borderBottom: 'none',
    backgroundColor: 'white',
    fontWeight: 'bold',
    fontSize: '1.2em'
  },
  middle: {
    border: '2px #DCDCC6 dashed',
    borderBottom: 'none',
    borderTop: 'none',
    backgroundColor: 'white'
  },
  bottom: {
    border: '2px #DCDCC6 dashed',
    borderTop: 'none',
    backgroundColor: 'white'
  }
}

export const MAP = {
  it: {
    website: 'websiteUrl',
    language: 'languageCode',
    company: 'name'
  },
  sm: {
    address: 'address1'
  },
  ns: {
    address: 'address1'
  }
}
