import React from 'react'
import NumberFormat from 'react-number-format'
import { FormControl } from 'react-bootstrap'

export const CheckboxRenderer = props => {
  const checkedHandler = (e) => {
    const checked = e.target.checked
    const colId = props.column.colId
    props.node.setDataValue(colId, checked)
  }
  return (
    <input
      type='checkbox'
      onClick={checkedHandler}
      checked={props.value}
    />
  )
}

export const NumberRenderer = props => {
  return (
    <NumberFormat
      value={props.value}
      customInput={FormControl}
      thousandSeparator={' '}
      decimalSeparator=','
      allowedDecimalSeparators={[',', '.']}
      fixedDecimalScale
      decimalScale={1}
      isNumericString
      allowNegative={false}
      onValueChange={(values) => {
        const value = values.value
        const colId = props.column.colId
        props.node.setDataValue(colId, value)
      }}
    />
  )
}

export const getDatePicker = () => {
  function DatePicker () {}
  DatePicker.prototype.init = function (params) {
    this.eInput = document.createElement('input')
    this.eInput.setAttribute('type', 'date')
    this.cell = params.eGridCell
    this.oldWidth = this.cell.style.width
    this.cell.style.width = '162px'
    this.eInput.value = params.value
  }
  DatePicker.prototype.getGui = function () {
    return this.eInput
  }
  DatePicker.prototype.afterGuiAttached = function () {
    this.eInput.focus()
    this.eInput.select()
  }
  DatePicker.prototype.getValue = function () {
    this.cell.style.width = this.oldWidth
    return this.eInput.value
  }
  DatePicker.prototype.destroy = function () {
  }
  DatePicker.prototype.isPopup = function () {
    return false
  }
  return DatePicker
}
