import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, FormControl, Grid, Row, Table } from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'
import { style } from '../../../variables/Variables'
import { Card } from '../../../components/Card/Card'
import Loader from '../Components/Loader'
import { Link } from 'react-router-dom'
import FORM_MODEL from './salary'
import { API_SERVER, checkAuthIdUser, checkUserType } from '../authData'
import moment from 'moment'
import Select from 'react-select'
import NumberFormat from 'react-number-format'

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 1 }),
  control: style => ({ ...style, minHeight: 30, fontSize: 12 }),
  option: style => ({ ...style, padding: 4, textAlign: 'center', fontSize: 12 })
}

class SalaryGrid extends Component {
  constructor (props) {
    super(props)
    this.setBtxMain = props.setBtxMain
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      colParams: FORM_MODEL,
      loader: true,
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      generateFrom: '2018-01',
      thisMonth: moment().format('YYYY-MM'),
      viewMonth: moment().format('YYYY-MM'),
      data: {}
    }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    const isDev = checkUserType(loggedUser, 'dev')
    const isHR = checkUserType(loggedUser, 'hr')
    const isAuthIdUser = checkAuthIdUser(loggedUser,
      ['200'] // Cezary Śledziak
    )
    this.setState({
      isFinance,
      isDev,
      isHR,
      isAuthIdUser
    })
    this.getData()
  }

  getData () {
    const { loggedUser } = this.state
    fetch(`${API_SERVER}/api/get-salary`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        person: loggedUser
      })
    })
      .then(res => res.json())
      .catch(error => {
        console.error('Error:', error)
      })
      .then(response => {
        if (!response || response.error) {
          console.error('Error:', (response || {}).error)
          this.setState({ loader: false })
          this.showNotification('error', `Wystąpił błąd: ${(response || {}).error}`)
        } else {
          this.setState({
            data: response.success,
            loader: false
          })
        }
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  renderTableHeader () {
    const { colParams } = this.state
    const style = col => ({
      width: colParams[col] ? colParams[col].width : ''
    })

    return (
      Object.keys(colParams).map((col, key) => (
        <th key={key} style={style(col)}>{colParams[col] ? colParams[col].pl : col}</th>
      ))
    )
  }

  renderFormControl (data, viewMonth, key, col) {
    return (
      <FormControl
        type='text'
        value={data[viewMonth][key][col]}
        style={{ marginRight: 20, height: 24 }}
        onChange={(e) => {
          data[viewMonth][key][col] = Number(e.target.value)
          this.setState({ data })
        }}
      />
    )
  }

  renderNumberFormat (data, viewMonth, key, col) {
    return (
      <NumberFormat
        value={data[viewMonth][key][col]}
        customInput={FormControl}
        thousandSeparator={' '}
        decimalSeparator=','
        allowedDecimalSeparators={[',', '.']}
        fixedDecimalScale
        decimalScale={2}
        isNumericString
        onValueChange={(values) => {
          data[viewMonth][key][col] = values.value
          this.setState({ data })
        }}
      />
    )
  }

  getRowValues (col, key, colParams) {
    const { data, viewMonth } = this.state
    const type = colParams[col].type

    if (type === 'text') { return this.renderFormControl(data, viewMonth, key, col) }
    if (type === 'number') { return this.renderNumberFormat(data, viewMonth, key, col) }
  }

  renderTableContent () {
    const { colParams, data, viewMonth } = this.state
    const selectedData = data[viewMonth]

    return selectedData && Object.values(selectedData).map((row, key) => (
      <tr key={key}>
        {
          Object.keys(colParams).map((col, key2) => (
            <td key={key2}>
              {this.getRowValues(col, key, colParams, row)}

            </td>
          ))
        }
        <td><input
          type='hidden'
          name={data[viewMonth][key].step && data[viewMonth][key].hourly ? `values${key}` : null}
          value={`${data[viewMonth][key].step}|${data[viewMonth][key].hourly}|${viewMonth}`}
            />
        </td>
      </tr>
    ))
  }

  getRangeOfDates (start, end, key, arr = [start.startOf(key)]) {
    const next = moment(start).add(1, key).startOf(key)
    if (next.isAfter(end, key)) return arr
    return this.getRangeOfDates(next, end, key, arr.concat(next))
  }

  generateMonths () {
    const { generateFrom, thisMonth } = this.state
    const rangeOfDates = this.getRangeOfDates(moment(generateFrom), moment(thisMonth), 'month')
    return rangeOfDates.map(el => moment(el).format('YYYY-MM')).reverse() || []
  }

  renderMonthSelect () {
    const { viewMonth } = this.state
    const months = this.generateMonths()
    const options = months.map(el => ({ value: el, label: el }))
    const initValue = { value: viewMonth, label: viewMonth }

    return (
      <div style={{ width: 150, display: 'inline-block' }}>
        <Select
          options={options}
          value={initValue}
          styles={selectStyles}
          onChange={(e) => {
            this.setState({ viewMonth: e.value })
          }}
        />
      </div>
    )
  }

  renderAddButton () {
    const { data, viewMonth } = this.state
    return (
      <button
        type='button'
        className='btn btn-primary btn-sm'
        style={{ marginLeft: 16 }}
        value='Dodaj nowy'
        onClick={() => {
          Array.isArray(data[viewMonth]) ? data[viewMonth].push({}) : data[viewMonth] = [{}]
          this.setState({ data })
        }}
      >+ Dodaj nowy
      </button>
    )
  }

  renderSubmitButton () {
    return (
      <FormControl
        type='submit'
        className='btn btn-primary'
        value='Zapisz'
      />
    )
  }

  handleSubmit (e) {
    e.preventDefault()
    e.stopPropagation()
    const { loggedUser } = this.state
    const params = new FormData(e.target)
    const inputValues = {}
    for (const pair of params.entries()) {
      inputValues[pair[0]] = pair[1]
    }

    this.showNotification('info', 'Wysyłanie danych')

    fetch(`${API_SERVER}/api/save-salary`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        person: loggedUser,
        inputValues
      })
    })
      .then(res => res.json())
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
      .then(response => {
        if (!response || response.error) {
          this.showNotification('error', `Wystąpił błąd: ${(response && response.error) || ''}`)
          return console.error('Error:', response && response.error)
        }
        if (response.success) {
          this.showNotification('success', response.success || '', 6)
          this.getData()
        }
      })
  }

  render () {
    const { loader, viewMonth, isFinance, isAuthIdUser, isDev, isHR } = this.state
    if (isFinance || isDev || isAuthIdUser || isHR) {
      return (
        <div className='content SalaryGrid'>
          <NotificationSystem ref='notificationSystem' style={style} />
          <Loader display={loader} />
          <form className='form-inline' onSubmit={this.handleSubmit}>
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Card
                    content={
                      <div>
                        <div className='breadcrumb'>
                          <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                            to='/settlements/users'
                                                                           >Pracownicy
                          </Link> » <Link
                                                                             to='/settlements/salary-grid'
                                                                                     >Siatka płac
                                                                                     </Link>
                        </div>

                      </div>
                    }
                  />
                </Col>
              </Row>
              <div className={loader ? 'noEvents' : ''}>
                <Row>
                  <Col md={12}>
                    <div>
                      <div style={{ textAlign: 'center', marginBottom: 10 }}>
                        Wybierz miesiąc: {this.renderMonthSelect()}
                      </div>
                      <Card
                        title={<span>Siatka płac <b>{viewMonth}</b></span>}
                        ctTableFullWidth
                        ctTableResponsive
                        content={
                          <div>

                            <Table striped hover>
                              <thead>
                                <tr>
                                  {this.renderTableHeader()}
                                </tr>
                              </thead>
                              <tbody>
                                {this.renderTableContent()}
                              </tbody>
                            </Table>
                            {this.renderAddButton()}
                          </div>
                          }
                      />
                      {this.renderSubmitButton()}
                    </div>
                  </Col>
                </Row>
              </div>
            </Grid>
          </form>
        </div>
      )
    } else {
      return (
        <div className='content SalaryGrid'>
          <NotificationSystem ref='notificationSystem' style={style} />
          <div>Brak dostępu</div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main
  }
}

export default connect(mapStateToProps)(SalaryGrid)
