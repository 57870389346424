import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './StatsCard2.css'

export class StatsCard2 extends Component {
  render () {
    return (
      <div className='card card-stats StatsCard2'>
        <div className='content'>
          <Row>
            <Col xs={5}>
              <div className='icon-big text-center icon-warning'>
                {this.props.bigIcon}
              </div>
            </Col>
            <Col xs={7}>
              <div className='numbers'>
                <p>{this.props.statsText}</p>
                {this.props.statsValue}
              </div>
            </Col>
          </Row>
          <div className='footer'>
            <hr />
            <div className='stats' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link
                to={this.props.linkLeftTo || ''}
                onClick={this.props.clickLeft || null}
                className={this.props.linkLeftTo ? 'Link' : ''}
                target={this.props.target || ''}
              >
                <div>{this.props.linkLeftText}</div>
              </Link> |
              <Link
                to={this.props.linkRightTo || ''}
                onClick={this.props.clickRight || null}
                className={this.props.linkRightTo ? 'Link' : ''}
              >
                <div>{this.props.linkRightText}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default StatsCard2
