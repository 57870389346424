module.exports = {
  _id: '',
  order_id: { pl: 'ID zlecenia', type: 'text', filterable: true, req: true },
  main_depart: { pl: 'Dział główny', type: 'select', source: 'departments', filterable: true, req: true },
  main_person: { pl: 'Opiekun Klienta', type: 'select', source: 'users', filterable: true, req: true },
  from_depart: { pl: 'Dział zlecający', type: 'select', source: 'departments', filterable: true, req: true },
  from_person: { pl: 'Osoba zlecająca', type: 'select', source: 'users', filterable: true, req: true },
  resp_depart: { pl: 'Dział realizujący', type: 'select', source: 'departments', filterable: true, req: true },
  resp_person: { pl: 'Osoba realizująca', type: 'select', source: 'users', filterable: true, req: true },
  start: { pl: 'Start', width: 100, type: 'date', filterable: true, req: true },
  end: { pl: 'Koniec', width: 100, type: 'date', filterable: true }
}
