import React from 'react'

export default function FirstPage () {
  return (
    <>
      <h2 className='title'>Cześć! 👋</h2>
      <br /><br />
      <p className='text'>Cieszę się, że tu jesteś. 😄 Zapraszam Cię do krótkiego quizu, który pomoże określić, do jakiego profilu Buyer Persony możemy przypisać Twojego Klienta.</p>
      <p className='text'>Zasady są proste: przejdziesz przez listę pytań i w każdym pytaniu zaznaczysz 4 opisy, które najlepiej pasują do charakterystyki Twojego Klienta.</p>
      <p className='text'>Na koniec quizu dowiesz się, jak rozkładają się Twoje odpowiedzi między 4 profile Buyer Persony. Zachęcam do zapoznania się ze szczegółowym opisem tych, które zdobyły najwięcej punktów.</p>
      <p className='greet'>
        Powodzenia! ✨
      </p>
    </>
  )
}
