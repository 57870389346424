import React from 'react'
import './DataGrid.css'
import { connect } from 'react-redux'
import Select from 'react-select'

const {
  Filters: {
    // NumericFilter,
    AutoCompleteFilter
    // MultiSelectFilter,
    // SingleSelectFilter
  }
} = require('react-data-grid-addons')

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 0, width: 15 }),
  control: style => ({ ...style, padding: '0 2px', minHeight: 20, borderRadius: 3, cursor: 'pointer', fontWeight: 400 }),
  clearIndicator: style => ({ ...style, padding: 0, width: 15 }),
  singleValue: style => ({ ...style, padding: 0 }),
  placeholder: style => ({ ...style, padding: 0 }),
  valueContainer: style => ({ ...style, padding: 0 }),
  option: style => ({ ...style, padding: 4, fontWeight: 400 })
}

class AutoCompleteFilter2 extends AutoCompleteFilter {
  constructor (props) {
    super(props)
    this.state = {
      ...this.state,
      placeholder: 'Szukaj',
      filters2: {}
    }
  }

  componentDidMount () {
    const { filters } = this.props
    this.setFilters2(filters)
  }

  UNSAFE_componentWillReceiveProps (props) {
    // this.props.onFilterChange()
    const { filters = {} } = props
    this.setFilters2(filters)
  // const filters = value;
  // this.props.onChange({ filterTerm: filters, column: this.props.column, rawValue: value, filterValues: this.filterValues });
  }

  setFilters2 (filters2) {
    this.setState({ filters2 })
  }

  render () {
    const { filters2 = {} } = this.state
    return (
      <Select
        autosize={false}
        name={`filter-${this.props.column.key}`}
        options={this.state.options}
        placeholder={this.state.placeholder}
        onChange={this.handleChange}
        isClearable
        escapeClearsValue
        styles={selectStyles}
        multi={this.props.multiSelection !== undefined && this.props.multiSelection !== null ? this.props.multiSelection : true}
        value={filters2[this.props.column.key] ? filters2[this.props.column.key].filterTerm : ''}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: state.settlements.filters.params
  }
}

export default connect(mapStateToProps)(AutoCompleteFilter2)
