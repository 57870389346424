import moment from 'moment'
import { toSeconds } from '../../TimeReport/timeFunctions'

export const getCompanyName = (data, arr = []) => {
  const obj = arr.length ? arr.find(o => o.ID === data + '') : ''
  return obj ? obj[Object.keys(obj)[2]] : data
}

export const parseTime = time => time && moment(time).local().format('YYYY.MM.DD HH:mm:ss')

export const countSum = jobs => Array.isArray(jobs)
  ? jobs.reduce((sum, item) => (sum + toSeconds(item.time) || 0), 0)
  : 0

export const countDiffTime = (dataSum, workingTime, min = 0.9, max = 1.05) => {
  if (dataSum < Math.floor(min * workingTime)) {
    return Math.floor((dataSum - min * workingTime) / 60) * 60
  } else if (dataSum > Math.floor(max * workingTime)) {
    // wylaczona gorna granica dla b2b i km
    return null
    // return Math.ceil((dataSum - max * workingTime)/ 60) * 60;
  } else {
    return null
  }
}
