import React, { Component } from 'react'
import { Multiselect } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css'

class ScMultiselect extends Component {
  constructor (props) {
    super(props)
    this.state = { ...props }
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps !== this.props) {
      this.setState({ ...nextProps })
    }
  }

  handleChange (value, name) {
    this.setState({ value }, () =>
      this.props.getValues(value.map(el => el.id || el), name))
  }

  groupHeading ({ item }) {
    const { value = [], options = [], groupComponent } = this.state
    const { name } = this.props
    if (groupComponent) {
      const prevValue = options.filter(el => value.includes(name === 'users' ? el.name : el.id))
      const prepareValue = options
        .filter(el => name === 'users' ? el.dep === item : el.assigned === item)
        .concat(prevValue)
        .filter((el, idx, arr) => arr.indexOf(el) === idx)
      return (
        <span
          style={{ display: 'block', cursor: 'pointer' }}
          onClick={() => this.setState({ open: false }, () => this.handleChange(prepareValue, name))}
        >
          {item}
        </span>
      )
    }
    return (
      <span>{item}</span>
    )
  }

  render () {
    const { value = [], options = [], valueField, textField, groupBy, name, placeholder, open = false, cleaner, readonly = false } = this.state
    return (
      <div className='multiselect'>
        <Multiselect
          data={options}
          value={value}
          valueField={valueField}
          textField={textField}
          groupComponent={item => this.groupHeading(item)}
          groupBy={groupBy}
          placeholder={placeholder}
          open={open}
          onToggle={open => this.setState({ open })}
          filter='contains'
          onChange={value => this.handleChange(value, name)}
          readOnly={readonly}
        />
        {
          cleaner
            ? <p
                className={`clear ${!value.length ? 'disable' : ''}`}
                onClick={() => this.handleChange([], name)}
              >wyczyść
            </p>
            : null
        }
      </div>
    )
  }
}

export default ScMultiselect
