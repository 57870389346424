import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import InfluencerForm from './InfluencerForm'
import NotificationSystem from 'react-notification-system'
import { style } from '../../variables/Variables'
import { Redirect } from 'react-router-dom'

const API_SERVER = process.env.REACT_APP_API_SERVER || '..'

class Add extends Component {
  constructor (props) {
    super(props)
    this.state = {
      validatedForm: false
    }
    this.afterValidate = this.afterValidate.bind(this)
  }

  afterValidate (formBody) {
    const formParams = []
    let pair
    for (pair of formBody.entries()) {
      formParams.push(`${encodeURIComponent(pair[0])}=${encodeURIComponent(pair[1])}`)
    }
    fetch(`${API_SERVER}/db/api/influencers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formParams.join('&')
    })
      .then(response => response.json())
      .then((response) => {
        if (response.error) {
          console.error(response.error)
          this.refs.notificationSystem.addNotification({
            title: (<span data-notify='icon' className='pe-7s-close-circle' />),
            message: (
              <div>Wystąpił błąd. Spróbuj ponownie<br />{response.error.message}</div>
            ),
            level: 'error',
            position: 'tl',
            autoDismiss: 6
          })
          this.setState({ validatedForm: false })
        } else {
          this.refs.notificationSystem.addNotification({
            title: (<span data-notify='icon' className='pe-7s-check' />),
            message: (
              <div>Influencer dodany</div>
            ),
            level: 'success',
            position: 'tl',
            autoDismiss: 6
          })
          setTimeout(() => this.setState({ validatedForm: true }), 3000)
        }
      })
      .catch((error) => {
        console.error(error)
        this.refs.notificationSystem.addNotification({
          title: (<span data-notify='icon' className='pe-7s-close-circle' />),
          message: (
            <div>Wystąpił błąd. Spróbuj ponownie</div>
          ),
          level: 'error',
          position: 'tl',
          autoDismiss: 6
        })
        this.setState({ validatedForm: false })
      })
  }

  render () {
    if (this.state.validatedForm) {
      return (
        <Redirect to='/influencers' />
      )
    } else {
      return (
        <div className='content'>
          <NotificationSystem ref='notificationSystem' style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <InfluencerForm afterValidate={this.afterValidate} />
              </Col>
            </Row>
          </Grid>
        </div>
      )
    }
  }
}

export default Add
