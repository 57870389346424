import img1 from './img/1.webp'
import img2 from './img/2.webp'
import img3 from './img/3.webp'
import img4 from './img/4.webp'

export const defaultSummary = {
  totalPoints: 11,
  results: {
    ij: { name: 'Innowacyjny Janek', points: 0 },
    pz: { name: "Performance'owy Zbyszek", points: 0 },
    tk: { name: 'Teamowa Kasia', points: 0 },
    zi: { name: 'Zielona Iga', points: 0 }
  }
}

export const questions = [
  {
    question: 'OZNACZ JEŚLI UWAŻASZ, ŻE TEN OPIS DOBRZE CHARAKTERYZUJE TWOJEGO KLIENTA',
    image: img1,
    choices: [
      {
        name: 'ij',
        answers: [
          'Skupia się na innowacjach analityczno-technologicznych.',
          'Chce być pionierem. Nie chce kopiować, a szuka nowych, lepszych rozwiązań.',
          'Buduje zaufanie do agencji szczególnie na wiedzy, eksperckości i doświadczeniu partnera.'
        ]
      },
      {
        name: 'pz',
        answers: [
          'Skupia się na efektywności finansowej.',
          'Chce, żeby jego firma rosła szybciej niż rynek, ale nie chce być pionierem i przecierać szlaków.',
          'Buduje zaufanie do agencji szczególnie na gwarancji rezultatów i wynikach.'
        ]
      },
      {
        name: 'tk',
        answers: [
          'Skupia się na budowaniu zmotywowanych, eksperckich zespołów.',
          'Chce dorównac standardom rynkowym.',
          'Buduje zaufanie do agencji szczególnie na wiedzy, eksperckości i doświadczeniu partnera.'
        ]
      },
      {
        name: 'zi',
        answers: [
          'Skupia się na przejrzystym i prostym raportowaniu celów.',
          'Chce dorównac standardom rynkowym.',
          'Buduje zaufanie do agencji szczególnie na gwarancji rezultatów i wynikach.'
        ]
      }
    ]
  }, {
    question: 'OZNACZ JEŚLI UWAŻASZ, ŻE TO MOGĄ BYĆ GŁÓWNE WYZWANIA TWOJEGO KLIENTA',
    image: img2,
    choices: [
      {
        name: 'ij',
        answers: [
          'Potrzebuje partnera z wysoką priorytetyzacją projektów analitycznych. Chce wykorzystać analitykę do optymalizacji działań mediowych.',
          'Aby rosnąć potrzebuje zaawansowanych analiz real-time. Chce mieć jasność co do przyczyn wpływających na marżę, a tym samym poczucie, że jego decyzje opierają się na danych i solidnych insightach.',
          'Chce aby hipotezy firmy były zwalidowane i odpowiednio ocenione przez eksperta. Chce aby partner był chellengujący.'
        ]
      },
      {
        name: 'pz',
        answers: [
          'Potrzebuje partnera, który tak jak on będzie koncetrował się na finansowej optymalizacji. Koncetruje się na monetyzacji włąsnych zasobów; priorytetyzacji działań o najwyższym ROI oraz kasowaniu tych nieefektywnych.',
          'Chce pozyskać klientów konkurencji. Agresywne przejmowanie klientów jest dla niego sposobem na szybkie osiągnięcie wzrostów.',
          'Klient potrzebuje partnera, który będzie chciał inwestować w jego biznes czas, wiedzę, a nawet pieniądze i rozliczać się jeśli ryzyko się opłaci.'
        ]
      },
      {
        name: 'tk',
        answers: [
          'Potrzebuje partnera, który pomoże zbudować wizję biznesu, strategiczny plan działań, urealni cele. ',
          'Skupia się na budowaniu marki user firendly. Chce budować pozytywne skojarzenia i uderzać w najnowsze trendy.',
          'Wymaga nie tylko głębokiej wiedzy technicznej i marketingowej, ale również strategicznego planowania w ramach działań komunikacyjnych, mediowych czy monetyzacji zasobów własnych. Partner musi mieć dostęp do danych z rynku (od konkurencji, podobnych podmiotów) aby wspierać decyzje strategiczne.'
        ]
      },
      {
        name: 'zi',
        answers: [
          'Potrzebuje partnera z niesamowitym warsztatem mediowym aby wykorzystać obecne, ograniczone możliwości rozwjou kampanii oraz stratega w zakresie komunikacji.',
          'Chce utrzymać wysoką pozycję lidera na rynku. Szuka niestandardowych rozwiązań, out of the box.',
          'Klient potrzebuje partnera, który będzie chciał inwestować w jego biznes czas, wiedzę, a nawet pieniądze i rozliczać się jeśli ryzyko się opłaci.'
        ]
      }
    ]
  }, {
    question: 'OZNACZ, JEŚLI UWAŻASZ, ŻE TO GŁÓWNY POWÓD ZMIANY AGENCJI',
    image: img3,
    choices: [
      {
        name: 'ij',
        answers: [
          'Agencja "udaje", że ma doświadczenie we wszystkim.',
          'Brak zrozumienia celu, do którego działania mają nas doprowadzić',
          'Brak zaangażowania ekspertów w konretnych dziedzinach'
        ]
      },
      {
        name: 'pz',
        answers: [
          'Podejmowanie niepotrzebnego ryzyka. Niekontrolowane mnożenie kosztów',
          'Brak ambicji ze strony partnera, stawiania sobie wysokich celów',
          'Brak wsparcia TOP Managementu we współpracy'
        ]
      },
      {
        name: 'tk',
        answers: [
          'Podejmowanie niepotrzebnego ryzyka. Niekontrolowane mnożenie kosztów',
          'Brak konsultacji, doradztwa ',
          'Brak poczucia, że agencja jest częścią zespołu i zależy jej na wynikach równie mocno co pracownikom marki Klienta.'
        ]
      },
      {
        name: 'zi',
        answers: [
          'Brak ochrony przed brakiem wyników',
          'Brak wiedzy o ograniczeniach i proponowanie niemożliwych rozwiązań',
          'Brak zaangażowania ekspertów w konretnych dziedzinach'
        ]
      }
    ]
  }, {
    question: 'OZNACZ JEŚLI UWAŻASZ, ŻE TO MOŻE BYĆ WAŻNY ARGUMENT DLA NIEGO DO WYBORU AGENCJI',
    image: img4,
    choices: [
      {
        name: 'ij',
        answers: [
          'Współpraca z liderem w branży; w dziedzinie',
          'Uwzględnienie jego wizji w rozmowach/ofercie i planach'
        ]
      },
      {
        name: 'pz',
        answers: [
          'Współpraca z zarządem; top managementem i ich wsparcie',
          'Gruntownie przeprowadzony audyt pokazujący fokus na smart taktyki wzrostu rentowności'
        ]
      },
      {
        name: 'tk',
        answers: [
          'Strateg na pokładzie',
          'Precyzyjny plan działań'
        ]
      },
      {
        name: 'zi',
        answers: [
          'Współpraca z liderem w branży; w dziedzinie',
          'Współpraca z kimś kto pracował u konkurencji'
        ]
      }
    ]
  }
]
