import React, { Component } from 'react'
import * as moment from 'moment'
import 'moment/locale/pl'
import NotificationSystem from 'react-notification-system'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Card } from '../../components/Card/Card'
import { style } from '../../variables/Variables'
import '../style.css'

import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { withFixedColumnsScrollEvent } from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css'

const Table = withFixedColumnsScrollEvent(ReactTable)

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'

const styleTimereportTable = {
  textTransform: 'uppercase',
  fontSize: '12px',
  textAlign: 'right',
  color: '#9a9a9a',
  paddingBottom: '5px',
  paddingRight: '10px',
  lineHeight: 1.43
}

class TimeReportComponent extends Component {
  constructor (props) {
    super(props)
    this.onResponse = props.onResponse
    this.setParams = props.setParams
    this.state = {
      dayStart: this.props.propDayStart || moment().subtract(7, 'days').format('YYYY-MM-DD'),
      dayEnd: this.props.propDayEnd || moment().format('YYYY-MM-DD'),
      showAll: this.props.propShowAll || false,
      active: this.props.propActive || false,
      disableHideAccepted: this.props.propDisableHideAccepted || false,
      lastUpdate: ''
    }
    this.showNotification = this.showNotification.bind(this)
  }

  componentDidMount () {
    this.loadNext()
  }

  UNSAFE_componentWillReceiveProps (props) {
    if ((JSON.stringify(props) !== JSON.stringify(this.props)) && props.load) {
      this.setState({
        dayStart: props.propDayStart || moment().subtract(7, 'days').format('YYYY-MM-DD'),
        dayEnd: props.propDayEnd || moment().format('YYYY-MM-DD'),
        showAll: props.propShowAll || false,
        active: props.propActive || false,
        disableHideAccepted: props.propDisableHideAccepted || false
      }, () => {
        this.loadNext()
      })
    }
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }
    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  loadNext () {
    this.setState({ loader: true })
    const { user } = this.props
    const { tasksFakeUser = {} } = this.props.tasks
    const loggedUser = tasksFakeUser.ID ? tasksFakeUser : user

    fetch(`${API_SERVER}/api/tasks/report`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        dayStart: this.state.dayStart,
        dayEnd: this.state.dayEnd,
        showAll: this.state.showAll,
        active: this.state.active,
        email: loggedUser.EMAIL
      })
    })
      .then(res => res.json())
      .then(response => {
        if (response) {
          this.setState({ data: response, loader: false })
          if (this.props.checkIsGod && response[0] && response[0].isGod) this.props.checkIsGod(response[0].isGod)
        } else {
          console.error(response)
          this.showNotification('error', 'Wystąpił błąd')
        }
      })
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
  }

  toHours (seconds) {
    if (!seconds) return '0:00'
    let hh = parseInt(seconds / 3600, 10)
    let mm = Math.ceil((seconds - (hh * 3600)) / 60)
    if (mm === 60) { hh += 1; mm = 0 }
    const format2d = number => (`0${number}`).slice(-2)
    return hh + ':' + format2d(mm)
  }

  render () {
    const { data = [], disableHideAccepted } = this.state
    const { params = {} } = this.props.tasks

    // Adds red color for times smaller than 6h
    const colorTime = (day, time, worktime, confirmed) => {
      return (
        moment(day).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
          ? confirmed ? 'green' : (time < 0.9 * worktime || time > 1.05 * worktime) ? 'red' : (time ? 'blue' : '')
          : confirmed ? 'green' : ''
      )
    }

    // Table Headers
    const columns = data[0] && data[0].times
      ? Object.keys(data[0].times).sort().map((key) => ({
        Header: () => (
          <span>
            {moment(key).format('dddd')}<br />{key}
          </span>
        ),
        Cell: row => (
          <Link
            onClick={() => this.setParams({
              ...params,
              emailToView: row.original.Email,
              userNameToView: row.original.Pracownik,
              idToView: row.original.Id,
              dayToView: moment(key).format('YYYY-MM-DD')
            })}
            onMouseEnter={(e) => this.setState({
              updateDate: row.row[key].lastUpdate ? row.row[key].lastUpdate : 'brak tasków',
              worktime: row.row[key].worktime ? this.toHours(row.row[key].worktime) : 'brak informacji'
            })}
            onMouseLeave={() => this.setState({ updateDate: '' })}
            className='time'
            to='/timereportedit/'
          >
            <span className={colorTime(key, row.row[key].seconds, row.row[key].worktime, row.row[key].confirmed)}>{this.toHours(row.row[key].seconds)}</span> /
            <span>{this.toHours(row.row[key].worktime)}</span>
          </Link>
        ),
        accessor: key,
        style: { textAlign: 'right', paddingRight: '10px', opacity: moment(key).format('e') >= 5 ? '.5' : 1 },
        headerStyle: { ...styleTimereportTable, opacity: moment(key).format('e') >= 5 ? '.5' : 1 }
      })
      )
      : []

    columns.unshift(
      {
        Header: () => <span>Pracownik</span>,
        accessor: 'Pracownik',
        fixed: 'left',
        width: 230,
        style: { textAlign: 'left' },
        headerStyle: { ...styleTimereportTable, textAlign: 'left', paddingTop: '15px' }
      },
      {
        Header: () => <span>Zadania</span>,
        accessor: 'Suma',
        fixed: 'left',
        width: 70,
        style: { paddingRight: '10px' },
        headerStyle: { ...styleTimereportTable, paddingTop: '15px' }
      },
      {
        Header: () => <span>Praca</span>,
        accessor: 'SumaPraca',
        fixed: 'left',
        width: 70,
        style: { paddingRight: '10px' },
        headerStyle: { ...styleTimereportTable, paddingTop: '15px' }
      },
      {
        Header: () => <span>Dni</span>,
        accessor: 'SumaDni',
        fixed: 'left',
        width: 70,
        style: { paddingRight: '10px' },
        headerStyle: { ...styleTimereportTable, paddingTop: '15px' }
      },
      {
        Header: () => <span>Nadgodz.</span>,
        accessor: 'Nadgodziny',
        fixed: 'left',
        width: 70,
        style: { paddingRight: '10px' },
        headerStyle: { ...styleTimereportTable, paddingTop: '15px' }
      }
    )

    // Table Content
    const final = []
    if (data && Array.isArray(data)) {
      data.forEach(user => {
        let sumTimes = 0
        let SumaPraca = 0
        let sumConfirmedDays = 0
        let sumWorktimeDays = 0
        const retObj = {
          Pracownik: user.NAME,
          Email: user.EMAIL,
          Id: user.ID
        }
        let hasNotAcceptedDays = 0
        Object.entries(user.times).forEach(time => {
          retObj[time[0]] = {
            seconds: time[1].totalTime,
            lastUpdate: time[1].lastUpdate,
            worktime: user.workdays[time[0]].value,
            confirmed: user.workdays[time[0]].confirmed
          }
          sumTimes += time[1].totalTime
          SumaPraca += user.workdays[time[0]].value
          sumConfirmedDays += user.workdays[time[0]].confirmed ? 1 : 0
          sumWorktimeDays += time[1].totalTime || user.workdays[time[0]].value || user.workdays[time[0]].confirmed ? 1 : 0
          if ((time[1].totalTime && user.workdays[time[0]].value === 0) || (user.workdays[time[0]].value > 0 && !user.workdays[time[0]].confirmed && time[0] !== moment().format('YYYY-MM-DD'))) {
            hasNotAcceptedDays = 1
          }
        })

        retObj.Suma = this.toHours(sumTimes)
        retObj.SumaPraca = this.toHours(SumaPraca)
        retObj.SumaDni = <><span className={(sumConfirmedDays < sumWorktimeDays) ? 'red' : ''}>{sumConfirmedDays}</span>/{sumWorktimeDays}</>
        retObj.Nadgodziny = <span className={(user.overTime && user.overTime !== '0:00') ? 'red' : ''}>{user.overTime || '0:00'}</span>
        if (disableHideAccepted || (!params.isHideAccepted || (params.isHideAccepted && hasNotAcceptedDays))) {
          final.push(retObj)
        }
      })
    }

    return (
      <div>
        <NotificationSystem ref='notificationSystem' style={style} />
        {this.state.loader ? <div className='lds-dual-ring' id='spinner' /> : ''}
        <Card
          title='Raport czasu pracy'
          category={`Data aktualizacji: ${this.state.updateDate || ''}, zalogowany czas: ${this.state.worktime || ''}`}
          content={
            <Table
              data={final}
              columns={columns}
              showPagination={false}
              minRows={1}
              resizable={false}
              sortable={false}
              className='-striped -highlight timereport'
              style={{ zIndex: 0, textAlign: 'right', maxHeight: '50vh' }}
              defaultPageSize={5000}
            />
          }
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    main: state.main,
    tasks: state.tasks
  }
}

const mapDispatchToProps = {
  onRequest: id => ({
    type: 'REQUEST_TASKS'
  }),
  setParams: params => ({
    type: 'TASKS_PARAMS',
    params
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeReportComponent)
