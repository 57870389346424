import React, { Component } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { StatsCard2 } from '../../components/StatsCard/StatsCard2'
import Card from '../../components/Card/Card.jsx'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NotificationSystem from 'react-notification-system'
import SCModal from './Components/Modal'
import Select from 'react-select'
import moment from 'moment'
import {
  allowedFromDepartments,
  API_SERVER,
  checkIsB2BUser,
  checkIsKMUser,
  checkIsUPUser,
  checkUserType
} from './authData'
import { getLabel } from './common'
import { style } from '../../variables/Variables'

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 1 }),
  control: style => ({ ...style, minHeight: 30 })
}
const labelStyles = {
  fontSize: 10,
  margin: '10px 0 0 5px',
  color: '#9a9a9a',
  fontWeight: 400,
  textTransform: 'uppercase'
}

class SettlementsDashboard extends Component {
  constructor(props) {
    super(props)
    this.setModalParams = this.setModalParams.bind(this)
    this.changeFakeUser = this.changeFakeUser.bind(this)
    this.setFakeUser = props.setFakeUser
    this.month = moment().format('YYYY-MM')
    this.state = {
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user
    }
  }

  componentDidMount() {
    this.userType()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const props = this.props
    if (JSON.stringify(prevProps.fakeUser) !== JSON.stringify(props.fakeUser)) {
      this.setState({
        fakeUser: props.fakeUser,
        loggedUser: props.fakeUser.ID ? props.fakeUser : props.user
      }, () => this.userType())
    }
  }

  userType() {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    const isOffice = checkUserType(loggedUser, 'office')
    const isHR = checkUserType(loggedUser, 'hr')
    const isB2B = checkIsB2BUser(loggedUser, this.month)
    const isKM = checkIsKMUser(loggedUser, this.month)
    const isUP = checkIsUPUser(loggedUser, this.month)
    this.setState({
      isFinance,
      isOffice,
      isHR,
      isB2B,
      isKM,
      isUP
    })
  }

  allowedDepartments(allDepart, allowed) {
    return allDepart.filter(el => allowed.includes(parseInt(el.ID, 10)))
  }

  allowedUsers(allUsers = {}, allowed) {
    return allUsers
      .filter(el => (el.scDepartment || []).includes(allowed))
    // .filter(el => el.ACTIVE);
  }

  /**
   * Obsługa powiadomień
   */
  showNotification(level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'tr',
      autoDismiss
    })
  }

  /**
   * Obsługa modala
   */
  renderSelect(row, key) {
    const { fakeUser = {} } = this.state
    const { main } = this.props
    let source = main[row] || []

    if (row === 'departments') {
      source = this.allowedDepartments(source, allowedFromDepartments
        .map(Number)
        .reduce((arr, el) => [
          358, // Blip
          356 // Influency
        ].includes(el) ? [...arr] : [...arr, el], [])
        .concat([
          86, // Communications & Marketing - id 86
          448, // CSD 448
          44, //  Finance 44
          76, // HR 76
          350, // IT Support 350
          1, // Zarząd 1
          452, // Payroll (id 452)
          424, //  Data Science (424)
          208, //  Publishing (208)
          288, //  Strategy  (288)
          454 // Sales
        ]))
    }

    let options = source.map(item => {
      return ({
        value: item.ID,
        label: item.NAME
      })
    })

    if (row === 'users') {
      source = this.allowedUsers(source, fakeUser.departments)
      options = source.sort((a = {}, b) => a.ACTIVE > b.ACTIVE ? -1 : 1).map(item => {
        return ({
          value: item.ID,
          label: item.ACTIVE ? item.NAME : `--${item.NAME}`
        })
      })
    }

    const initValue = {
      value: fakeUser[row],
      label: getLabel(main[row], fakeUser[row])
    }

    return (
      <div style={{ margin: '0 50px' }} key={key}>
        <label style={labelStyles}>{row}</label>
        <Select
          options={options}
          placeholder='ppp'
          name={row}
          value={initValue}
          styles={selectStyles}
          onChange={(e) => {
            fakeUser[row] = e.value
            fakeUser.users = row === 'departments' ? '' : fakeUser.users
            fakeUser[`${row}Name`] = getLabel(main[row], e.value)
            this.setState(() => ({ fakeUser }))
          }}
        />
        <input
          type='hidden'
          name={row}
          readOnly
        />
      </div>
    )
  }

  changeFakeUser() {
    const { fakeUser } = this.state
    const { main } = this.props
    const propUser = main.users.find(el => el.ID === fakeUser.users)

    this.setFakeUser({
      ID: fakeUser.users,
      UF_DEPARTMENT: [Number(fakeUser.departments)],
      departName: fakeUser.departmentsName,
      userName: fakeUser.usersName,
      scDepartment: propUser.scDepartment,
      scHead: propUser.scHead,
      scEstimactionNull: propUser.scEstimactionNull,
      EMAIL: propUser.EMAIL,
      csvPass: propUser.csvPass,
      monthly: propUser.monthly,
      accessToSettlementsIds: propUser.accessToSettlementsIds
    })
  }

  runCreateInvoices() {
    this.showNotification('info', 'Zlecono wygenerowanie faktur')
    fetch(`${API_SERVER}/api/create-invoices`)
      .then(res => res.json())
      .catch((err) => {
        console.error('Error:', err)
        this.showNotification('error', 'Wystąpił błąd')
      })
      .then((response) => {
        console.log(response)
        if (response.error) {
          this.showNotification('error', response.error)
        } else {
          this.showNotification('success', response.success, 6)
        }
      })
  }

  setModalParams(show = false, title = '', content = '', method = null) {
    this.setState({
      modalShow: show,
      modalTitle: title,
      modalContent: content,
      modalMethod: method
    })
  }

  renderModal() {
    const { modalShow, modalTitle, modalMethod } = this.state
    const rows = ['departments', 'users']
    const modalContent = rows.map((row, key) => this.renderSelect(row, key))

    return (
      <SCModal
        modalShow={modalShow}
        modalTitle={modalTitle}
        modalContent={modalContent}
        setModalParams={this.setModalParams}
        propMethod={modalMethod}
      />
    )
  }

  fetchBtxData(url) {
    fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET'
    })
      .then(res => res.json())
      .then(response => {
        if (!response || !response.success) {
          this.showNotification('error', response.message, 30)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  };

  btxUpdate(e, dbName) {
    e.preventDefault()
    this.showNotification('info', 'Zlecono aktualizację. Możesz zamknąć to okno.', 25)
    this.fetchBtxData(`${API_SERVER}/api/bitrix/update/${dbName}`)
  }

  render() {
    const { isFinance, isOffice, isHR, isB2B, isUP, isKM } = this.state

    /** tego chyba nie będę używać */
    const isAllowedUser = true
    /*
    // const isAllowedUser = user.UF_DEPARTMENT.some(el => allowedFromDepartments.filter(el2 => el2 !== 48).includes(el))
    //   || user.UF_DEPARTMENT.some(el => FINANCE_DEPARTMENTS.includes(el))
    */
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className='breadcrumb'>
                    <Link to='/settlements'>Panel rozliczeń</Link>
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <Row style={{ margin: '20px 0 0 -13px' }}>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-keypad text-warning' />}
                        statsText=''
                        statsValue='Zlecenia'
                        linkLeftTo='/settlements/orders'
                        linkLeftText='Lista'
                        linkRightTo='/settlements/order/add'
                        linkRightText='Nowe'
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-edit text-warning' />}
                        statsText=''
                        statsValue='Podzlecenia'
                        linkLeftTo='/settlements/jobs'
                        linkLeftText='Lista'
                        linkRightTo={isAllowedUser ? '/settlements/job/add' : ''}
                        linkRightText={isAllowedUser ? 'Nowe' : ''}
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-note2 text-warning' />}
                        statsText=' '
                        statsValue='Rozliczenia'
                        linkLeftTo='/settlements/list'
                        linkLeftText='Lista'
                        linkRightTo={isFinance || isOffice || this.state.loggedUser.ID === '22' ? '/settlements/settlement/add' : ''}
                        linkRightText={isFinance || isOffice || this.state.loggedUser.ID === '22' ? 'Nowe' : ''} // Mateusz
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-news-paper text-info' />}
                        statsText=' '
                        statsValue='Faktury'
                        linkLeftTo='/settlements/invoices'
                        linkLeftText='Lista'
                      />
                    </Col>
                    {
                      isFinance || isOffice
                        ? <Col lg={4} md={6}>
                          <StatsCard2
                            bigIcon={<i className='pe-7s-copy-file text-info' />}
                            statsText=''
                            statsValue='Klienci Office'
                            statsIcon={<i className='fa fa-link' />}
                            statsIconText='Lista i edycja Klientów działu Office'
                            linkLeftTo='/settlements/office-clients'
                            linkLeftText='Lista'
                          />
                        </Col>
                        : null
                    }
                    {
                      isFinance || ['360', '200', '536', '168'].includes(this.props.user.ID)
                        ? <Col lg={4} md={6}>
                          <StatsCard2
                            bigIcon={<i className='pe-7s-users text-info' />}
                            statsText=''
                            statsValue='Zmień Usera'
                            statsIcon={<i className='fa fa-link' />}
                            statsIconText=''
                            linkLeftTo={' '}
                            clickLeft={() => this.setModalParams(true, 'Zmień Usera', '', this.changeFakeUser)}
                            linkLeftText='Zmień'
                          />
                        </Col>
                        : null
                    }
                    {
                      isFinance
                        ? <Col lg={4} md={6}>
                          <StatsCard2
                            bigIcon={<i className='pe-7s-keypad text-danger' />}
                            statsText=''
                            statsValue='Upload CSV'
                            statsIcon={<i className='fa fa-link' />}
                            statsIconText=''
                            linkLeftTo='/settlements/upload'
                            linkLeftText='Upload'
                            linkRightTo={' '}
                            clickRight={() => this.runCreateInvoices()}
                            linkRightText='Generuj faktury'
                          />
                        </Col>
                        : null
                    }
                    {
                      // tymczasowo także dla user ID
                      isFinance || isHR || ['200', '360', '536', '168'].includes(this.props.user.ID)
                        ? <Col lg={4} md={6}>
                          <StatsCard2
                            bigIcon={<i className='pe-7s-users text-danger' />}
                            statsText=''
                            statsValue='Pracownicy'
                            statsIcon={<i className='fa fa-link' />}
                            statsIconText=''
                            linkLeftTo='/settlements/users'
                            linkLeftText='Zarządzaj'
                            linkRightTo='/settlements/salary-grid'
                            linkRightText='Siatka płac'
                          />
                        </Col>
                        : null
                    }
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-target text-danger' />}
                        statsText=''
                        statsValue='Estymacje'
                        statsIcon={<i className='fa fa-link' />}
                        statsIconText=''
                        linkLeftTo='/settlements/estimations'
                        linkLeftText='Zarządzaj'
                      />
                    </Col>
                    {
                      isFinance || ['200', '360', '536', '168'].includes(this.props.user.ID)
                        ? <Col lg={4} md={6}>
                          <StatsCard2
                            bigIcon={<i className='pe-7s-wallet text-info' />}
                            statsText=''
                            statsValue='HCVA'
                            statsIcon={<i className='fa fa-link' />}
                            statsIconText=''
                            linkLeftTo='/settlements/hcva'
                            linkLeftText='Ustaw'
                          />
                        </Col>
                        : null
                    }
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-download text-info' />}
                        statsText=''
                        statsValue='Pobierz CSV'
                        statsIcon={<i className='fa fa-link' />}
                        statsIconText=''
                        linkLeftTo='/settlements/getcsv'
                        linkLeftText='Lista'
                      />
                    </Col>
                    {
                      isB2B || isFinance || ['200', '360', '1', '330', '536', '168'].includes(this.props.user.ID)
                        ? <Col lg={4} md={6}>
                          <StatsCard2
                            bigIcon={<i className='pe-7s-note2 text-info' />}
                            statsText=''
                            statsValue='Raport B2B/UZ'
                            statsIcon={<i className='fa fa-link' />}
                            statsIconText=''
                            linkLeftTo='/settlements/worktime-report/b2b'
                            linkLeftText='Pokaż'
                            linkRightTo='/settlements/worktime-settings/b2b'
                            linkRightText={(isFinance || isHR || ['298', '200', '360'].includes(this.props.user.ID)) ? 'Ustawienia' : ''}
                          />
                        </Col>
                        : null
                    }
                    {
                      isKM || isFinance || ['200', '360', '1', '330', '536', '168'].includes(this.props.user.ID)
                        ? <Col lg={4} md={6}>
                          <StatsCard2
                            bigIcon={<i className='pe-7s-note2 text-info' />}
                            statsText=''
                            statsValue='Raport KM'
                            statsIcon={<i className='fa fa-link' />}
                            statsIconText=''
                            linkLeftTo='/settlements/worktime-report/km'
                            linkLeftText='Pokaż'
                            linkRightTo='/settlements/worktime-settings/km'
                            linkRightText={(isFinance || isHR || ['298', '200', '360'].includes(this.props.user.ID)) ? 'Ustawienia' : ''}
                          />
                        </Col>
                        : null
                    }
                    {
                      isUP || isFinance || ['200', '360', '1', '330', '536', '168'].includes(this.props.user.ID)
                        ? <Col lg={4} md={6}>
                          <StatsCard2
                            bigIcon={<i className='pe-7s-note2 text-info' />}
                            statsText=''
                            statsValue='Raport UP'
                            statsIcon={<i className='fa fa-link' />}
                            statsIconText=''
                            linkLeftTo='/settlements/worktime-report/up'
                            linkLeftText='Pokaż'
                          />
                        </Col>
                        : null
                    }
                    <Col md={12} />
                    <hr />
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-keypad text-success' />}
                        statsText=''
                        statsValue='Aktualizuj firmy&nbsp;BX'
                        statsIcon={<i className='fa fa-link' />}
                        statsIconText=''
                        linkLeftTo={' '}
                        clickLeft={e => this.btxUpdate(e, 'companies')}
                        linkLeftText='Aktualizuj'
                        linkRightText='(ok. 2 minuty)'
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      <StatsCard2
                        bigIcon={<i className='pe-7s-keypad text-success' />}
                        statsText=''
                        statsValue='Aktualizuj userów&nbsp;BX'
                        statsIcon={<i className='fa fa-link' />}
                        statsIconText=''
                        linkLeftTo={' '}
                        clickLeft={e => this.btxUpdate(e, 'users')}
                        linkLeftText='Aktualizuj'
                        linkRightText='(ok. 15 minut)'
                      />
                    </Col>
                    {
                      ['200', '360', '158', '536', '168', '46'].includes(this.state.loggedUser.ID) &&
                      <Col lg={4} md={6}>
                        <StatsCard2
                          bigIcon={<i className='pe-7s-keypad text-success' />}
                          statsText=''
                          statsValue='COO'
                          statsIcon={<i className='fa fa-link' />}
                          statsIconText=''
                          linkLeftTo='/settlements/coo'
                          linkLeftText='Aktualizuj'
                        />
                      </Col>
                    }
                  </Row>
                }
              />
            </Col>
          </Row>
        </Grid>
        {this.renderModal()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main
  }
}

const mapDispatchToProps = {
  setFakeUser: user => ({
    type: 'SET_FAKE_USER',
    user
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementsDashboard)
