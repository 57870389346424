import React from 'react'
import NumberFormat from 'react-number-format'

export default (prop) => (
  <NumberFormat
    value={prop.value}
    displayType='text'
    thousandSeparator={' '}
    decimalSeparator={prop.decimalScale === '0' ? '' : prop.decimalSeparator || '.'}
    allowedDecimalSeparators={[',', '.']}
    fixedDecimalScale
    decimalScale={prop.decimalScale || 2}
    isNumericString
  />
)
