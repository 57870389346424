import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Grid, Row, Button } from 'react-bootstrap'
import Card from '../../../components/Card/Card.jsx'
import { Link } from 'react-router-dom'
import NotificationSystem from 'react-notification-system'
import { style } from '../../../variables/Variables'
import moment from 'moment'
import FORM_MODEL from './jobs'
import { getMainMap, getMapLabel } from '../common'
import { API_SERVER } from '../authData'
import Loader from '../Components/Loader'
import DataGrid from '../Components/DataGrid'
import ReactMonthPicker from '../Components/ReactMonthPicker'

const colParams = {
  list: { pl: 'Lista', width: 38, filterable: false },
  settled: { pl: 'Rozliczonych', width: 80, filterable: true },
  company: { pl: 'Zleceniodawca', filterable: true },
  client: { pl: 'Klient', filterable: true },
  order_name: { pl: 'Nazwa zlecenia', filterable: true },
  ...FORM_MODEL
}

class JobList extends Component {
  constructor (props) {
    super(props)
    this.setJobsMonth = props.setJobsMonth
    this.linkTo = this.linkTo.bind(this)
    this.onChangeMonth = this.onChangeMonth.bind(this)
    this.state = {
      loader: true,
      colParams,
      prevButton: false,
      nextButton: true,
      jobsDepart: 0,
      month: props.jobsMonth || moment().format('YYYY/MM'),
      preparedCols: this.prepareCols(colParams),
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      mainMap: getMainMap(props.main)
    }
  }

  componentDidMount () {
    this.getJobs()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.main) !== JSON.stringify(this.props.main)) {
      const mainMap = getMainMap(this.props.main)
      this.setState({ mainMap })
    }
  }

  getJobs () {
    const { loggedUser, month, jobsDepart } = this.state
    fetch(`${API_SERVER}/api/get-jobs`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        person: loggedUser,
        jobsDepart,
        month
      })
    })
      .then(res => res.json())
      .then(response => {
        if (response) {
          this.setState({
            loader: false,
            preparedRows: this.prepareRows(response)
          })
        } else {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', response)
        }
      })
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }
    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  onChangeMonth (months) {
    const [year, month] = this.state.month.split('/').map(el => Number(el))
    if (JSON.stringify({ year, month }) !== JSON.stringify(months.to)) {
      const newDate = months ? `${months.to.year}/${months.to.month}` : months
      this.setState({
        month: newDate,
        preparedRows: [],
        loader: true
      }, () => {
        this.setJobsMonth({ jobsMonth: newDate })
        this.getJobs()
      })
    }
  }

  renderMonthPicker () {
    const [year, month] = this.state.month.split('/').map(el => Number(el))
    return (
      <ReactMonthPicker
        onChangeMonth={this.onChangeMonth.bind(this)}
        date={{ to: { year, month } }}
        showDel
      />
    )
  }

  linkTo (link) {
    this.props.history.push({ pathname: `${link}` })
  }

  prepareRows (data = []) {
    const { colParams, mainMap } = this.state

    return data.length
      ? data.map((job) => {
        let row = {}
        Object.keys(colParams).forEach((col) => {
          const value = col === 'company'
            ? getMapLabel(mainMap.companies, job[col])
            : ['main_person', 'from_person', 'resp_person'].includes(col)
                ? getMapLabel(mainMap.users, job[col])
                : ['main_depart', 'from_depart', 'resp_depart'].includes(col)
                    ? getMapLabel(mainMap.departments, job[col])
                    : job[col] || ''
          row = { ...row, [col]: value || '' }
        })
        return row
      })
      : []
  }

  prepareCols (colParams) {
    const dontShow = ['_id']

    const columns = []
    columns.push({ key: 'actions', name: '', width: 95, frozen: true, filterable: false })

    Object.keys(colParams).forEach((col) => {
      if (!dontShow.includes(col)) {
        columns.push({
          key: col,
          name: colParams[col] ? colParams[col].pl : col,
          filterable: !!colParams[col].filterable,
          resizable: true,
          sortable: true,
          width: colParams[col].width
        })
      }
    })
    return columns
  }

  renderDataGrid () {
    const { preparedRows = [], preparedCols = [], clearAllFilters } = this.state
    return (
      preparedCols.length && preparedRows.length
        ? <DataGrid
            clearAllFilters={clearAllFilters}
            columns={preparedCols}
            rows={preparedRows}
            linkTo={this.linkTo}
            table='jobs'
            links={[
              { name: 'Rozlicz', href: '/settlements/settlement/add?', id: '_id' },
              { name: 'Edytuj', href: '/settlements/job/', id: '_id' },
              { name: 'Lista', href: '/settlements/list/', id: '_id', month: moment(this.state.month).format('YYYY-MM') }
            ]}
          />
        : null
    )
  }

  render () {
    const { loader, month, clearAllFilters } = this.state

    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Loader display={loader} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className='breadcrumb'>
                    <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                      to='/settlements/jobs'
                                                                     >Lista podzleceń
                    </Link>
                  </div>
                }
              />
            </Col>
          </Row>
          <div className={loader ? 'noEvents' : ''}>
            <Row>
              <Col md={12}>
                <nav className='navbar navbar-light bg-light'>
                  <div className='btn-group' role='group' style={{ display: 'flex' }}>
                    <Link to='/settlements/job/add' className='btn btn-success' role='button'>Nowe podzlecenie</Link>
                    <Button className='btn' onClick={() => this.setState({ clearAllFilters: !clearAllFilters })}>Usuń wszystkie filtry</Button>
                    {this.renderMonthPicker()}
                  </div>
                </nav>
                <Card
                  title={<span>Lista Podzleceń - <b>{month ? moment(month, 'YYYY/MM').format('MMMM YYYY') : 'cały okres'}</b></span>}
                  content={this.renderDataGrid()}
                />
              </Col>
            </Row>
          </div>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main,
    jobsMonth: state.settlements.jobsMonth
  }
}

const mapDispatchToProps = {
  setJobsMonth: jobsMonth => ({
    type: 'JOBS_MONTH',
    jobsMonth
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(JobList)
