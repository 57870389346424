import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, Table, FormControl } from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'
import Card from '../../../components/Card/Card.jsx'
import Select from 'react-select'
import { formInvoice, statusCode } from './invoices'
import { getInvoices, getSettlements, format2d } from './common'
import { getMainMap, getDepartmentsMap, getMapLabel } from '../common'
import Loader from '../Components/Loader'
import moment from 'moment'
import { API_SERVER, allowedFromDepartments, checkUserType } from '../authData'
import { style } from '../../../variables/Variables'
import { Link } from 'react-router-dom'
import NumberFormatText from '../Components/NumberFormat'
import NumberFormat from 'react-number-format'

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 1 }),
  control: style => ({ ...style, minHeight: 30, fontSize: 12 })
}

class InvoiceDetails extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      loader: true,
      colParams: formInvoice,
      statusCode,
      valid: false,
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      mainMap: getMainMap(props.main),
      statusMap: getDepartmentsMap(statusCode)
    }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    const isOffice = checkUserType(loggedUser, 'office')
    this.setState({
      isFinance,
      isOffice
    })
    this.loadNext()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.main) !== JSON.stringify(this.props.main)) {
      const mainMap = getMainMap(this.props.main)
      this.setState({ mainMap })
    }
  }

  loadNext () {
    const { slug } = this.props.match.params
    const { loggedUser } = this.state

    getInvoices(loggedUser, slug)
      .catch((err) => {
        this.showNotification('error', 'Wystąpił błąd' + err)
      })
      .then(res => {
        if (!res || res.error) {
          this.showNotification('error', 'Wystąpił błąd' + res && res.error)
        } else {
          this.setState({ data: res.result })
        }
      })
      .then(() => {
        const { data = [] } = this.state
        const invoice = data[0] || []
        getSettlements({
          order_id: invoice.order_id,
          month: invoice.month,
          invoices: invoice.invoices,
          person: loggedUser
        })
          .catch(() => this.showNotification('error', 'Wystąpił błąd'))
          .then(res => this.setState({ details: res, loader: false }))
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  sumDetails (data) {
    return data.length
      ? data
        .map(el => parseFloat(el.revenue || 0))
        .reduce((a, b) => a + b, 0)
      : ''
  }

  renderInvoiceTableHeader () {
    const { colParams } = this.state
    const dontShow = ['_id', 'comment']
    const style = col => ({
      display: dontShow.includes(col) ? 'none' : '',
      width: colParams[col] ? colParams[col].width : ''
    })
    return Object.keys(colParams).map((col, key) =>
      <th key={key} style={style(col)}>{colParams[col] ? colParams[col].pl : col}</th>
    )
  }

  renderInvoiceTableContent () {
    const { data = [], details = [], colParams, mainMap, statusMap } = this.state
    const dontShow = ['_id', 'comment']

    return data.length
      ? data.map((elem, key) => (
        <tr
          key={key}
          className={!['2'].includes(elem.status) ? 'closed' : ''}
        >
          {Object.keys(colParams).map((col, key2) => (
            !dontShow.includes(col)
              ? <td key={key2}>{
                col === '_id'
                  ? key + 1
                  : col === 'sum'
                    ? <div><NumberFormatText value={format2d(this.sumDetails(details))} /> zł</div>
                    : col === 'company'
                      ? `${getMapLabel(mainMap.companies, elem[col])} ${getMapLabel(mainMap.companies, elem[col], 'nip')}`
                      : col === 'mainDepart'
                        ? getMapLabel(mainMap.departments, elem[col])
                        : col === 'mainPerson'
                          ? getMapLabel(mainMap.users, elem[col])
                          : col === 'status'
                            ? getMapLabel(statusMap, elem[col])
                            : elem[col]
}
                </td>
              : null
          )
          )}
        </tr>
      )
      )
      : null
  };

  renderComment () {
    const { data = [] } = this.state
    return (
      <div style={{ margin: '0 15' }}>
        <textarea
          name='comment'
          className='form-control'
          rows={7}
          style={{
            width: '95%',
            height: 100,
            display: 'inline-block',
            color: '#999'
          }}
          placeholder='Uwagi'
          value={data[0] ? data[0].comment : ''}
          onChange={(e) => {
            data[0].comment = e.target.value
            this.setState({ data })
          }}
        />
        <button
          className='btn btn-primary btn-sm'
          style={{ display: 'block', margin: '10 0' }}
          onClick={() => {
            this.handleSubmit('comment')
          }}
        >Zapisz uwagi
        </button>
      </div>
    )
  }

  renderManualChangesForFinance () {
    const { isFinance } = this.state
    if (isFinance) {
      return (
        <div
          style={{ display: 'flex', background: '#eaeaea', width: '80%', margin: '10px auto', padding: '15px 10px 5px 10px' }}
        >
          {this.renderChangeMainDepart()}
          {this.renderChangeMainPerson()}
          {this.renderChangeStatus()}
        </div>
      )
    }
  }

  renderChangeMainDepart () {
    const { data = [] } = this.state
    const { departments } = this.props.main
    const depart = departments.filter(el => allowedFromDepartments.includes(el.ID))
    const options = depart
      .sort((a, b) => ((a.NAME > b.NAME) ? 1 : -1))
      .map((el) => (
        {
          value: el.ID,
          label: el.NAME
        }
      ))

    return (
      <div style={{ width: 330, margin: '0 15' }}>
        <Select
          options={options}
          styles={selectStyles}
          placeholder='Zmień dział główny'
          onChange={(e) => {
            data[0].mainDepart = e.value
            this.setState({ data })
          }}
        />
        <button
          className='btn btn-primary btn-sm'
          style={{ margin: '10 0' }}
          onClick={() => {
            this.handleSubmit('mainDepart')
          }}
        >Zapisz dział główny
        </button>
      </div>
    )
  }

  renderChangeMainPerson () {
    const { data = [] } = this.state
    const { users } = this.props.main
    const options = users
      .sort((a, b) => ((a.NAME > b.NAME) ? 1 : -1))
      .map((el) => (
        {
          value: el.ID,
          label: el.NAME
        }
      ))

    return (
      <div style={{ width: 330, margin: '0 15' }}>
        <Select
          options={options}
          styles={selectStyles}
          placeholder='Zmień opiekuna Klienta'
          onChange={(e) => {
            data[0].mainPerson = e.value
            this.setState({ data })
          }}
        />
        <button
          className='btn btn-primary btn-sm'
          style={{ margin: '10 0' }}
          onClick={() => {
            this.handleSubmit('mainPerson')
          }}
        >Zapisz opiekuna Klienta
        </button>
      </div>
    )
  }

  renderChangeStatus () {
    const { data = [] } = this.state
    const options = (this.state.statusCode || []).map((el) => (
      {
        value: el.ID,
        label: el.NAME
      }
    ))

    return (
      <div style={{ width: 330, margin: '0 15' }}>
        <Select
          options={options}
          styles={selectStyles}
          placeholder='Zmień status'
          onChange={(e) => {
            data[0].status = e.value
            this.setState({ data })
          }}
        />
        <button
          className='btn btn-primary btn-sm'
          style={{ margin: '10 0' }}
          onClick={() => {
            this.handleSubmit('status')
          }}
        >Zapisz status
        </button>
      </div>
    )
  }

  renderInvoiceTableDetails () {
    const { details = [], mainMap } = this.state

    return details.length
      ? details.map((elem, key) => (
        <tr key={key}>
          <td>{getMapLabel(mainMap.departments, elem.department)}</td>
          <td
            className={this.checkLastDayOfMonth(elem.date) ? 'red' : ''}
          >{elem.date !== 'undefined' ? elem.date : ''}
          </td>
          <td className='right'><NumberFormatText value={format2d(elem.revenue)} /> zł</td>
          <td className='right'><NumberFormatText value={format2d(elem.invoiced)} /> zł</td>
          <td
            className='right'
            style={elem.revenue && ((elem.revenue || 0) !== elem.invoiced) ? { color: 'blue' } : {}}
          ><NumberFormatText value={format2d(elem.rest)} /> zł
          </td>
        </tr>
      ))
      : null
  };

  renderConfirmButton () {
    const { data = [], loggedUser, isFinance } = this.state
    const { status } = data[0] || {}
    const { mainPerson } = data[0] || ''

    // Button dostępny dla Opiekuna Klienta i jego przełożonego
    //
    const isMainPerson = loggedUser.ID === mainPerson || [...(loggedUser.scHead || []), ...(loggedUser.accessToSettlementsIds || [])].includes(mainPerson)
    const isEnabled = (['1'].includes(status) && (isMainPerson || isFinance)) || (['5'].includes(status) && isFinance)

    if (isEnabled) {
      return (
        <button
          className='btn btn-primary'
          style={{ margin: 10 }}
          disabled={!isEnabled}
          onClick={() => {
            data[0].status = '2'
            this.setState({ data }, () => this.handleSubmit())
          }}
        >{status === '5' ? 'Zatwierdź korektę' : 'Zatwierdź'}
        </button>
      )
    }
  }

  generateOptions (val) {
    const { mainMap } = this.state
    if (!val.department) {
      return ({
        value: '',
        label: ''
      })
    }

    return ({
      value: `${val.department}|${val.date}|${format2d(val.revenue)}|${format2d(val.rest)}`,
      label: <div>{getMapLabel(mainMap.departments, val.department)} | {val.date} (<NumberFormatText value={format2d(val.revenue)} /> zł)</div>
    })
  }

  checkLastDayOfMonth (date) {
    if (date !== 'undefined') {
      return (date !== moment(date)
        .endOf('month')
        .format('YYYY-MM-DD'))
    }
  }

  renderInvoices () {
    const { data = [], details = [], mainMap } = this.state
    const { invoices = [] } = data[0] || []
    const options = details.map((el) => this.generateOptions(el))

    return invoices.map((elem, key) => {
      return elem.entries.length
        ? elem.entries.map((elem2, key2) => {
          if (!elem2.new) {
            return (
              <tr key={key2}>
                {key2 < 1
                  ? <td rowSpan={elem.entries.length} className='border'> </td>
                  : null}
                {key2 < 1
                  ? <td rowSpan={elem.entries.length} className='border'>{elem.number}</td>
                  : null}
                <td>{getMapLabel(mainMap.departments, elem2.department)}</td>
                <td>{elem2.date}</td>
                <td style={{ textAlign: 'right' }}><NumberFormatText value={format2d(elem2.amount)} /> zł</td>
              </tr>
            )
          } else {
            const sel = invoices[key].entries[key2] || {}
            const initValue = this.generateOptions(sel)
            return (
              <tr key={key2} className={key2 < 1 ? 'border' : ''}>
                {key2 < 1
                  ? <td rowSpan={elem.entries.length} className='border'>
                    <div
                      className='del'
                      title='Usuń fakturę'
                      onClick={() => {
                        invoices.splice(key, 1)
                        this.setState({ data }, () => this.validate(data))
                      }}
                    >x
                    </div>
                    </td>
                  : null}
                {key2 < 1
                  ? <td rowSpan={elem.entries.length}>
                    <FormControl
                      type='text'
                      value={invoices[key].number}
                      onChange={(e) => {
                        invoices[key].number = e.target.value
                        this.setState({ data }, () => this.validate(data))
                      }}
                    />
                  </td>
                  : null}
                <td colSpan={2}>
                  <Select
                    options={options}
                    value={initValue}
                    styles={selectStyles}
                    onChange={(e) => {
                      const parts = e.value.split('|')
                      invoices[key].entries[key2] = {
                        department: parts[0],
                        date: parts[1],
                        revenue: format2d(parts[2]),
                        amount: format2d(parts[3]),
                        new: true
                      }
                      this.setState({ data }, () => this.validate(data))
                    }}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={invoices[key].entries[key2].amount}
                    customInput={FormControl}
                    thousandSeparator={' '}
                    decimalSeparator=','
                    allowedDecimalSeparators={[',', '.']}
                    fixedDecimalScale
                    decimalScale={2}
                    isNumericString
                    className={format2d(invoices[key].amount) > format2d(invoices[key].revenue) ? 'error' : ''}
                    onBlur={(e) => {
                      invoices[key].entries[key2].amount = format2d(e.target.value)
                      this.setState({ data })
                    }}
                    onValueChange={(values) => {
                      invoices[key].entries[key2].amount = values.value
                      this.setState({ data }, () => this.validate(data))
                    }}
                  />
                </td>
                <td>
                  {invoices[key].entries.length !== 1
                    ? <div
                        className='del' title='Usuń pozycję'
                        onClick={() => {
                          invoices[key].entries.length === 1
                            ? invoices.splice(key, 1)
                            : invoices[key].entries.splice(key2, 1)
                          this.setState({ data }, () => this.validate(data))
                        }}
                      >x
                    </div>
                    : null}
                </td>
                {key2 < 1
                  ? <td rowSpan={elem.entries.length}>{this.renderAddPosition(key)}</td>
                  : null}
              </tr>
            )
          }
        })
        : null
    })
  }

  renderAddInvoiceButton () {
    const { data = [], isFinance, isOffice } = this.state
    const { invoices, status } = data[0] || []
    const isEnabled = (status === '2') && (isOffice || isFinance)
    return (
      <button
        className={`btn btn-default btn-sm ${status === '2' && isEnabled ? 'btn-primary' : null}`}
        style={{ margin: 10 }}
        disabled={!isEnabled}
        onClick={() => {
          invoices.push({ entries: [{ new: true }] })
          this.setState({ data }, () => this.validate(data))
        }}
      >+ Dodaj fakturę
      </button>
    )
  }

  renderAddPosition (key) {
    const { data = [] } = this.state
    const { invoices, status } = data[0] || []

    return (
      <button
        className={`btn btn-default btn-sm ${status === '2' ? 'btn-primary' : null}`}
        style={{ margin: 10 }}
        disabled={status !== '2'}
        onClick={() => {
          invoices[key].entries.push({ new: true })
          this.setState({ data }, () => this.validate(data))
        }}
      >+ Dodaj pozycję
      </button>
    )
  }

  renderSummary () {
    const { data = [], details = [], valid = false } = this.state
    const { invoices = [] } = data[0] || []

    // Grupuje wszystkie faktury wg działu i daty

    const newInvoices = {}
    invoices.map(el => el.entries
      .map(el2 => {
        const key = el2.department + '|' + el2.date
        newInvoices[key] = newInvoices[key] || 0
        return newInvoices[key] += parseFloat(el2.amount)
      })
    )

    // Sprawdza czy zostały wystawione wszystkie wymagane faktury

    const sumDepartInvoices = details.every(det => {
      const key = det.department + '|' + det.date
      return format2d(newInvoices[key]) === format2d(det.revenue)
    })

    // Zlicza sumę wszystkich wystawionych faktur

    const sumInvoices = invoices
      .map(el => el.entries
        .map(el2 => parseFloat(el2.amount || 0)))
      .reduce((a, b) => a.concat(b), [])
      .reduce((a, b) => a + b, 0)

    // Zlicza pozostałą różnicę do wyfakturowania

    const result = this.sumDetails(details) - (sumInvoices || 0)

    return (
      <tr>
        <td colSpan={2}> </td>
        <td>Pozostało <NumberFormatText value={format2d(result)} /> zł</td>
        <td>
          <input
            type='submit'
            className={!valid || !sumDepartInvoices ? 'btn' : 'btn btn-primary'}
            value='Wystaw'
            disabled={!valid || !sumDepartInvoices}
            onClick={() => {
              data[0].status = '3'
              this.setState({ data }, () => this.validate(data))
              this.handleSubmit()
            }}
          />
        </td>
      </tr>
    )
  }

  validate (data = []) {
    const { invoices = [], status } = data[0]
    try {
      const validNumbers = invoices
        .map(el => el.hasOwnProperty('number') && el.number !== '')
      const validAmounts = invoices
        .map(el => el.entries
          .map(el2 => el2.hasOwnProperty('amount') && el2.amount !== ''))
        .reduce((a, b) => a.concat(b), [])

      if (
        status === '2' &&
        validNumbers.every(el => el) &&
        validAmounts.every(el => el)) {
        this.setState({ valid: true })
      } else {
        this.setState({ valid: false })
      }
    } catch (e) {
      this.setState({ valid: false })
    }
  }

  handleSubmit (type) {
    const { data = [] } = this.state
    let body = {}

    // Zapisywanie samego komentarza

    if (type === 'comment') {
      body._id = data[0]._id
      body.status = data[0].status
      body.comment = data[0].comment
    }

    // Zapisywanie działu głównego

    else if (type === 'mainDepart') {
      body._id = data[0]._id
      body.status = data[0].status
      body.mainDepart = data[0].mainDepart
    }

    // Zapisywanie opiekuna Klienta

    else if (type === 'mainPerson') {
      body._id = data[0]._id
      body.status = data[0].status
      body.mainPerson = data[0].mainPerson
    }

    // Zapisywanie statusu

    else if (type === 'status') {
      body._id = data[0]._id
      body.status = data[0].status
    }

    // Zapisywanie całej faktury

    else {
      data[0].invoices = data[0].invoices || []
      data[0].invoices.forEach((el = []) => {
        el.entries.forEach((el2) => {
          delete el2.new
          delete el2.revenue
        })
      })
      delete data[0].client
      delete data[0].company
      delete data[0].order_name
      delete data[0].orderStatus
      body = data[0]
    }
    this.showNotification('info', 'Wysyłanie danych')
    fetch(`${API_SERVER}/api/save-invoice`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
      .then(response => {
        if (response) {
          this.showNotification('success', 'Dane zostały zapisane', 6)
          console.log('Success:', response)
          this.setState({ data, valid: false })
          this.loadNext()
        } else {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', response)
        }
      })
  }

  render () {
    const { loader, data = [] } = this.state
    const { month = '', order_id = '', orderStatus = '' } = data[0] || []

    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Loader display={loader} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className='breadcrumb'>
                    <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                      to='/settlements/invoices'
                                                                     >Lista faktur
                    </Link> » <Link
                                                                       to={this.props.location.pathname}
                                                                               >Szczegóły faktury
                                                                               </Link>
                  </div>
                }
              />
            </Col>
          </Row>
          <div className={loader ? 'noEvents' : ''}>
            <Row>
              <Col md={12}>
                <Card
                  title={
                    <span>Zlecenie {order_id} | <small>miesiąc</small> {month}
                      {
                      orderStatus
                        ? <span> | <small>status</small>
                          <span
                            className={!orderStatus.includes('w toku') ? 'bg-danger' : ''}
                            style={{ marginLeft: 5, padding: '3px 5px' }}
                          >{orderStatus}
                          </span>
                          </span>
                        : null
                    }
                    </span>
                  }
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <div>
                      <Table striped hover>
                        <thead>
                          <tr>
                            {this.renderInvoiceTableHeader()}
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderInvoiceTableContent()}
                        </tbody>
                      </Table>
                      {this.renderComment()}
                      {this.renderManualChangesForFinance()}
                    </div>
                  }
                />
                <Card
                  title='Szczegóły'
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          <th>Dział</th>
                          <th>Data sprzedaży</th>
                          <th className='right'>Kwota</th>
                          <th className='right'>Wystawione</th>
                          <th className='right'>Różnica</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderInvoiceTableDetails()}
                        <tr>
                          <td colSpan={5} style={{ textAlign: 'right' }}>
                            {this.renderConfirmButton()}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  }
                />
                <Card
                  title='Faktury'
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table className='invoices'>
                      <thead>
                        <tr>
                          <th />
                          <th>Faktura</th>
                          <th>Dział</th>
                          <th>Data sprzedaży</th>
                          <th>Kwota</th>
                          <th />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderInvoices()}
                        <tr className='border'>
                          <td colSpan={7}>
                            {this.renderAddInvoiceButton()}
                          </td>
                        </tr>
                        {this.renderSummary()}
                      </tbody>
                    </Table>
                }
                />
              </Col>
            </Row>
          </div>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main
  }
}

export default connect(mapStateToProps)(InvoiceDetails)
