const orders = (state = {
  filters: {
    page: 0,
    query: ''
  },
  jobFilters: {
    page: 0,
    query: ''
  }
}, action) => {
  switch (action.type) {
    case 'ORDERS_SET_FILTERS':
      return Object.assign({}, state, {
        filters: action.filters
      })
    case 'JOB_SET_FILTERS':
      return Object.assign({}, state, {
        jobFilters: action.jobFilters
      })
    default:
      return state
  }
}

export default orders
