import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import './Modal.css'

class SCModal extends Component {
  constructor (props) {
    super(props)
    this.modalClose = this.modalClose.bind(this)
    this.modalAgree = this.modalAgree.bind(this)
    this.state = {
      show: false,
      setShow: false
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState({
      show: props.modalShow
    })
  }

  modalClose () {
    this.props.setModalParams()
  }

  modalAgree () {
    this.props.propMethod()
    this.modalClose()
  }

  render () {
    const { show } = this.state
    const { modalTitle, modalContent, setHeight, agreeButton = true, agreeText = 'Zatwierdź', closeButton = true, closeText = 'Anuluj', size, dialogClassName = '', allowToSubmit = true, utilityButton = false, utilityCallback = null, utilityText = '' } = this.props
    return (
      <Modal
        bsSize={size}
        dialogClassName={`${size === 'wide' ? 'modal-wide' : ''} ${dialogClassName}`}
        show={show}
        onHide={this.modalClose}
        className={setHeight ? 'setHeight' : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalContent}
        </Modal.Body>

        <Modal.Footer>
          {utilityButton ? <Button className='btn' onClick={utilityCallback}>{utilityText}</Button> : null}
          {closeButton ? <Button className='btn btn-secondary' onClick={this.modalClose}>{closeText}</Button> : null}
          {agreeButton ? <Button className='btn btn-primary' onClick={this.modalAgree} disabled={!allowToSubmit}>{agreeText}</Button> : null}
        </Modal.Footer>
      </Modal>
    )
  }
}

export default SCModal
