import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import imagine from '../../assets/img/sidebar-3.jpg'
import logo from '../../assets/img/cube_logo_floating.png'

import appRoutes from '../../routes/app'
import { connect } from 'react-redux'

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth,
      fullMenu: false
    }
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth })
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  slideMenu() {
    this.setState({ fullMenu: !this.state.fullMenu })
  }

  render() {
    const sidebarBackground = {}
    const user = this.props.user
    const { fullMenu } = this.state
    return (
      <div
        id='sidebar'
        style={{
          width: fullMenu === false ? '90px' : '260px',
          boxShadow: fullMenu === false ? '' : '10px 0 20px 0 rgba(0, 0, 0, 0.4)',
          transition: 'width ease .3s',
          overflow: 'hidden'
        }}
        className='sidebar'
        data-color='black'
        data-image={imagine}
        onMouseOver={() => this.slideMenu()}
        onMouseOut={() => this.slideMenu()}
      >
        <div className='sidebar-background' style={sidebarBackground} />
        <div className='logo'>
          <a href='/' className='simple-text logo-mini'>
            <div className='logo-img'>
              <img src={logo} alt='logo_image' />
            </div>

          </a>
          {fullMenu === false ? '' : <a href='/' className='simple-text logo-normal'>Super&nbsp;Cube</a>}
        </div>
        <div className={fullMenu === false ? 'sidebar-wrapper' : 'sidebar-wrapper full-menu'}>
          <ul className='nav'>
            {
              appRoutes.map((prop, key) => {
                if (!user) {
                  return null
                } else if (!prop.hidden && !prop.redirect && prop.name && (
                  !prop.auth || process.env.NODE_ENV === 'development' ||
                  (user && user.UF_DEPARTMENT && user.UF_DEPARTMENT.filter((department) => prop.auth.indexOf(department) > -1).length) ||
                  (user && user.ID && prop.userIds && prop.userIds.indexOf(user.ID) > -1)
                )) {
                  return (
                    <li
                      className={prop.upgrade ? 'active active-pro' : this.activeRoute(prop.path)}
                      key={key}
                    >
                      <NavLink to={prop.path} className={fullMenu === false ? 'nav-link' : 'nav-link full-menu'} activeClassName='active'>
                        <i className={prop.icon} />
                        <p style={fullMenu === false ? { display: 'none' } : {}}>{prop.name}</p>
                      </NavLink>
                    </li>
                  )
                }
                return null
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(Sidebar)
