import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap'

import HeaderLinks from './HeaderLinks.jsx'

import appRoutes from '../../routes/app.jsx'

class Header extends Component {
  constructor (props) {
    super(props)
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this)
    this.state = {
      sidebarExists: false
    }
  }

  mobileSidebarToggle (e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      })
    }
    e.preventDefault()
    document.documentElement.classList.toggle('nav-open')
  }

  getBrand () {
    let name
    appRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name
          }
          return null
        })
      } else if (prop.path === this.props.location.pathname) {
        name = prop.name
      }
      return null
    })
    return name
  }

  render () {
    return (
      <Navbar fluid>
        <Navbar.Header>
          <Navbar.Brand>
            {this.getBrand()}
          </Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        <Navbar.Collapse>
          <HeaderLinks {...this.props} />
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Header
