export default () => ({
  adspaceSubcategories: {
    'subject-step_4_bussiness': 'biznes',
    'subject-step_4_home': 'dom i wnętrze',
    'subject-step_4_ecommerce': 'e-commerce',
    'subject-step_4_finance': 'finanse',
    'subject-step_4_games': 'gry',
    'subject-step_4_gambling': 'hazard',
    'subject-step_4_film': 'film i muzyka',
    'subject-step_4_information': 'informacje',
    'subject-step_4_culinary': 'kulinaria',
    'subject-step_4_culture': 'kultura i sztuka',
    'subject-step_4_books': 'książki i literatura',
    'subject-step_4_lifestyle': 'lifestyle',
    'subject-step_4_fashion': 'moda',
    'subject-step_4_automotive': 'motoryzacja',
    'subject-step_4_parenting': 'parenting',
    'subject-step_4_science': 'nauka i edukacja',
    'subject-step_4_work': 'praca i kariera',
    'subject-step_4_entertainment': 'rozrywka',
    'subject-step_4_sport': 'sport',
    'subject-step_4_beauty': 'uroda',
    'subject-step_4_technologies': 'technologie i innowacje',
    'subject-step_4_telecommunication': 'telekomunikacja',
    'subject-step_4_tourism': 'turystyka',
    'subject-step_4_health': 'zdrowie',
    'subject-step_4_electronic': 'elektronika i oprogramowanie'
  }
})
