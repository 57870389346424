const salesmediaPrograms = require('./salesmedia-programs')
const netsalesPrograms = require('./netsales-programs')
const { API_SERVER } = require('../authData')

const getIgPrograms = () => new Promise((resolve, reject) => {
  fetch(`${API_SERVER}/api/get-settlements/get-ig-programs`)
    .then(res => res.json())
    .catch((err) => {
      console.error('Error:', err)
      reject(err)
    })
    .then(response => {
      if (response && response.length) {
        resolve(response)
      } else {
        console.error('Error:', response)
        reject()
      }
    })
})

const getPrograms = () => new Promise((resolve, reject) => {
  // Pobieranie listy programów SalesmediaPrograms
  //
  const convertedSalesmediaPrograms = Object.entries(salesmediaPrograms)
    .map(el => Object.assign({ ID: el[0], TITLE: `${el[0]}: ${el[1]}` }))
    .sort((a, b) => ((a.TITLE > b.TITLE) ? 1 : -1))

  // Pobieranie listy programów NetSalesPrograms
  //
  const convertedNetSalesPrograms = Object.entries(netsalesPrograms)
    .map(el => Object.assign({ ID: el[0], TITLE: `${el[0]}: ${el[1]}` }))
    .sort((a, b) => ((a.TITLE > b.TITLE) ? 1 : -1))

  const programs = {
    Ingenious: [],
    SalesMedia: convertedSalesmediaPrograms,
    NetSales: convertedNetSalesPrograms
  }

  // Pobieranie listy programów IngeniousPrograms
  //
  getIgPrograms()
    .catch(err => console.error(err))
    .then(response => {
      programs.Ingenious = response
      resolve(programs)
    })
})

module.exports = {
  getPrograms
}
