import React, { Component } from 'react'
import Button from '../../elements/CustomButton/CustomButton.jsx'
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap'

const PLATFORM_TYPE = ['Wordpress', 'Blogger', 'Tumblr', 'Weebly', 'Blox', 'Wix', 'Inne']
const VISITS_NO = ['do 10000', '10000-50000', '50000-100000', '100000-200000', '200000-500000', 'powyżej 500000']
const SUBJECTS = ['biznes', 'dom i wnętrze', 'elektronika i oprogramowanie', 'finanse', 'gry', 'film i muzyka', 'informacje', 'kulinaria', 'kultura i sztuka', 'książki i literatura',
  'lifestyle', 'moda', 'motoryzacja', 'nauka i edukacja', 'parenting', 'praca i kariera', 'rozrywka', 'sport', 'technologie i innowacje', 'turystyka', 'uroda', 'zdrowie', 'DYI']
const COOPERATION_ACCEPTS = ['FF', 'barter + FF', 'FF+CPS/CPL/CPA', 'CPC']
const STATUS = ['zgłoszony', 'przyjęty', 'odrzucony', 'dodany do bazy ręcznie']
const PRODUCTS = ['BlogUp', 'BlogManago', 'Agencja', 'Testy barter']

class InfluencerForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {
        name: '',
        surname: '',
        blogName: '',
        blogUrl: '',
        email: '',
        blogPlatform: [],
        visitsNo: '',
        subject: [],
        earnDisplay: '',
        earnAffiliation: '',
        earnBrands: '',
        cooperationAccept: [],
        postCost: '',
        productPlacementCost: '',
        fbPostCost: '',
        instaPostCost: '',
        productValue100: '',
        productValue500: '',
        productValue1000: '',
        comment: '',
        fiModel: '',
        address: '',
        nip: '',
        accountNo: '',
        status: '',
        products: [],
        pesel: ''
      },
      errors: {}
    }
    this.validationErrors = {}
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
  }

  componentDidMount () {
    if (this.props.influencer !== undefined) {
      this.setState({
        data: { ...this.state.data, ...this.props.influencer }
      })
    }
  }

  validate (model) {
    return new Promise((resolve, reject) => {
      this.validationErrors = {}
      const validationRules = [
        [['name', 'surname', 'blogName', 'blogUrl', 'email', 'blogPlatform', 'visitsNo'], 'required'],
        [['email'], 'email'],
        [['pesel'], 'pesel']
        // [['nip', 'pesel'], 'requiredOr']
      ]
      validationRules.forEach((rules) => {
        const validateFunction = 'validate_' + rules[1]
        if (rules[1] === 'requiredOr') {
          const values = rules[0].map((rule) => (model[rule] ? model[rule] : undefined))
          this[validateFunction](rules[0], values)
        } else {
          rules[0].forEach((rule) => {
            this[validateFunction](rule, (model[rule]) ? model[rule] : undefined)
          })
        }
      })
      if (Object.keys(this.validationErrors).length > 0) {
        reject(this.validationErrors)
      } else {
        resolve()
      }
    })
  }

  validate_required (attr, value) {
    if (value === undefined || value.length === 0) {
      this.validationErrors[attr] = 'Pole jest wymagane'
    }
  }

  validate_email (attr, value) {
    if (value !== undefined && value.length > 0) {
      const emailExpression = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
      if (!emailExpression.test(value)) {
        this.validationErrors[attr] = 'Pole nie jest prawidłowym addressem email'
      }
    }
  }

  validate_pesel (attr, value) {
    if (value !== undefined && value.length > 0) {
      const reg = /^[0-9]{11}$/
      if (reg.test(value) === false) {
        this.validationErrors[attr] = 'Pesel nieprawidłowy'
      } else {
        const dig = ('' + value).split('')
        let control = (1 * parseInt(dig[0], 10) + 3 * parseInt(dig[1], 10) + 7 * parseInt(dig[2], 10) + 9 * parseInt(dig[3], 10) + 1 * parseInt(dig[4], 10) + 3 * parseInt(dig[5], 10) + 7 * parseInt(dig[6], 10) + 9 * parseInt(dig[7], 10) + 1 * parseInt(dig[8], 10) + 3 * parseInt(dig[9], 10)) % 10
        if (control === 0) {
          control = 10
        }
        control = 10 - control
        if (parseInt(dig[10], 10) !== control) {
          this.validationErrors[attr] = 'Pesel nieprawidłowy'
        }
      }
    }
  }

  validate_requiredOr (attrs, values) {
    const comparisionArr = []
    attrs.forEach((attr, key) => {
      if (values[key] === undefined || values[key].length === 0) {
        comparisionArr.push(attr)
      }
    })
    if (comparisionArr.length === values.length) {
      const strErr = comparisionArr.join(' lub ')
      comparisionArr.forEach(attr => {
        this.validationErrors[attr] = `Pola ${strErr} są wymagane`
      })
    }
  }

  onFieldChange (event) {
    const data = this.state.data
    data[event.target.name] = event.target.value
    this.setState({ data })
  }

  onCheckboxChange (event) {
    const data = this.state.data
    if (event.target.checked) {
      data[event.target.name].push(event.target.value)
    } else {
      const index = data[event.target.name].indexOf(event.target.value)
      data[event.target.name].splice(index, 1)
    }
    this.setState({ data })
  }

  onFormSubmit (event) {
    event.preventDefault()
    const form = new FormData(event.target)
    const formData = {}
    form.forEach(function (value, key) {
      formData[key] = value
    })
    this.validate(formData)
      .then(() => {
        this.props.afterValidate(form)
        this.setState({
          errors: {}
        })
      })
      .catch((validationErrors) => {
        this.setState({
          errors: validationErrors
        })
      })
  }

  render () {
    const data = this.state.data
    return (
      <form onSubmit={this.onFormSubmit}>
        <h4 style={{ marginTop: '0' }}>Dane podstawowe</h4>

        {data.id &&
          <FormGroup controlId='id'>
            <ControlLabel>ID</ControlLabel>
            <FormControl
              type='text'
              placeholder='ID'
              value={data.id}
              readOnly='true'
            />
          </FormGroup>}
        <FormGroup controlId='name' validationState={this.state.errors.name ? 'error' : null}>
          <ControlLabel>Imię</ControlLabel>
          <FormControl
            type='text'
            placeholder='Imię'
            name='name'
            value={data.name}
            onChange={this.onFieldChange}
          />
          {this.state.errors.name &&
            <HelpBlock>{this.state.errors.name}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='surname' validationState={this.state.errors.surname ? 'error' : null}>
          <ControlLabel>Nazwisko</ControlLabel>
          <FormControl
            type='text'
            placeholder='Nazwisko'
            name='surname'
            value={data.surname}
            onChange={this.onFieldChange}
          />
          {this.state.errors.surname &&
            <HelpBlock>{this.state.errors.surname}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='blogName' validationState={this.state.errors.blogName ? 'error' : null}>
          <ControlLabel>Nazwa bloga</ControlLabel>
          <FormControl
            type='text'
            placeholder='Nazwa bloga'
            name='blogName'
            value={data.blogName}
            onChange={this.onFieldChange}
          />
          {this.state.errors.blogName &&
            <HelpBlock>{this.state.errors.blogName}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='blogUrl' validationState={this.state.errors.blogUrl ? 'error' : null}>
          <ControlLabel>Adres bloga</ControlLabel>
          <FormControl
            type='text'
            placeholder='Adres bloga'
            name='blogUrl'
            value={data.blogUrl}
            onChange={this.onFieldChange}
          />
          {this.state.errors.blogUrl &&
            <HelpBlock>{this.state.errors.blogUrl}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='email' validationState={this.state.errors.email ? 'error' : null}>
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type='text'
            placeholder='Email'
            name='email'
            value={data.email}
            onChange={this.onFieldChange}
          />
          {this.state.errors.email &&
            <HelpBlock>{this.state.errors.email}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='blogPlatform' validationState={this.state.errors.blogPlatform ? 'error' : null}>
          <ControlLabel>Typ platformy blogowej</ControlLabel>
          <br />
          <div className='checkbox checkbox-inline'>
            {
            PLATFORM_TYPE.map((el, i) => (
              <span key={i}>
                <input id={`blogPlatform${i}`} type='checkbox' name='blogPlatform' onChange={this.onCheckboxChange} value={el} checked={!!((this.state.data.blogPlatform && this.state.data.blogPlatform.includes(el)))} />
                <label htmlFor={`blogPlatform${i}`}>{el}</label>
              </span>
            ))
          }
          </div>
          {this.state.errors.blogPlatform &&
            <HelpBlock>{this.state.errors.blogPlatform}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='visitsNo' validationState={this.state.errors.visitsNo ? 'error' : null}>
          <ControlLabel>Ilość wizyt</ControlLabel>
          <FormControl
            componentClass='select'
            name='visitsNo'
            type='select'
            value={data.visitsNo}
            onChange={this.onFieldChange}
          >
            <option value=''>wybierz z listy</option>
            {
              VISITS_NO.map((el, i) => (
                <option key={i} value={el}>{el}</option>
              ))
            }
          </FormControl>
          {this.state.errors.visitsNo &&
            <HelpBlock>{this.state.errors.visitsNo}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='subject' validationState={this.state.errors.subject ? 'error' : null}>
          <ControlLabel>Tematyka</ControlLabel>
          <br />
          <div className='checkbox checkbox-inline'>
            {
            SUBJECTS.map((el, i) => (
              <span key={i}>
                <input id={`subject${i}`} type='checkbox' name='subject' onChange={this.onCheckboxChange} value={el} checked={!!((this.state.data.subject && this.state.data.subject.includes(el)))} />
                <label htmlFor={`subject${i}`}>{el}</label>
              </span>
            ))
          }
          </div>
          {this.state.errors.subject &&
            <HelpBlock>{this.state.errors.subject}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='earnDisplay' validationState={this.state.errors.earnDisplay ? 'error' : null}>
          <ControlLabel>Czy obecnie zarabiasz na display (emisja banerów reklamowych)</ControlLabel>
          <FormControl
            componentClass='select'
            name='earnDisplay'
            type='select'
            value={data.earnDisplay}
            onChange={this.onFieldChange}
          >
            <option value=''>wybierz z listy</option>
            <option value='tak'>tak</option>
            <option value='nie'>nie</option>
          </FormControl>
          {this.state.errors.earnDisplay &&
            <HelpBlock>{this.state.errors.earnDisplay}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='earnAffiliation' validationState={this.state.errors.earnAffiliation ? 'error' : null}>
          <ControlLabel>Czy obecnie zarabiasz na afiliacji</ControlLabel>
          <FormControl
            componentClass='select'
            name='earnAffiliation'
            type='select'
            value={data.earnAffiliation}
            onChange={this.onFieldChange}
          >
            <option value=''>wybierz z listy</option>
            <option value='tak'>tak</option>
            <option value='nie'>nie</option>
          </FormControl>
          {this.state.errors.earnAffiliation &&
            <HelpBlock>{this.state.errors.earnAffiliation}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='earnBrands' validationState={this.state.errors.earnBrands ? 'error' : null}>
          <ControlLabel>Czy obecnie zarabiasz na wspópracach bezpośrednich z markami</ControlLabel>
          <FormControl
            componentClass='select'
            name='earnBrands'
            type='select'
            value={data.earnBrands}
            onChange={this.onFieldChange}
          >
            <option value=''>wybierz z listy</option>
            <option value='tak'>tak</option>
            <option value='nie'>nie</option>
          </FormControl>
          {this.state.errors.earnBrands &&
            <HelpBlock>{this.state.errors.earnBrands}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='cooperationAccept' validationState={this.state.errors.cooperationAccept ? 'error' : null}>
          <ControlLabel>Rodzaje współprac które akceptuje</ControlLabel>
          <br />
          <div className='checkbox checkbox-inline'>
            {
              COOPERATION_ACCEPTS.map((el, i) => (
                <span key={i}>
                  <input id={`cooperationAccept${i}`} type='checkbox' name='cooperationAccept' onChange={this.onCheckboxChange} value={el} checked={!!((this.state.data.cooperationAccept && this.state.data.cooperationAccept.includes(el)))} />
                  <label htmlFor={`cooperationAccept${i}`}>{el}</label>
                </span>
              ))
            }
          </div>
          {this.state.errors.cooperationAccept &&
            <HelpBlock>{this.state.errors.cooperationAccept}</HelpBlock>}
        </FormGroup>

        <h4>FF</h4>
        <FormGroup controlId='postCost' validationState={this.state.errors.postCost ? 'error' : null}>
          <ControlLabel>Koszt wpisu sponsorowanego</ControlLabel>
          <FormControl
            type='text'
            placeholder='Koszt wpisu sponsorowanego'
            name='postCost'
            value={data.postCost}
            onChange={this.onFieldChange}
          />
          {this.state.errors.postCost &&
            <HelpBlock>{this.state.errors.postCost}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='productPlacementCost' validationState={this.state.errors.productPlacementCost ? 'error' : null}>
          <ControlLabel>Koszt lokowania produktu</ControlLabel>
          <FormControl
            type='text'
            placeholder='Koszt lokowania produktu'
            name='productPlacementCost'
            value={data.productPlacementCost}
            onChange={this.onFieldChange}
          />
          {this.state.errors.productPlacementCost &&
            <HelpBlock>{this.state.errors.productPlacementCost}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='fbPostCost' validationState={this.state.errors.fbPostCost ? 'error' : null}>
          <ControlLabel>Koszt wpisu na fb</ControlLabel>
          <FormControl
            type='text'
            placeholder='Koszt wpisu na fb'
            name='fbPostCost'
            value={data.fbPostCost}
            onChange={this.onFieldChange}
          />
          {this.state.errors.fbPostCost &&
            <HelpBlock>{this.state.errors.fbPostCost}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='instaPostCost' validationState={this.state.errors.instaPostCost ? 'error' : null}>
          <ControlLabel>Koszt wpisu na Instagramie</ControlLabel>
          <FormControl
            type='text'
            placeholder='Koszt wpisu na Instagramie'
            name='instaPostCost'
            value={data.instaPostCost}
            onChange={this.onFieldChange}
          />
          {this.state.errors.instaPostCost &&
            <HelpBlock>{this.state.errors.instaPostCost}</HelpBlock>}
        </FormGroup>

        <h4>Barter + FF</h4>
        <FormGroup controlId='productValue100' validationState={this.state.errors.productValue100 ? 'error' : null}>
          <ControlLabel>Produkt do wartości 100 pln</ControlLabel>
          <FormControl
            type='text'
            placeholder='Produkt do wartości 100 pln'
            name='productValue100'
            value={data.productValue100}
            onChange={this.onFieldChange}
          />
          {this.state.errors.productValue100 &&
            <HelpBlock>{this.state.errors.productValue100}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='productValue500' validationState={this.state.errors.productValue500 ? 'error' : null}>
          <ControlLabel>Produkt do wartości 500 pln</ControlLabel>
          <FormControl
            type='text'
            placeholder='Produkt do wartości 500 pln'
            name='productValue500'
            value={data.productValue500}
            onChange={this.onFieldChange}
          />
          {this.state.errors.productValue500 &&
            <HelpBlock>{this.state.errors.productValue500}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='productValue1000' validationState={this.state.errors.productValue1000 ? 'error' : null}>
          <ControlLabel>Produkt do wartości 1000 pln</ControlLabel>
          <FormControl
            type='text'
            placeholder='Produkt do wartości 1000 pln'
            name='productValue1000'
            value={data.productValue1000}
            onChange={this.onFieldChange}
          />
          {this.state.errors.productValue1000 &&
            <HelpBlock>{this.state.errors.productValue1000}</HelpBlock>}
        </FormGroup>

        <h4>Dane do uzupełnienia</h4>
        <FormGroup controlId='comment' validationState={this.state.errors.comment ? 'error' : null}>
          <ControlLabel>Komentarz</ControlLabel>
          <FormControl
            type='textarea'
            placeholder='Komentarz'
            name='comment'
            value={data.comment}
            onChange={this.onFieldChange}
          />
          {this.state.errors.comment &&
            <HelpBlock>{this.state.errors.comment}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='fiModel' validationState={this.state.errors.fiModel ? 'error' : null}>
          <ControlLabel>Model rozliczenia</ControlLabel>
          <FormControl
            componentClass='select'
            name='fiModel'
            type='select'
            value={data.fiModel}
            onChange={this.onFieldChange}
          >
            <option value=''>wybierz z listy</option>
            <option value='FV'>FV</option>
            <option value='Rachunek'>Rachunek</option>
          </FormControl>
          {this.state.errors.fiModel &&
            <HelpBlock>{this.state.errors.fiModel}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='address' validationState={this.state.errors.address ? 'error' : null}>
          <ControlLabel>Adres</ControlLabel>
          <FormControl
            type='text'
            placeholder='Adres'
            name='address'
            value={data.address}
            onChange={this.onFieldChange}
          />
          {this.state.errors.address &&
            <HelpBlock>{this.state.errors.address}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='nip' validationState={this.state.errors.nip ? 'error' : null}>
          <ControlLabel>NIP</ControlLabel>
          <FormControl
            type='text'
            placeholder='NIP'
            name='nip'
            value={data.nip}
            onChange={this.onFieldChange}
          />
          {this.state.errors.nip &&
            <HelpBlock>{this.state.errors.nip}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='accountNo' validationState={this.state.errors.accountNo ? 'error' : null}>
          <ControlLabel>Numer konta</ControlLabel>
          <FormControl
            type='text'
            placeholder='Numer konta'
            name='accountNo'
            value={data.accountNo}
            onChange={this.onFieldChange}
          />
          {this.state.errors.accountNo &&
            <HelpBlock>{this.state.errors.accountNo}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='status' validationState={this.state.errors.status ? 'error' : null}>
          <ControlLabel>Status</ControlLabel>
          <FormControl
            componentClass='select'
            name='status'
            type='select'
            value={data.status}
            onChange={this.onFieldChange}
          >
            <option value=''>wybierz z listy</option>
            {
              STATUS.map((el, i) => (
                <option key={i} value={el}>{el}</option>
              ))
            }
          </FormControl>
          {this.state.errors.status &&
            <HelpBlock>{this.state.errors.status}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='products' validationState={this.state.errors.products ? 'error' : null}>
          <ControlLabel>Produkty</ControlLabel>
          <br />
          <div className='checkbox checkbox-inline'>
            {
              PRODUCTS.map((el, i) => (
                <span key={i}>
                  <input id={`products${i}`} type='checkbox' name='products' onChange={this.onCheckboxChange} value={el} checked={!!((this.state.data.products && this.state.data.products.includes(el)))} />
                  <label htmlFor={`products${i}`}>{el}</label>
                </span>
              ))
            }
          </div>
          {this.state.errors.products &&
            <HelpBlock>{this.state.errors.products}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId='pesel' validationState={this.state.errors.pesel ? 'error' : null}>
          <ControlLabel>PESEL</ControlLabel>
          <FormControl
            type='text'
            placeholder='PESEL'
            name='pesel'
            value={data.pesel}
            onChange={this.onFieldChange}
          />
          {this.state.errors.pesel &&
            <HelpBlock>{this.state.errors.pesel}</HelpBlock>}
        </FormGroup>

        <Button type='submit' className='btn-block btn btn-lg btn-primary'>Zapisz</Button>

      </form>
    )
  }
}

export default InfluencerForm
