import React, { Component } from 'react'
import { NavItem, Nav } from 'react-bootstrap'
import moment from 'moment'
import { connect } from 'react-redux'

class HeaderLinks extends Component {
  user() {
    const { user } = this.props
    if (user) {
      return (
        <Nav pullRight>
          <NavItem eventKey={1}>Czas pracy:</NavItem>
          <NavItem eventKey={2} href={'/timereportedit/' + moment().subtract(1, 'd').format('YYYY-MM-DD')}>wczoraj</NavItem>
          <NavItem eventKey={3} href={'/timereportedit/' + moment().format('YYYY-MM-DD')}>dziś</NavItem>
          <NavItem
            eventKey={4} href='/logout' onClick={() => {
              window.sessionStorage.removeItem('redirectUrl')
              this.props.onLogout(null)
            }}
          >Log out
          </NavItem>
        </Nav>
      )
    }
  }

  render() {
    return (
      <div>
        <Nav>
          <NavItem eventKey={1} href='#/'>
            <i className='fa fa-dashboard' />
            <p className='hidden-lg hidden-md'>Dashboard</p>
          </NavItem>
        </Nav>
        {this.user()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = {
  onLogout: user => ({
    type: 'SET_USER',
    user
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinks)
