module.exports = (monthly = {}, thisMonth, nextMonth) => {
  let data = monthly[thisMonth] || monthly[nextMonth]
  if (!data) {
    const sorted = Object.entries(monthly).sort((a, b) => a[0] < b[0] ? 1 : -1)
    const lastMonthly = (sorted.length && sorted[0]) || []
    data = lastMonthly[1] || {}
  }
  const { upReport, kmReport, b2bReport } = data
  const arr = []
  if (upReport) { arr.push('UP') }
  if (kmReport) { arr.push('KM') }
  if (b2bReport) { b2bReport === 'uz' ? arr.push('UZ') : arr.push('B2B') }
  return arr.join('+')
}
