import React, { useEffect, useState } from 'react'

const placeholders = {
  up: 'Skomentuj przyczynę zerowego zrealizowanego czasu pracy, aby zaakceptować raport.',
  km: 'Jeśli chcesz zaraportować zerowy czas zadań, wpisz uzasadnienie, aby zaakceptować raport. W przeciwnym przypadku uzupełnij czas zadań.'
}
export const ReasonInput = ({ type = 'up', data, setData, setConfirmAllowed }) => {
  const [inputValue, setInputValue] = useState(data.reason || '')

  useEffect(() => {
    const timeOutId = setTimeout(() => setData({ ...data, reason: inputValue.trim() }), 500)
    setConfirmAllowed(inputValue.trim().length > 0)
    return () => clearTimeout(timeOutId)
  }, [inputValue])

  return (
    <div style={{ marginTop: '2rem', marginBottom: '3rem' }}>
      <label htmlFor='reason'><b>Powód zerowego czasu w raporcie:</b></label>
      <input
        type='text'
        name='reason'
        value={inputValue}
        placeholder={placeholders[type]}
        readOnly={data.confirmed}
        id='reason'
        className='hoverInput'
        style={{
          textAlign: 'left',
          borderColor: '#eaeaea',
          cursor: data.confirmed ? 'not-allowed' : 'initial',
          backgroundColor: (data.reason && data.reason.length > 0) ? '#e5f2ff' : '#ffefef',
        }}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </div>
  )
}
