import React, { useState, useEffect, useRef } from 'react'
import { Col, Glyphicon, Grid, Row, FormGroup, ControlLabel, Button, Tooltip, OverlayTrigger, FormControl } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Notification, notificationSystemRef, useNotification as notification } from '../../useNotification'
import Autocomplete from 'react-autocomplete'
import './Chat.scss'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  TypingIndicator,
  Avatar,
  Button as ChatButton,
  InputToolbox,
  AttachmentButton,
  SendButton
} from '@chatscope/chat-ui-kit-react'
import CGLogo from '../../assets/img/cube_logo_floating.png'
const FormData = require('form-data')

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : (process.env.REACT_APP_API_SERVER || '')

export default function Chat () {
  const welcomeText = 'Cześć%%, Jestem Super AITur! Zapytaj mnie o rzeczy związane z Twoją pracą!'
  const { user: { ID: userId, PERSONAL_PHOTO: userPhoto, NAME: userName } = {} } = useSelector((state) => state)

  const [loader, setLoader] = useState(true)
  const [autocompleteClientSelected, setAutocompleteClientSelected] = useState()
  const [dbClientsAutocomplete, setDbClientsAutocomplete] = useState([])
  const [accordionClientActiveNumber, setAccordionClientActiveNumber] = useState()
  const [accordionLabelActiveNumber, setAccordionLabelActiveNumber] = useState()

  const [isEditingLabelId, setEditingLabelId] = useState()
  const [editingLabelName, setEditingLabelName] = useState()

  const [isSelectedTopic, setTopicSelectedId] = useState()

  const [dbClients, setDbClients] = useState([])
  const [chatCategories, setChatCategories] = useState([])
  const [chatTopics, setChatTopics] = useState([])
  const [threadId, setThreadId] = useState()

  console.log(userName)

  const [messages, setMessages] = useState([
    {
      message: userName && userName.split(' ')[1] && userName.split(' ')[1].length ? welcomeText.replace('%%', ` ${userName.split(' ')[1]}`) : welcomeText.replace('%%', ''),
      fake: true,
      sender: 'assistant',
      direction: 'incoming'
    }
  ])
  const [chatMessageInputValue, setChatMessageInputValue] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [chatAttachment, setChatAttachment] = useState()
  const [chatAttachmentList, setChatAttachmentList] = useState([])

  const prevStateRef = useRef([])

  const fileInputRef = useRef(null)

  useEffect(() => {
    (async () => {
      try {
        // @TODO można zmienić btx_companies aby poberało się w chat-categories-and-labels. Nie będzie wtedy trzeba wyciągać nazw klientów filtrując za każdym razem tqblicę
        const [dbClients, { payload, errors }, chatTopics] = await Promise.all(
          [
            `${API_SERVER}/db/api/btx_companies?filter[fields][ID]=true&filter[fields][TITLE]=true`,
            `${API_SERVER}/api/chat-get-categories-with-labels`,
            `${API_SERVER}/db/api/chat_topics?filter[order]=order%20ASC`
          ].map(url => fetch(url).then(response => response.json()))
        )

        if (chatTopics.error) {
          throw Error(chatTopics.error.message ? chatTopics.error.message : 'Wystąpił błąd przy pobieraniu tematów')
        }

        if (dbClients.error) {
          throw Error(dbClients.error.message ? dbClients.error.message : 'Wystąpił błąd przy pobieraniu Klientów')
        }

        if (errors) {
          throw Error(errors[0].superchatapi ? errors[0].superchatapi : 'Wystąpił błąd przy pobieraniu kategorii')
        }

        setDbClients(dbClients)
        setChatTopics(chatTopics)
        setChatCategories(payload)
        handleAccordionOpen(payload[0]?.id, payload[0]?.labels[0]?.id)
      } catch (ex) {
        console.error(ex)
        notification(ex instanceof Error && ex.message ? ex.message : 'Wystąpił błąd przy pobraniu danych')
      } finally {
        setLoader(false)
      }
    })()
  }, [])

  useEffect(() => {
    setDbClientsAutocomplete(removeClientFromAutocompleteList(chatCategories, dbClients))
    if (prevStateChatCategories.length !== chatCategories.length) {
      handleAccordionOpen(chatCategories[0]?.id, chatCategories[0]?.labels[0]?.id)
    }
    prevStateRef.current = chatCategories
  }, [chatCategories])

  const prevStateChatCategories = prevStateRef.current || []

  function removeClientFromAutocompleteList (clientsToRemove, dbClientsArr) {
    // Zwróc tylko unikalne wartości do opcji autocomplete
    return dbClientsArr.filter(({ ID }) => !clientsToRemove.some(chatCategories => chatCategories.client_id === ID))
  }

  function getClientNameById (clientId) {
    const { TITLE } = dbClients.find(({ ID }) => ID === clientId) || { TITLE: 'Brak danych...' }
    return TITLE
  }

  function tooltip (text) {
    return (
      <Tooltip id='tooltip'>{text}</Tooltip>
    )
  }

  function handleAccordionOpen (chatCategoryId, chatLabelId) {
    setAccordionClientActiveNumber(`category-${chatCategoryId}`)
    setAccordionLabelActiveNumber(`label-${chatLabelId}`)
  }

  async function handleChatRequest () {
    const setErrorMsg = (prevMessages) => prevMessages.map((message, index) => {
      if (index === prevMessages.length - 1) {
        message.message = `${message.message}\n\n<span class='msg-error'>wiadomość nie została wysłana</span>`
        return { ...message, fake: true }
      }
      return message
    })

    const newMessage = {
      message: chatMessageInputValue,
      direction: 'outgoing',
      sender: 'user'
    }

    setMessages((prevMessages) => [...prevMessages, newMessage])
    setIsTyping(true)
    setChatMessageInputValue('')

    try {
      const [, categoryId, labelId, topicId] = isSelectedTopic.split('-')

      const data = new FormData()
      data.append('message', chatMessageInputValue)
      data.append('categoryId', categoryId)
      data.append('labelId', labelId)
      data.append('topicId', topicId)

      if (threadId) {
        data.append('threadId', threadId)
      }

      if (chatAttachment) {
        data.append('file', chatAttachment, chatAttachment.name)
      }

      const apiChatRes = await fetch(`${API_SERVER}/api/chat-conversation`, {
        method: 'POST',
        body: data
      })

      const { success, payload, errors } = await apiChatRes.json()

      if (success) {
        const chatGPTResponse = {
          message: payload.content,
          sender: payload.role,
          direction: 'incoming'
        }

        if (chatAttachment) {
          setChatAttachmentList((prevList) => [...prevList, chatAttachment.name])
        }

        setThreadId(payload.threadId)
        setMessages((prevMessages) => [...prevMessages, chatGPTResponse])
      }

      if (errors && errors.length) {
        notification(errors[0].superchatapi)
        setMessages(setErrorMsg)
      }
    } catch (ex) {
      console.error(ex)
      notification('Coś poszło nie tak. Spróbuj ponownie.')
      setMessages(setErrorMsg)
    } finally {
      setIsTyping(false)
      setChatAttachment(null)
    }
  };

  async function handleAddNewCategory (category) {
    setLoader(true)

    try {
      const response = await fetch(`${API_SERVER}/api/chat-add-new-category`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          category
        })
      })
      const { success, payload, errors } = await response.json()

      if (!success) {
        throw Error(errors[0].superchatapi)
      }

      setChatCategories((prevState) => [payload, ...prevState])
    } catch (ex) {
      console.error(ex)
      notification('Coś poszło nie tak. Spróbuj ponownie.')
    } finally {
      setLoader(false)
    }
  }

  async function handleAddNewLabel (chatCategoryId) {
    setLoader(true)

    try {
      const response = await fetch(`${API_SERVER}/db/api/chat_label`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        // @TODO aktualnie brak sprawdzania na backend user_id. Może podstawić przez consolę dowolne inne i zadziała
        body: JSON.stringify({
          name: 'Przykładowa etykieta...',
          category_id: chatCategoryId,
          user_id: userId
        })
      })
      const { id, name, category_id: categoryId, error } = await response.json()

      if (error) {
        throw Error(error.message ? error.message : 'Wystąpił błąd przy dodawaniu etykiety')
      }

      setChatCategories((prevState) => {
        const newState = prevState.map(cat => {
          if (cat.id === chatCategoryId) {
            return {
              ...cat,
              labels: [...cat.labels, { id, name, category_id: categoryId }]
            }
          }
          return cat
        })
        handleAccordionOpen(chatCategoryId, id)
        return newState
      })
    } catch (ex) {
      console.error(ex)
      notification(ex instanceof Error && ex.message ? ex.message : 'Coś poszło nie tak. Spróbuj ponownie.')
    } finally {
      setLoader(false)
    }
  }

  async function handleCategoryDelete (chatCategoryId) {
    if (window.confirm('Czy na pewno chcesz usunąć całą kategorię?')) {
      setLoader(true)

      try {
        const response = await fetch(`${API_SERVER}/db/api/chat_categories/${chatCategoryId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        const { error } = await response.json()

        if (error && error.name !== 'ControlledError') {
          throw Error(error.message ? error.message : 'Wystąpił błąd przy usuwaniu kategorii')
        }

        setChatCategories((prevState) => {
          const newState = prevState.filter(cat => cat.id !== chatCategoryId)
          handleAccordionOpen(newState[0]?.id, newState[0]?.labels[0]?.id)
          return newState
        })
      } catch (ex) {
        console.error(ex)
        notification(ex instanceof Error && ex.message ? ex.message : 'Coś poszło nie tak. Spróbuj ponownie.')
      } finally {
        setLoader(false)
      }
    }
  }

  async function handleLabelDelete (chatCategoryId, chatLabelId) {
    if (window.confirm('Czy na pewno chcesz usunąć etykietę?')) {
      setLoader(true)

      try {
        const response = await fetch(`${API_SERVER}/db/api/chat_label/${chatLabelId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        const { error } = await response.json()

        if (error && error.name !== 'ControlledError') {
          throw Error(error.message ? error.message : 'Wystąpił błąd przy usuwaniu etykiety')
        }

        setChatCategories((prevState) => {
          const newState = prevState.map(cat => {
            if (cat.id === chatCategoryId) {
              return {
                ...cat,
                labels: cat.labels.filter(({ id: labelId }) => labelId !== chatLabelId)
              }
            }
            return cat
          })
          const firstLabelId = newState.find(cat => cat.id === chatCategoryId)?.labels[0]?.id
          handleAccordionOpen(chatCategoryId, firstLabelId)
          return newState
        })
      } catch (ex) {
        console.error(ex)
        notification(ex instanceof Error && ex.message ? ex.message : 'Coś poszło nie tak. Spróbuj ponownie.')
      } finally {
        setLoader(false)
      }
    }
  }

  async function handleLabelNameUpdate (chatCategoryId, chatLabelId) {
    setLoader(true)

    try {
      const response = await fetch(`${API_SERVER}/db/api/chat_label/${chatLabelId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: editingLabelName
        })
      })
      const { error } = await response.json()

      if (error) {
        throw Error(error.message ? error.message : 'Wystąpił błąd przy usuwaniu etykiety')
      }

      setChatCategories((prevState) => {
        return prevState.map(cat => {
          if (cat.id === chatCategoryId) {
            return {
              ...cat,
              labels: cat.labels.filter((label) => {
                if (label.id === chatLabelId) {
                  label.name = editingLabelName
                }
                return label
              })
            }
          }
          return cat
        })
      })
      setEditingLabelId()
    } catch (ex) {
      console.error(ex)
      notification(ex instanceof Error && ex.message ? ex.message : 'Coś poszło nie tak. Spróbuj ponownie.')
    } finally {
      setLoader(false)
    }
  }

  function handelSelectedTopic (topic) {
    const [, , , topicId] = topic.split('-')
    const { system_behavior_desc: systemBehaviorDesc } = chatTopics.find(({ id }) => id === parseInt(topicId, 10))
    setMessages([{
      message: `Cześć, Jestem Super AITur!\n${systemBehaviorDesc}`,
      fake: true,
      sender: 'assistant'
    }])
    setTopicSelectedId(() => {
      setThreadId()
      setChatAttachment()
      setChatAttachmentList([])
      return topic
    })
  }

  async function handleCopyLastMessage () {
    const { message } = lastMessageFromAssistant()
    try {
      await navigator.clipboard.writeText(message)
      notification('Ostatnia wypowiedź skopiowana do schowka', 'info', 5)
    } catch (err) {
      notification('Ostatnia wypowiedź skopiowana do schowka')
    }
  }

  function lastMessageFromAssistant () {
    return messages.slice().reverse().find(msg => msg.sender === 'assistant' && !msg.fake)
  }

  return (
    <>
      <Notification ref={notificationSystemRef} />
      <div className='content' style={{ backgroundColor: '#FFF' }}>
        <Grid fluid>
          <Row>
            <Col xs={12} md={4} className='chat-accordion-clients'>
              <FormGroup controlId='clients-autocomplete'>
                <ControlLabel>Wybierz klienta <span style={{ textTransform: 'none', color: '#9a9a9aa1' }}>(jeżeli nie ma go jeszcze na Twojej liście poniżej)</span></ControlLabel>
                <Autocomplete
                  inputProps={{ className: 'form-control autocomplete-input', placeholder: 'Lista Klientów', name: 'clients' }}
                  wrapperStyle={{ display: 'block' }}
                  menuStyle={{
                    borderRadius: '6px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.25)',
                    background: '#FFF',
                    padding: '7px',
                    lineHeight: '24px',
                    fontSize: '14px',
                    position: 'fixed',
                    overflow: 'auto',
                    maxHeight: '50%',
                    zIndex: '999'
                  }}
                  getItemValue={(item) => item.TITLE}
                  items={dbClientsAutocomplete}
                  renderItem={({ ID, TITLE }, isHighlighted) => <div style={{ background: isHighlighted ? '#EEE' : '#FFF' }} key={`autocomplete-option-${ID}`}>{TITLE}</div>}
                  value={autocompleteClientSelected}
                  shouldItemRender={({ TITLE }, inputValue) => {
                    return TITLE.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                  }}
                  onChange={(e) => {
                    return setAutocompleteClientSelected(e.target.value)
                  }}
                  onSelect={(_, { ID }) => {
                    const newCategory = { user_id: userId, client_id: ID }
                    handleAddNewCategory(newCategory)
                  }}
                />
              </FormGroup>
              {chatCategories.map((category) => {
                return (
                  <div key={`client-accordion-${category.id}`} className={`panel panel-clients${accordionClientActiveNumber === `category-${category.id}` ? ' panel-open' : ''}`}>
                    <div className='panel-heading panel-clients-heading' onClick={() => handleAccordionOpen(category.id, category.labels[0].id)}>
                      <h3 className='panel-title bold'>
                        {getClientNameById(category.client_id)}
                        <OverlayTrigger placement='right' overlay={tooltip('Usuń całą grupę')}>
                          <Button bsSize='xsmall' bsStyle='warning' className='pull-right' onClick={() => handleCategoryDelete(category.id)}>
                            <Glyphicon glyph='trash' />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='right' overlay={tooltip('Dodaj nową etykietę')}>
                          <Button bsSize='xsmall' bsStyle='success' className='pull-right' onClick={() => handleAddNewLabel(category.id)}>
                            <Glyphicon glyph='plus' />
                          </Button>
                        </OverlayTrigger>
                      </h3>
                    </div>
                    <div className='panel-body panel-clients-body'>
                      <div className='panel-body-content panel-clients-content'>
                        {category.labels && category.labels.length > 0 &&
                        category.labels.map((label) => {
                          return (
                            <div key={`client-label-${label.id}`} className={`panel panel-topics-holder${accordionLabelActiveNumber === `label-${label.id}` ? ' panel-open' : ''}`}>
                              <div className='panel-heading' onClick={() => setAccordionLabelActiveNumber(`label-${label.id}`)}>
                                {isEditingLabelId === label.id
                                  ? (
                                    <div className='input-group'>
                                      <FormControl
                                        type='text'
                                        bsSize='small'
                                        className='input-update-label'
                                        value={editingLabelName}
                                        onChange={(e) => setEditingLabelName(e.target.value)}
                                      />
                                      <Glyphicon glyph='ok' className='text-success input-group-addon input-btn-update-label' onClick={() => handleLabelNameUpdate(category.id, label.id)} />
                                      <Glyphicon glyph='remove' className='text-danger input-group-addon input-btn-update-label' onClick={() => setEditingLabelId()} />
                                    </div>
                                    )
                                  : (
                                    <h3 className='panel-title bold'>
                                      {label.name}
                                      {category.labels.length > 1 &&
                                        <OverlayTrigger placement='right' overlay={tooltip('Usuń etykietę z tematami')}>
                                          <Button bsSize='xsmall' bsStyle='warning' className='pull-right' onClick={() => handleLabelDelete(category.id, label.id)}>
                                            <Glyphicon glyph='trash' />
                                          </Button>
                                        </OverlayTrigger>}
                                      <OverlayTrigger placement='right' overlay={tooltip('Edytuj nazwę etykiety')}>
                                        <Button
                                          bsSize='xsmall' bsStyle='info' className='pull-right' onClick={() => {
                                            setEditingLabelId(label.id)
                                            setEditingLabelName(label.name)
                                          }}
                                        >
                                          <Glyphicon glyph='pencil' />
                                        </Button>
                                      </OverlayTrigger>
                                    </h3>
                                    )}
                              </div>
                              <div className='panel-body'>
                                <div className='panel-body-content'>
                                  <div className='list-group'>
                                    {chatTopics.map((topic) => {
                                      const topicId = `topic-${category.id}-${label.id}-${topic.id}`
                                      // TYLKO NA BETA TEST ZASILANIA CHATA INFORMACJAMI
                                      if ([14, 15].includes(topic.id) && ['168', '46', '932', '1', '536'].includes(userId)) {
                                        return (
                                          <button
                                            type='button'
                                            className={`list-group-item${isSelectedTopic === topicId ? ' is-active' : ''}`}
                                            style={{ fontWeight: '700', backgroundColor: topic.id === 15 ? '#c0ff00' : '#00d0ff' }}
                                            key={topicId}
                                            onClick={() => handelSelectedTopic(topicId)}
                                          >
                                            {topic.name}
                                          </button>
                                        )
                                      } else if ([14, 15].includes(topic.id) === false) {
                                        return (
                                          <button
                                            type='button'
                                            className={`list-group-item${isSelectedTopic === topicId ? ' is-active' : ''}`}
                                            key={topicId}
                                            onClick={() => handelSelectedTopic(topicId)}
                                          >
                                            {topic.name}
                                          </button>
                                        )
                                      } else {
                                        return null
                                      }
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </Col>
            <Col xs={12} md={8} className='chat-conversation'>
              <MainContainer>
                <ChatContainer>
                  <MessageList
                    typingIndicator={isTyping ? <TypingIndicator content='SuperChat odpisuje...' /> : null}
                  >
                    {messages.map((message, i) => {
                      return (
                        <Message
                          key={`msg-${i}`}
                          model={message}
                          avatarPosition={message.sender === 'assistant' ? 'tl' : 'tr'}
                          className={message.fake === true && message.sender === 'user' ? 'is-fake' : ''}
                          // type='text'
                        >
                          {
                              message.sender === 'assistant'
                                ? <Avatar src={CGLogo} name='SuperChat' />
                                : <Avatar src={userPhoto} name={userName} />
                            }
                        </Message>
                      )
                    })}
                  </MessageList>
                  <InputToolbox>
                    <textarea
                      rows={3}
                      placeholder='Twoja wiadomość...'
                      disabled={isTyping || !isSelectedTopic}
                      value={chatMessageInputValue}
                      onChange={e => setChatMessageInputValue(e.target.value)}
                    />
                    <span className={`file-message${!chatAttachment ? ' d-none' : ''}`}>{chatAttachment?.name}</span>
                    <OverlayTrigger placement='top' overlay={tooltip('Kopiuj odpowiedź asystenta')}>
                      {
                      lastMessageFromAssistant()
                        ? <ChatButton border onClick={handleCopyLastMessage} icon={<Glyphicon glyph='share' />} />
                        : <ChatButton border disabled onClick={handleCopyLastMessage} icon={<Glyphicon glyph='share' />} />
                    }
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' overlay={tooltip('Dodaj załącznik')}>
                      <AttachmentButton
                        border
                        disabled={isTyping || !isSelectedTopic}
                        onClick={() => {
                          if (fileInputRef.current) {
                            fileInputRef.current.click()
                          }
                        }}
                      />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' overlay={tooltip('Wyślij pytanie')}>
                      <SendButton
                        border
                        disabled={isTyping || !isSelectedTopic || chatMessageInputValue.trim().length < 3}
                        onClick={handleChatRequest}
                      />
                    </OverlayTrigger>
                    {
                      chatAttachmentList.length > 0 &&
                        <ul className='file-list'>
                          {chatAttachmentList.map((fileName, index) => <li key={`file-${fileName}-${index}`}>{fileName}</li>)}
                        </ul>
                    }
                  </InputToolbox>
                </ChatContainer>
              </MainContainer>
              <div className='d-none'>
                <input
                  type='file'
                  name='openai-file'
                  ref={fileInputRef}
                  onChange={(e) => {
                    setChatAttachment(e.target?.files[0] || undefined)
                  }}
                  className='d-none'
                  accept='text/x-c, text/x-c++, application/csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/html, text/x-java, application/json, text/markdown, application/pdf, text/x-php, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/x-python, text/x-script.python, text/x-ruby, text/x-tex, text/plain'
                />
              </div>
              <div className='clearfix' />
            </Col>
          </Row>
        </Grid>
      </div>
      <div id='own-preloader' className={`${loader ? 'show' : ''}`}>
        <div id='own-loader' />
      </div>
    </>
  )
}
