import fetchApi, { API_SERVER } from '../../../fetchApi'
import moment from 'moment'
import { useNotification as notification } from '../../../useNotification'

export const getData = async (type, userId, month) => {
  if (!userId) return {}
  const response = await fetchApi(`${API_SERVER}/db/api/worktime_report/` +
    `?filter[where][and][0][type]=${type}` +
    `&filter[where][and][1][userId]=${userId}` +
    `&filter[where][and][2][month]=${moment(month).format('YYYY-MM')}`, {})
  return Array.isArray(response) && response.length ? response[0] : {}
}

export const areAllDaysAccepted = async (userId, dayStart, dayStop) => {
  return await fetchApi(`${API_SERVER}/api/areAllDaysAccepted`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      dayStart,
      dayStop
    })
  })
}

export const sendData = async data => {
  notification('Wysyłam dane...', 'info', '2')
  let url = `${API_SERVER}/db/api/worktime_report/`
  let method = 'POST'
  if (data._id) {
    url += data._id
    method = 'PATCH'
  }
  const response = await fetchApi(url, {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...data
    })
  })
  if (response.error) {
    console.error(response.error)
    notification('Wystąpił błąd')
  } else {
    notification('Dane zostały zapisane', 'success')
  }
  return response || {}
}

export const apiConfirmReport = async data => {
  notification('Wysyłam dane...', 'info', '2')
  const response = await fetchApi(`${API_SERVER}/api/workreport/confirm-worktime-report`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...data
    })
  })
  if (response) {
    notification('Dane zostały zapisane', 'success')
  }
  return response || {}
}

export const userWorkedInMonth = async (userId, month) => {
  if (!userId) return false
  return await fetchApi(`${API_SERVER}/api/workreport/user-worked-in-month/${userId}/${month}`)
}
