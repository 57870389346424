const { API_SERVER } = require('./authData')

const getMainBtx = () => new Promise((resolve, reject) => {
  fetch(`${API_SERVER}/api/get-main-btx`)
    .then(res => res.json())
    .catch((err) => {
      console.error('Error:', err)
      reject(err)
    })
    .then((response) => {
      if (response) {
        resolve(response)
      } else {
        console.error('Brak wyników')
        reject('Brak wyników')
      }
    })
})

const onlyActiveUsers = allUsers => (
  allUsers.filter(el => el.ACTIVE)
)

const getLabel = (arr = [], val) => {
  const obj = arr.length ? arr.find(o => o.ID === val) : ''
  return obj ? obj.NAME || obj.TITLE : ''
}

const getUsersMap = (users = []) => {
  const usersMap = {}
  users
    .sort((a, b) => a.NAME > b.NAME ? 1 : -1)
    .forEach(el => {
      usersMap[el.ID] = {
        name: el.NAME,
        scDepartment: el.scDepartment || [],
        scHead: el.scHead || []
      }
    })
  return usersMap
}

const getDepartmentsMap = (departments = []) => {
  const departmentsMap = {}
  departments
    .sort((a, b) => a.NAME > b.NAME ? 1 : -1)
    .forEach(el => {
      departmentsMap[el.ID] = {
        name: el.NAME
      }
    })
  return departmentsMap
}

const getCompaniesMap = (companies = []) => {
  const companiesMap = {}
  companies.forEach(el => {
    companiesMap[el.ID] = {
      name: el.TITLE,
      nip: el.UF_CRM_1530178687
    }
  })
  return companiesMap
}

const getMainMap = ({ users, departments, companies }) => {
  const mainMap = {}
  mainMap.users = getUsersMap(users) || {}
  mainMap.departments = getDepartmentsMap(departments) || {}
  mainMap.companies = getCompaniesMap(companies) || {}
  return mainMap
}

const getMapLabel = (map = {}, val, type = 'name') => {
  return val ? (map[val] || {})[type] || '---' : ''
}

module.exports = {
  getMainBtx,
  onlyActiveUsers,
  getLabel,
  getDepartmentsMap,
  getMainMap,
  getMapLabel
}
