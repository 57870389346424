import React, { Component, forwardRef } from 'react'
import { Grid, Col, Table, Row, FormControl, Button, FormGroup, HelpBlock, ControlLabel } from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'
import { connect } from 'react-redux'
import { style } from '../../variables/Variables'
import moment from 'moment'
import Card from '../../components/Card/Card.jsx'
import Autocomplete from 'react-autocomplete'
import './newsletter.css'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import Modal from '../../components/Modal/Modal'
import helper from '../helper_methods'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '../..'
const AUTH_DEPARTMENTS = [7, 208, 338, 354]
const USER_IDS = ['386']

const CustomDatePicker = forwardRef((props, ref) => (
  <div ref={ref} className='input-group' style={{ zIndex: 0 }}>
    <FormControl
      id={props.id}
      type='text'
      className='form-control'
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onKeyUp={props.onKeyUp}
      onBlur={props.onBlur}
    />
    <span className='input-group-addon btnIco' onClick={props.onClearFix}>
      x
    </span>
  </div>
))

class ClientNewsletter extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.onTypeChange = this.onTypeChange.bind(this)
    this.sendForm = this.sendForm.bind(this)
    this.handleBtnActiton = this.handleBtnActiton.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.sendNotification = this.sendNotification.bind(this)
    this.clearDatepickerValuesFix = this.clearDatepickerValuesFix.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.onCheckboxChangePrimaryCat = this.onCheckboxChangePrimaryCat.bind(this)
    this.titles = [
      'Zmiana warunków programu',
      'Zamknięcie kampanii',
      'Wstrzymanie kampanii',
      'Przerwa techniczna',
      'Nowe materiały promocyjne',
      'Zmiany w regulaminie kampanii',
      'Wznowienie kampanii',
      'Promocja/kod rabatowy',
      'Własny html'
    ]
    this.client = []
    this.state = {
      data: {
        id: null,
        type: '0',
        title: '',
        startDate: '',
        endDate: '',
        immediateSend: '0',
        changeDesctription: '', // opis zmian
        replacementProgram: '', // program zastępczy
        resonOfSuspension: '', // przyczyna wstrzymania
        moreInformation: '', // więcej informacji
        nameOfPromoMaterials: '', // nazwa materiałów zastępczych
        promoRules: '', // warunki pormocji
        promoCode: '', // kod rabatowy
        promoLink: '', // link do promocyji
        isSended: false,
        accepted: false,
        affiliates: [],
        adSpaceCategories: []
      },
      fieldsFromType: {},
      errors: {},
      loader: true,
      newsletters: [],
      autocompleteAff: '',
      autocompleteClients: '',
      partnersArr: [],
      clientsArr: [],
      adSpaceCategoriesArr: {},
      newsletterPreview: '',
      modal: false,
      apiError: false
    }
  }

  getNewsletters (itid) {
    return new Promise((resolve, reject) => {
      fetch(`${API_SERVER}/db/api/newsletters?filter[where][clientITID]=${itid}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'GET'
      })
        .then((newslettersRes) => newslettersRes.json())
        .then((newsletters) => {
          resolve(newsletters)
        })
        .catch((newslettersErr) => reject(newslettersErr))
    })
  }

  sendNotification (icon, msg, lvl) {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify='icon' className={icon} />,
      message: <div>{msg}</div>,
      level: lvl,
      position: 'tr'
    })
  }

  clearForm (callback) {
    this.setState(
      {
        data: {
          id: null,
          type: '0',
          title: '',
          startDate: '',
          endDate: '',
          immediateSend: '0',
          changeDesctription: '',
          replacementProgram: '',
          resonOfSuspension: '',
          moreInformation: '',
          nameOfPromoMaterials: '',
          promoRules: '',
          promoCode: '',
          promoLink: '',
          isSended: false,
          accepted: false,
          keyAccountManagerId: '',
          clientName: '',
          affiliates: [],
          adSpaceCategories: []
        },
        autocompleteClients: '',
        autocompleteAff: '',
        fieldsFromType: {},
        errors: {},
        loader: false
      },
      typeof callback === 'function' ? callback : () => {}
    )
  }

  refreshNewslettersList () {
    this.getNewsletters(this.client.ITID)
      .then((newsletters) => {
        this.setState({
          loader: false,
          newsletters
        })
      })
      .catch((err) => console.error('Error: ', err))
  }

  sendForm (formBody) {
    const url = `${API_SERVER}/db/api/newsletters${this.state.data.id ? '/' + this.state.data.id : ''}`
    fetch(url, {
      method: this.state.data.id ? 'PATCH' : 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formBody
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          console.error(response.error)
          this.sendNotification('pe-7s-close-circle', `Wystąpił błąd. Spróbuj ponownie<br/>${response.error.message}`, 'error')
        } else if (response.immediateSend) {
          const formData = new FormData()
          formData.append('id', response.id)
          fetch(`${API_SERVER}/api/client/send-newsletter`, {
            method: 'POST',
            body: formData
          })
            .then((newsletterSendRes) => newsletterSendRes.json())
            .then((newsletterSendRes) => {
              if (newsletterSendRes.success) {
                this.sendNotification('pe-7s-check', 'Newsletter wysłany', 'success')
              } else {
                this.sendNotification('pe-7s-check', 'Newsletter nie został wysłany', 'error')
              }
              this.clearForm()
              this.refreshNewslettersList()
            })
            .catch((err) => {
              console.error('Error: ', err)
              this.sendNotification('pe-7s-check', `Wystąpił błąd. Spróbuj ponownie<br/>${err}`, 'error')
              this.clearForm()
              this.refreshNewslettersList()
            })
        } else {
          this.clearForm()
          this.refreshNewslettersList()
        }
      })
      .catch((error) => {
        console.error(error)
        this.sendNotification('pe-7s-close-circle', 'Wystąpił błąd. Spróbuj ponownie', 'error')
      })
  }

  getScenarioByType (typeId) {
    let fieldsFromType = {}
    switch (typeId) {
      case '1':
        fieldsFromType = {
          title: 'required',
          startDate: 'required',
          changeDesctription: '',
          immediateSend: ''
        }
        break
      case '2':
        fieldsFromType = {
          title: 'required',
          endDate: 'required',
          moreInformation: '',
          replacementProgram: '',
          immediateSend: ''
        }
        break
      case '3':
        fieldsFromType = {
          title: 'required',
          startDate: 'required',
          resonOfSuspension: '',
          replacementProgram: '',
          immediateSend: ''
        }
        break
      case '4':
        fieldsFromType = {
          title: 'required',
          startDate: 'required',
          endDate: 'required',
          moreInformation: '',
          replacementProgram: '',
          immediateSend: ''
        }
        break
      case '5':
        fieldsFromType = {
          title: 'required',
          nameOfPromoMaterials: 'required'
        }
        break
      case '6':
        fieldsFromType = {
          title: 'required',
          startDate: 'required',
          changeDesctription: 'required'
        }
        break
      case '7':
        fieldsFromType = {
          title: 'required',
          startDate: 'required',
          nameOfPromoMaterials: 'required'
        }
        break
      case '8':
        fieldsFromType = {
          title: 'required',
          startDate: 'required',
          promoRules: 'required',
          promoCode: '',
          promoLink: 'required',
          immediateSend: ''
        }
        break
      case '100':
        fieldsFromType = {
          title: 'required',
          changeDesctription: 'required'
        }
        break
      default:
        fieldsFromType = {}
    }
    return fieldsFromType
  }

  componentDidMount () {
    const queryClients = {
      where: {
        and: [
          { company: { exists: true } },
          { company: { neq: '' } },
          { company: { neq: null } },
          { ITID: { exists: true } },
          { external_id: { exists: true } },
          { external_id: { neq: '' } },
          { external_id: { neq: null } },
          { 'it.status': 'ACTIVE' }
        ]
      },
      fields: {
        ITID: true,
        company: true
      }
    }
    const urls = [
      `${API_SERVER}/db/api/clients?filter[where][ITID]=${this.props.match.params.id}`,
      `${API_SERVER}/api/client/get-partners/${this.props.match.params.id}`,
      `${API_SERVER}/db/api/clients?filter=${JSON.stringify(queryClients)}`,
      `${API_SERVER}/db/api/adspace_categories`
    ]
    Promise.all(
      urls.map((url) =>
        fetch(url, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'GET'
        }).then((response) => response.json())
      )
    )
      .then((response) => {
        let hasError = false
        if (response[0].length === 0) {
          hasError = 'Brak pobranego klienta.'
        } else if (response[1].length === 0) {
          hasError = 'Brak aktywnych relacji klienta.'
        } else if (response[2].length === 0) {
          hasError = 'Lista klientów nie została pobrana.'
        } else if (response[3].length === 0) {
          hasError = 'Lista adspace nie została pobrana.'
        }
        if (hasError) {
          this.setState({ apiError: hasError, loader: false })
        } else {
          this.client = response[0][0]
          response[1].unshift({ itid: '0', company: '>> == Zaznacz wszystkich == <<' })
          this.setState(
            {
              adSpaceCategoriesArr: this.groupAdspaces(response[3]),
              partnersArr: response[1],
              clientsArr: response[2].map((item) => ({ company: `${item.company} (${item.ITID})`, ITID: item.ITID }))
            },
            () => this.refreshNewslettersList()
          )
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        this.sendNotification('pe-7s-close-circle', 'Wystąpił błąd przy pobieraniu danych. Spróbuj ponownie.', 'error')
      })
  }

  groupAdspaces (categories) {
    let categoriesArr = []
    categories.forEach((cat) => {
      if (cat.group && cat.group.id) {
        if (!categoriesArr[cat.group.id]) {
          categoriesArr = { ...categoriesArr, ...{ [cat.group.id]: [] } }
        }
        categoriesArr[cat.group.id].push(cat)
      }
    })
    return categoriesArr
  }

  onCheckboxChange (event) {
    const data = this.state.data
    data.affiliates = []
    const name = event.target.dataset.name ? event.target.dataset.name : event.target.name
    if (event.target.checked) {
      data[name].push(event.target.value)
    } else {
      const index = data[name].indexOf(event.target.value)
      data[name].splice(index, 1)
    }
    this.setState({ data })
  }

  onCheckboxChangePrimaryCat (event) {
    const name = event.target.dataset.name ? event.target.dataset.name : event.target.name
    const data = this.state.data
    data.affiliates = []
    event.target.value.split(',').forEach((catId) => {
      const index = data[name].indexOf(catId)
      if (event.target.checked) {
        if (index === -1) data[name].push(catId)
      } else {
        data[name].splice(index, 1)
      }
    })
    this.setState({ data })
  }

  onFieldChange (e) {
    const data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data })
  }

  handleClientsSelect (item) {
    const state = this.state
    state.data.replacementProgram = item
    state.autocompleteClients = item
    this.setState({ state })
  }

  clearDatepickerValuesFix (id) {
    const data = this.state.data
    const el = document.getElementById(id)
    el.removeAttribute('value')
    data[el.getAttribute('name')] = ''
    this.setState({ data })
  }

  onTypeChange (e) {
    const val = e.target.value
    const fieldsFromType = this.getScenarioByType(val)
    this.clearForm(() => {
      const data = this.state.data
      data.type = val
      this.setState({ data, fieldsFromType, errors: {} })
    })
  }

  setFormValues (formObject, accepted) {
    const form = new FormData(formObject)
    let formData = {}
    let adsIndex = 0
    form.forEach((value, key) => {
      if (key.indexOf('adSpaceCategories') > -1) {
        // loopback musi mieć ułożone klucze w kolejności
        key = `adSpaceCategories[${adsIndex}]`
        adsIndex++
      }
      formData[key] = value
    })
    const errors = {}
    if (formData.type === undefined || formData.type.replace(/ /g, '') === '' || formData.type === '0') {
      errors.type = 'Pole jest wymagane'
    }
    Object.keys(this.state.fieldsFromType).forEach((val) => {
      if (this.state.fieldsFromType[val] === 'required' && (formData[val] === undefined || formData[val].replace(/ /g, '') === '')) {
        errors[val] = 'Pole jest wymagane'
      }
    })
    if (Object.keys(errors).length > 0) {
      this.setState({ errors })
    } else {
      this.setState(
        {
          errors: {},
          loader: true
        },
        () => {
          const user = this.props.user
          if (user) {
            formData = { ...formData, ...{ user } }
          }
          if (!this.state.data.id) {
            formData.isSended = false
            formData.createdAt = `${moment().format()}`
            formData.clientITID = `${this.client.ITID}`
            formData.clientName = `${this.client.company}`
            formData.accepted = false
            formData.keyAccountManagerId = `${this.client.it.keyAccountManagerId}`
          }
          if (accepted !== undefined) {
            formData.accepted = accepted
          }
          this.sendForm(helper.JSON_to_URLEncoded(formData))
        }
      )
    }
  }

  handleSubmit (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setFormValues(e.target)
  }

  handleAcceptNewsletter (form, accepted) {
    this.setFormValues(form, accepted)
  }

  handleDelete (e, id) {
    e.preventDefault()
    if (window.confirm('Czy jesteś pewien, że chcesz usunąć newsletter? Operacja jest nieodwracalna.')) {
      this.setState(
        {
          loader: true
        },
        () => {
          fetch(`${API_SERVER}/db/api/newsletters/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
          })
            .then((response) => response.json())
            .then(() => {
              this.sendNotification('pe-7s-check', 'Rekord usunięty', 'success')
              this.refreshNewslettersList()
            })
            .catch((error) => {
              console.error('Error:', error)
              this.sendNotification('pe-7s-close-circle', 'Wystąpił błąd', 'error')
            })
        }
      )
    }
  }

  handleBtnActiton (e, id, action) {
    e.preventDefault()
    const newsletter = this.state.newsletters.filter((newsletter) => newsletter.id === id)
    const fieldsFromType = this.getScenarioByType(newsletter[0].type)
    const affiliates = []
    if (newsletter[0].affiliates) {
      newsletter[0].affiliates.forEach((item) => {
        const aff = this.state.partnersArr.filter((partner) => partner.itid === item)
        if (aff.length > 0) {
          affiliates.push({
            company: aff[0].company,
            itid: aff[0].itid
          })
        }
      })
    }
    const state = {
      data: {
        id: null,
        type: newsletter[0].type,
        immediateSend: newsletter[0].immediateSend ? '1' : '0',
        title: newsletter[0].title,
        startDate: newsletter[0].startDate,
        endDate: newsletter[0].endDate,
        changeDesctription: newsletter[0].changeDesctription,
        replacementProgram: newsletter[0].replacementProgram,
        resonOfSuspension: newsletter[0].resonOfSuspension,
        moreInformation: newsletter[0].moreInformation,
        nameOfPromoMaterials: newsletter[0].nameOfPromoMaterials,
        promoRules: newsletter[0].promoRules,
        promoCode: newsletter[0].promoCode,
        promoLink: newsletter[0].promoLink,
        isSended: false,
        accepted: false,
        keyAccountManagerId: '',
        affiliates,
        adSpaceCategories: newsletter[0].adSpaceCategories ? newsletter[0].adSpaceCategories : []
      },
      errors: {},
      fieldsFromType
    }
    if (action === 'edit') {
      state.data = {
        ...state.data,
        ...{ id: newsletter[0].id, accepted: newsletter[0].accepted, keyAccountManagerId: newsletter[0].keyAccountManagerId }
      }
    }
    this.setState(state)
  }

  renderSubmitBtn () {
    const user = this.props.user
    const btn = (
      <Button
        type='button'
        className='btn btn-success pull-left btn-fill'
        onClick={() => {
          document.getElementById('newsletterFormButton').click()
        }}
        style={{ marginLeft: '10px' }}
      >
        zapisz
      </Button>
    )
    if (this.state.data.type > 0 && !this.state.data.isSended) {
      if (this.state.data.accepted === false) {
        return btn
      } else if (
        process.env.NODE_ENV === 'development' ||
        (user && user.UF_DEPARTMENT && (user.UF_DEPARTMENT.filter((department) => AUTH_DEPARTMENTS.indexOf(department) > -1).length || USER_IDS.includes(user.ID)))
      ) {
        return btn
      }
    }
  }

  handleView (newsletter) {
    newsletter.clientName = newsletter.clientName && newsletter.clientName.length > 0 ? newsletter.clientName : this.client.company
    newsletter.keyAccountManagerId = newsletter.keyAccountManagerId && newsletter.keyAccountManagerId.length > 0 ? newsletter.keyAccountManagerId : this.client.it.keyAccountManagerId
    this.setState({ loader: true }, () => {
      fetch(`${API_SERVER}/api/client/view-newsletter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ newsletter })
      })
        .then((response) => (response.status !== 200 ? response.json() : response.text()))
        .then((response) => {
          let iframe = <div />
          if (response.error) {
            console.error('Error:', response.error)
            iframe = <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '650px' }}>{`Błąd! ${typeof response.error === 'object' ? JSON.stringify(response.error) : response.error}`}</div>
          } else {
            iframe = (
              <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '650px' }}>
                <iframe title='newsletterPreview' id='newsletterPreview' width='650' height='600' frameBorder='0' srcDoc={response} />
              </div>
            )
          }
          this.setState({ loader: false, modal: true, newsletterPreview: iframe })
        })
        .catch((error) => {
          console.error('Error:', error)
          this.setState({ loader: false, modal: false, newsletterPreview: '' })
        })
    })
  }

  renderAcceptBtn () {
    const user = this.props.user
    if (
      this.state.data.id &&
      !this.state.data.isSended &&
      (process.env.NODE_ENV === 'development' ||
        (user && user.UF_DEPARTMENT && (user.UF_DEPARTMENT.filter((department) => AUTH_DEPARTMENTS.indexOf(department) > -1).length || USER_IDS.includes(user.ID))))
    ) {
      return (
        <Button
          type='button'
          className='btn btn-info pull-left'
          onClick={() => {
            this.handleAcceptNewsletter(document.getElementById('newsletterForm'), !this.state.data.accepted)
          }}
          style={{ marginLeft: '10px' }}
        >
          {this.state.data.accepted ? 'odrzuć' : 'akceptuj'}
        </Button>
      )
    }
  }

  renderViewBtn () {
    if (this.state.data.type !== '0') {
      return (
        <Button
          type='button'
          className='btn btn-warning pull-left'
          onClick={() => {
            this.handleView(this.state.data)
          }}
          style={{ marginLeft: '10px' }}
        >
          podejrzyj
        </Button>
      )
    }
  }

  renderDeleteBtn (prop) {
    const user = this.props.user
    if (
      process.env.NODE_ENV === 'development' ||
      (user && user.UF_DEPARTMENT && (user.UF_DEPARTMENT.filter((department) => AUTH_DEPARTMENTS.indexOf(department) > -1).length || USER_IDS.includes(user.ID)))
    ) {
      return (
        <span className='tooltip-holder' style={{ marginRight: '10px' }}>
          <i
            className='pe-7s-trash ico-cursor'
            onClick={(e) => {
              this.handleDelete(e, prop.id)
            }}
          />
          <div className='tooltip top' style={{ width: '100px', right: '0', top: '-50px' }}>
            <div className='tooltip-arrow' style={{ left: 'unset', right: '10px' }} />
            <div className='tooltip-inner'>usuń</div>
          </div>
        </span>
      )
    }
  }

  renderEditBtn (prop) {
    return (
      <span className='tooltip-holder' style={{ marginRight: '10px' }}>
        <i
          className='pe-7s-pen ico-cursor'
          onClick={(e) => {
            this.handleBtnActiton(e, prop.id, 'edit')
          }}
        />
        <div className='tooltip top' style={{ width: '100px', right: '0', top: '-50px' }}>
          <div className='tooltip-arrow' style={{ left: 'unset', right: '10px' }} />
          <div className='tooltip-inner'>edytuj</div>
        </div>
      </span>
    )
  }

  renderAffiliates () {
    return (
      <div className='panel-body checked-aff-box'>
        {this.state.data.affiliates.map((item, key) => (
          <div className='btn-group btnOwnGroup' key={key}>
            <FormControl type='hidden' name={`affiliates[${key}]`} value={item.itid} />
            <button type='button' className='btn btn-default btn-sm disabled'>
              {item.company}
            </button>
            <button
              type='button'
              className='btn btn-default btn-sm rm-record'
              onClick={(e) => {
                e.preventDefault()
                const data = this.state.data
                this.state.data.affiliates.forEach((aff, key) => {
                  if (aff.itid === item.itid) {
                    data.affiliates.splice(key, 1)
                  }
                })
                this.setState({ data })
              }}
            >
              X
            </button>
          </div>
        ))}
      </div>
    )
  }

  renderAdsCategories () {
    let z = 0
    return Object.keys(this.state.adSpaceCategoriesArr).map((catId) => {
      const adSpaceCategories = this.state.adSpaceCategoriesArr[catId]
      return (
        <Col className='col-md-13' key={`adsCat${catId}`}>
          {adSpaceCategories.map((adsCat, key) => {
            let primaryCat = ''
            if (key === 0 && adSpaceCategories.length > 1) {
              const checkedCats = adSpaceCategories.map((item) => item.id).filter((item) => this.state.data.adSpaceCategories.includes(item)).length
              primaryCat = (
                <div className='ownCheckbox primaryCat'>
                  <label>
                    <input
                      type='checkbox'
                      data-name='adSpaceCategories'
                      value={adSpaceCategories.map((item) => item.id).join(',')}
                      onChange={this.onCheckboxChangePrimaryCat}
                      checked={checkedCats === adSpaceCategories.length}
                    />
                    {adsCat.group.name} ({adsCat.group.type.name})
                  </label>
                </div>
              )
            }
            const secondaryCat = (
              <div className={`ownCheckbox ${adSpaceCategories.length > 1 ? 'secondaryCat' : ''}`}>
                <label>
                  <input
                    type='checkbox'
                    name={`adSpaceCategories[${z}]`}
                    data-name='adSpaceCategories'
                    value={adsCat.id}
                    onChange={this.onCheckboxChange}
                    checked={this.state.data.adSpaceCategories.includes(adsCat.id)}
                  />
                  {adsCat.name}
                </label>
              </div>
            )
            z++
            return (
              <div key={`adsCats${adsCat.id}`}>
                {primaryCat}
                {secondaryCat}
              </div>
            )
          })}
        </Col>
      )
    })
  }

  render () {
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Grid fluid>
          <Card
            title='Formularz newsletter'
            stats={
              !this.state.apiError && (
                <div className='content clearfix'>
                  <Button type='button' className='btn btn-danger pull-left' onClick={this.clearForm}>
                    wyczyść
                  </Button>
                  {this.renderAcceptBtn()}
                  {this.renderViewBtn()}
                  {this.renderSubmitBtn()}
                </div>
              )
            }
            content={
              this.state.apiError
                ? (
                  <div className='alert alert-warning' style={{ fontSize: '16px' }}>
                    {this.state.apiError}
                  </div>
                  )
                : (
                  <form id='newsletterForm' className='bg-white' onSubmit={this.handleSubmit}>
                    <div className='clearfix' />
                    <FormGroup controlId='type' validationState={this.state.errors.type ? 'error' : null}>
                      <ControlLabel>Rodzaj newsa</ControlLabel>
                      <FormControl componentClass='select' name='type' type='select' value={this.state.data.type} onChange={this.onTypeChange}>
                        <option value='0'>wybierz z listy</option>
                        <option value='1'>Zmiana warunków</option>
                        <option value='2'>Zamknięcie</option>
                        <option value='3'>Wstrzymanie</option>
                        <option value='4'>Przerwa techniczna</option>
                        <option value='5'>Nowe materiały promocyjne</option>
                        <option value='6'>Zmiany w regulaminie</option>
                        <option value='7'>Wznowienie</option>
                        <option value='8'>Promocja/kod rabatowy</option>
                        <option value='100'>Własne</option>
                      </FormControl>
                      {this.state.errors.type && <HelpBlock>{this.state.errors.type}</HelpBlock>}
                    </FormGroup>
                    <FormGroup controlId='autocompleteAff'>
                      <ControlLabel>
                        Wybrani wydawcy{' '}
                        <span style={{ textTransform: 'none', color: '#9a9a9aa1' }}> (gdy nie wybierzesz nic,wiadomość zostanie wysłana do wszystkich wydawców emitujących kampanię)</span>
                      </ControlLabel>
                      <div className='panel panel-default'>
                        <div className='panel-heading'>
                          <Autocomplete
                            inputProps={{ className: 'form-control', placeholder: 'Lista wydawców' }}
                            wrapperStyle={{ display: 'block' }}
                            menuStyle={{
                              borderRadius: '3px',
                              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                              background: 'rgba(255, 255, 255, 0.9)',
                              padding: '5px',
                              lineHeight: '24px',
                              fontSize: '14px',
                              position: 'fixed',
                              overflow: 'auto',
                              maxHeight: '50%',
                              zIndex: '3'
                            }}
                            shouldItemRender={(state, value) => {
                              return state.company.toLowerCase().indexOf(value.toLowerCase()) !== -1
                            }}
                            getItemValue={(item) => item.company}
                            items={this.state.partnersArr}
                            renderItem={(item, isHighlighted) => <div style={{ background: isHighlighted ? '#EEE' : '#FFF' }}>{item.company}</div>}
                            value={this.state.autocompleteAff}
                            onChange={(e) => {
                              this.setState({ autocompleteAff: e.target.value })
                            }}
                            onSelect={(val, item) => {
                              const data = this.state.data
                              data.adSpaceCategories = []
                              if (item.itid === '0') {
                                const nonExistingYet = this.state.partnersArr.filter((affArr, index) => {
                                  let hasAff = true
                                  this.state.data.affiliates.forEach((affState) => {
                                    if (affArr.itid === affState.itid) hasAff = false
                                  })
                                  return hasAff && index > 0
                                })
                                data.affiliates = [...data.affiliates, ...nonExistingYet]
                                this.setState({ data })
                              } else {
                                const findExisting = this.state.data.affiliates.filter((aff) => aff.itid === item.itid)
                                if (findExisting.length === 0) {
                                  const data = this.state.data
                                  data.affiliates.push({
                                    company: item.company,
                                    itid: item.itid
                                  })
                                  this.setState({ data })
                                }
                              }
                            }}
                          />
                        </div>
                        {this.renderAffiliates()}
                      </div>
                    </FormGroup>
                    <FormGroup controlId='adspaceCategories'>
                      <ControlLabel>Wybrane kategorie adspace-ów</ControlLabel>
                      <Row className='adspaceCategories'>{this.renderAdsCategories()}</Row>
                    </FormGroup>
                    <FormGroup controlId='title' style={{ display: this.state.fieldsFromType.title !== undefined ? 'block' : 'none' }} validationState={this.state.errors.title ? 'error' : null}>
                      <ControlLabel>{`${this.state.data.type === '100' ? 'Tytuł newsa (np. Kratki - Wstrzymanie kampanii)' : 'Nazwa kampanii'}`}</ControlLabel>
                      <FormControl
                        type='text'
                        name='title'
                        readOnly={this.state.data.type !== '100'}
                        value={this.state.data.type === '100' ? this.state.data.title : this.client.company + ' - ' + this.titles[this.state.data.type - 1]}
                        onChange={this.onFieldChange}
                      />
                      {this.state.errors.title && <HelpBlock>{this.state.errors.title}</HelpBlock>}
                    </FormGroup>
                    <FormGroup style={{ display: this.state.fieldsFromType.startDate !== undefined ? 'block' : 'none' }} validationState={this.state.errors.startDate ? 'error' : null}>
                      <ControlLabel>Data początkowa</ControlLabel>
                      <DayPickerInput
                        inputProps={{
                          id: 'startDateInput',
                          name: 'startDate',
                          className: 'form-control'
                        }}
                        component={forwardRef((props, ref) => (
                          <CustomDatePicker
                            ref={ref}
                            {...props}
                            onClearFix={() => {
                              this.clearDatepickerValuesFix('startDateInput')
                            }}
                          />
                        ))}
                        value={this.state.data.startDate}
                        formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                        placeholder=''
                        onDayChange={(date) => {
                          const data = this.state.data
                          data.startDate = moment(date).format('YYYY-MM-DD')
                          this.setState({ data })
                        }}
                      />
                      {this.state.errors.startDate && <HelpBlock>{this.state.errors.startDate}</HelpBlock>}
                    </FormGroup>
                    <FormGroup style={{ display: this.state.fieldsFromType.endDate !== undefined ? 'block' : 'none' }} validationState={this.state.errors.endDate ? 'error' : null}>
                      <ControlLabel>Data końcowa</ControlLabel>
                      <DayPickerInput
                        inputProps={{
                          id: 'endDateInput',
                          name: 'endDate',
                          className: 'form-control'
                        }}
                        component={forwardRef((props, ref) => (
                          <CustomDatePicker
                            ref={ref}
                            {...props}
                            onClearFix={() => {
                              this.clearDatepickerValuesFix('endDateInput')
                            }}
                          />
                        ))}
                        value={this.state.data.endDate}
                        formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                        placeholder=''
                        onDayChange={(date) => {
                          const data = this.state.data
                          data.endDate = moment(date).format('YYYY-MM-DD')
                          this.setState({ data })
                        }}
                      />
                      {this.state.errors.endDate && <HelpBlock>{this.state.errors.endDate}</HelpBlock>}
                    </FormGroup>
                    <FormGroup
                      controlId='changeDesctription'
                      style={{ display: this.state.fieldsFromType.changeDesctription !== undefined ? 'block' : 'none' }}
                      validationState={this.state.errors.changeDesctription ? 'error' : null}
                    >
                      <ControlLabel>{`Opis zmian ${this.state.data.type === '1' ? '(opcjonalnie)' : ''}`}</ControlLabel>
                      <FormControl componentClass='textarea' name='changeDesctription' rows='8' value={this.state.data.changeDesctription} onChange={this.onFieldChange} />
                      {this.state.errors.changeDesctription && <HelpBlock>{this.state.errors.changeDesctription}</HelpBlock>}
                    </FormGroup>
                    <FormGroup
                      controlId='replacementProgram'
                      style={{ display: this.state.fieldsFromType.replacementProgram !== undefined ? 'block' : 'none' }}
                      validationState={this.state.errors.replacementProgram ? 'error' : null}
                    >
                      <ControlLabel>Program zastępczy</ControlLabel>
                      <Autocomplete
                        inputProps={{ className: 'form-control', placeholder: 'Lista reklamodawców', name: 'replacementProgram' }}
                        wrapperStyle={{ display: 'block' }}
                        menuStyle={{
                          borderRadius: '3px',
                          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                          background: 'rgba(255, 255, 255, 0.9)',
                          padding: '5px',
                          lineHeight: '24px',
                          fontSize: '14px',
                          position: 'fixed',
                          overflow: 'auto',
                          maxHeight: '50%',
                          zIndex: '3'
                        }}
                        getItemValue={(item) => item.company.replace(/ *\([^)]*\) */g, '')}
                        items={this.state.clientsArr}
                        renderItem={(item, isHighlighted) => <div style={{ background: isHighlighted ? '#EEEEEE' : '#FFFFFF' }}>{item.company}</div>}
                        value={this.state.autocompleteClients}
                        shouldItemRender={(state, value) => state.company.toLowerCase().indexOf(value.toLowerCase()) !== -1}
                        onChange={(e) => {
                          this.setState({ autocompleteClients: e.target.value })
                        }}
                        onSelect={(val) => {
                          this.handleClientsSelect(val)
                        }}
                      />
                      {this.state.errors.replacementProgram && <HelpBlock>{this.state.errors.replacementProgram}</HelpBlock>}
                    </FormGroup>
                    <FormGroup
                      controlId='resonOfSuspension'
                      style={{ display: this.state.fieldsFromType.resonOfSuspension !== undefined ? 'block' : 'none' }}
                      validationState={this.state.errors.resonOfSuspension ? 'error' : null}
                    >
                      <ControlLabel>{`Przyczyna wstrzymania ${this.state.data.type === '3' ? '(opcjonalnie)' : ''}`}</ControlLabel>
                      <FormControl componentClass='textarea' name='resonOfSuspension' rows='8' value={this.state.data.resonOfSuspension} onChange={this.onFieldChange} />
                      {this.state.errors.resonOfSuspension && <HelpBlock>{this.state.errors.resonOfSuspension}</HelpBlock>}
                    </FormGroup>
                    <FormGroup
                      controlId='moreInformation'
                      style={{ display: this.state.fieldsFromType.moreInformation !== undefined ? 'block' : 'none' }}
                      validationState={this.state.errors.moreInformation ? 'error' : null}
                    >
                      <ControlLabel>
                        Więcej informacji <span style={{ textTransform: 'none', color: '#9a9a9aa1' }}> (miejsce na dodatkowe informacje dotyczące kampanii)</span>
                      </ControlLabel>
                      <FormControl componentClass='textarea' name='moreInformation' rows='8' value={this.state.data.moreInformation} onChange={this.onFieldChange} />
                      {this.state.errors.moreInformation && <HelpBlock>{this.state.errors.moreInformation}</HelpBlock>}
                    </FormGroup>
                    <FormGroup
                      controlId='nameOfPromoMaterials'
                      style={{ display: this.state.fieldsFromType.nameOfPromoMaterials !== undefined ? 'block' : 'none' }}
                      validationState={this.state.errors.nameOfPromoMaterials ? 'error' : null}
                    >
                      <ControlLabel>Nazwa materiałów promocyjnych</ControlLabel>
                      <FormControl type='text' name='nameOfPromoMaterials' value={this.state.data.nameOfPromoMaterials} onChange={this.onFieldChange} />
                      {this.state.errors.nameOfPromoMaterials && <HelpBlock>{this.state.errors.nameOfPromoMaterials}</HelpBlock>}
                    </FormGroup>
                    <FormGroup
                      controlId='promoRules'
                      style={{ display: this.state.fieldsFromType.promoRules !== undefined ? 'block' : 'none' }}
                      validationState={this.state.errors.promoRules ? 'error' : null}
                    >
                      <ControlLabel>Warunki promocji</ControlLabel>
                      <FormControl componentClass='textarea' name='promoRules' rows='8' value={this.state.data.promoRules} onChange={this.onFieldChange} />
                      {this.state.errors.promoRules && <HelpBlock>{this.state.errors.promoRules}</HelpBlock>}
                    </FormGroup>
                    <FormGroup
                      controlId='promoCode'
                      style={{ display: this.state.fieldsFromType.promoCode !== undefined ? 'block' : 'none' }}
                      validationState={this.state.errors.promoCode ? 'error' : null}
                    >
                      <ControlLabel>Kod rabatowy</ControlLabel>
                      <FormControl type='text' name='promoCode' value={this.state.data.promoCode} onChange={this.onFieldChange} />
                      {this.state.errors.promoCode && <HelpBlock>{this.state.errors.promoCode}</HelpBlock>}
                    </FormGroup>
                    <FormGroup
                      controlId='promoLink'
                      style={{ display: this.state.fieldsFromType.promoLink !== undefined ? 'block' : 'none' }}
                      validationState={this.state.errors.promoLink ? 'error' : null}
                    >
                      <ControlLabel>Nazwa materiałów promocyjnych</ControlLabel>
                      <FormControl type='text' name='promoLink' value={this.state.data.promoLink} onChange={this.onFieldChange} />
                      {this.state.errors.promoLink && <HelpBlock>{this.state.errors.promoLink}</HelpBlock>}
                    </FormGroup>
                    <FormGroup
                      controlId='immediateSend'
                      style={{ display: this.state.fieldsFromType.immediateSend !== undefined ? 'block' : 'none' }}
                      validationState={this.state.errors.immediateSend ? 'error' : null}
                    >
                      <ControlLabel>Czy wysyłać natychmiast?</ControlLabel>
                      <FormControl componentClass='select' name='immediateSend' type='select' value={this.state.data.immediateSend} onChange={this.onFieldChange}>
                        <option value='0'>Nie</option>
                        <option value='1'>Tak</option>
                      </FormControl>
                      {this.state.errors.immediateSend && <HelpBlock>{this.state.errors.immediateSend}</HelpBlock>}
                    </FormGroup>
                    <Button type='submit' id='newsletterFormButton' style={{ display: 'none' }} />
                  </form>
                  )
            }
          />
          <Row>
            <Col md={12}>
              <Card
                title='Lista newsletterów'
                category={
                  <ul className='ownLegend'>
                    <li>
                      <span /> brak akcji
                    </li>
                    <li>
                      <span className='bg-info' /> zaakceptowany
                    </li>
                    <li>
                      <span className='bg-success' /> wysłany
                    </li>
                  </ul>
                }
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table>
                    <thead>
                      <tr>
                        <th>Tytuł</th>
                        <th>Data utworzenia</th>
                        <th>Data wysłania</th>
                        <th>Utworzony przez</th>
                        <th>Status</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.newsletters.length > 0 &&
                        this.state.newsletters.map((prop, key) => (
                          <tr key={key} className={prop.isSended ? 'success' : prop.accepted ? 'info' : 'default'}>
                            <td>{prop.title}</td>
                            <td>{moment(prop.createdAt._date).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{prop.isSended ? moment(prop.sendedAt._date).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
                            <td>{prop.user && prop.user.EMAIL ? prop.user.EMAIL : 'brak informacji'}</td>
                            <td>{prop.isSended ? 'wysłany' : prop.accepted ? 'zaakceptowany' : 'do akeptacji'}</td>
                            <td>
                              {!prop.isSended && (
                                <span>
                                  {this.renderDeleteBtn(prop)}
                                  {this.renderEditBtn(prop)}
                                </span>
                              )}
                              <span className='tooltip-holder' style={{ marginRight: '10px' }}>
                                <i
                                  className='pe-7s-look ico-cursor'
                                  onClick={() => {
                                    this.handleView(prop)
                                  }}
                                />
                                <div className='tooltip top' style={{ width: '100px', right: '0', top: '-50px' }}>
                                  <div className='tooltip-arrow' style={{ left: 'unset', right: '10px' }} />
                                  <div className='tooltip-inner'>podejrzyj</div>
                                </div>
                              </span>
                              <span className='tooltip-holder'>
                                <i
                                  className='pe-7s-copy-file ico-cursor'
                                  onClick={(e) => {
                                    this.handleBtnActiton(e, prop.id, 'copy')
                                  }}
                                />
                                <div className='tooltip top' style={{ width: '100px', right: '0', top: '-50px' }}>
                                  <div className='tooltip-arrow' style={{ left: 'unset', right: '10px' }} />
                                  <div className='tooltip-inner'>kopiuj</div>
                                </div>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
        <div id='own-preloader' className={`${this.state.loader === true ? 'show' : ''}`}>
          <div id='own-loader' />
        </div>
        <Modal
          show={this.state.modal}
          content={this.state.newsletterPreview}
          handleClosing={() => {
            this.setState({ modal: false })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(ClientNewsletter)
