import React, { Component } from 'react'
import { Col, FormControl, Row, Button } from 'react-bootstrap'
import SCModal from '../Settlements/Components/Modal'
import { connect } from 'react-redux'
import { DropdownList } from 'react-widgets'
import {
  // allowedTimeReportsDepartments,
  API_SERVER, additionalUsers
} from '../Settlements/authData'
import NotificationSystem from 'react-notification-system'
import Moment from 'moment'
import { style } from '../../variables/Variables'
import './CommentsModal.css'
const MomentRange = require('moment-range')
const moment = MomentRange.extendMoment(Moment)

// const departmentsList = allowedTimeReportsDepartments.concat(
//   Object.values(additionalUsers).filter((el, idx, arr) => arr.indexOf(el) === idx)
// );

const defaultText =
  '1. Z czego wynika niska rentowność Klienta?\n' +
  '\n' +
  '\n' +
  '2. Jakie działania planujemy podjąć, aby poprawić rentowność w kolejnym miesiącu?\n' +
  ''

class CommentsModal extends Component {
  constructor(props) {
    super(props)
    this.setModalParams = this.setModalParams.bind(this)
    const { user } = this.props
    const { tasksFakeUser } = this.props.tasks
    this.state = {
      loggedUser: tasksFakeUser.ID ? tasksFakeUser : user,
      isAllowedToSave: false,
      showEditRow: false,
      mainMap: this.createMainMap(),
      monthRange: this.monthRange(),
      formValid: false,
      form: {},
      isOpen: false
      // filter: {
      //   month: moment(props.params.dayStart).format('YYYY-MM'),
      // }
    }
  }

  componentDidMount() {
    const { client = {} } = this.props.params
    const { companies = {} } = this.state.mainMap || {}
    this.setModalParams(true, companies[client].name, '', () => { })
    this.getData()
  }

  createMainMap() {
    const { main = {} } = this.props
    const mainMap = {}
    Object.keys(main).forEach(elem => (
      main[elem].forEach(el => {
        const val = { name: el.NAME || el.TITLE || '', dep: (el.scDepartment || [])[0] || '', active: el.ACTIVE || '' }
        if (mainMap[elem]) {
          mainMap[elem][el.ID] = val
        } else {
          mainMap[elem] = { [el.ID]: val }
        }
      })
    ))

    Object.keys(additionalUsers).forEach(key => {
      mainMap.users[key].dep = additionalUsers[key] + ''
    })
    mainMap.departments['1'].name = 'Zarząd'
    return mainMap
  }

  showNotification(message, level = 'error', autoDismiss = 5) {
    this.refs.notificationSystem.addNotification({ message, level, position: 'br', autoDismiss })
  }

  /** GET COMMENTS **/

  getData() {
    const { client, departTo, dayStart, dayEnd } = this.props.params
    const { loggedUser = {}, monthRange } = this.state
    // const { main = {} } = this.props
    // const assigned = main.companies.find(el => el.ID === client).ASSIGNED_BY_ID

    fetch(`${API_SERVER}/api/tasks/client-comments-get`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client, departTo, user: loggedUser, dayStart, dayEnd
        // assigned
      })
    }).catch(err => {
      console.error(err)
      this.showNotification('Wystąpił błąd: ' + (err || ''))
    })
      .then(res => res.json())
      .then(response => {
        if (response && !response.error) {
          return this.setState({
            data: response.data,
            // 2022.09.19 testowo Cezary wyłącza warunek
            // isAllowedToSave: (assigned === loggedUser.ID) || (loggedUser.scHead || []).includes(assigned) || topManagersList.includes(loggedUser.ID),
            isAllowedToSave: true,
            showEditRow: false,
            form: {
              departTo,
              month: monthRange[0]
            }
          })
        }
        console.error('Wystąpil błąd: ' + (response.error || ''))
        this.showNotification('Wystąpił błąd: ' + (response.error || ''))
      })
  }

  formatDate(date) {
    return moment(date).format('YYYY-MM-DD HH:mm')
  }

  // renderFilters() {
  //   const { filtersOpen } = this.state;
  //   return (
  //     <div>
  //       <Button className="btn btn-primary btn-sm sm-btn"
  //               onClick={() => this.setState({ filtersOpen: !filtersOpen })}
  //       >Zmień filtry</Button>
  //       <div className={`filter-options`} style={{ display: filtersOpen ? 'block' : 'none' }}>
  //         <Row>
  //           <Col xs={4}><span className="c-label"> od miesiąca</span> {this.monthSelect('filter', 'month', 'od miesiąca')}</Col>
  //           <Col xs={4}><span className="c-label"> z działu</span> {this.departmentSelect('filter', 'departFrom', 'z działu')}</Col>
  //           <Col xs={4}><span className="c-label"> do działu</span> {this.departmentSelect('filter', 'departTo', 'do działu')}</Col>
  //         </Row>
  //       </div>
  //     </div>
  //   );
  // }

  getDepartName(id) { return (this.state.mainMap.departments[id] || {}).name || '' };

  getUserName(id) { return (this.state.mainMap.users[id] || {}).name || '' };

  renderRow(comment) {
    const { mainMap = {}, form, loggedUser } = this.state

    return (
      <div key={comment._id} className='comment-row'>
        <Row className='title'>
          <Col xs={5}><span className='c-label'>Miesiąc rozliczeń: </span> <b>{comment.month}</b></Col>
          <Col xs={7} className='text-right'><span className='c-label' /> <b>{this.getDepartName(comment.departTo)}</b></Col>
        </Row>
        <Row>
          <Col xs={12} className='text'>
            {comment.text}
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            {
              !Object.keys(comment.reply || {}).length && ((mainMap.users[loggedUser.ID] || {}).dep === comment.departTo)
                ? <div
                  className='btn btn-default btn-sm sm-btn'
                  onClick={() => {
                    // form.text = `\r\n- -\r\nMiesiąc rozliczeń: ${comment.month}, dodany przez: ${userFrom} (${departFrom}) w dniu ${this.formatDate(comment.date)}\r\n${comment.text}`;
                    form.month = comment.month
                    form.departTo = comment.departFrom
                    form.id = comment._id
                    this.setState({ showEditRow: true, form })
                  }}
                >Odpowiedz
                </div>
                : null
            }
          </Col>
          <Col xs={10} className='small text-right'>
            <i>Dodany przez: {this.getUserName(comment.userFrom)}, <b>{this.getDepartName(comment.departFrom)}</b> w dniu {this.formatDate(comment.date)}</i>
          </Col>
        </Row>
        {
          comment.hasOwnProperty('reply')
            ? <Row>
              <Col xs={1} />
              <Col xs={11} className='reply'>
                <Row>
                  <Col xs={12}>
                    <span className='c-label'><b>Odpowiedź</b></span>
                    <br />
                    <span className='text'>
                      {comment.reply.text}
                    </span>
                  </Col>
                  <Col xs={12} className='small text-right'>
                    <i>Dodany przez: {this.getUserName(comment.reply.userFrom)}, <b>{this.getDepartName(comment.reply.departFrom)}</b> w dniu {this.formatDate(comment.reply.date)}</i>
                  </Col>
                </Row>
              </Col>
            </Row>
            : null
        }
      </div>
    )
  }

  /** SAVE COMMENT **/

  handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    const form = new FormData(e.target)
    const formData = {}
    for (const key of form.entries()) {
      formData[key[0]] = encodeURIComponent(key[1])
    }

    fetch(`${API_SERVER}/api/tasks/client-comments-save`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    }).catch(err => {
      console.error(err)
      this.showNotification('Wystąpił błąd: ' + (err.error || ''))
    })
      .then(res => res.json())
      .then(response => {
        if (response && response.success) {
          return this.getData()
        }
        this.showNotification('Wystąpił błąd: ' + (response.error || ''))
      })
  }

  validate() {
    const { form, formValid } = this.state
    const required = ['month', 'departTo', 'text']
    const newFormValid = Object.keys(form).filter(key => required.includes(key)).every(key => form[key])
    if (formValid !== newFormValid) {
      this.setState({ formValid: newFormValid })
    }
  }

  monthRange() {
    // const range = moment.range(moment('2019-05', 'YYYY-MM'), moment(moment(), 'YYYY-MM'));
    const { dayStart, dayEnd } = this.props.params
    const range = moment.range(moment(dayStart, 'YYYY-MM'), moment(dayEnd, 'YYYY-MM'))
    return Array.from(range.by('month')).map(el => moment(el).format('YYYY-MM')).reverse()
  }

  monthSelect(form, name, placeholder = 'Wybierz miesiąc') {
    return (
      <DropdownList
        data={this.state.monthRange || []}
        name={name}
        disabled={this.state.monthRange.length === 1 || !!this.state.form.id}
        value={this.state[form][name]}
        placeholder={placeholder}
        onChange={value => this.handleChange(form, value, name)}
      />
    )
  }

  // userSelect() {
  //   const { users = {}, departments = {} } = this.state.mainMap || {};
  //   const { userTo } = this.state.form;
  //   const options = Object.keys(users)
  //     .filter(key => (departmentsList.includes(Number(users[key].dep)) && users[key].active))
  //     .map(el => ({ id: el, name: users[el].name, dep: (departments[users[el].dep] || {}).name }))
  //     .sort((a,b) => a.dep > b.dep ? 1 : -1);
  //
  //   return (
  //     <div>
  //       <DropdownList
  //         data={options}
  //         textField='name'
  //         placeholder='Wybierz osobę'
  //         filter='contains'
  //         value={userTo ? { id: userTo, name: (users[userTo] || {}).name } : null}
  //         groupBy={user => user.dep}
  //         onChange={value => this.handleChange('form', value.id, 'userTo')}
  //       />
  //       <input type="hidden" name='userTo' value={(this.state.form || {}).userTo} />
  //     </div>
  //   );
  // }

  // departmentSelect(form, name, placeholder) {
  //   const { departments = {} } = this.state.mainMap || {};
  //   const options = departmentsList
  //     .map(el => ({ id: el+'', name: (departments[el] || {}).name }))
  //     .sort((a,b) => a.name > b.name ? 1 : -1);
  //   options.unshift({ id: '', name: '(wszystkie)' });
  //
  //   return (
  //     <div>
  //     <DropdownList
  //       data={options}
  //       textField='name'
  //       placeholder={placeholder}
  //       defaultValue=''
  //       filter='contains'
  //       onChange={value => this.handleChange(form, value.id, name)}
  //     />
  //     <input type="hidden" name={name} value={(this.state.form || {}).department} />
  //     </div>
  //   );
  // }

  handleChange(obj, value, name) {
    this.setState({ [obj]: { ...this.state[obj], [name]: value } },
      () => obj === 'form' ? this.validate() : {})
  }

  renderEditRow() {
    const { departments = {} } = this.state.mainMap || {}
    const { client, departTo } = this.props.params
    const { loggedUser, form } = this.state
    return (
      <div className='edit-row-container'>
        <form
          onSubmit={this.handleSubmit.bind(this)}
          className='edit-row'
        >
          <Row className='row'>
            <Col xs={12}>
              <h4>Dodaj {form.id ? 'odpowiedź' : 'komentarz'}</h4>
              <hr />
            </Col>
            <Col xs={4}>
              <div className='c-label'>miesiąc rozliczeniowy</div>
              {this.monthSelect('form', 'month')}
            </Col>
            <Col xs={1} />
            <Col xs={6}>
              <span className='c-label' style={{ paddingLeft: 0 }}>{departTo === '1'
                ? 'Komentarz do wyników wszystkich działów'
                : 'Komentarz do wyników działu'}
              </span>
              <br /><b>{departments[departTo].name}</b>
              <input type='hidden' name='departTo' value={departTo} />
              {/* {this.userSelect()} */}
            </Col>
          </Row>
          <Row className='row'>
            <Col xs={12}>
              <div className='c-label'>komentarz</div>
              <FormControl
                componentClass='textarea'
                name='text'
                rows='5'
                value={form.text}
                placeholder='Wpisz komentarz'
                onChange={e => this.handleChange('form', e.target.value, 'text')}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='text-right'>
              <Button
                className='btn btn-sm btn-secondary'
                style={{ marginRight: 15 }}
                onClick={() => this.setState({ showEditRow: false })}
              >Anuluj
              </Button>
              <Button
                className='btn btn-sm btn-primary'
                type='submit'
                disabled={!this.state.formValid}
              >Zapisz
              </Button>
              <input type='hidden' name='userFrom' value={loggedUser.ID} />
              <input type='hidden' name='departFrom' value={((loggedUser.scDepartment || [])[0]) || additionalUsers[loggedUser.ID] || ''} />
              <input type='hidden' name='client' value={client} />
              {form.id ? <input type='hidden' name='id' value={form.id} /> : null}
            </Col>
          </Row>
        </form>
      </div>
    )
  }

  /** RENDER MODAL **/

  modalContent() {
    const {
      data = [], showEditRow, isAllowedToSave
      // filter
    } = this.state
    // const filteredData = data;
    // .filter(el => filter.month ? el.month >= filter.month : el)
    // .filter(el => filter.departFrom ? el.departFrom === filter.departFrom : el)
    // .filter(el => filter.departTo ? el.departTo === filter.departTo : el);

    return (
      <div className='comments-modal'>
        <div className='text-right'>
          {showEditRow ? this.renderEditRow() : null}
          {
            isAllowedToSave
              ? <button
                className='btn btn-primary btn-sm'
                style={{ display: showEditRow ? 'none' : 'inline-block' }}
                onClick={() => this.setState({ showEditRow: true, form: { ...this.state.form, text: data.length > 0 ? '' : defaultText } })}
              >Dodaj komentarz
              </button>
              : null
          }
        </div>
        <div style={{ marginTop: showEditRow ? 355 : 0 }}>
          {/* {this.renderFilters()} */}
          {data.map(el => this.renderRow(el))}
        </div>
      </div>
    )
  }

  setModalParams(show = false, title = '', content = '', method = null) {
    this.setState({
      modalShow: show,
      modalTitle: title,
      modalContent: content,
      modalMethod: method
    }, () => method === null ? this.props.setCommentParams({ loggedUser: this.state.loggedUser }) : null)
  }

  render() {
    const { modalShow, modalTitle, modalMethod } = this.state
    const modalContent = this.modalContent()

    return (
      <div>
        <NotificationSystem ref='notificationSystem' style={style} />
        <SCModal
          modalShow={modalShow}
          modalTitle={modalTitle}
          modalContent={modalContent}
          setModalParams={this.setModalParams}
          propMethod={modalMethod}
          setHeight
          agreeButton={false}
          closeText='Zamknij'
          size='large'
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    main: state.main,
    tasks: state.tasks
  }
}

export default connect(mapStateToProps)(CommentsModal)
