module.exports = {
  _id: {},
  created_on: { pl: 'Data dodania', width: '10%' },
  created_by: { pl: 'Dodane przez', width: '10%' },
  order_id: { pl: 'ID zlecenia', width: '10%', filterable: true, resizable: true, sortable: true, req: true },
  order_name: { pl: 'Nazwa zlecenia', width: '20%', filterable: true, resizable: true, sortable: true, req: true },
  company: { pl: 'Zleceniodawca', width: '20%', filterable: true, resizable: true, sortable: true, req: true },
  client: { pl: 'Klient', width: '20%', filterable: true, resizable: true, sortable: true, req: true },
  responsible_person: { pl: 'Opiekun Klienta', width: '20%', filterable: true, resizable: true, sortable: true, req: true },
  status: { pl: 'Status', width: 145, filterable: true, resizable: true, sortable: true, sortDescendingFirst: true, req: true },
  endDate: { pl: 'warunkowo do dnia', width: 0 },
  file: { pl: 'Załącznik', width: 0 },
  link: { pl: 'Link do Baseline', width: 0 }
}
