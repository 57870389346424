const main = (state = {}, action = '') => {
  switch (action.type) {
    case 'MAIN_BTX':
      return action.mainBtx

      // pozostawione dla starszych skryptów
    case 'MAIN_COMPANIES':
      return Object.assign({}, state, {
        companies: action.companies
      })
    case 'MAIN_USERS':
      return Object.assign({}, state, {
        users: action.users
      })
    case 'MAIN_DEPARTMENTS':
      return Object.assign({}, state, {
        departments: action.departments
      })
    default:
      return state
  }
}

export default main
