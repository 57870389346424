module.exports = {
  2: 'Druga test',
  4: 'Oferta testowa 2',
  37: 'Pizzaportal',
  40: 'Ministerstwo Gadzetow',
  154: 'Olive.pl',
  162: 'Empik.com',
  168: 'Smyk.com',
  190: 'Orange i Neostrada',
  208: 'Chocolissimo',
  212: 'ButyXL',
  230: 'Amronet - wymiana walut onlie',
  384: 'Centrumrowerowe',
  434: 'home.pl',
  452: 'Kei.pl',
  476: 'E-zebra.pl',
  484: 'Groupon',
  492: 'Patrizia Aryton',
  516: 'MediaMarkt',
  518: 'Saturn',
  526: 'NJU',
  554: 'Beautikon.com',
  562: 'Neo24.pl',
  610: 'Marionex',
  692: 'Auchan Direct',
  702: 'Nexterio',
  704: 'T-Mobile',
  716: 'Neonet.pl',
  732: 'Okazje.eholiday.pl',
  734: 'AZ.pl',
  740: 'Virtualo.pl',
  744: 'Kajasport.pl',
  746: 'Kuptravel.pl',
  751: 'Filarum',
  759: 'Travelist',
  777: 'Pakamera.pl',
  785: 'Vobis',
  799: 'Virgin Mobile',
  809: 'Triverna',
  819: 'Femestage',
  821: 'Telezakupy Mango',
  843: 'Vistula',
  855: 'KFC Dostawa',
  914: 'Black Red White',
  930: 'Heyah',
  947: 'Smacznaherbata',
  951: 'Diverse',
  967: '4Home',
  969: 'Księgarnia C.H. Beck',
  977: 'RTV Euro AGD',
  981: 'TaniaKsiazka.pl',
  987: 'CASH SPORT',
  989: 'Wólczanka',
  993: 'W.KRUK',
  1013: 'Oleole.pl',
  1017: 'Kolba.pl',
  1021: 'Conrad',
  1025: 'ePrezenty',
  1038: 'Materace Dla Ciebie',
  1044: 'ABCFITNESS.PL',
  1048: 'OpenCard',
  1054: 'PERFUMESCO',
  1058: 'SnowShop.pl',
  1062: 'SuperPrezenty.pl',
  1068: 'Helion',
  1074: 'travelday.pl',
  1080: 'bikesalon.pl',
  1082: 'Pizza Hut',
  1086: 'Muza S.A.',
  1088: 'Officeshoes',
  1092: 'Sport-Shop.pl',
  1096: 'Telepizza',
  1120: 'UPC',
  1148: 'Ebookpoint',
  1152: 'Arkana Cosmetics',
  1160: 'i-styl',
  1162: 'Trena',
  1168: 'InBook.pl',
  1170: 'Netia',
  1182: 'Plus CPS',
  1188: 'TransferGo CPA',
  1194: 'SklepEstetyka.pl',
  1202: 'Cyfrowy Polsat CPA',
  1208: 'NC+',
  1210: 'Ebookoo.pl',
  1212: 'Trena de it',
  1216: 'Play',
  1228: 'Onepress',
  1230: 'Sensus',
  1234: 'Bezdroza',
  1236: 'Videopoint',
  1242: 'dotsport.pl',
  1304: 'Endo',
  1306: 'AliExpress',
  1322: 'e-trena.de',
  1324: 'e-trena.it',
  1328: 'dhosting.pl',
  1330: 'Rentalcars.com',
  1332: 'motocentrum.pl',
  1341: 'Rentalcars.com Europa ',
  1343: 'Rentalcars.com Wielka Brytania',
  1345: 'Patrizia Aryton 2 (all)',
  1347: 'Aurore.pl CPS',
  1349: 'Travelgory ',
  1359: 'Decathlon ',
  1363: 'Telekarma',
  1365: 'Lancerto',
  1371: 'Kratki',
  1379: 'Motoautko.pl',
  1385: 'Bikestacja.pl CPS',
  1389: 'Redcoon.pl',
  1401: 'MK Cafe Fresh CPO',
  1409: 'nexto.pl',
  1419: 'Megakoszulki',
  1421: 'Gearbest.com',
  1427: 'Recman',
  1435: 'Pandora',
  1437: 'Smyk.com CPM',
  1441: 'Gino Rossi CPS ',
  1443: 'Kakadu',
  1447: 'Hop-Sport.pl',
  1449: 'Ravelo.pl',
  1455: 'bardotti.pl',
  1459: 'Nodik',
  1461: 'MOTIVE & MORE',
  1471: 'TOUS',
  1475: 'Polenastole.pl',
  1479: 'Castorama',
  1481: 'Sweetsen.pl',
  1483: 'ProLine.pl',
  1495: 'Eye For Fashion',
  1501: 'ZAFUL',
  1505: 'Plush',
  1517: 'Perfumeria Neness',
  1519: 'Mikrorata',
  1521: 'Boss pożyczka',
  1523: 'Ekodrogeria',
  1527: '(NS) Finansowyplaner.pl',
  1529: 'zlotewyprzedaze dla Vente-Privee CPL',
  1533: 'Premium Mobile CPL',
  1535: 'egmont.pl',
  1537: 'Wójcik Fashion',
  1541: 'Orsay',
  1543: 'Lilou',
  1547: 'Pachnidełko',
  1549: 'Patrizia Aryton CPC',
  1551: 'XL Energy Drink Sklep',
  1553: '(NS) T-mobile prepaid 20 GB Internetu za darmo',
  1563: 'Totolotek - zakłady bukmacherskie',
  1573: 'Castorama – Programmatic',
  1579: 'T-Mobile – Programmatic',
  1583: 'Ucando',
  1585: 'Orange Światłowód',
  1587: 'Time&More CPS',
  1589: 'Wittchen',
  1593: 'Kontri CPS',
  1595: 'Avaro CPS',
  1603: 'Prosto.pl',
  1609: 'Ombre',
  1611: 'Nju Mobile',
  1615: 'Pani Torbalska',
  1617: 'Marthu.com',
  1619: 'PożyczkaGo',
  1623: 'Orange (LOVE, FTTH, B2C)',
  1625: 'Nju CPS',
  1627: 'Citibank karta kredytowa bonus Douglas',
  1629: 'Naturativ – kosmetyki naturalne CPS',
  1631: 'Tutlo',
  1647: 'Planeta Klocków',
  1651: 'Pearl 24',
  1653: 'Selfieroom',
  1657: 'BrooklynButik',
  1659: 'Modoline',
  1663: 'eSky.pl',
  1665: 'Eurofirany',
  1669: 'Mosquito',
  1671: 'RISK Made in Warsaw ',
  1673: 'Halopożyczka CPS',
  1675: 'Chwilówkomat CPS',
  1677: 'Guns&Tuxedos CPS',
  1679: 'Telepizza CPS',
  1683: 'fitinactive.com',
  1685: 'Dstreet.pl',
  1687: 'Kabak.com.pl',
  1689: 'Disney English CPM',
  1691: 'BeGlossy.com - drogeria internetowa ',
  1693: 'Empik-School - mailing',
  1697: 'Profi-Lingua',
  1699: 'Pizza Hut Dostawa',
  1701: 'Miloan CPS',
  1703: 'Allegro',
  1707: 'iParts.pl CPS',
  1709: 'Miugo CPS',
  1713: 'Materace FDM.pl',
  1715: 'Fotobum',
  1719: 'Meblowiec',
  1721: 'Aj Produkty Mailing CPC',
  1723: 'Łatwykredyt CPS',
  1725: 'T-mobile MIX',
  1727: 'SuperPharm',
  1731: 'Ombre.de',
  1733: 'mlamp.pl CPS'
}
