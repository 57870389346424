import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import NotificationSystem from 'react-notification-system'

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Sidebar from '../../components/Sidebar/Sidebar'
import { style } from '../../variables/Variables'

import appRoutes from '../../routes/app'
import '../../views/style.css'

import demo from '../../assets/img/demo.png'
import Login from '../../views/Login/Login'

const API_SERVER = process.env.REACT_APP_API_SERVER || '../..'

class App extends Component {
  constructor(props) {
    super(props)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.onLogin = props.onLogin.bind(this)
    this.handleNotificationClick = this.handleNotificationClick.bind(this)
    this.state = {
      _notificationSystem: null
    }
  }

  handleNotificationClick(position) {
    const color = Math.floor((Math.random() * 4) + 1)
    let level
    switch (color) {
      case 1:
        level = 'success'
        break
      case 2:
        level = 'warning'
        break
      case 3:
        level = 'error'
        break
      case 4:
        level = 'info'
        break
      default:
        break
    }
    this.state._notificationSystem.addNotification({
      title: (<span data-notify='icon' className='pe-7s-gift' />),
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for every web developer.
        </div>
      ),
      level,
      position,
      autoDismiss: 15
    })
  }

  storeLocalTokens(data) {
    const { accessToken, refreshToken } = data || {}
    if (accessToken && refreshToken) {
      window.localStorage.setItem('accessToken', accessToken)
      window.localStorage.setItem('refreshToken', refreshToken)
    }
  }

  getBitrixUser() {
    setInterval(() => {
      fetch(`${API_SERVER}/api/oauth2/user`, {
        credentials: 'same-origin'
      })
        .then(res => res.json())
        .then((data) => {
          // this.storeLocalTokens(data)
          // delete data.accessToken
          // delete data.refreshToken
          this.onLogin(data)
        }).catch(e => console.error(e))
    }, 870000) // 14.5m
  }

  componentDidMount() {
    if (this.props.location.pathname.indexOf('login') === -1 && this.props.location.pathname !== '/' && this.props.user === null) {
      const from = this.props.location.pathname
      window.sessionStorage.setItem('redirectUrl', encodeURIComponent(from))
    }
    this.setState({ _notificationSystem: this.refs.notificationSystem })
    fetch(`${API_SERVER}/api/oauth2/user`)
      .then(res => res.json())
      .then((data) => {
        // this.storeLocalTokens(data)
        // delete data.accessToken
        // delete data.refreshToken
        this.onLogin(data)
      }).catch(e => console.error(e))
    this.getBitrixUser()
  }

  componentDidUpdate(e) {
    if (window.innerWidth < 993 && e.history.location.pathname !== e.location.pathname && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open')
    }
  }

  render() {
    const { user } = this.props
    const alphaBg = window.location.host.includes('alpha') ? `url(${demo})` : 'transparent'
    const hideNavbarIfSettlements = /\/settlements/.test(window.location.href)
      ? '.main-panel .navbar.navbar-default {display: none}'
      : null
    const sessionRedirect = window.sessionStorage.getItem('redirectUrl')
    return (
      <div className='wrapper' style={{ background: alphaBg }}>
        <style>{hideNavbarIfSettlements}</style>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Sidebar {...this.props} style={{ overflow: 'hidden' }} />
        <div id='main-panel' className='main-panel'>
          <Header {...this.props} />
          <Switch>
            {

              user === null
                ? <>
                  <Route path='/login/:redirect' component={Login} />
                  <Redirect to={`/login/${sessionRedirect || encodeURIComponent('/')}`} />
                </>
                : appRoutes.map((prop, key) => {
                  if (prop.name === 'Notifications') {
                    return (
                      <Route
                        path={prop.path}
                        key={key}
                        render={routeProps =>
                        (<prop.component
                          {...routeProps}
                          handleClick={this.handleNotificationClick}
                        />)}
                      />
                    )
                  }
                  if (prop.redirect) {
                    return (
                      <Redirect from={prop.path} to={prop.to} key={key} />
                    )
                  }
                  if ((user.ID || process.env.NODE_ENV === 'development') && prop.path !== '/login/:redirect') {
                    return (
                      <Route path={prop.path} component={prop.component} key={key} />
                    )
                  } else if (user.ID && prop.path === '/login/:redirect') {
                    return (
                      <Redirect from={prop.path} to={sessionRedirect ? decodeURIComponent(sessionRedirect) : '/'} key={key} />
                    )
                  }
                  return (
                    <Redirect from={prop.path} to={`/login/${sessionRedirect || encodeURIComponent('/')}`} key={key} />
                  )
                })
            }
          </Switch>
          <Footer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = {
  onLogin: user => ({
    type: 'SET_USER',
    user
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
