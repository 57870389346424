import React from 'react'

const Pagination = (props) => {
  const { page, count, limit } = props
  const loopIterations = Math.ceil(count / limit)
  const maxLoopIterations = 10
  const paginationStart = Math.max(0, page - 2)
  const paginationEnd = Math.min(paginationStart + maxLoopIterations, loopIterations - 1)
  const paginationItems = []
  for (let i = paginationStart; i <= paginationEnd; i++) {
    const key = i + 1
    paginationItems.push(
      <li key={`${key}pag`} className={`${page === key ? 'active' : ''}`}>
        <span
          style={{ cursor: `${page === key ? 'not-allowed' : 'pointer'}` }}
          onClick={(e) => {
            if (page === key) e.preventDefault()
            else props.search(key)
          }}
        >
          {key}
        </span>
      </li>
    )
  }
  return (
    <nav className='text-center'>
      {loopIterations > 1 && (
        <ul className='pagination'>
          <li className={`${page === 1 ? 'disabled' : ''}`}>
            <span
              onClick={(e) => {
                if (page === 1) e.preventDefault()
                else props.search(page - 1)
              }}
            >
              &laquo;
            </span>
          </li>
          {paginationItems}
          <li className={`${page === loopIterations ? 'disabled' : ''}`}>
            <span
              style={{ cursor: `${page === loopIterations ? 'not-allowed' : 'pointer'}` }}
              onClick={(e) => {
                if (page === loopIterations) e.preventDefault()
                else props.search(page + 1)
              }}
            >
              &raquo;
            </span>
          </li>
        </ul>
      )}
    </nav>
  )
}

export default Pagination
