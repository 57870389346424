import React from 'react'
import PropTypes from 'prop-types'
import { Col, Table } from 'react-bootstrap'
import Card from '../Card/Card'

const SimpleDataTable = ({ data, prop }) =>
  (<Col md={4}>
    <Card
      title={prop}
      ctTableResponsive
      content={
        <Table striped hover>
          <tbody>
            {
              Object.keys(data[prop]).map((val, valKey) =>
                data[prop][val] &&
                  <tr key={valKey}>
                    <td><label>{val}</label></td>
                    <td>{(typeof data[prop][val] !== 'object') ? data[prop][val] : JSON.stringify(data[prop][val])}</td>
                  </tr>)
            }
          </tbody>
        </Table>
      }
    />
   </Col>)

SimpleDataTable.defaultProps = {
  data: undefined,
  prop: undefined
}

SimpleDataTable.propTypes = {
  data: PropTypes.object,
  prop: PropTypes.string
}

export default SimpleDataTable
