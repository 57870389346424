import fetchApi, { API_SERVER } from '../../../../fetchApi'
import moment from 'moment'

export const generateDays = async (userId, month) => {
  const startOfMonth = moment(month).startOf('month').format('YYYY-MM-DD')
  const endOfMonth = moment(month).endOf('month').format('YYYY-MM-DD')
  return await fetchApi(`${API_SERVER}/db/api/timemans/` +
    `?filter[where][and][0][userId]=${userId}` +
    `&filter[where][and][1][date][gte]=${startOfMonth}` +
    `&filter[where][and][2][date][lte]=${endOfMonth}` +
    '&filter[order]=date asc', {})
}
