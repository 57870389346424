const reports_generated = (state = [], action) => {
  switch (action.type) {
    case 'SET_REPORTS_GENERATED':
      return action.reports_generated
    default:
      return state
  }
}

export default reports_generated
