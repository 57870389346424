import React, { Component } from 'react'
import { Grid, Row, Col, Table } from 'react-bootstrap'
import moment from 'moment'
import NotificationSystem from 'react-notification-system'
// import DatePicker from 'react-datepicker';
import { Card } from '../../components/Card/Card'
import { style } from '../../variables/Variables'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'

class ClicksAddHistory extends Component {
  constructor (props) {
    super(props)
    // this.handleDate = this.handleDate.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      date: moment().format('YYYY-MM-DD')
    }
  }

  componentDidMount () {
    this.loadNext()
  }

  loadNext () {
    // fetch(`https://super.cubegroup.pl/db/api/clicks?filter[where][time]=${this.state.date}`)
    fetch(`${API_SERVER}/db/api/clicks?filter[where][time]=${this.state.date}`)
      .then(res => res.json())
      .catch((error) => {
        console.error('Error:', error)
      })
      .then((response) => {
        this.setState({ clicks: response })
      })
  }

  format (value) {
    return (Math.round(value * 100) / 100).toFixed(2)
  }

  // handleDate(val) {
  //   this.setState({ date: moment(val).format('YYYY-MM-DD') }, () => { this.loadNext(); });
  // }
  handleChange (event) {
    this.setState({ date: event.target.value }, () => { this.loadNext() })
  }

  render () {
    const data = this.state.clicks ? this.state.clicks : []
    const commissionSum = data.reduce((a, b) => (
      { commission: Number(a.commission) + Number(b.commission) }), { commission: 0 })
    const feeSum = data.reduce((a, b) => (
      { fee: Number(a.fee) + Number(b.fee) }), { fee: 0 })

    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <form className='form-inline'>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <nav className='navbar navbar-light bg-light'>
                  <div className='btn-group' role='group' aria-label='Basic example' style={{ display: 'flex' }}>
                    <button
                      className='btn'
                      onClick={() => {
                        this.props.history.push({ pathname: '/clicks-validation/:month' })
                      }}
                    >
                      &lt; powrót do listy
                    </button>
                  </div>

                  <Card
                    title='Wybierz datę'
                    content={
                      <div className='datePick'>
                        {/* <DatePicker */}
                        {/* id="datePicker" */}
                        {/* dateFormat="YYYY-MM-DD" */}
                        {/* selected={moment(this.state.date)} */}
                        {/* onChange={this.handleDate} */}
                        {/* maxDate={moment()} */}
                        {/* /> */}
                        <input type='text' value={this.state.date} onChange={this.handleChange} />
                      </div>
                    }
                  />
                </nav>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card
                  title={`Raport zmian z dnia ${this.state.date}`}
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          <th style={{ width: '3%' }}>Lp</th>
                          <th style={{ width: '20%' }}>advertiserId</th>
                          <th style={{ width: '20%' }}>partnerId</th>
                          <th style={{ width: '20%' }}>adspaceId</th>
                          <th style={{ width: '20%', textAlign: 'right' }}>Commision</th>
                          <th style={{ width: '17%', textAlign: 'right' }}>Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((el, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{data[key].previous ? data[key].previous[0].advertiserName : data[key].advertiserId} <span className='sup'>{data[key].advertiserId}</span></td>
                            <td>{data[key].previous ? data[key].previous[0].partnerName : data[key].partnerId} <span className='sup'>{data[key].partnerId}</span></td>
                            <td>{data[key].previous ? data[key].previous[0].adspaceName : data[key].adspaceId} <span className='sup'>{data[key].adspaceId}</span></td>
                            <td style={{ textAlign: 'right' }}>{data[key].commission}</td>
                            <td style={{ textAlign: 'right' }}>{data[key].fee}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={3}>&nbsp;</td>
                          <td><b>Suma</b></td>
                          <td style={{ textAlign: 'right' }}><b>{this.format(commissionSum.commission)}</b></td>
                          <td style={{ textAlign: 'right' }}><b>{this.format(feeSum.fee)}</b></td>
                        </tr>
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </form>
      </div>
    )
  }
}

export default (ClicksAddHistory)
