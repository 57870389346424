import React, { Component } from 'react'
import { style } from '../../variables/Variables'
import { Grid, Row, Col, Table, FormControl, Button } from 'react-bootstrap'
import Card from '../../components/Card/Card.jsx'
import NotificationSystem from 'react-notification-system'
import 'react-day-picker/lib/style.css'
import moment from 'moment'
import Select from 'react-select'
import Modal from '../../components/Modal/Modal'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'
const AUTH_DEPARTMENTS = [7, 208, 338, 354]
const USER_IDS = ['386']

class NewsletterList extends Component {
  constructor (props) {
    super(props)
    this.sendNotification = this.sendNotification.bind(this)
    this.search = this.search.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.types = [
      { value: '1', label: 'Zmiana warunków' },
      { value: '2', label: 'Zamknięcie' },
      { value: '3', label: 'Wstrzymanie' },
      { value: '4', label: 'Przerwa techniczna' },
      { value: '5', label: 'Nowe materiały promocyjne' },
      { value: '6', label: 'Zmiany w regulaminie' },
      { value: '7', label: 'Wznowienie' },
      { value: '8', label: 'Promocja/kod rabatowy' },
      { value: '100', label: 'Własne' }
    ]
    this.state = {
      count: 0,
      page: 1,
      loader: true,
      newsletters: [],
      newsletterPreview: '',
      modal: false,
      query: {
        type: '',
        isSended: '',
        accepted: ''
      }
    }
  }

  UNSAFE_componentWillMount () {
    this.timer = null
    this.delay = 600
    this.limit = 20
  }

  componentDidMount () {
    this.search(1)
  }

  sendNotification (icon, msg, lvl) {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify='icon' className={icon} />,
      message: <div>{msg}</div>,
      level: lvl,
      position: 'tr'
    })
  }

  search (page = 1, searchParam = null, limit = true, cb = () => {}) {
    const state = this.state
    const query = { limit: this.limit, skip: page - 1 < 0 ? 0 : (page - 1) * this.limit, where: { and: [] } }
    if (!limit) {
      delete query.limit
      delete query.skip
    }
    state.page = page
    state.query = searchParam ? { ...state.query, ...searchParam } : state.query
    let key
    for (key in state.query) {
      if (state.query[key] && state.query[key].length) {
        switch (key) {
          case 'title':
            query.where.and.push({ title: { like: state.query[key] } })
            break
          case 'user':
            query.where.and.push({ 'user.EMAIL': { like: state.query[key] } })
            break
          case 'status':
            if (state.query[key] === '1') {
              query.where.and.push({ accepted: false })
            } else if (state.query[key] === '2') {
              query.where.and.push({ accepted: true })
            } else if (state.query[key] === '3') {
              query.where.and.push({ isSended: true })
            } else if (state.query[key] === '4') {
              query.where.and.push({ isSended: false })
            }
            break
          default:
            query.where.and.push({ [key]: { eq: state.query[key] } })
        }
      }
    }
    if (!query.where.and.length) {
      delete query.where
    }
    query.order = '_id DESC'
    fetch(`${API_SERVER}/db/api/newsletters?filter=${JSON.stringify(query)}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET'
    })
      .then((res) => {
        state.count = res.headers.get('X-Total-Count')
        return res
      })
      .then((res) => res.json())
      .then((response) => {
        state.newsletters = response
        state.loader = false
        this.setState(state, () => cb())
      })
      .catch((err) => {
        console.error(err)
        state.loader = false
        this.setState(state, () => cb())
      })
  }

  handleChange (e) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.setState({ loader: true }, () => {
        this.search(1, { [e.name]: e.value })
      })
    }, this.delay)
  }

  getType (type) {
    return this.types.filter((item) => item.value === type)[0].label
  }

  renderAcceptBtn (news) {
    const user = this.props.user
    if (
      process.env.NODE_ENV === 'development' ||
      (!news.isSended && ((user && user.UF_DEPARTMENT && user.UF_DEPARTMENT.filter((department) => AUTH_DEPARTMENTS.indexOf(department) > -1)) || USER_IDS.includes(user.ID)))
    ) {
      return (
        <Button
          type='button'
          className={`btn btn-xs ${news.accepted ? 'btn-warning' : 'btn-info'}`}
          onClick={() => {
            fetch(`${API_SERVER}/db/api/newsletters/${news.id}`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
              },
              body: `accepted=${!news.accepted}`
            })
              .then((response) => response.json())
              .then((response) => {
                if (response.error) {
                  console.error(response.error)
                  this.sendNotification('pe-7s-close-circle', `Wystąpił błąd. Spróbuj ponownie<br/>${response.error.message}`, 'error')
                } else {
                  this.search(this.state.page, this.state.query, true, () => {
                    this.sendNotification('pe-7s-check', 'Zmiany wprowadzone', 'success')
                  })
                }
              })
              .catch((error) => {
                console.error(error)
                this.sendNotification('pe-7s-close-circle', 'Wystąpił błąd. Spróbuj ponownie', 'error')
              })
          }}
          style={{ marginTop: '5px', width: '70px', marginRight: '5px' }}
        >
          {news.accepted ? 'odrzuć' : 'akceptuj'}
        </Button>
      )
    }
  }

  renderDeleteBtn (news) {
    const user = this.props.user
    if (
      process.env.NODE_ENV === 'development' ||
      (!news.isSended && ((user && user.UF_DEPARTMENT && user.UF_DEPARTMENT.filter((department) => AUTH_DEPARTMENTS.indexOf(department) > -1)) || USER_IDS.includes(user.ID)))
    ) {
      return (
        <Button
          type='button'
          className='btn btn-xs btn-danger'
          onClick={(e) => {
            e.preventDefault()
            if (window.confirm('Czy jesteś pewien, że chcesz usunąć newsletter? Operacja jest nieodwracalna.')) {
              this.setState(
                {
                  loader: true
                },
                () => {
                  fetch(`${API_SERVER}/db/api/newsletters/${news.id}`, {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    }
                  })
                    .then((response) => response.json())
                    .then(() => {
                      this.search(this.state.page, this.state.query, true, () => {
                        this.sendNotification('pe-7s-check', 'Rekord usunięty', 'success')
                      })
                    })
                    .catch((error) => {
                      console.error('Error:', error)
                      this.sendNotification('pe-7s-close-circle', 'Wystąpił błąd', 'error')
                    })
                }
              )
            }
          }}
          style={{ marginTop: '5px', width: '70px' }}
        >
          usuń
        </Button>
      )
    }
  }

  handleView (newsletter) {
    newsletter.clientName = newsletter.clientName && newsletter.clientName.length > 0 ? newsletter.clientName : this.client.company
    newsletter.keyAccountManagerId = newsletter.keyAccountManagerId && newsletter.keyAccountManagerId.length > 0 ? newsletter.keyAccountManagerId : this.client.it.keyAccountManagerId
    this.setState({ loader: true }, () => {
      fetch(`${API_SERVER}/api/client/view-newsletter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ newsletter })
      })
        .then((response) => response.text())
        .then((response) => {
          const iframe = (
            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '650px' }}>
              <iframe title='newsletterPreview' id='newsletterPreview' width='650' height='600' frameBorder='0' srcDoc={response} />
            </div>
          )
          this.setState({ loader: false, modal: true, newsletterPreview: iframe })
        })
        .catch((error) => {
          console.error('Error:', error)
          this.setState({ loader: false, modal: false, newsletterPreview: '' })
        })
    })
  }

  renderPagination () {
    const loopIterations = Math.ceil(this.state.count / this.limit)
    return (
      <nav style={{ textAlign: 'center' }}>
        {loopIterations > 1 && (
          <ul className='pagination'>
            <li className={`${this.state.page === 1 ? 'disabled' : ''}`}>
              <span
                onClick={(e) => {
                  if (this.state.page === 1) e.preventDefault()
                  else this.search(this.state.page - 1)
                }}
              >
                &laquo;
              </span>
            </li>
            {[...Array(loopIterations)].map((item, key) => {
              key = key + 1
              return (
                <li key={`${key}pag`} className={`${this.state.page === key ? 'active' : ''}`}>
                  <span
                    onClick={(e) => {
                      if (this.state.page === key) e.preventDefault()
                      else this.search(key)
                    }}
                  >
                    {key}
                  </span>
                </li>
              )
            })}
            <li className={`${this.state.page === loopIterations ? 'disabled' : ''}`}>
              <span
                onClick={(e) => {
                  if (this.state.page === loopIterations) e.preventDefault()
                  else this.search(this.state.page + 1)
                }}
              >
                &raquo;
              </span>
            </li>
          </ul>
        )}
      </nav>
    )
  }

  render () {
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title='Lista wysłanych raportów'
                category={
                  <ul className='ownLegend'>
                    <li>
                      <span /> brak akcji
                    </li>
                    <li>
                      <span className='bg-info' /> zaakceptowany
                    </li>
                    <li>
                      <span className='bg-success' /> wysłany
                    </li>
                  </ul>
                }
                content={
                  <div>
                    <Table hover className='mb-0'>
                      <thead>
                        <tr>
                          <th>Typ</th>
                          <th>Tytuł</th>
                          <th>Data utworzenia</th>
                          <th>Data wysłania</th>
                          <th>Utworzony przez</th>
                          <th>Status</th>
                          <th />
                        </tr>
                        <tr>
                          <td style={{ maxWidth: '200px', minWidth: '150px' }}>
                            <Select isClearable options={this.types} placeholder='' onChange={(e) => this.handleChange({ name: 'type', value: e && e.value ? e.value : '' })} />
                          </td>
                          <td style={{ maxWidth: '100px' }}>
                            <FormControl type='text' name='title' defaultValue={this.state.query.title} onChange={(e) => this.handleChange({ name: e.target.name, value: e.target.value })} />
                          </td>
                          <td />
                          <td />
                          <td style={{ maxWidth: '100px' }}>
                            <FormControl type='text' name='user' defaultValue={this.state.query.user} onChange={(e) => this.handleChange({ name: e.target.name, value: e.target.value })} />
                          </td>
                          <td style={{ minWidth: '100px' }}>
                            <Select
                              isClearable
                              options={[
                                { value: '1', label: 'Do akceptacji' },
                                { value: '2', label: 'Zaakcpetowane' },
                                { value: '3', label: 'Wysłane' },
                                { value: '4', label: 'Niewysłane' }
                              ]}
                              placeholder=''
                              onChange={(e) => this.handleChange({ name: 'status', value: e && e.value ? e.value : '' })}
                            />
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.newsletters.length > 0
                          ? (
                              this.state.newsletters.map((news, key) => (
                                <tr key={key} className={news.isSended ? 'success' : news.accepted ? 'info' : 'default'}>
                                  <td>{this.getType(news.type)}</td>
                                  <td>{news.title}</td>
                                  <td>{moment(news.createdAt._date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                  <td>{news.isSended ? moment(news.sendedAt).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
                                  <td>{news.user && news.user.EMAIL ? news.user.EMAIL : 'brak informacji'}</td>
                                  <td>{news.isSended ? 'wysłany' : news.accepted ? 'zaakceptowany' : 'do akceptacji'}</td>
                                  <td style={{ whiteSpace: 'nowrap' }}>
                                    <Button
                                      type='button'
                                      className='btn btn-default btn-xs'
                                      onClick={() => {
                                        this.handleView(news)
                                      }}
                                      style={{ width: '70px' }}
                                    >
                                      podejrzyj
                                    </Button>
                                    <Link to={`/client/newsletter/${news.clientITID}`} className='btn btn-success btn-xs' role='button' style={{ marginLeft: '5px', width: '70px' }}>
                                      przejdź
                                    </Link>
                                    <br />
                                    {this.renderAcceptBtn(news)}
                                    {this.renderDeleteBtn(news)}
                                  </td>
                                </tr>
                              ))
                            )
                          : (
                            <tr className='bg-warning'>
                              <td colSpan='7'>Brak wyników</td>
                            </tr>
                            )}
                      </tbody>
                    </Table>
                    {this.renderPagination()}
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        <div id='own-preloader' className={`${this.state.loader === true ? 'show' : ''}`}>
          <div id='own-loader' />
        </div>
        <Modal
          show={this.state.modal}
          content={this.state.newsletterPreview}
          handleClosing={() => {
            this.setState({ modal: false })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(NewsletterList)
