import React from 'react'

export default function StepsNavigation ({ totalSteps, currentStep, setStep }) {
  return (
    <div className='steps-navigation'>
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div key={index} className={`step-container ${index <= currentStep ? 'active' : ''}`}>
          <div onClick={() => setStep(index)} className={`step-dot ${index === currentStep ? 'current' : ''}`}><span>{index + 1}</span></div>
        </div>
      ))}
    </div>
  )
}
