import fetchApi, { API_SERVER } from '../../../../fetchApi'
import { useNotification as notification } from '../../../../useNotification'
import { toHours, toSeconds } from '../../../TimeReport/timeFunctions'

export const getUserWorkingHours = async (userId, month, type) => {
  const response = await fetchApi(`${API_SERVER}/api/getUserWorkingHours`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      month,
      type
    })
  })
  return toHours(toSeconds(response))
}

export const generateRowsFromList = async (userId, userDepartment, type) => {
  const response = await fetchApi(`${API_SERVER}/api/getHogJobs`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      userDepartment,
      type: `${type}-settings`
    })
  })
  return Object.entries(response || {}).map(([name, time]) => ({ name, time }))
}

/* b2b settings */

export const getSettings = async (type) => {
  const response = await fetchApi(`${API_SERVER}/db/api/common/` +
    `?filter[where][_id]=${type}-settings`)
  return Array.isArray(response) ? response[0] : {}
}

export const saveSettings = async (data, type) => {
  const response = await fetchApi(`${API_SERVER}/db/api/common/${type}-settings`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      jobs: data.jobs
    })
  })
  if (response.error) {
    console.error(response.error)
    notification('Wystąpił błąd')
  } else {
    notification('Dane zostały zapisane', 'success')
    return response
  }
}
