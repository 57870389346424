import React, { Component } from 'react'
import { Grid, Row, Col, Table } from 'react-bootstrap'
import Card from '../../components/Card/Card.jsx'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NotificationSystem from 'react-notification-system'
import { style } from '../../variables/Variables'

const API_SERVER = process.env.REACT_APP_API_SERVER || '..'
const PAGE_COUNT = 15

class TableList extends Component {
  constructor (props) {
    super(props)
    this.onRequest = props.onRequest
    this.onResponse = props.onResponse
    this.setCheckboxes = props.setCheckboxes
    this.setPage = props.setPage
    this.setFilter = props.setFilter
    this.onClickPrev = this.onClickPrev.bind(this)
    this.onClickNext = this.onClickNext.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.checkNavPages = this.checkNavPages.bind(this)
    this.onClickCheckbox = this.onClickCheckbox.bind(this)
    this.clientsUpdate = this.clientsUpdate.bind(this)
    this.state = {
      disabledPrevButton: true,
      disabledNextButton: false
    }
    this.loadNext()
  }

  loadNext () {
    const onResponse = this.onResponse
    const { filter } = this.props
    let next = filter.page * PAGE_COUNT
    if (next < 0) {
      next = 0
    }

    setTimeout(() => {
      this.checkNavPages()
    }, 500)

    this.onRequest()
    let query = { where: { and: [{}] } }
    if (filter.query !== '') {
      query.where.and.push({
        or: [
          { company: { like: `.*${filter.query}.*`, options: 'i' } },
          { alias: { like: `.*${filter.query}.*`, options: 'i' } },
          { 'sm.company': { like: `.*${filter.query}.*`, options: 'i' } },
          { 'ns.company': { like: `.*${filter.query}.*`, options: 'i' } },
          { 'it.name': { like: `.*${filter.query}.*`, options: 'i' } },
          { ITID: { like: `.*${filter.query}.*`, options: 'i' } },
          { SMID: { like: `.*${filter.query}.*`, options: 'i' } },
          { NSID: { like: `.*${filter.query}.*`, options: 'i' } }
        ]
      })
    }
    if (filter.isInfluency) {
      query.where.and.push({ isInfluency: true })
    }
    if (filter.emptyWww) {
      query.where.and.push({
        or: [
          { website: '' },
          { domain: '' }
        ]
      })
    }
    if (filter.noBitrix) {
      query.where.and.push({
        or: [
          { bitrix: '' },
          { bitrix: { exists: false } }
        ]
      })
    }
    if (filter.onlyIG) {
      query.where.and.push({ ITID: { gt: '' } })
    }
    if (filter.seoReports) {
      query.where.and.push({ 'raporty.seo': '1' })
    }

    query = { ...query, limit: PAGE_COUNT, skip: next }

    fetch(`${API_SERVER}/db/api/clients?filter=${JSON.stringify(query)}`)
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then((response) => {
        onResponse(response || [])
      })
  }

  onClickPrev () {
    this.setPage(this.props.filter.page - 1)
    setTimeout(() => {
      this.loadNext()
    }, 10)
  }

  onClickNext () {
    this.setPage(this.props.filter.page + 1)
    setTimeout(() => {
      this.loadNext()
    }, 10)
  }

  checkNavPages () {
    const data = this.props.clients
    const prevValue = this.props.filter.page <= 0
    const nextValue = data.length < 15
    this.setState(() => ({
      disabledPrevButton: prevValue,
      disabledNextButton: nextValue
    }))
  }

  onInputChange (event) {
    const queryInput = document.getElementById('query')
    this.setFilter(
      queryInput.value
    )
    setTimeout(() => {
      this.loadNext()
    }, 10)
  }

  getCompany (data) {
    const value = [<div key={data._id}>{data.company}</div>]
    if (data.sm && (data.sm.company !== data.company)) {
      value.push(<div key={data._id + 'smcomp'}>{data.sm.company} (SM)</div>)
    }
    if (data.ns && (data.ns.company !== data.company)) {
      value.push(<div key={data._id + 'nscomp'}>{data.ns.company} (NS)</div>)
    }
    if (data.payment_ns && data.payment_ns.company && (data.payment_ns.company !== data.company)) {
      value.push(<div key={data._id + 'pnspayment'}>{data.payment_ns.company} (PNS)</div>)
    }
    if (data.payment_sm && data.payment_sm.company && (data.payment_sm.company !== data.company)) {
      value.push(<div key={data._id + 'psmpayment'}>{data.payment_sm.company} (PSM)</div>)
    }
    return value
  }

  getID (data) {
    const value = []
    if (data.SMID) {
      value.push(<div key={data._id + 'SMID'}>SM: {data.SMID}</div>)
    }
    if (data.NSID) {
      value.push(<div key={data._id + 'NSID'}>NS: {data.NSID}</div>)
    }
    if (data.ITID) {
      value.push(<div key={data._id + 'IT'}>IT: {data.ITID}</div>)
    }
    return value
  }

  getStatus (data) {
    const value = []
    if (data.sm) {
      value.push(<div key={data._id + 'smstatus'}>SM: {data.sm.status}</div>)
    }
    if (data.ns) {
      value.push(<div key={data._id + 'nsstatus'}>NS: {data.ns.status}</div>)
    }
    return value
  }

  getWebsites (data) {
    return data.websites
      ? data.websites
        .filter((item, pos, a) => a.indexOf(item) === pos)
        .map(website => (<div className='publishers-websites'>{website}</div>))
      : ''
  }

  addGap (number) {
    if (number >= 10 && number < 100) {
      return `0${number}`
    } else if (number < 10) {
      return `00${number}`
    }
    return number
  }

  getProfit (profit) {
    if (profit > 1000) {
      return `${Math.floor(profit / 1000)} ${this.addGap(profit % 1000)}`
    }

    return profit
  }

  onClickCheckbox (event) {
    const { id } = event.target
    const result = this.props.filter[id] = !this.props.filter[id]
    this.setCheckboxes(id, result)
    setTimeout(() => {
      this.loadNext()
    }, 10)
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  clientsUpdate () {
    this.showNotification('info', 'Trwa aktualizacja...')
    fetch(`${API_SERVER}/api/clients-update`)
      .then(res => res.json())
      .catch(error => {
        this.showNotification('error', 'Aktualizacja nie powiodła się.', 0)
        console.error('Error:', error)
      })
      .then(response => {
        if (response.success) {
          this.showNotification('success', response.success)
          console.log(response.success)
        } else {
          this.showNotification('error', response.error)
          console.error('Error:', response.error)
        }
      })
  }

  render () {
    const data = this.props.clients ? this.props.clients : []
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <nav className='navbar navbar-light bg-light'>
                <div className='btn-group' role='group' aria-label='Basic example'>
                  <form className='form-inline my-2 my-lg-0' onSubmit={e => e.preventDefault()}>
                    <button type='button' className='btn btn-secondary' onClick={this.onClickPrev} disabled={this.state.disabledPrevButton}>« poprzednia</button>
                    <button type='button' className='btn btn-secondary' onClick={this.onClickNext} disabled={this.state.disabledNextButton}>następna »</button>
                    <input type='text' id='query' className='form-control' placeholder='Szukaj...' value={this.props.filter.query} onChange={this.onInputChange} />
                    <Link to='/client/add' className='btn btn-success' role='button'>Dodaj klienta</Link>
                    <button type='button' id='isInfluency' className={this.props.filter.isInfluency ? 'btn btn-primary' : 'btn btn-default'} onClick={this.onClickCheckbox}>Influencerzy</button>
                    <button type='button' id='emptyWww' className={this.props.filter.emptyWww ? 'btn btn-primary' : 'btn btn-default'} onClick={this.onClickCheckbox}>Brak WWW</button>
                    <button type='button' id='noBitrix' className={this.props.filter.noBitrix ? 'btn btn-primary' : 'btn btn-default'} onClick={this.onClickCheckbox}>Bez Bitrixa</button>
                    <button type='button' id='onlyIG' className={this.props.filter.onlyIG ? 'btn btn-primary' : 'btn btn-default'} onClick={this.onClickCheckbox}>Tylko IG</button>
                    <button type='button' id='seoReports' className={this.props.filter.seoReports ? 'btn btn-primary' : 'btn btn-default'} onClick={this.onClickCheckbox}>Raporty SEO</button>
                    <button type='button' className='btn btn-default' onClick={this.clientsUpdate}>Aktualizuj bazę</button>
                  </form>
                </div>
              </nav>
            </Col>
            <Col md={12}>
              <Card
                title='Lista Klientów'
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th style={{ width: '5%' }}>#</th>
                        <th style={{ width: '10%' }}>ID</th>
                        <th style={{ width: '40%' }}>Firma</th>
                        <th style={{ width: '20%' }}>Strona</th>
                        <th style={{ width: '20%' }}>Alias</th>
                        <th style={{ width: '5%' }}>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data.map((prop, key) => (
                          <tr
                            style={{ cursor: 'pointer' }}
                            key={key}
                            onClick={() => {
                              this.props.history.push({
                                pathname: `/client/${prop._id}`,
                                client: { data: prop }
                              })
                            }}
                          >
                            <td>{this.props.filter.page * PAGE_COUNT + key + 1}</td>
                            <td>{this.getID(prop)}</td>
                            <td>{this.getCompany(prop)}</td>
                            <td>{prop.website}</td>
                            <td>{prop.alias}</td>
                            <td>{this.getStatus(prop)}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                }
              />
            </Col>

            <Col md={12}>
              <nav className='navbar navbar-light bg-light'>
                <div className='btn-group' role='group' aria-label='Basic example'>
                  <form className='form-inline my-2 my-lg-0'>
                    <button type='button' className='btn btn-secondary' onClick={this.onClickPrev} disabled={this.state.disabledPrevButton}>« poprzednia</button>
                    <button type='button' className='btn btn-secondary' onClick={this.onClickNext} disabled={this.state.disabledNextButton}>następna »</button>
                  </form>
                </div>
              </nav>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  clients: state.clients.data,
  filter: state.clients.filter
})

const mapDispatchToProps = {
  onRequest: id => ({
    type: 'REQUEST_CLIENT'
  }),
  onResponse: clients => ({
    type: 'CLIENTS_SET_CLIENTS',
    clients
  }),
  setCheckboxes: (id, checkbox) => ({
    type: 'CLIENTS_SET_CHECKBOXES',
    id,
    checkbox
  }),
  setPage: page => ({
    type: 'CLIENTS_SET_PAGE',
    page
  }),
  setFilter: query => ({
    type: 'CLIENTS_SET_FILTER',
    query
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(TableList)
