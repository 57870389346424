import React, { Component } from 'react'
import { Col, FormControl, Grid, Row } from 'react-bootstrap'
import * as moment from 'moment'
import 'moment/locale/pl'
import { connect } from 'react-redux'
import { Card } from '../../components/Card/Card'
import '../style.css'
import TimeReportComponent from './TimeReportComponent'
import TimeReportClients from './TimeReportClients'
import TimeReportProjects from './TimeReportProjects'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import SCModal from '../Settlements/Components/Modal'
import { getLabel, getMainBtx, onlyActiveUsers } from '../Settlements/common'
import Select from 'react-select'
import ReactMonthPicker from '../Settlements/Components/ReactMonthPicker'
import { topManagersList } from '../Settlements/authData'
import ToggleSwitch from '../Settlements/Components/ToggleSwitch'

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 1 }),
  control: style => ({ ...style, minHeight: 30 })
}
const labelStyles = {
  fontSize: 10,
  margin: '10px 0 0 5px',
  color: '#9a9a9a',
  fontWeight: 400,
  textTransform: 'uppercase'
}

class TimeReport extends Component {
  constructor (props) {
    super(props)
    this.updateLoader = this.updateLoader.bind(this)
    this.checkIsGod = this.checkIsGod.bind(this)
    this.setModalParams = this.setModalParams.bind(this)
    this.changeFakeUser = this.changeFakeUser.bind(this)
    this.onChangeMonth = this.onChangeMonth.bind(this)
    this.toggleSwitch = this.toggleSwitch.bind(this)
    this.onResponse = props.onResponse
    this.setParams = props.setParams
    this.setBtxMain = props.setBtxMain
    this.setFakeUser = props.setFakeUser
    const year = Number(moment(this.props.tasks.params.dayEnd || []).format('YYYY'))
    const month = Number(moment(this.props.tasks.params.dayEnd || []).format('MM'))
    this.state = {
      dayStart: this.props.tasks.params.dayStart || moment().subtract(6, 'days').format('YYYY-MM-DD'),
      dayEnd: this.props.tasks.params.dayEnd || moment().format('YYYY-MM-DD'),
      months: {
        from: { year, month },
        to: { year, month }
      }
    }
  }

  componentDidMount () {
    getMainBtx()
    // .catch(() => this.showNotification('error', 'Wystąpił błąd'))
      .catch(err => console.error('Wystąpił błąd', err))
      .then(response => this.setBtxMain(response || {}))

    const { tasksFakeUser } = this.props.tasks || {}
    const { user } = this.props
    const loggedUser = tasksFakeUser.ID ? tasksFakeUser : user

    this.setState({
      loggedUser
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ((JSON.stringify(prevState) !== JSON.stringify(this.state)) ||
      (JSON.stringify(prevProps.tasks.params) !== JSON.stringify(this.props.tasks.params))) {
      const { params } = this.props.tasks || {}
      const { params: prevParams } = prevProps.tasks || {}
      let { dayStart, dayEnd, months } = this.state
      if (!!params.reportType && !['usersView', 'projectsViewUsers', 'projectsViewDeparments'].includes(params.reportType)) {
        dayStart = moment({ year: months.from.year, month: months.from.month - 1 }).startOf('month').format('YYYY-MM-DD')
        dayEnd = moment({ year: months.to.year, month: months.to.month - 1 }).endOf('month').format('YYYY-MM-DD')
      }
      const year = Number(moment(dayEnd || []).format('YYYY'))
      const month = Number(moment(dayEnd || []).format('MM'))
      const estimatedProfit = this.shouldEstimatedProfit(prevState, dayStart, dayEnd, prevParams, params)
      this.setParams({ ...params, dayStart, dayEnd, estimatedProfit })
      this.setState({
        dayStart,
        dayEnd,
        months: {
          from: {
            year: (months.from || {}).year || year,
            month: (months.from || {}).month || month
          },
          to: {
            year: (months.to || {}).year || year,
            month: (months.to || {}).month || month
          }
        }
      })
    }
  }

  updateLoader (loader) {
    this.setState({ loader })
  }

  checkIsGod (isGod) {
    this.setState({ isGod })
  }

  onChangeMonth (months) {
    if (JSON.stringify(this.state.months) !== JSON.stringify(months)) {
      const dayStart = moment({ year: months.from.year, month: months.from.month - 1 }).startOf('month').format('YYYY-MM-DD')
      const dayEnd = moment({ year: months.to.year, month: months.to.month - 1 }).endOf('month').format('YYYY-MM-DD')
      this.setState({
        dayStart,
        dayEnd,
        months
      })
    }
  }

  shouldEstimatedProfit (prevState, dayStart, dayEnd, prevParams, params) {
    const isDayChanged = prevState.dayEnd !== dayEnd
    const isReportChanged = prevParams.reportType !== params.reportType
    const isAllowedReport = !!params.reportType && ['assignedView', 'clientsViewDepartment'].includes(params.reportType)
    const isThisMonth = moment().format('YYYY-MM') === moment(dayEnd).format('YYYY-MM')

    return (isDayChanged || isReportChanged)
      ? (isAllowedReport && isThisMonth)
      : params.estimatedProfit
  }

  toggleSwitch (type) {
    const { params } = this.props.tasks || {}
    return this.setParams({ ...params, [type]: !params[type] })
  }

  renderShowAllSwitch () {
    const { isGod, loggedUser = {} } = this.state
    const { params = {} } = this.props.tasks
    const allowedReportTypes = ['usersView', 'clientsViewUsers', 'assignedView', 'projectsViewUsers']

    if ((!params.reportType || allowedReportTypes.includes(params.reportType)) &&
      (isGod || topManagersList.indexOf(loggedUser.ID) > -1)) {
      return (
        <ToggleSwitch
          checked={params.isShowAll}
          name='showAll'
          label='cała firma'
          toggleSwitch={() => this.toggleSwitch('isShowAll')}
        />
      )
    }
  }

  renderShowActiveSwitch () {
    const { params = {} } = this.props.tasks

    if ((!params.reportType || params.reportType === 'usersView')) {
      return (
        <ToggleSwitch
          checked={params.isShowActive}
          name='showActive'
          label='tylko aktywni'
          toggleSwitch={() => this.toggleSwitch('isShowActive')}
        />
      )
    }
  }

  renderReportCostSwitch () {
    const { params } = this.props.tasks || {}
    if (!!params.reportType && params.reportType !== 'usersView') {
      return (
        <ToggleSwitch
          checked={params.reportCost}
          name='reportCost'
          label='rentowność'
          toggleSwitch={() => this.toggleSwitch('reportCost')}
        />
      )
    }
  }

  renderHideAcceptedSwitch () {
    const { params } = this.props.tasks || {}
    if (!params.reportType || params.reportType === 'usersView') {
      return (
        <ToggleSwitch
          checked={params.isHideAccepted}
          name='hideAccepted'
          label='ukryj zaakceptowane'
          toggleSwitch={() => this.toggleSwitch('isHideAccepted')}
        />
      )
    }
  }

  renderEstimatedProfitSwitch () {
    const { params } = this.props.tasks || {}
    if (!!params.reportType && ['assignedView', 'clientsViewDepartment'].includes(params.reportType)) {
      return (
        <ToggleSwitch
          checked={params.estimatedProfit}
          name='estimatedProfit'
          label='estymowany zysk'
          toggleSwitch={() => this.toggleSwitch('estimatedProfit')}
          className='red'
        />
      )
    }
  }

  renderReportTypeSelect () {
    const { tasks = {} } = this.props
    const { params = {} } = tasks
    return (
      <div>
        <FormControl
          componentClass='select'
          name='reportType'
          type='select'
          value={params.reportType}
          onChange={(e) => {
            this.setParams({ ...params, reportType: e.target.value })
          }}
        >
          <optgroup label='Akceptacja dnia pracy'>
            <option value='usersView'>Raport Pracownika</option>
          </optgroup>
          <optgroup label='Klienci Twojego zespołu'>
            <option value='clientsView'>Raport własnej pracy</option>
            <option value='clientsViewYourTeam'>Raport Twojego zespołu</option>
            <option value='clientsViewUsers'>Raport osób zespołu</option>
            <option value='clientsViewDepartment' style={{ color: 'blue' }}>Raport działu</option>
          </optgroup>
          <optgroup label='Klienci Opiekuna w działach'>
            <option value='assignedViewUsers'>Raport osób realizujących</option>
            <option value='assignedView' style={{ color: 'blue' }}>Raport wyników w działach</option>
          </optgroup>
          <optgroup label='Projekty'>
            <option value='projectsViewUsers'>Raport projektów imienny</option>
            <option value='projectsViewDepartments'>Raport projektów w działach</option>
          </optgroup>
        </FormControl>
      </div>
    )
  }

  changeFakeUser () {
    const { tasksFakeUser } = this.state
    this.setFakeUser({
      ID: tasksFakeUser.users,
      UF_DEPARTMENT: [Number(tasksFakeUser.departments)],
      departName: tasksFakeUser.departmentsName,
      userName: tasksFakeUser.usersName,
      EMAIL: tasksFakeUser.email,
      scHead: tasksFakeUser.scHead,
      scDepartment: tasksFakeUser.scDepartment
    })
  }

  /**
   * Obsługa modala
   */

  allowedUsers (allUsers = [], allowed) {
    return allUsers.filter(el => el.DEPARTMENT && el.DEPARTMENT.includes(parseInt(allowed, 10)))
  }

  getPropsData (id, type) {
    const { main } = this.props
    return (main.users.filter(el => el.ID === id)[0] || {})[type]
  }

  renderSelect (row, key) {
    const { tasksFakeUser = {} } = this.state
    const { main } = this.props
    let source = main[row] || []

    if (row === 'users') { source = this.allowedUsers(onlyActiveUsers(source), tasksFakeUser.departments) }

    const options = source.map(item => {
      return ({
        value: item.ID,
        label: item.NAME
      })
    })

    const initValue = {
      value: tasksFakeUser[row],
      label: getLabel(main[row], tasksFakeUser[row])
    }

    return (
      <div style={{ margin: '0 50px' }} key={key}>
        <label style={labelStyles}>{row}</label>
        <Select
          options={options}
          name={row}
          value={initValue}
          styles={selectStyles}
          onChange={(e) => {
            tasksFakeUser[row] = e.value
            tasksFakeUser.users = row === 'departments' ? '' : tasksFakeUser.users
            tasksFakeUser[`${row}Name`] = getLabel(main[row], e.value)
            tasksFakeUser.email = row === 'departments' ? '' : this.getPropsData(tasksFakeUser.users, 'EMAIL')
            tasksFakeUser.scHead = row === 'departments' ? [] : this.getPropsData(tasksFakeUser.users, 'scHead')
            tasksFakeUser.scDepartment = row === 'departments' ? [] : this.getPropsData(tasksFakeUser.users, 'scDepartment')
            this.setState({ tasksFakeUser })
          }}
        />
        <input
          type='hidden'
          name={row}
          readOnly
        />
      </div>
    )
  }

  setModalParams (show = false, title = '', content = '', method = null) {
    this.setState({
      modalShow: show,
      modalTitle: title,
      modalContent: content,
      modalMethod: method
    })
  }

  renderModal () {
    const { modalShow, modalTitle, modalMethod } = this.state
    const rows = ['departments', 'users']
    const modalContent = rows.map((row, key) => this.renderSelect(row, key))

    return (
      <SCModal
        modalShow={modalShow}
        modalTitle={modalTitle}
        modalContent={modalContent}
        setModalParams={this.setModalParams}
        propMethod={modalMethod}
      />
    )
  }

  renderChangeUser () {
    const { loggedUser = {} } = this.state
    const allowedIds = ['298', '200', '360', '46', '536', '168']
    if (allowedIds.includes(loggedUser.ID)) {
      return (
        <a
          href='/#' key='0' className='btn btn-default btn-sm' style={{ marginLeft: 40 }}
          onClick={(e) => {
            e.preventDefault()
            this.setModalParams(true, 'Zmień Usera', '', this.changeFakeUser)
          }}
        >Zmień usera
        </a>
      )
    }
  }

  render () {
    const { loader, tasks, user } = this.props
    const { params = {}, tasksFakeUser } = tasks
    const { reportType = 'usersView' } = params
    const { dayStart, dayEnd, months } = this.state
    const loggedUser = tasksFakeUser.ID ? tasksFakeUser : user
    const propName = tasksFakeUser.ID ? tasksFakeUser.userName : `${user.LAST_NAME} ${user.NAME}`

    return (
      <div className='content'>
        <form className='form-inline'>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <nav className='navbar navbar-light bg-light'>
                  {loader ? <div className='lds-dual-ring' id='spinner' /> : ''}
                  <Card
                    title={[`Raport ${reportType === 'usersView' ? 'Pracownika' : 'Klientów'} ${propName}`,
                      <a key='1' className='header-link' target='_blank' rel='noopener noreferrer' href='https://cube.bitrix24.pl/company/personal/user/200/blog/3456/' style={{ fontSize: 16 }}>FAQ. Akceptacja dnia pracy</a>,
                      <a key='2' className='header-link' target='_blank' rel='noopener noreferrer' href='https://cube.bitrix24.pl/company/personal/user/200/blog/3450/' style={{ fontSize: 16 }}>Opis rodzajów raportów</a>,
                      reportType !== 'usersView' ? <a key='3' className='header-link' target='_blank' rel='noopener noreferrer' href='https://cube.bitrix24.pl/company/personal/user/200/blog/5310/' style={{ fontSize: 16 }}>Komentarze rentowności</a> : '',
                      this.renderChangeUser()
                    ]}
                    footer='hide'
                    content={
                      <div className='timereport-panel'>
                        <div style={{ width: 450 }}>
                          <div>{params.reportCost ? 'Wybierz miesiąc' : 'Wybierz zakres'}</div>
                          {
                            ['usersView', 'projectsViewUsers', 'projectsViewDepartments'].includes(reportType)
                              ? <DateRangePicker
                                  startDate={moment(dayStart)}
                                  startDateId='dayStart'
                                  endDate={moment(dayEnd)}
                                  endDateId='dayEnd'
                                  onDatesChange={({ startDate, endDate }) => {
                                    if (startDate > endDate) { endDate = startDate }
                                    this.setState({
                                      dayStart: moment(startDate).format('YYYY-MM-DD'),
                                      dayEnd: moment(endDate).format('YYYY-MM-DD')
                                    })
                                  }}
                                  focusedInput={this.state.focusedInput}
                                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                                  showDefaultInputIcon
                                  noBorder
                                  minimumNights={0}
                                  initialVisibleMonth={() => moment().subtract(1, 'month')}
                                  isOutsideRange={(startDate, endDate) => startDate >= moment() || endDate <= moment()}
                                />
                              : <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ReactMonthPicker
                                  onChangeMonth={this.onChangeMonth}
                                  date={months}
                                  showDel={false}
                                  monthRangeCheckbox
                                />
                              </div>
                          }
                        </div>
                        {this.renderShowAllSwitch()}
                        {this.renderShowActiveSwitch()}
                        {/* {this.renderReportCostSwitch()} */}
                        {this.renderHideAcceptedSwitch()}
                        {this.renderEstimatedProfitSwitch()}
                        {this.renderReportTypeSelect()}
                      </div>
                    }
                  />
                </nav>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {
                  ['projectsViewUsers', 'projectsViewDepartments'].includes(reportType)
                    ? <TimeReportProjects
                        propDayStart={dayStart}
                        propDayEnd={dayEnd}
                        load={!this.state.focusedInput}
                        email={loggedUser.EMAIL}
                        propShowAll={params.isShowAll}
                      />
                    : reportType !== 'usersView'
                      ? <TimeReportClients
                          propDayStart={dayStart}
                          propDayEnd={dayEnd}
                          load={!this.state.focusedInput}
                          email={loggedUser.EMAIL}
                          propShowAll={params.isShowAll}
                          months={months}
                        />
                      : <TimeReportComponent
                          propDayStart={dayStart}
                          propDayEnd={dayEnd}
                          email={loggedUser.EMAIL}
                          load={!this.state.focusedInput}
                          updateLoader
                          checkIsGod={this.checkIsGod}
                          propShowAll={params.isShowAll}
                          propActive={params.isShowActive}
                        />
                }
              </Col>
            </Row>
          </Grid>
        </form>
        {this.renderModal()}
        {
          tasksFakeUser.ID
            ? <div className='fakeInfo'>
              <div>Zalogowany jako: <b>{tasksFakeUser.userName}</b> ({tasksFakeUser.departName}) </div>
              <div onClick={() => this.setFakeUser({})} style={{ cursor: 'pointer' }}>RESETUJ</div>
            </div>
            : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    main: state.main,
    tasks: state.tasks
  }
}

const mapDispatchToProps = {
  onRequest: id => ({
    type: 'REQUEST_TASKS'
  }),
  setParams: params => ({
    type: 'TASKS_PARAMS',
    params
  }),
  setBtxMain: mainBtx => ({
    type: 'MAIN_BTX',
    mainBtx
  }),
  setFakeUser: user => ({
    type: 'SET_TASKS_FAKE_USER',
    user
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeReport)
