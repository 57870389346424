import React, { Component } from 'react'
import { Grid, Row, Col, Table, FormControl } from 'react-bootstrap'
import Card from '../../components/Card/Card.jsx'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import adspaceSubcategories from './Details/adspaceSubcategories'

const API_SERVER = process.env.REACT_APP_API_SERVER || '..'
const PAGE_COUNT = 15
const API_INGENIOUS = API_SERVER + '/ig/masterdata'
const ADSPACE_SUBCATEGORIES = adspaceSubcategories()

class TableList extends Component {
  constructor (props) {
    super(props)
    this.onRequest = props.onRequest
    this.onResponse = props.onResponse
    this.setAdSpaces = props.setAdSpaces
    this.setClients = props.setClients
    this.setStatus = props.setStatus
    this.setQuery = props.setQuery
    this.setPage = props.setPage
    this.setCheckboxes = props.setCheckboxes
    this.setAgreement3 = props.setAgreement3
    this.setConfirmed = props.setConfirmed
    this.setPartner = props.setPartner
    this.setCodesVerification = props.setCodesVerification
    this.setCodesVerificationComment = props.setCodesVerificationComment
    this.onClickPrev = this.onClickPrev.bind(this)
    this.onClickNext = this.onClickNext.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onClickCheckbox = this.onClickCheckbox.bind(this)
    this.state = {
      disabledPrevButton: true,
      disabledNextButton: false
    }

    this.loadNext()
    this.fetchData(`${API_INGENIOUS}/category/adspace`, 'adSpaceCategories')
    this.fetchData(`${API_SERVER}/db/api/clients?filter[where][ITID][exists]=true`, 'clientsList')

    this.queryTimer = null
  }

  selectAgreement3 () {
    return (
      <FormControl
        componentClass='select'
        placeholder='placeholder'
        type='select'
        className='btn btn-default'
        value={this.props.filter.agreement3}
        style={{ border: this.props.filter.agreement3 !== 'placeholder' ? '2px solid #3472F7' : '' }}
        onChange={(e) => {
          this.setAgreement3(e.target.value)
          setTimeout(() => {
            this.loadNext()
          }, 10)
        }}
      >
        <option value='placeholder'>Regulamin</option>
        <option value='tak'>Regulamin (tak)</option>
        <option value='nie'>Regulamin (nie)</option>
      </FormControl>
    )
  }

  selectConfirmed () {
    return (
      <FormControl
        componentClass='select'
        placeholder='placeholder'
        type='select'
        className='btn btn-default'
        value={this.props.filter.confirmed}
        style={{ border: this.props.filter.confirmed !== 'placeholder' ? '2px solid #3472F7' : '' }}
        onChange={(e) => {
          this.setConfirmed(e.target.value)
          setTimeout(() => {
            this.loadNext()
          }, 10)
        }}
      >
        <option value='placeholder'>Potwierdzeni</option>
        <option value='tak'>Potwierdzeni (tak)</option>
        <option value='nie'>Potwierdzeni (nie)</option>
      </FormControl>
    )
  }

  selectStatus () {
    return (
      <FormControl
        componentClass='select'
        placeholder='placeholder'
        type='select'
        className='btn btn-default'
        value={this.props.filter.status}
        style={{ border: this.props.filter.status !== 'placeholder' ? '2px solid #3472F7' : '' }}
        onChange={(e) => {
          this.setStatus(e.target.value)
          setTimeout(() => {
            this.loadNext()
          }, 10)
        }}
      >
        <option value='placeholder'>Wszystkie statusy</option>
        {
          ['oczekuje', 'odrzucony', 'zaakceptowany', 'duplikat', 'w trakcie wyjaśniania', 'fraud', 'zamknięty'].map(item => <option key={`status${item}`} value={item}>{item}</option>)
        }
      </FormControl>
    )
  }

  fetchData (url, stateData) {
    fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET'
    })
      .then(res => res.json())
      .catch((error) => {
        console.error('Error:', error)
      })
      .then((response) => {
        this.setState({ [stateData]: response })
      })
  }

  selectAdSpaceCategories () {
    const { adSpaceCategories } = this.state
    if (adSpaceCategories) {
      const options = adSpaceCategories.map((item) => {
        let name = item.description || item.name
        if (item.group) {
          name = `${item.group.name} \\ ${name}`
        }
        return ({
          value: item.id,
          label: `${name} (${item.id})`
        })
      })

      return (
        <Select
          isMulti
          options={options}
          placeholder='Kategorie AdSpace'
          defaultValue={this.props.filter.adspaces}
          styles={{ control: styles => ({ ...styles, width: 400, marginRight: 5, fontSize: 14 }) }}
          onChange={(event) => {
            const selectedAds = []
            let elem
            for (elem of event) {
              selectedAds.push({ label: elem.label, value: elem.value })
            }
            this.setAdSpaces(selectedAds)
            setTimeout(() => {
              this.loadNext()
            }, 100)
          }}
        />
      )
    }
  }

  selectPartners () {
    return (
      <FormControl
        componentClass='select'
        placeholder='placeholder'
        type='select'
        className='btn btn-default'
        value={this.props.filter.partner}
        style={{ border: this.props.filter.partner !== 'placeholder' ? '2px solid #3472F7' : '' }}
        onChange={(e) => {
          this.setPartner(e.target.value)
          setTimeout(() => {
            this.loadNext()
          }, 10)
        }}
      >
        <option value='placeholder'>Partnerzy</option>
        <option value='home'>Home.pl</option>
      </FormControl>
    )
  }

  selectNSMAdSpaceCategories () {
    const options = Object.keys(ADSPACE_SUBCATEGORIES.adspaceSubcategories).map((item) => ({
      value: item,
      label: ADSPACE_SUBCATEGORIES.adspaceSubcategories[item]
    }))
    return (
      <Select
        isMulti
        options={options}
        placeholder='NSM AdSpace Category'
        defaultValue={this.props.filter.NSMAdSpaceCategories}
        styles={{ control: styles => ({ ...styles, width: 400, marginRight: 5, fontSize: 14 }) }}
        onChange={(event) => {
          this.props.setNSMAdSpaceCategories(event)
          setTimeout(() => {
            this.loadNext()
          }, 100)
        }}
      />
    )
  }

  selectCodesVerification () {
    return (
      <FormControl
        componentClass='select'
        placeholder='placeholder'
        type='select'
        className='btn btn-default'
        value={this.props.filter.codesVerification}
        style={{ border: this.props.filter.codesVerification !== 'placeholder' ? '2px solid #3472F7' : '' }}
        onChange={(e) => {
          this.setCodesVerification(e.target.value)
          setTimeout(() => {
            this.loadNext()
          }, 10)
        }}
      >
        <option value='placeholder'>Weryfikacja kodów</option>
        {['niezweryfikowane', 'akceptacja', 'akceptacja warunkowa', 'odmowa'].map((item, key) => (
          <option key={`code${item}`} value={item}>{item}</option>
        ))}
      </FormControl>
    )
  }

  selectCodesVerificationComment () {
    return (
      <FormControl
        componentClass='select'
        placeholder='placeholder'
        type='select'
        className='btn btn-default'
        value={this.props.filter.codesVerificationComment}
        style={{ border: this.props.filter.codesVerificationComment !== 'placeholder' ? '2px solid #3472F7' : '' }}
        onChange={(e) => {
          this.setCodesVerificationComment(e.target.value)
          setTimeout(() => {
            this.loadNext()
          }, 10)
        }}
      >
        <option value='placeholder'>Komentarz weryfikacji kodów</option>
        <option value='puste'>puste</option>
        <option value='uzupełniono'>uzupełniono</option>
      </FormControl>
    )
  }

  selectClients () {
    const { clientsList } = this.state
    if (clientsList) {
      const options = clientsList.map((item) => {
        return ({
          value: item.ITID,
          label: `${item.company} (${item.ITID})`
        })
      })

      return (
        <Select
        // isMulti
          isClearable
          options={options}
          placeholder='Przynależność do programu'
          defaultValue={this.props.filter.clients}
          styles={{ control: styles => ({ ...styles, width: 400, marginRight: 5, fontSize: 14 }) }}
          onChange={(event) => {
          // gdyby był potrzebny wariant multiselect
          // const selectedClients = [];
          // for (const elem of event) {
          //   selectedClients.push({label: elem.label, value: elem.value});
          // }
          // this.setClients(selectedClients);
            event ? this.setClients([{ label: event.label, value: event.value }]) : this.setClients([])
            setTimeout(() => {
              this.loadNext()
            }, 100)
          }}
        />
      )
    }
  }

  loadNext () {
    const onResponse = this.onResponse
    const { filter } = this.props
    const next = (filter.page * PAGE_COUNT) < 0 ? 0 : (filter.page * PAGE_COUNT)

    setTimeout(() => {
      this.checkNavPages()
    }, 500)

    this.onRequest()
    let query = { where: { and: [{}] } }
    if (filter.query) {
      query.where.and.push({
        or: [
          { company: { like: `.*${filter.query}.*`, options: 'i' } },
          { 'sm.company': { like: `.*${filter.query}.*`, options: 'i' } },
          { 'ns.company': { like: `.*${filter.query}.*`, options: 'i' } },
          { 'it.name': { like: `.*${filter.query}.*`, options: 'i' } },
          { ITID: { like: `.*${filter.query}.*`, options: 'i' } },
          { SMID: { like: `.*${filter.query}.*`, options: 'i' } },
          { NSID: { like: `.*${filter.query}.*`, options: 'i' } },
          { 'it.email': { like: `.*${filter.query}.*`, options: 'i' } },
          { 'sm.email': { like: `.*${filter.query}.*`, options: 'i' } },
          { 'ns.email': { like: `.*${filter.query}.*`, options: 'i' } },
          { user_email: { like: `.*${filter.query}.*`, options: 'i' } },
          { email: { like: `.*${filter.query}.*`, options: 'i' } }
        ]
      })
    }
    if (filter.confirm) {
      query.where.and.push({ confirm: true })
    }
    if (filter.confirmed === 'tak') {
      query.where.and.push({ confirmed: true })
    }
    if (filter.confirmed === 'nie') {
      query.where.and.push({ confirmed: { neq: true } })
    }
    if (filter.agreement3 === 'tak') {
      query.where.and.push({ agreement3: true })
    }
    if (filter.agreement3 === 'nie') {
      query.where.and.push({ agreement3: { neq: true } })
    }
    if (filter.status && filter.status !== 'placeholder') {
      query.where.and.push({ status: filter.status })
    }
    if (filter.partner !== 'placeholder') {
      query.where.and.push({ partner: filter.partner })
    }
    if (filter.codesVerification !== 'placeholder') {
      if (filter.codesVerification === 'niezweryfikowane') {
        query.where.and.push({ codesVerification: filter.codesVerification }, { codesVerification: { neq: { exists: true } } })
      } else {
        query.where.and.push({ codesVerification: filter.codesVerification })
      }
    }
    if (filter.codesVerificationComment === 'puste') {
      query.where.and.push({ codesVerificationComment: '' }, { codesVerificationComment: { neq: { exists: true } } })
    }
    if (filter.codesVerificationComment === 'uzupełniono') {
      query.where.and.push({ codesVerificationComment: { exists: true } }, { codesVerificationComment: { neq: '' } })
    }
    query = { ...query, limit: PAGE_COUNT, skip: next }

    if (filter.adspaces && filter.adspaces.length > 0) {
      const subquery = []
      let item
      for (item of filter.adspaces) {
        subquery.push(item.value)
        query.where.and.push({ 'adSpaces.categoryId': { inq: subquery } })
      }
    }
    if (filter.NSMAdSpaceCategories && filter.NSMAdSpaceCategories.length > 0) {
      for (const item of filter.NSMAdSpaceCategories) {
        query.where.and.push({ [`adSpaces.adSpaceData.${item.value}`]: { $in: [true, 'true'] } })
      }
    }
    if (filter.clients && filter.clients.length > 0) {
      const { clients } = filter
      const queryAdSpaceIds = `filter[where][advertiserId]=${clients[0].value}`
      fetch(`${API_SERVER}/db/api/relationships?${queryAdSpaceIds}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'GET'
      })
        .then(res => res.json())
        .catch((error) => {
          console.error('Error:', error)
        })
        .then((response) => {
          const subquery = []
          let item2
          for (item2 of response) {
            subquery.push(item2.adspaceId)
          }
          query.where.and.push({ 'adSpaces.id': { inq: subquery } })
        })
    }

    setTimeout(() => {
      fetch(`${API_SERVER}/db/api/partners?filter=${JSON.stringify(query)}`, {
        credentials: 'same-origin'
      }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then((response) => {
          onResponse(response || [])
        })
    }, 100)
  }

  onClickPrev () {
    this.setPage(this.props.filter.page - 1)
    setTimeout(() => {
      this.loadNext()
    }, 10)
  }

  onClickNext () {
    this.setPage(this.props.filter.page + 1)
    setTimeout(() => {
      this.loadNext()
    }, 10)
  }

  checkNavPages () {
    const data = this.props.partners
    const prevValue = this.props.filter.page <= 0
    const nextValue = data.length < 15
    this.setState(() => ({
      disabledPrevButton: prevValue,
      disabledNextButton: nextValue
    }))
  }

  onClickCheckbox (event) {
    const { id } = event.target
    const result = this.props.filter[id] = !this.props.filter[id]
    this.setCheckboxes(id, result)
    setTimeout(() => {
      this.loadNext()
    }, 10)
  }

  onInputChange (event) {
    this.setQuery(event.target.value)
    clearTimeout(this.queryTimer)
    this.queryTimer = setTimeout(() => {
      this.loadNext()
    }, 800)
  }

  renderSendEmailButton () {
    const data = this.props.partners ? this.props.partners : []
    if (data.length) {
      let emails = ''
      let elem
      for (elem of data) {
        emails += `${elem.email}; `
      }
      return (
        <a className='btn btn-primary' href={`mailto:?bcc=${emails}`}>Wyślij e-mail</a>
      )
    }
  }

  getCompany (data) {
    const value = []
    if (data.company) {
      value.push(<div key={`comp${data.company}`}>{data.company}</div>)
    }
    if (data.prename || data.name) {
      value.push(<div key={`nam${data.prename || data.name}`}>{data.prename || data.user_first_name} {data.name || data.last_name}</div>)
    }
    if (data.sm && (data.sm.company !== data.company)) {
      value.push(<div key={`cosm${data.sm.company}`}>{data.sm.company} (SM)</div>)
    }
    if (data.ns && (data.ns.company !== data.company)) {
      value.push(<div key={`cons${data.ns.company}`}>{data.ns.company} (NS)</div>)
    }
    if (data.it && (data.it.company !== data.company)) {
      value.push(<div key={`co${data.it.company}`}>{data.it.company} (IT)</div>)
    }
    if (data.payment_ns && data.payment_ns.company && (data.payment_ns.company !== data.company)) {
      value.push(<div key={`payns${data.payment_ns.company}`}>{data.payment_ns.company} (PNS)</div>)
    }
    if (data.payment_sm && data.payment_sm.company && (data.payment_sm.company !== data.company)) {
      value.push(<div key={`paysm${data.payment_sm.company}`}>{data.payment_sm.company} (PSM)</div>)
    }
    value.push(<div key={`em${data.email || data.user_email}`}>{data.email || data.user_email}</div>)
    return value
  }

  getID (data) {
    const value = []
    if (data.SMID) {
      value.push(<div key={`smid${data.SMID}`}>SM: {data.SMID}</div>)
    }
    if (data.NSID) {
      value.push(<div key={`nsid${data.NSID}`}>NS: {data.NSID}</div>)
    }
    if (data.ITID) {
      value.push(<div key={`itid${data.ITID}`}>IT: {data.ITID}</div>)
    }
    value.push(<div key={`el${data.id}`}><button type='button' className='btn btn-sm' style={{ border: '1px solid #eaeaea', padding: '2px 8px' }}>więcej</button></div>)
    return value
  }

  getStatus (data) {
    const value = []
    if (data.sm) {
      value.push(<div>SM: {data.sm.status}</div>)
    }
    if (data.ns) {
      value.push(<div>NS: {data.ns.status}</div>)
    }
    if (data.it) {
      value.push(<div>NS: {data.it.status}</div>)
    }
    return value
  }

  getWebsites (data) {
    const websites = data.websites || (Array.isArray(data.adSpaces) ? data.adSpaces.map(item => item.websiteUrl) : [])
    return websites ? websites.filter((item, pos, a) => a.indexOf(item) === pos).map(website => (<div key={`website${website}`} className='publishers-websites'>{website}</div>)) : ''
  }

  addGap (number) {
    if (number >= 10 && number < 100) {
      return `0${number}`
    } else if (number < 10) {
      return `00${number}`
    }
    return number
  }

  getProfit (profit) {
    if (profit > 1000) {
      return `${Math.floor(profit / 1000)} ${this.addGap(profit % 1000)}`
    }
    return profit
  }

  pickIcon (option) {
    const icons = { akceptacja: 'check', 'akceptacja warunkowa': 'info', odmowa: 'less', niezweryfikowane: 'help1' }
    return <i className={'pe-7s-' + icons[option] + ' pe-2x'} />
  }

  render () {
    const data = this.props.partners ? this.props.partners : []
    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <nav className='navbar navbar-light bg-light'>
                <div className='btn-group' role='group' aria-label='Basic example'>
                  <form className='form-inline my-2 my-lg-0' onSubmit={this.preventDefault}>
                    <button type='button' className='btn btn-secondary' onClick={this.onClickPrev} disabled={this.state.disabledPrevButton}>« poprzednia</button>
                    <button type='button' className='btn btn-secondary' onClick={this.onClickNext} disabled={this.state.disabledNextButton}>następna »</button>
                    <input type='text' id='query' className='form-control' placeholder='Szukaj...' value={this.props.filter.query} onChange={this.onInputChange} />
                    <button type='button' id='confirm' className={this.props.filter.confirm ? 'btn btn-primary' : 'btn btn-default'} onClick={this.onClickCheckbox}>Nowi</button>
                    {this.selectConfirmed()}
                    {this.selectAgreement3()}
                    {this.selectStatus()}
                    {this.selectPartners()}
                    {this.selectCodesVerification()}
                    {this.selectCodesVerificationComment()}
                    <Link to='/publisher/add' className='btn btn-success' role='button'>Dodaj wydawcę</Link>
                    {this.renderSendEmailButton()}
                    <div id='multiSelects' style={{ display: 'flex', marginBottom: '15px' }}>
                      {this.selectAdSpaceCategories()}
                      {this.selectClients()}
                      {this.selectNSMAdSpaceCategories()}
                    </div>
                  </form>
                </div>
              </nav>
            </Col>
            <Col md={12}>
              <Card
                title='Lista Wydawców'
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th style={{ width: '5%' }}>#</th>
                        <th style={{ width: '10%' }}>ID</th>
                        <th style={{ width: '30%' }}>Nazwa</th>
                        <th style={{ width: '25%' }}>Adres</th>
                        <th style={{ width: '5%' }}>Regulamin</th>
                        <th style={{ width: '5%' }}>Potwierdzony</th>
                        <th style={{ width: '10%' }}>Akceptacja kodów</th>
                        <th style={{ width: '10%' }}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      data.map((prop, key) => (
                        <tr key={key}>
                          <td>{this.props.filter.page * PAGE_COUNT + key + 1}</td>
                          <td
                            style={{ cursor: 'pointer' }} onClick={() => {
                              this.props.history.push({
                                pathname: `/publisher/${prop._id}`,
                                client: { data: prop }
                              })
                            }}
                          >{this.getID(prop)}
                          </td>
                          <td>{this.getCompany(prop)}</td>
                          <td>{this.getWebsites(prop)}</td>
                          <td style={{ textAlign: 'center' }}>{prop.agreement3 ? <i className='pe-7s-check pe-2x' /> : ''}</td>
                          <td style={{ textAlign: 'center' }}>{prop.confirmed ? <i className='pe-7s-check pe-2x' /> : ''}</td>
                          <td style={{ textAlign: 'center' }}>{prop.codesVerification ? this.pickIcon(prop.codesVerification) : ''}</td>
                          <td>{prop.status}</td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </Table>
                }
              />
            </Col>
            <Col md={12}>
              <nav className='navbar navbar-light bg-light'>
                <div className='btn-group' role='group' aria-label='Basic example'>
                  <form className='form-inline my-2 my-lg-0'>
                    <button type='button' className='btn btn-secondary' onClick={this.onClickPrev} disabled={this.state.disabledPrevButton}>poprzednia</button>
                    <button type='button' className='btn btn-secondary' onClick={this.onClickNext} disabled={this.state.disabledNextButton}>następna</button>
                  </form>
                </div>
              </nav>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    partners: state.partners.data,
    filter: state.partners.filter
  }
}

const mapDispatchToProps = {
  onRequest: id => ({
    type: 'REQUEST_CLIENT'
  }),
  onResponse: partners => ({
    type: 'SET_PARTNERS',
    partners
  }),
  setAgreement3: agreement3 => ({
    type: 'SET_AGREEMENT3',
    agreement3
  }),
  setPartner: partner => ({
    type: 'SET_PARTNER',
    partner
  }),
  setCodesVerification: codesVerification => ({
    type: 'SET_CODES_VERIFICATION',
    codesVerification
  }),
  setCodesVerificationComment: codesVerificationComment => ({
    type: 'SET_CODES_VERIFICATION_COMMENT',
    codesVerificationComment
  }),
  setConfirmed: confirmed => ({
    type: 'SET_CONFIRMED',
    confirmed
  }),
  setStatus: status => ({
    type: 'SET_STATUS',
    status
  }),
  setAdSpaces: adspaces => ({
    type: 'SET_ADSPACES',
    adspaces
  }),
  setNSMAdSpaceCategories: NSMAdSpaceCategories => ({
    type: 'SET_NSM_ADSPACE_CATEGORIES',
    NSMAdSpaceCategories
  }),
  setQuery: query => ({
    type: 'SET_QUERY',
    query
  }),
  setPage: page => ({
    type: 'SET_PAGE',
    page
  }),
  setCheckboxes: (id, checkbox) => ({
    type: 'SET_CHECKBOXES',
    id,
    checkbox
  }),
  setClients: clients => ({
    type: 'SET_CLIENTS',
    clients
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(TableList)
