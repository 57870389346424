export function JSON_to_URLEncoded (element, key, list) {
  list = list || []
  if (typeof (element) === 'object') {
    let idx
    for (idx in element) {
      this.JSON_to_URLEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list)
    }
  } else {
    list.push(key + '=' + encodeURIComponent(element))
  }
  return list.join('&')
};

export default { JSON_to_URLEncoded }
