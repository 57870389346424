module.exports = {
  2: 'Wonga PL',
  6: 'Test PL',
  16: 'Wolna - Testy PL',
  42: 'RWE PL',
  116: 'nc+ PL',
  118: '(Tracking) Autopromocja PL',
  232: 'NetSales - Wydawcy PL',
  373: '4Life Direct - Bezterminowe Ubezpieczenie',
  435: 'NN Investment Partners TFI PL',
  759: 'Peugeot Warszawa i okolice',
  914: 'BlaBlaCar PL',
  940: 'Home Broker CPL PL',
  978: 'Raiffeisen Polbank - konto + lokata PL',
  988: 'Jedenwniosek.pl PL',
  1000: 'Danone Actimel - Ruch Motywowany',
  1006: 'Goodyear - 4 za 3 PL',
  1132: 'zarobki.pracuj.pl CPL',
  1190: 'Audioteka CZ',
  1250: '19.Dzielnica. PL',
  1326: 'ERGO Hestia - You Can Drive | Kwotacja CPL',
  1376: 'Kekuko MX',
  1422: 'Jeep – jazda próbna PL',
  1442: 'Promedica24 - opiekunki PL',
  1448: 'Credy szybka pożyczka online - CPL',
  1532: 'Gotówka Natychmiast - CPL',
  1534: 'Ekredycik - CPL',
  1576: 'mtu24.pl – ubezpieczenia komunikacyjne PL',
  1600: 'Netia Home CPL',
  1683: 'Inea - Internet 50% taniej CPL',
  1741: 'HiShine kupon na pierwsze pranie PL',
  1763: 'Skarbiec.pl - TFI',
  1935: '(MS) Telezakupy Mango CPO',
  1951: 'L\'Oreal - Casting Creme Gloss ',
  2043: 'Pizza Hut - Festiwal Pizzy',
  2053: '(MS) L ff = cpc',
  2079: 'Partners GG',
  2163: 'Comeon GG 2',
  2237: 'Orange CPO + CPL',
  2277: 'Kredytok.pl CPL+CPS',
  2296: 'Monedo Now – szybka pożyczka online CPS',
  2302: 'za-kontaktowani',
  2308: 'Opel artykuły',
  2314: 'Santander lokata RTB - CPL',
  2318: 'RKantor – kantor walutowy online CPA',
  2398: 'Domodi CPA',
  2414: 'Miła Ratka CPL+CPS',
  2452: 'Volkswagen CPL ',
  2476: 'F-Trust Fundusze Inwestycyjne',
  2508: 'Kratki Criteo',
  2528: 'Fiat CPL',
  2542: 'Kratki artykuły',
  2562: '4gift.pl / bralbym.to',
  2564: 'mygiftdna.pl / bralbym.to',
  2574: 'takapaka.pl / bralbym.to',
  2578: 'fabrykaform.pl / bralbym.to',
  2580: 'woolet.co / bralbym.to',
  2586: '(GG) Kratki artykuły',
  2590: 'Agata Meble sierpień',
  2604: 'Orange Finanse CPA',
  2614: 'Jeep CPL',
  2616: 'Emirates CPM',
  2620: 'Skoda ASO',
  2628: 'T-Mobile CPL - OFERTA RODZINNA',
  2642: 'INNOGY Miło Cię widzieć Wrzesień CPC',
  2644: 'INNOGY Miło Cię widzieć Wrzesień CPM',
  2650: 'Kratki Edipresse',
  2676: '(MS) Expander CPL',
  2694: 'Orange Światłowody',
  2696: 'Manutan RTB',
  2702: 'SmartTel',
  2726: 'Officeshoes Ceneo',
  2733: 'Renault Megane Grandtour',
  2745: 'InviMed CPC',
  2755: 'Telepizza Halloween',
  2757: 'PEKAO UCL CPC',
  2761: 'Pandora CPO',
  2763: 'Medicover Good For U – CPL',
  2767: 'Akredo kredyt CPL',
  2769: 'Akredo pożyczka CPL',
  2771: 'Gino Rossi CPS ',
  2773: 'Rossmann Edipresse',
  2781: 'Denley.pl CPC',
  2785: 'innogy fotowoltaika',
  2787: 'Nestbank – kredyt firmowy',
  2793: 'Orange CPI Andoid',
  2795: 'Orange CPI iOS',
  2797: 'Enel Med. CPL',
  2799: 'Everydayme CPL',
  2807: 'PLUSH CPC GG',
  2815: 'MasterCard + Google Play mobile',
  2817: 'Manutan',
  2841: 'Żywiec nie lada degustacja',
  2847: 'Nivea Grudzień 2016 SUC',
  2849: 'Provident pożyczka samoobsługowa ',
  2855: 'World of Tanks CPA',
  2857: 'INNOGY – gniazdka wifi',
  2867: 'P&G Edipresse',
  2871: '(SM) NJU Mobile ',
  2873: 'Pracuj.pl SUC',
  2877: 'Wawel CPA ',
  2883: 'eMAG.pl',
  2889: 'EY CPC ',
  2891: 'Redcoon CPM',
  2893: 'Promedica24 - TradeTracker',
  2895: 'Frisco – Masterpass CPL',
  2897: 'iPhone 7',
  2899: 'Renault Dacia Sandero - Moto i Sport',
  2901: 'Renault Dacia Sandero - Dom i ogród',
  2903: 'Play Loteria',
  2905: 'SuperGrosz 2017 CPA',
  2907: 'UniLokata CPL',
  2909: 'Pracuj.pl handlowcy SUC',
  2913: 'SuperGrosz 2017 CPC',
  2915: 'Credilo CPS',
  2917: 'Dom Development CPC',
  2919: 'Netia Mobile CPL 2016',
  2921: 'Provident CPC',
  2923: '(SM) Sexshop112.pl',
  2925: '(SM) Złotewyprzedaże.pl CPS',
  2927: 'Filarum CPC',
  2929: 'One System',
  2931: 'Answear CPO - mailing',
  2933: 'Słodowiec City mailing ',
  2935: 'Citroen C3 ',
  2937: '(SM) Ravelo.pl',
  2939: ' LSP CPC',
  2941: '(SM) W.KRUK',
  2943: 'SOWA FINANSOWA 2016',
  2945: 'Manutan mailing CPC',
  2947: 'SuperCredit CPS',
  2949: 'Kratki Walentynki Biokominki CPC',
  2951: 'Fortuna GG',
  2953: 'MUMU Bags',
  2957: ' (SM) Smyk – CPS',
  2959: 'mBank konto z księgowością ',
  2961: 'Microsoft Office CPC',
  2963: 'Polcard Terminale',
  2969: 'Tchibo B2B CPL',
  2973: 'Ubezpieczenia online CPL',
  2975: '(SM) Wólczanka',
  2977: 'Renault wyprzedaż SUC',
  2979: 'Kratki Podstawa CPC',
  2981: 'PIPERINE CPC',
  2983: 'Kratki Ekoprojekt',
  2985: '(SM) eButik - CPS',
  2987: 'mBank konto indywidualne ',
  2989: 'Kratki Piece CPC',
  2993: 'Peugeot B2C M35+/ moto additional_promo',
  2995: 'LendON CPC',
  2997: '(SM) PizzaPortal',
  2999: '(SM) Smyk.com CPM',
  3001: '(SM) Vistula',
  3003: 'mbank marcowe CPC',
  3005: 'Nivea Club luty 2017',
  3007: 'Orange pre-paid CPL',
  3009: 'Oral B ME 2017',
  3011: '(SM) TOUS',
  3013: '(SM) Kakadu',
  3015: '(SM) Telekarma',
  3017: 'Westwing CPL',
  3019: 'Viper Print - CPC',
  3021: 'Obi mailing',
  3023: 'Nest Bank konto osobiste',
  3025: 'Obi Leroy',
  3027: 'Oral B MM 2017',
  3029: 'Kratki Dzień Kobiet CPC',
  3031: 'Apartamenty Marymont CPC',
  3037: 'eobuwie.pl CPC',
  3039: '(SM) 5-10-15',
  3041: 'Pampers mailing CPC II 2017 ',
  3057: 'Volkswagen CPL marzec',
  3067: '(SM) Chocolissimo',
  3069: 'Smaczna Herbata CPC',
  3071: 'Smyk CPC i CPM',
  3077: 'Officeshoes',
  3081: 'Toyota Czechy CPC',
  3083: 'Żabka Pet Food CPC',
  3085: 'Velvet Loteria',
  3087: 'Peugeot XII Wyprzedaż rocznika styczeń/luty 2017 b2c',
  3089: 'Peugeot XII Wyprzedaż rocznika styczeń/luty 2017 moto',
  3091: 'Alior konsożyczka CPC',
  3093: 'Medicover Good For U – CPS',
  3095: 'Renault LCV - pakiet biznes ',
  3097: 'ezebra edipresse',
  3099: 'Dacia Outdoor SUC',
  3101: 'innogy fotowoltaika mailing',
  3103: 'Showroomprive CPL',
  3105: 'MK Cafe CPC',
  3107: 'Polsat History',
  3109: 'Eurobank CPC',
  3111: 'Speak UP jutro',
  3113: '(SM) Decathlon',
  3115: 'Philip Morris CPC',
  3117: 'Obi display',
  3119: 'Tchibo_B2B_Gastro',
  3121: 'Pregna parenting',
  3123: 'Samsung Newsletter ',
  3125: 'Centrum Nauki Kopernik mailing CPC',
  3127: 'Credit Agricole kampania lokalna',
  3129: 'Peugeot Serwis CPC ',
  3131: 'Kratki budujemydom',
  3133: 'Renault True Stories',
  3135: 'Credit Agricole rob_to_co_kochasz',
  3137: 'Plus CPC',
  3139: 'Renault Business Days SUC',
  3141: 'DPD 2017 CPL',
  3143: '(SM) T-Mobile',
  3145: 'Nivea podwórko',
  3147: 'Magne B6',
  3149: 'Mercedes mailing',
  3151: 'Webinar CPL',
  3153: 'Global Science - Haluksy',
  3155: 'Indykpol CPL',
  3157: 'Hankook CPC moto',
  3159: 'Mazda CX 3 cpc',
  3161: 'Caseo CPC mailing',
  3163: 'Microsoft mailing',
  3165: 'Red Bull Tancerze mailing CPC',
  3167: 'Pracuj Kwiecień 2017',
  3169: 'ProFigure – plany dietetyczne',
  3171: 'Bulwary Książęce BPi CPC',
  3173: 'Kratki KB Kwiecień',
  3175: 'Dstreet GG CPC',
  3177: 'XL Energy Drink Loteria CPC',
  3179: 'XL Energy Drink Loteria CPM I FF',
  3181: 'XL Energy Drink Loteria CPA',
  3183: 'NISSAN XO kwiecień pakiet motoryzacja',
  3185: 'NISSAN XO kwiecień pakiet mężczyzna ',
  3187: 'OFFTEAM PALLADIUM IV CPC',
  3189: 'Rankomat CPC',
  3191: 'Marvipol CPC kwiecień',
  3193: 'Żywiec Zdrój Planting',
  3195: 'Bezokularow CPC',
  3197: 'Credit Agricole - Tygodnik Podhalański',
  3199: 'eobuwie CPC – Czechy',
  3201: 'eobuwie CPC – Węgry',
  3203: 'Plush Abo',
  3205: 'Essentiale',
  3207: 'eobuwie CPC – Słowacja',
  3209: 'Aviva – juniorGo CPL',
  3211: 'Credit Agricole - Bochnianin',
  3213: 'AASA dla Biznesu CPA',
  3215: 'Audi – oferta CPL',
  3217: 'Renault Clio SUC',
  3219: 'McDonald Kwiecień 2017',
  3221: 'Alior pożyczki CPC II',
  3223: 'PAH Polska Akcja Humanitarna CPC',
  3225: 'Polsat Viasat Nature',
  3227: 'Credit Agricole - Info Stargard',
  3229: 'T-mobile B2B',
  3231: 'Medicover Good For U – CPS',
  3233: 'Plush na kartę GG II',
  3235: 'Peugeot 2008 perfect drive ',
  3237: 'McDonald\'s praca CPL',
  3239: 'Peugeot 2008 test drive',
  3241: 'Śnieżka otwarcie sklepu CPC',
  3243: 'Salvum Odszkodowania – CPL',
  3245: 'Śnieżka satynowa CPC',
  3247: 'Orange Love Biznes',
  3249: 'Microsoft Webinar CPC',
  3251: 'Speak Up – konkurs',
  3253: 'Oral – B maj',
  3255: 'GSK Rotawirusy CPV',
  3257: 'Nationale Nederlanden życie',
  3259: 'Babyliss porównywarki',
  3261: 'UBEA - CPA',
  3263: 'Oral-B KIDS',
  3265: 'McDonalds Kupony Maj 2017',
  3267: 'Marvipol Riviera CPC',
  3269: 'Totolotek CPC',
  3271: 'Citroen C -elysee',
  3273: 'Credit Agricole Afilicja CPS',
  3275: 'Credit Agricole – eBełchatów',
  3277: 'Orange Canal+',
  3279: 'Magne b6 czerwiec SUC',
  3281: 'Chocolissimo mailing CPC',
  3283: 'MSFT WEB maj',
  3285: 'OralB CPC',
  3287: 'Dacia Picnic 2017 Moto',
  3289: 'Dacia Picnic 2017 Parenting',
  3291: ' Renault Kadjar 2017 ',
  3293: 'innogy_fotowoltaika_2017',
  3295: 'Renault Koleos maj 2017 ',
  3297: 'Nissan XO maj',
  3299: 'Green Magma Suplementy CPL',
  3301: 'Lake Park CPC',
  3303: 'Rankomat ubezpieczenie turystyczne',
  3305: 'Pampers czerwiec',
  3309: 'Inea + Play multisim',
  3311: 'Żabka cashback',
  3313: 'Żabka cashback lifestyle',
  3315: '(SM) RTV Euro AGD',
  3319: 'Chwilówkomat cpc',
  3321: 'Nationale Nederlanden Strażnik przyszłości 2017 CPL',
  3323: 'Marvipol CPC czerwiec',
  3325: 'CEWE FOTOKSIĄŻKA CPL',
  3327: 'Euroloan CPL',
  3329: 'Samsung CPL czerwiec',
  3331: 'MOJE INNOGY CPC',
  3333: 'T-mobile prepaid 20 GB Internetu za darmo',
  3335: 'Finansowyplaner.pl',
  3337: 'Nivea Men after shave 2017',
  3339: '(SM) Pizza Hut',
  3341: 'Mailing ING czerwiec',
  3343: 'Wonga mailing remonty',
  3345: 'Wonga mailing wakacje',
  3347: 'Vidaron CPC',
  3349: 'Nivea Club Wyzwanie 2017',
  3351: '(SM) Auchan Direct',
  3353: 'Renault Master 2017',
  3355: 'Intermarche CPC',
  3357: 'W.KRUK mailing',
  3359: 'Lexus CPC',
  3361: 'Neonet wyprzedaż czerwiec',
  3363: 'Loteria Żywiec CPL',
  3365: 'Fotowoltaika – program rekomendacyjny',
  3367: 'Lech Summer 2017 Konkurs',
  3369: 'BIBBY CPL',
  3371: 'Plush GG',
  3373: 'Nivea Podwórka głosowanie',
  3375: 'Virtualo Programmatic',
  3377: 'Everydayme CPC czerwiec 17',
  3379: 'Knorr przepisy.pl CPL mailing',
  3381: 'Coca Cola',
  3383: 'Nissan XO lipiec SUC',
  3385: 'Alior Trader',
  3387: 'Pampers CPL',
  3389: 'Fotojoker',
  3391: 'mfind – ubezpieczenia oc/ac',
  3393: 'Takto Finanse',
  3395: 'Coca Cola General CPL',
  3397: 'KRD / NFG Faktoring quality visitor',
  3399: 'Nivea creme Foto',
  3401: 'InviMed – Programmatic',
  3403: 'Orange CPL',
  3405: 'Aviva HH',
  3407: 'Wolska2',
  3409: 'McDonalds Kupony lipiec 2017',
  3411: 'Alior Pożyczka Internetowa',
  3413: 'Niewielkapozyczka CPS',
  3415: 'Alior doradztwo inwestycyjne',
  3417: 'Mitsubishi ASX',
  3419: 'NIVEA foto CPL',
  3421: 'Pekao Kantor CPC',
  3423: 'McDonalds Kupony lipiec II',
  3425: 'Fotojoker - Fuji',
  3427: 'Green Magma Suplementy CPL 2',
  3429: 'GSK Oliatum',
  3431: 'Marvipol CPC lipiec',
  3433: 'Agata meble zainteresowania',
  3435: 'Agata meble behavioral',
  3437: 'CPS mFind ubezpieczenia oc/ac',
  3439: 'CPL mFind ubezpieczenia oc/ac',
  3441: 'Multihome',
  3443: 'Alior pożyczka dla dwojga',
  3445: 'Zencard CPM',
  3447: 'Castorama CPC',
  3449: 'Natural CPA',
  3451: 'Allegro Ogród',
  3453: 'BabySafe CPL mailing',
  3455: 'Żywiec Męskie Granie',
  3457: 'T-Mobile HTC U11',
  3459: 'Time for Wax',
  3461: 'Garnier DEO konkurs',
  3463: 'Aviva Koło za Kołem',
  3465: 'InviMed FF',
  3467: 'TransferGo mailing CPC',
  3469: 'Nissan QQ sierpień',
  3471: 'Marvipol CPC sierpień',
  3472: 'Mcdonalds kupony sierpień',
  3474: ' 3M żużel',
  3476: 'Speak UP Tracking',
  3478: 'Kratki_mailing',
  3480: 'Obi gazetka sierpień CPC ',
  3482: 'Ratanaplus.pl CPS',
  3484: 'Królewskie Niefiltrowane',
  3486: 'Bioderma',
  3488: 'Halopożyczka CPS',
  3490: 'Alegotówka CPC',
  3492: 'Renault Kredyt',
  3494: 'Castorama CPC',
  3496: 'McDonalds Kupony sierpień II',
  3498: ' Everydayme WĘGRY',
  3500: 'Golden Line CPC',
  3502: 'EverydayMe Darmowe Próbki CPL',
  3504: 'Pizza Hut Kids Eat For Free CPL',
  3506: 'Gratka Dom CPA',
  3508: 'Gratka moto CPA',
  3510: 'IdeaBank',
  3512: 'Nissan QQ wrzesień',
  3514: 'Microsoft Webinar Dynamics Mailing',
  3516: 'Studia MBA mailing',
  3518: 'BMW CPL',
  3520: 'Orange Love i trzy smartfony CPC',
  3522: 'INNOGY - MOC I POMOC',
  3524: 'Salvum',
  3526: 'INNOGY SME',
  3528: 'Pekao SME mailing',
  3530: 'Renault Koleos wrzesień',
  3532: 'Inea CPC mailing wrzesień',
  3534: 'Gra Crossout CPA',
  3538: 'Intel praca CPC',
  3540: 'LabData',
  3542: 'McDonalds Kupony wrzesień',
  3544: 'Natural CPC',
  3546: 'Finiata Faktoring',
  3548: 'Smaczna Herbata wrzesień CPC',
  3550: 'KOLSKA OD NOWA CPC',
  3552: 'APARTAMENTY MARYMONT',
  3554: 'Cyfrowy Polsat CPC',
  3556: 'ESKK – kursy online CPC',
  3558: 'Kratki_ZPR',
  3560: 'Porsche jazdy próbne',
  3562: 'Dolce Gusto CPC',
  3564: 'Marvipol wrzesień CPC',
  3566: 'Pekao Weterynarze CPC',
  3568: 'HPE webinar wrzesień',
  3570: 'Eurobank - wrzesień mailing',
  3572: 'Credit Agricole – Leasing',
  3574: 'Showmax',
  3576: 'Weterynarze Pekao CPC',
  3578: 'L\'Oreal_Akademia Salon Expert',
  3580: 'Rondo Wiatraczna',
  3582: 'Provident Criteo',
  3584: '3M mailing CPM',
  3588: 'Soczewki Essilor CPC zainteresowania',
  3590: 'Soczewki Essilor CPC słowa kluczowe',
  3592: 'Optegra mailing',
  3594: 'Kanlux lampy CPC',
  3596: 'Osiedle na górnej',
  3598: 'PLUS CPL',
  3600: 'Vectra CPL',
  3602: 'Fun With Mum',
  3604: 'Masha Bear behavioral CPC',
  3606: 'Masha Bear parenting CPC',
  3608: 'PremiumMobile CPC',
  3610: 'Essilor_mailing CPC',
  3612: 'Nissan X Trail SUC',
  3614: 'Nivea Deo',
  3616: 'Moje Innogy 4Q',
  3618: 'Bosch bezpieczeństwo',
  3620: 'Marvipol październik CPC',
  3622: 'Nivea pacynki',
  3626: 'ampdepilacja',
  3628: 'Fotowoltaika WAW Październik CPC',
  3630: 'Netia CPC',
  3632: 'Miasto Wola',
  3634: 'Allegro moda październik/listopad',
  3636: 'SuperGrosz - CPS',
  3638: 'Premium Mobile Mailing',
  3640: 'NETIA STANDARD CPL',
  3642: 'Pozyczkaplus.pl CPS',
  3644: 'Smartpozyczka.pl CPS',
  3646: 'Inea mailing październik konkurencja',
  3648: 'mBank Stała Stopa',
  3650: 'Intermarche CPD',
  3652: 'Bioderma Jesień',
  3654: 'Renault Megane Biznes',
  3656: 'Agata Rumia październik',
  3658: 'Exxon Mobile',
  3660: 'Super Grosz - CPC',
  3662: 'Cyfrowy Polsat mailing',
  3664: 'mBank Stała Stopa display CPC',
  3668: 'Helpfind CPL',
  3670: 'Rekrutacja Philip Morris',
  3672: 'Alior msi',
  3674: 'Alior msb',
  3676: 'Volkswagen Użytkowe CPL',
  3678: 'Babyliss Curl Secret porównywarki',
  3680: 'mBank stała stopa display październik',
  3682: 'Babyliss Curl Secret VOD',
  3684: 'mBank Affluenci',
  3686: 'Provident doradcy 2017 mailing',
  3688: 'Hankook 2017',
  3690: 'Kratki - kalkulatory budowlane CPC',
  3692: 'Peugeot wyprzedaż listopad/grudzień',
  3694: 'Vienna Life',
  3696: 'Egmont mailing CPC+CPS',
  3698: 'Fit and Easy Konkurs',
  3700: 'Ucando Mailing CPC',
  3702: 'Toyota CPC',
  3704: 'Lexus CPC Luty',
  3706: '3M Maska Aura SMOG',
  3712: 'Alior listopad CPC',
  3714: 'Alior pożyczka elastyczna CPC',
  3718: 'Nissan Crossover',
  3720: 'Samsung Newsletter CPL',
  3722: 'Work Service CPC',
  3724: 'BZW BK konto firmowe CPS',
  3726: 'Toyota Sprint Lokata',
  3728: 'Work Service zagranica CPC',
  3730: 'Łowicz Konkurs',
  3732: 'Babyliss Digital Sensor VOD',
  3734: 'Indesit pralka CPC',
  3736: 'Mercedes Truck CPC',
  3738: 'ORAL-B Media Markt Black Friday',
  3740: 'Gennet mailing',
  3742: 'Play eshop UV',
  3744: 'Lion’s Bank Private',
  3746: 'Bosch akumulatory',
  3748: 'Pampers Czechy CPL',
  3750: 'Pampers Czechy CPC',
  3752: 'Cetaphil Display konkurs',
  3754: 'Łatwykredyt.pl CPC',
  3756: 'Wolska Kamienica',
  3758: 'Inter Medicus',
  3760: 'Tchibo CPL Vista',
  3762: 'Voltaren CPC',
  3764: 'LG G6',
  3766: 'Przewodnik ubezpieczeniowy życie',
  3768: 'GP Baterie CPC',
  3770: 'Lions Bank Edukacja',
  3772: 'Rzeczpospolita display',
  3774: 'Parkiet mailing',
  3776: 'Ferratum Plusloan',
  3778: 'Ferratum Microloan',
  3780: 'Monevia mailing',
  3782: 'Alior pożyczka na Święta',
  3784: 'Shell karta paliwowa',
  3786: 'NIVEA XMASS KARTKI',
  3788: 'Answear CPC mailing',
  3790: 'dstreet.pl',
  3792: 'Nivea Real Madryt',
  3794: 'Prezentownik Blaber',
  3796: 'Credit Agricole Ukraina',
  3798: 'Drogerie Natura Mailing',
  3802: 'Grupa BSP',
  3804: 'Time&More Mailing CPC',
  3806: 'Nissan CO Wyprzedaż',
  3808: 'Pampers Węgry CPC',
  3810: 'Pampers Węgry CPL',
  3812: 'K2 CPC',
  3814: 'Epic Drama banner',
  3816: 'Epic Drama Video',
  3818: 'Marvipol CPC 2018',
  3820: 'D3 CPC',
  3822: 'Pampers active fit',
  3824: 'Ubezpieczenie narciarzy',
  3826: 'Budlex',
  3828: 'LendON styczeń 2018',
  3830: 'Nissan wyprzedaż II',
  3832: 'Perfumesco CPC styczeń 2018',
  3834: 'Opel wyprzedaż 2017 CPC',
  3836: 'WSIP megapack empikschool profi-lingua profimatura',
  3840: 'Volkswagen wyprzedaż 2017',
  3842: 'Ford – zimowy przegląd 2018 mailing',
  3844: 'Netia Mobile CPL',
  3846: 'PLAY cpc styczeń 2018',
  3848: 'BMW wyprzedaż',
  3850: 'Avon mailing',
  3852: 'JustDrive CPI',
  3854: 'Electrolux CPC',
  3858: 'Knorr Phoenix ',
  3860: 'Polycom',
  3862: 'Nissan Wyprzedaż luty 2018',
  3864: 'Klasyków CPC',
  3866: 'Omega Podejmij wyzwanie',
  3868: 'Homeit',
  3870: 'Alior Konsolidacja',
  3872: 'Ford Jazdy Próbne - wyprzedaż rocznika CPL',
  3874: 'LoanMe CPL',
  3876: 'Ford Jazdy Próbne - wyprzedaż rocznika CPC',
  3878: 'K2 roczna',
  3880: 'CA – Kredyt Gotówkowy 3x0%',
  3882: 'Zott Montewator CPC',
  3884: 'Nissan QQ',
  3886: 'Pracuj.pl targi pracy Kraków',
  3888: 'Penirium',
  3890: 'Sliminazer',
  3892: 'Artroser',
  3894: 'Provident CPC ShortForm ',
  3896: 'IdeaMoney Faktoring CPC',
  3898: 'INNOGY Inteligentne Ogrzewanie',
  3900: 'SAMSUNG STAR',
  3902: 'Nazwa.pl CPC',
  3904: 'Extraportfel 2018',
  3906: 'Volvo XC40',
  3908: 'mBank = KORPO Z WOŚP mailing',
  3910: 'mBank = KORPO Z WOŚP display CPC',
  3912: 'T-Mobile CPC luty ',
  3914: 'Mercedes Sosnowiec - dostawcze CPC',
  3916: 'Triverna CPC luty',
  3918: 'Travelist CPL',
  3920: 'Eurobank Marzec',
  3922: ' MOTO 2018 CPC',
  3924: 'Allegro moda marzec CPC',
  3926: 'BIK CPL',
  3928: 'Expacto',
  3930: 'Finiata CPC',
  3932: 'Ford Motorcraft - autoryzowany serwis',
  3934: 'Fotojoker CEWE Fotoksiążka CPC',
  3936: 'Triverna CPC - Marzec - Majowka',
  3940: 'BIG CPL',
  3942: 'Plus ABO CPC',
  3944: 'Alior Bank pożyczka we dwoje',
  3948: 'Powercum',
  3950: 'LashParade',
  3952: 'Dominium CPC Płock',
  3954: 'Mailing Fortuna CPC',
  3956: 'Play SamsungA5 CPC',
  3958: 'Knorr Display',
  3960: 'Konkurs Akademia Smaku',
  3962: 'Estee Lauder Mailing CPC',
  3964: 'Plush ABO CPC',
  3966: ' Ford Motorcraft - autoryzowany serwis CPC',
  3968: 'Peugeot SUV',
  3970: 'Tax Care CPL',
  3972: 'Hankook 2018',
  3974: 'Jaguar mailing',
  3976: 'Leroy Merlin Oferia',
  3978: 'Alior Bank Konto Osobiste',
  3982: 'BGŻ BNP Paribas Konto',
  3984: ' Eurostyl Mailing Gdynia',
  3988: 'Alcon AirOptix CPL',
  3990: 'Archicom CPC',
  3992: 'Porsche Panamera Hybryda',
  3994: 'Porsche Driving Experience',
  3996: 'Żywiec Ustaw sobie Ż',
  3998: 'Growultra - suplementy diety',
  4002: 'Bridgestone mailing',
  4004: 'Cafardini CPL',
  4006: 'Orange Bonus 100zł ',
  4008: 'Strabag CPC',
  4010: 'Podejmij wyzwanie na wiosnę z Natural',
  4012: 'Raiffeisen Konkurs JBL',
  4014: 'Nazwa.pl Domeny Kwiecień CPC CPO',
  4016: 'IDEA BANK KREDYT',
  4018: 'Fuso Center mailing',
  4020: 'IDEA BANK KONTO FIRMOWE',
  4022: 'Lion\'s Garage_mailing',
  4024: 'Fiat Professional CPC',
  4026: 'Triverna CPC - Kwiecien- Majowka',
  4028: 'Bioderma mailing kwiecień',
  4030: 'MyBosch zapis CPL',
  4032: 'Tryumf CPC',
  4034: 'Prostaxin CPC',
  4036: 'Blikomania Konkurs CPL',
  4038: 'Desperados Loteria',
  4040: 'Triverna CPC - Kwiecien- Wakacje',
  4042: 'INNOGY RAPORT B2B 2017 CPC',
  4044: 'WIZERUNEK PREWENCYJNIE CPC',
  4046: 'Eurobank Kredyt Online CPS',
  4048: 'Marvipol Unique Apartments CPC',
  4050: 'Wrocławskie Hospicjum CPC',
  4054: 'P&G Węgry CPC',
  4056: 'Land Rover mailing CPC',
  4058: 'Play mailing CPC',
  4060: 'Fotowoltaika 2018 raty',
  4062: 'Fiat (maj 2018) CPC',
  4064: 'Prudential (maj 2018)',
  4066: 'Fiat Fullback (maj 2018) CPC',
  4068: 'Runmageddon mailing CPC',
  4072: 'Knorr Przepisy.pl CPL DISPLAY ',
  4074: 'mBank kredyt gotówkowy',
  4076: 'Jeep Combo',
  4078: 'Mercedes MBT CPC',
  4080: 'Tous Czechy',
  4082: 'PerfectGym',
  4084: 'Disney English CPC',
  4086: 'Alior Kantor Walutowy',
  4088: 'Alior Bank Konto Monco Oszczędnościowe CPC',
  4090: 'Polska Bezgotówkowa',
  4094: 'NIVEA podwórka 2018',
  4096: ' HBO GO',
  4098: 'mBank konto dla młodych czerwiec CPC',
  4100: 'Citi Bank CPC',
  '﻿3800': 'Provident Pożyczka CPL + CPS'
}
