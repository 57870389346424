import React, { Component } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import { connect } from 'react-redux'

import JSZip from 'jszip'
import saveAs from 'file-saver'
import request from 'request'

const API_SERVER = 'https://super.cubegroup.pl'

const PRIVACY_POLICY_DESC_LONG = 'Cube Group SA, ul. Puławska 99A, 02-595 Warszawa, Sąd Rejonowy w Warszawie, XIII Wydział Gospodarczy KRS 0000306712, NIP 661-225-95-31, Kapitał zakładowy 775 415,70 zł.<br />Dane osobowe zawarte w korespondencji elektronicznej są przetwarzane przez Cube Group SA, ul.Puławska 99A, 02 - 595 Warszawa(Administrator) w celu umożliwienia kontaktu e - mailowego z Administratorem oraz w celu podejmowania kontaktu z adresatami, dokumentowania ustaleń dokonywanych z klientami, kontrahentami, innymi osobami, przyjmowania pism, zgłoszeń i wniosków oraz dochodzenia ewentualnych roszczeń.Więcej informacji w polityce prywatności https://cubegroup.pl/polityka-prywatnosci/. Ta wiadomość zawiera informacje, które mogą być w całości lub części poufne i / lub objęte ochroną prawną i / lub tajemnicą służbową.Jeżeli wiadomość niniejsza została dostarczona przez pomyłkę, proszę zawiadomić nadawcę, wysyłając odpowiedź na tę wiadomość.Jeśli nie są Państwo zamierzonym i / lub właściwym odbiorcą niniejszej wiadomości to nie mogą jej Państwo używać, ujawniać, rozpowszechniać, kopiować, drukować ani korzystać z niej.<br />This e - mail may contain confidential and / or privileged information.If you are not the intended(or have received this e - mail in error) please notify the sender immediately and destroy this e - mail.Any unauthorized copying, disclosure or distribution of the material in this e - mail is strictly forbidden.'

const PRIVACY_POLICY_DESC_SHORT = 'Dane osobowe zawarte w korespondencji są przetwarzane przez Cube Group SA zgodnie&nbsp;z&nbsp;<a target="_blank" href="https://cubegroup.pl/polityka-prywatnosci/" rel="noopener noreferrer">https://cubegroup.pl/polityka-prywatnosci/</a>'

const wholeSig = {
  padding: 0,
  margin: 0,
  backgroundColor: '#ffffff'
}
const fp = {
  marginBottom: 0,
  marginTop: '10px',
  fontSize: '18px',
  color: 'rgb(0,0,0)',
  fontFamily: 'Tahoma, Calibri, Verdana, Arial',
  fontWeight: 'bold'
}
const fpPerf = {
  margin: 0,
  fontSize: '18px',
  lineHeight: '33px',
  color: 'rgb(0,0,0)',
  fontFamily: 'Verdana, Tahoma, Calibri, Arial',
  fontWeight: 'bold'
}
const sps = {
  marginTop: 0,
  marginBottom: '5px',
  letterSpacing: '1px',
  color: 'rgb(0,0,0)',
  textTransform: 'uppercase',
  fontFamily: 'Calibri, Verdana, Arial',
  fontSize: '12px'
}
const spsPerf = {
  margin: 0,
  color: 'rgb(0,0,0)',
  textTransform: 'uppercase',
  fontFamily: 'Verdana, Calibri, Arial',
  fontSize: '12px',
  lineHeight: '20px'
}
const cgLogoS = { paddingTop: '10px', paddingBottom: '10px' }
const logaS = { veriticalAlign: 'middle' }
const styleBottomInfo = {
  paddingTop: '10px',
  fontSize: '9px',
  letterSpacing: '0px',
  lineHeight: '200%',
  color: '#81868a',
  fontFamily: 'Calibri, Verdana, Arial',
  textAlign: 'left'
}
const styleSmallInfo = {
  marginTop: '5px',
  marginBottom: '5px',
  fontFamily: 'Calibri, Verdana, Arial',
  fontSize: '14px',
  color: '#81868a',
  textDecoration: 'none',
  lineHeight: 1.4,
  display: 'inline-block',
  marginRight: '5px',
  whiteSpace: 'nowrap'
}
const styleSmallInfoPerf = {
  fontFamily: 'Verdana, Calibri, Arial',
  fontSize: '14px',
  color: '#000000',
  textDecoration: 'none',
  lineHeight: '18px',
  display: 'inline-block',
  margin: 0,
  whiteSpace: 'nowrap'
}

const styleSmallInfoA = {
  fontFamily: 'Calibri, Verdana, Arial',
  fontSize: '14px',
  color: '#81868a',
  textDecoration: 'none',
  lineHeight: 1.4,
  whiteSpace: 'nowrap'
}
const styleSmallInfoAPerf = {
  fontFamily: 'Calibri, Verdana, Arial',
  fontSize: '14px',
  color: '#5f5f5f',
  textDecoration: 'none',
  lineHeight: '22px',
  whiteSpace: 'nowrap'
}

const styleSmallInfoTd = {
  fontFamily: 'Calibri, Verdana, Arial',
  fontSize: '14px',
  color: '#81868a',
  marginBottom: '5px',
  textDecoration: 'none',
  lineHeight: 1.4,
  veriticalAlign: 'middle'
}

class UserProfile extends Component {
  state = {
    showQr: true,
    dynamicBanner: false
  }

  phone (user) {
    let phone = (user.PERSONAL_MOBILE || '').replace(/[^+0-9]/g, '')
    phone = `${phone.substr(0, 3)} ${phone.substr(3, 3)} ${phone.substr(6, 3)} ${phone.substr(9, 3)}`
    return (user.PERSONAL_MOBILE ? <span style={styleSmallInfo}>{phone}</span> : '')
  }

  phonePerf (user) {
    let phone = (user.PERSONAL_MOBILE || '').replace(/[^+0-9]/g, '')
    phone = `${phone.substr(0, 3)} ${phone.substr(3, 3)} ${phone.substr(6, 3)} ${phone.substr(9, 3)}`
    return (user.PERSONAL_MOBILE ? <span style={styleSmallInfo}>{phone}</span> : '')
  }

  emailSmall (user) {
    return (<a href={`mailto:${user.EMAIL}`} target='_new' style={styleSmallInfo}>{user.EMAIL}</a>)
  }

  email (user) {
    return (<span><a href={`mailto:${user.EMAIL}`} target='_new' style={styleSmallInfo}>{user.EMAIL}</a></span>)
  }

  emailPerf (user) {
    const email = user.EMAIL.replace('cubegroup', 'netsalesmedia')
    return (<span><a href={`mailto:${email}`} target='_new' style={styleSmallInfo}>{email}</a></span>)
  }

  lineGenerator (user) {
    if (user.PERSONAL_MOBILE) {
      return <span style={styleSmallInfo}> | </span>
    }
  }

  personalLine (user) {
    return (
      <tr>
        {this.userInfo(user)}
        <td height='100' width='30' rowSpan={2} />
      </tr>
    )
  }

  userInfo (user) {
    return (
      <td width='420' colSpan={5} height='75'>
        <p style={styleSmallInfo}>Pozdrawiam, Best Regards</p>
        <p style={fp}>{`${user.NAME} ${user.LAST_NAME}`}</p>
        <p style={sps}>{user.WORK_POSITION}</p>
      </td>
    )
  }

  qrCode (user, size = 42, onTop = true) {
    const isManagerAndHasLi = (user.UF_DEPARTMENT || []).includes(324) && user.linkedin
    const link = isManagerAndHasLi ? user.linkedin : 'https://docs.google.com/forms/d/e/1FAIpQLSc-kAtNsNSk32wA-QT9RTl3TmG-TAbJomJgRmvSdNgMB0Zy-g/formResponse'
    return (
      <>
        {onTop && <img alt='' src={`${API_SERVER}/api/user/qr-code?l=${link || ''}&s=${size}`} />}
        {isManagerAndHasLi
          ? <p className='qrD'> Dodaj mnie<br /> na LinkedIn </p>
          : <p className='qrD'> Oceń moją<br /> pracę </p>}
        {!onTop && <img alt='' src={`${API_SERVER}/api/user/qr-code?l=${link || ''}&s=${size}`} />}
      </>
    )
  }

  bigSignature (user) {
    let address = 'ul. Puławska 99A, 02-595 Warszawa'
    if (user.PERSONAL_CITY === 'Gdańsk') {
      address = 'Centrum Biznesu Zieleniak, Wały Piastowskie 1/715, 80-855 Gdańsk'
    }
    return (
      <div className='bigSig' style={wholeSig}>
        &nbsp;
        <style dangerouslySetInnerHTML={{ __html: 'img {vertical-align: middle}' }} />
        <table width='616' cellSpacing={0} cellPadding={0} border='0' id='bigSig' style={{ fontSize: '0' }}>
          <tbody>
            <tr>
              <td width='156' rowSpan={9} valign='top'>
                <a href={user.UF_LINKEDIN}>
                  <img
                    src={`${API_SERVER}/api/user/img?f=${user.PERSONAL_PHOTO || ''}`}
                    alt=''
                    id='personalImg'
                  />
                </a>
              </td>
              <td height='30' width='110' />
              <td height='30' width='140' />
              <td height='30' width='120' />
              <td height='30' width='25' />
              <td height='30' width='25' />
              <td height='30' width='30' />
            </tr>
            {this.personalLine(user)}
            <tr>
              <td width='420' height='39' colSpan={5}>
                <p style={{ marginBottom: '10px', marginTop: 0 }}>
                  {this.phone(user)}
                  {this.lineGenerator(user)}
                  {this.email(user)}
                </p>
              </td>
            </tr>
            <tr>
              <td width='420' colSpan={5} style={cgLogoS}><img src='https://super.cubegroup.pl/img/signature/cg_logo.png' alt='Cube Group' width={165} height={39} /></td>
              <td width='30' />
            </tr>
            <tr>
              <td width='110' style={styleSmallInfoTd}>Właściciel marek:</td>
              <td width='310' colSpan={4} style={logaS}>
                <img src='https://super.cubegroup.pl/img/signature/logo_netsalesmedia.gif' style={{ width: '82px !important', height: '21px !important', margin: '0 5px' }} alt='NetSalesMedia' id='test' />
                <img src='https://super.cubegroup.pl/img/signature/logo_influency.gif' style={{ width: '50px !important', height: '21px !important', margin: '0 5px' }} alt='Influency' />
                <img src='https://super.cubegroup.pl/img/signature/logo_semtec.gif' style={{ width: '74px !important', height: '21px !important', margin: '0 5px' }} alt='SEMTEC' />
                <img src='https://super.cubegroup.pl/img/signature/logo_mailsales.gif' style={{ width: '19px !important', height: '21px !important', margin: '0 5px' }} alt='MailSales' />
              </td>
              <td width='30' />
            </tr>
            <tr>
              <td width='420' colSpan={5} style={styleSmallInfoTd}>{address}</td>
              <td width='30' />
            </tr>
            <tr>
              <td width='370' colSpan={3} style={{ veriticalAlign: 'middle' }}><div style={styleSmallInfo}><a href='tel:+48222013290' target='_new' style={styleSmallInfoA}>+48&nbsp;22&nbsp;201&nbsp;32&nbsp;90</a>&nbsp;| <a href='mailto:biuro@cubegroup.pl' target='_new' style={styleSmallInfoA}>biuro@cubegroup.pl</a>&nbsp;| <a href='https://www.cubegroup.pl' style={styleSmallInfoA}>www.cubegroup.pl</a></div></td>
              <td width='25' style={{ veriticalAlign: 'middle', textAlign: 'center' }}>
                <a href='https://www.facebook.com/cubegroup/' style={styleSmallInfo}><img src='https://super.cubegroup.pl/img/signature/facebook_logo.png' alt='Facebook' style={{ margin: '5px 0' }} /></a>
              </td>
              <td width='25' style={{ veriticalAlign: 'middle', textAlign: 'center' }}>
                <a href='https://www.linkedin.com/company/cube-group-ltd/' style={styleSmallInfo}><img src='https://super.cubegroup.pl/img/signature/linkedin_logo.png' alt='LinkedIn' style={{ margin: '5px 0' }} /></a>
              </td>
              <td height='30' width='30'>&nbsp;</td>
            </tr>
            <tr>
              <td width='470' colSpan={6} />
            </tr>
          </tbody>
        </table>
        <div style={styleBottomInfo} dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY_DESC_LONG }} />
        &nbsp;
      </div>
    )
  }

  bigSignatureGmail (user) {
    let phone = (user.PERSONAL_MOBILE || '').replace(/[^+0-9]/g, '')
    phone = `${phone.substr(0, 3)} ${phone.substr(3, 3)} ${phone.substr(6, 3)} ${phone.substr(9, 3)}`
    const style = 'img{vertical-align:middle}.wholeSig{padding:0;margin:0;background-color:#fff}.fp{margin:0;font-size:16px;color:#000;line-height:21px;font-family:Arial,Bold;font-weight:700}.sI{margin:0;font-family:Arial,Regular;font-size:13px;color:#000;line-height:21px;display:block}.sGr{display:inline-block;font-family:Arial,Regular;font-size:13px;color:#888;text-decoration:none;line-height:21px;margin:0}.logoS{padding-top:10px;padding-bottom:10px}.col2{border-left:1px solid #efefef;}.bI{padding-top:10px;font-size:9px;letter-spacing:0;line-height:200%;color:#81868a;font-family:Calibri,Arial;text-align:left}.qrD{font-size:10px;color:#888;line-height:1.2;margin:5px 0}'
    return (
      <div className='wholeSig'>
        &nbsp;
        <style dangerouslySetInnerHTML={{ __html: style }} />
        <table width='600' cellSpacing={0} cellPadding={0} border='0'>
          <tbody>
            <tr>
              <td width='163' valign='top' style={{ padding: '0 15px' }}>
                <img
                  alt={user.NAME}
                  width={128}
                  height={128}
                  src={user.PERSONAL_PHOTO || ''}
                />
              </td>
              <td width='257' valign='top'>
                <table width='257' cellSpacing={0} cellPadding={0} border='0'>
                  <tbody>
                    <tr>
                      <td width='247' colSpan={2} valign='top' style={{ paddingRight: '10px' }}>
                        <p className='sI'>Pozdrawiam</p>
                        <p className='fp'>{`${user.NAME} ${user.LAST_NAME || ''}`}</p>
                        <p className='sGr' style={{ marginBottom: '10px' }}>{user.WORK_POSITION}</p>
                        <p>
                          <span><a href={`mailto:${user.EMAIL}`} target='_new' className='sGr'>{user.EMAIL}</a></span>
                          {user.PERSONAL_MOBILE ? <><br /><span className='sGr'>{phone}</span></> : ''}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td width='180' valign='top' align='center' className='col2'>
                <img src='https://storage.googleapis.com/cubegroup-pl-files/mail_footer/gp.png' alt='' />
              </td>
            </tr>
            <tr>
              <td width='163' style={{ textAlign: 'center' }}>
                {this.state.showQr && this.qrCode(user, 70)}
              </td>
              <td width='437' height='129' style={{ paddingTop: 15 }} colSpan={2} valign='top' align='center'>
                <table width='437'>
                  <tbody>
                    <tr>
                      <td colSpan={2} style={{ padding: '5px 0 10px' }}>
                        <img src='https://storage.googleapis.com/cubegroup-pl-files/mail_footer/logos.png' alt='' />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className='sGr'> ul. Puławska 99A, 02-595 Warszawa | <a href='https://www.cubegroup.pl' className='sGr'>www.cubegroup.pl</a></p>
                      </td>
                      <td width='93' style={{ textAlign: 'right' }}>
                        <a href='https://pl.linkedin.com/company/cube-group-ltd'>
                          <img src='https://storage.googleapis.com/cubegroup-pl-files/mail_footer/ln.jpg' style={{ marginLeft: 4 }} alt='' />
                        </a>
                        <a href='https://www.facebook.com/cubegroup'>
                          <img src='https://storage.googleapis.com/cubegroup-pl-files/mail_footer/fb.jpg' style={{ marginLeft: 4 }} alt='' />
                        </a>
                        <a href='https://www.youtube.com/@cubegroup4332'>
                          <img src='https://storage.googleapis.com/cubegroup-pl-files/mail_footer/yt.jpg' style={{ marginLeft: 4 }} alt='' />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td width='600' colSpan={3} style={{ padding: '10px 0' }}>
                <img width={599} height={130} style={{ width: '599px', height: '130px' }} src={`https://storage.googleapis.com/cubegroup-pl-files/mail_footer/banner.${this.state.dynamicBanner ? 'gif' : 'jpg'}`} alt='' />
              </td>
            </tr>
            <tr>
              <td width='600' colSpan={3}>
                <div className='bI' dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY_DESC_LONG }} />
                &nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  bigSignaturePerformance (user) {
    return (
      <div className='bigSig' style={wholeSig}>
        &nbsp;
        <style dangerouslySetInnerHTML={{ __html: 'img {vertical-align: middle}' }} />
        <table width='616' cellSpacing={0} cellPadding={0} border='0' id='bigSigPerf' style={{ fontSize: '0' }}>
          <tbody>
            <tr>
              <td width='156' rowSpan={5} valign='top' style={{ padding: '5px 0 0 20px' }}>
                <a href={user.UF_LINKEDIN}>
                  <img
                    src={`${API_SERVER}/api/user/img?r=${user.PERSONAL_PHOTO || ''}`}
                    alt=''
                    id='personalImg'
                  />
                </a>
              </td>
              <td height='1' width='70' />
              <td height='1' width='180' />
              <td height='1' width='120' />
              <td height='1' width='25' />
              <td height='1' width='25' />
              <td height='1' width='30' />
            </tr>
            <tr>
              <td width='420' colSpan={5} height='75'>
                <p style={fpPerf}>{`${user.NAME} ${user.LAST_NAME}`}</p>
                <p style={spsPerf}>{user.WORK_POSITION}</p>
              </td>
              <td height='100' width='30' rowSpan={2} />
            </tr>
            <tr>
              <td width='420' height='39' colSpan={5} style={{ paddingTop: '5px', paddingBottom: '15px' }}>
                <p style={{ margin: 0, color: '#000000' }}>
                  {this.phonePerf(user)}
                  <br />
                  {this.emailPerf(user)}
                </p>
              </td>
            </tr>
            <tr>
              <td width='90' style={{ textAlign: 'left' }}>
                <img src='https://super.cubegroup.pl/img/signature/logo_mailsales_new.png' style={{ width: '59px !important', height: '61px !important', margin: '0', display: 'inline-block' }} alt='Mailsales' id='Mailsales' />
              </td>
              <td width='350' align='left' colSpan={5} style={{ textAlign: 'left' }}>
                <img src='https://super.cubegroup.pl/img/signature/logo_nsm.png' style={{ width: '239px !important', height: '59px !important', margin: '0', display: 'inline-block' }} alt='Influency' />
              </td>
            </tr>
            <tr>
              <td width='440' colSpan={5} style={{ veriticalAlign: 'middle', paddingTop: '20px', paddingBottom: '10px' }}>
                <div style={styleSmallInfoPerf}>
                  <a href='tel:+48222013290' target='_new' style={styleSmallInfoAPerf}>+48&nbsp;22&nbsp;201&nbsp;32&nbsp;90</a> | <a href='https://mailsales.pl/' style={styleSmallInfoAPerf}>mailsales.pl</a> | <a href='https://netsalesmedia.pl/' style={styleSmallInfoAPerf}>netsalesmedia.pl</a>
                </div>
              </td>
              <td height='30' width='30'>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan={7} width='600'>
                <div
                  style={{
                    paddingTop: '10px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    fontSize: '10px',
                    letterSpacing: '0px',
                    lineHeight: '15px',
                    color: '#81868a',
                    fontFamily: 'Calibri, Verdana, Arial',
                    textAlign: 'left'
                  }} dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY_DESC_LONG }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        &nbsp;
      </div>
    )
  }

  smallSignature (user) {
    let phone = (user.PERSONAL_MOBILE || '').replace(/[^+0-9]/g, '')
    phone = `${phone.substr(0, 3)} ${phone.substr(3, 3)} ${phone.substr(6, 3)} ${phone.substr(9, 3)}`
    const style = 'img{vertical-align:middle}.smallSig{padding:0;margin:0;background-color:#fff}.fp{margin:0;font-size:16px;color:#000;line-height:21px;font-family:Arial,Bold;font-weight:700}.sI{margin:0;font-family:Arial,Regular;font-size:13px;color:#000;line-height:21px;display:block}.sGr{display:inline-block;font-family:Arial,Regular;font-size:13px;color:#888;text-decoration:none;line-height:21px;margin:0}.logoS{padding-top:10px;padding-bottom:10px}.col2{border-left:1px solid #efefef;padding-left:20px}.bI{padding-top:10px;font-size:9px;letter-spacing:0;line-height:200%;color:#81868a;font-family:Calibri,Arial;text-align:left}.qrD{font-size:10px;color:#888;line-height:1.2;margin: 5px 0}'
    return (
      <div className='smallSig'>
        &nbsp;
        <style dangerouslySetInnerHTML={{ __html: style }} />
        <table width='600' cellSpacing={0} cellPadding={0} border='0'>
          <tbody>
            <tr>
              <td width='163' valign='top' style={{ padding: '0 20px' }}>
                <img
                  alt={user.NAME}
                  width={128}
                  height={128}
                  src={user.PERSONAL_PHOTO || ''}
                />
              </td>
              <td width='257' valign='top' style={{ paddingRight: '10px' }}>
                <p className='sI'>Pozdrawiam</p>
                <p className='fp'>{`${user.NAME} ${user.LAST_NAME || ''}`}</p>
                <p className='sGr' style={{ marginBottom: '10px' }}>{user.WORK_POSITION}</p>
                <p style={{ marginBottom: '10px', marginTop: 0 }}>
                  <span><a href={`mailto:${user.EMAIL}`} target='_new' className='sGr'>{user.EMAIL}</a></span>
                  {user.PERSONAL_MOBILE ? <><br /><span className='sGr'>{phone}</span></> : ''}
                </p>
              </td>
              <td width='180' valign='middle' className='col2' style={{ textAlign: 'center' }}>
                {this.state.showQr && this.qrCode(user, 70, false)}
                <img src='https://super.cubegroup.pl/img/signature/cg_logo.png' alt='' width={112} height={26} style={{ marginTop: 5 }} />
              </td>
            </tr>
            <tr>
              <td width='600' colSpan={3} style={{ padding: '10px 0' }}>
                <img width={599} height={130} style={{ width: '599px', height: '130px' }} src={`https://storage.googleapis.com/cubegroup-pl-files/mail_footer/banner.${this.state.dynamicBanner ? 'gif' : 'jpg'}`} alt='' />
              </td>
            </tr>
            <tr>
              <td width='600' colSpan={3}>
                <div className='bI' dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY_DESC_SHORT }} />
              </td>
            </tr>
          </tbody>
        </table>
        &nbsp;
      </div>
    )
  }

  smallSignatureTeam (user) {
    return (
      <div className='smallSig' style={wholeSig}>
        &nbsp;
        <table width='616' cellSpacing={0} cellPadding={0} border='0'>
          <tbody>
            <tr>
              <td width='156' rowSpan={9} valign='top'>
                <a href={user.UF_LINKEDIN}>
                  <img
                    src={`${API_SERVER}/api/user/img?h=180&f=${user.PERSONAL_PHOTO || ''}`}
                    alt=' '
                  />
                </a>
              </td>
              <td height='30' width='110' />
              <td height='30' width='140' />
              <td height='30' width='120' />
              <td height='30' width='25' />
              <td height='30' width='25' />
              <td height='30' width='30' />
            </tr>
            {this.personalLine(user)}
            <tr>
              <td width='250' height='25' colSpan={2}>
                {this.phone(user)}
              </td>
              <td width='170' height='25' rowSpan={2} colSpan={3}><img src='https://super.cubegroup.pl/img/signature/cg_logo.png' alt='Cube Group' /></td>
            </tr>
            <tr>
              <td height='30' width='505' colSpan='2'>{this.emailSmall(user)}</td>
              <td height='30' width='30'>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <div style={styleBottomInfo} dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY_DESC_SHORT }} />
        &nbsp;
      </div>
    )
  }

  smallSignaturePerformance (user) {
    return (
      <div className='smallSig' style={wholeSig}>
        &nbsp;
        <style dangerouslySetInnerHTML={{ __html: 'img {vertical-align: middle}' }} />
        <table width='616' cellSpacing={0} cellPadding={0} border='0' id='smallSigPerf' style={{ fontSize: '0' }}>
          <tbody>
            <tr>
              <td width='156' rowSpan={4} valign='top' style={{ padding: '5px 0 0 20px' }}>
                <a href={user.UF_LINKEDIN}>
                  <img
                    src={`${API_SERVER}/api/user/img?r=${user.PERSONAL_PHOTO || ''}`}
                    alt=' '
                    id='personalImg'
                  />
                </a>
              </td>
              <td height='1' width='70' />
              <td height='1' width='180' />
              <td height='1' width='120' />
              <td height='1' width='25' />
              <td height='1' width='25' />
              <td height='1' width='30' />
            </tr>
            <tr>
              <td width='420' colSpan={5} height='75'>
                <p style={fpPerf}>{`${user.NAME} ${user.LAST_NAME}`}</p>
                <p style={spsPerf}>{user.WORK_POSITION}</p>
              </td>
              <td height='100' width='30' rowSpan={2} />
            </tr>
            <tr>
              <td width='420' height='39' colSpan={5} style={{ paddingTop: '5px', paddingBottom: '15px' }}>
                <p style={{ margin: 0, color: '#000000' }}>
                  {this.phonePerf(user)}
                  <br />
                  {this.emailPerf(user)}
                </p>
              </td>
            </tr>
            <tr>
              <td width='440' colSpan={5} style={{ veriticalAlign: 'middle', paddingTop: '0', paddingBottom: '10px' }}>
                <div style={styleSmallInfoPerf}>
                  <a href='tel:+48222013290' target='_new' style={styleSmallInfoAPerf}>+48&nbsp;22&nbsp;201&nbsp;32&nbsp;90</a> | <a href='https://mailsales.pl/' style={styleSmallInfoAPerf}>mailsales.pl</a> | <a href='https://netsalesmedia.pl/' style={styleSmallInfoAPerf}>netsalesmedia.pl</a>
                </div>
              </td>
              <td height='30' width='30'>&nbsp;</td>
            </tr>
            <tr>
              <td width='600' colSpan={7}>
                <div
                  style={{
                    paddingTop: '10px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    fontSize: '10px',
                    letterSpacing: '0px',
                    lineHeight: '15px',
                    color: '#81868a',
                    fontFamily: 'Calibri, Verdana, Arial',
                    textAlign: 'left'
                  }} dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY_DESC_SHORT }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        &nbsp;
      </div>
    )
  }

  createHtmlFile (userPhoto) {
    // collection of urls
    const urls = [
      `https://super.cubegroup.pl/api/user/img?f=${userPhoto || ''}`,
      'https://super.cubegroup.pl/img/signature/cg_logo.png',
      'https://super.cubegroup.pl/img/signature/logo_netsalesmedia.gif',
      'https://super.cubegroup.pl/img/signature/logo_influency.gif',
      'https://super.cubegroup.pl/img/signature/logo_semtec.gif',
      'https://super.cubegroup.pl/img/signature/logo_mailsales.gif',
      'https://super.cubegroup.pl/img/signature/facebook_logo.png',
      'https://super.cubegroup.pl/img/signature/linkedin_logo.png'
    ]

    // copy of html node that contains signature
    let basicHtmlValue = document.getElementById('here').cloneNode(true).outerHTML

    // call async action on all urls. action gets img as a response and converts it to base64, than calls func that changes html node (->basicHtmlValue)
    urls.forEach((url) => {
      request
        .get(url)
        .on('response', (response) => {
          response.setEncoding('base64')
          let body = `data:${response.headers['content-type']};base64,`
          response.on('data', (data) => { body += data })
          response.on('end', () => {
            urlStringReplace(body, url)
          })
        })
    })

    // img url replace in html
    let countHelp = 0
    function urlStringReplace (urlBase64, oldLink) {
      let urlString = oldLink
      if (urlString === urls[0]) {
        urlString = `${API_SERVER}/api/user/img?f=${userPhoto || ''}`
      }
      basicHtmlValue = basicHtmlValue.replace(urlString, urlBase64)
      console.log(basicHtmlValue)
      countHelp++
      if (countHelp > 7) {
        countHelp = 0
        zipGenerator(basicHtmlValue)
      }
    }

    // zip file generation
    function zipGenerator (htmltxt) {
      const zip = new JSZip()
      zip.file('personal.html', htmltxt)

      // generate zip file
      zip.generateAsync({ type: 'blob' })
        .then((content) => {
          saveAs(content, 'myEmailToe.zip')
        })
    }
  }

  // tymczasowo wraca okienko html do czasu naprawy zip
  getHtml () {
    const bigSig = document.getElementById('bigSig')
    const forHtml = document.getElementById('forHtml')
    forHtml.value = bigSig.outerHTML.replace('../api/', 'https://super.cubegroup.pl/api/')
  }

  render () {
    let { user } = this.props
    user = user || {}
    if (user.googleAuth) {
      return <div className='content'><div className='alert alert-danger'>Funkcjonalność dostępna tylko po zalogowaniu przez Bitrix</div></div>
    }
    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <div className='padding'>
                <p><b>UWAGA:</b> Zalecamy korzystać z przeglądarki chrome, w innych przeglądarkach mogą być problemy przy kopiowaniu.</p>
                <p><b>KOPIOWANIE:</b> Zaznacz stopkę i wklej ją do programu pocztowego.</p>
                <p><b>TWOJE ZDJĘCIE:</b> Zdjęcie w Bitrixie musi mieć kwadratowy format, zalecane wymiary 300x300. Zdjęcie jest Twoją wizytówką – zadbaj o to, aby było możliwie najbardziej profesjonalne i reprezentacyjne – jeśli masz wątpliwości, skonsultuj się z Działem PR & Marketing.</p>
                <p><b>TWOJE DANE:</b> Jeśli chcesz zaktualizować dane możesz to zrobić na stronie: <a href={`https://cube.bitrix24.pl/company/personal/user/${user.ID}/`} target='_blank' rel='noopener noreferrer'>bitrix</a>.</p>
                <p><b>BANER W STOPCE:</b> Opcjonalnie nasze stopki zawierają banery, w których promujemy ważne dla nas aktualności i informacje. O tym, czy baner jest obowiązkowy dla Ciebie decyduje Dział PR & Marketing oraz Twój przełożony. Jeśli Twoja stopka ma nie mieć baneru, wystarczy, że przy wklejaniu skopiowanej stopki usuniesz go.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='padding' style={{ display: 'inline-block' }}>Wersja pełna do pierwszego maila - gmail.</div>
              <div className='padding' style={{ display: 'inline-block' }}>
                Pokaż kod QR
                <input
                  type='checkbox'
                  checked={this.state.showQr}
                  onChange={(event) => {
                    const showQr = event.target.checked
                    this.setState({ showQr })
                  }}
                  style={{ marginLeft: '10px' }}
                />
              </div>
              <div className='padding' style={{ display: 'inline-block' }}>
                Dynamiczny banner
                <input
                  type='checkbox'
                  checked={this.state.dynamicBanner}
                  onChange={(event) => {
                    const dynamicBanner = event.target.checked
                    this.setState({ dynamicBanner })
                  }}
                  style={{ marginLeft: '10px' }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} id='here'>
              {this.bigSignatureGmail(user)}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='padding' style={{ display: 'inline-block' }}>Wersja prosta do odpowiedzi.</div>
              <div className='padding' style={{ display: 'inline-block' }}>
                Pokaż kod QR
                <input
                  type='checkbox'
                  checked={this.state.showQr}
                  onChange={(event) => {
                    const showQr = event.target.checked
                    this.setState({ showQr })
                  }}
                  style={{ marginLeft: '10px' }}
                />
              </div>
              <div className='padding' style={{ display: 'inline-block' }}>
                Dynamiczny banner
                <input
                  type='checkbox'
                  checked={this.state.dynamicBanner}
                  onChange={(event) => {
                    const dynamicBanner = event.target.checked
                    this.setState({ dynamicBanner })
                  }}
                  style={{ marginLeft: '10px' }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {this.smallSignature(user)}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='padding'>Wersja pełna do pierwszego maila. Stopka teamowa.</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {this.bigSignature({
                EMAIL: 'team@cubegroup.pl',
                LAST_NAME: 'Team',
                NAME: 'Service',
                PERSONAL_CITY: 'Warszawa',
                WORK_POSITION: 'Cube Group',
                PERSONAL_MOBILE: '+48222013290'
              })}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='padding'>Wersja prosta do odpowiedzi. Stopka teamowa.</div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {this.smallSignatureTeam({
                EMAIL: 'team@cubegroup.pl',
                LAST_NAME: 'Team',
                NAME: 'Service',
                PERSONAL_CITY: 'Warszawa',
                WORK_POSITION: 'Cube Group',
                PERSONAL_MOBILE: '+48222013290'
              })}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='padding'>Wersja pełna dla działu Performance.</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {this.bigSignaturePerformance(user)}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='padding'>Wersja prosta do odpowiedzi dla działu Performance.</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {this.smallSignaturePerformance(user)}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='padding'>Wersja pełna dla Thunderbird.</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <button className='btn' type='button' onClick={() => { this.createHtmlFile(user.PERSONAL_PHOTO) }} style={{ marginLeft: 30 }}>Pobierz w formacie ZIP</button><br /><br />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(UserProfile)
