import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import SCModal from '../Settlements/Components/Modal'
import { connect } from 'react-redux'
import { API_SERVER } from '../Settlements/authData'
import NotificationSystem from 'react-notification-system'
import { style } from '../../variables/Variables'
import { toHours } from './timeFunctions'
import moment from 'moment'

const systemParams = {
  'bitrix-calendar': { niceName: 'Calendar', className: 'bg-info' },
  'bitrix-task': { niceName: 'Bitrix', className: 'bg-success' },
  jira: { niceName: 'Jira', className: 'bg-warning' },
  manual: { niceName: '', className: 'bg-secondary-2' }
}

class TasksModal extends Component {
  constructor (props) {
    super(props)
    this.setModalParams = this.setModalParams.bind(this)
    const { user } = this.props
    const { tasksFakeUser } = this.props.tasks
    this.state = {
      loggedUser: tasksFakeUser.ID ? tasksFakeUser : user,
      filters: {},
      sortField: null,
      sortDirection: null,
      groupTasks: false,
      multipleMonths: false
    }
  }

  componentDidMount () {
    this.setModalParams(true, this.props.modalParams.clientName, '', () => {})
    this.getData()
  }

  showNotification (message, level = 'error', autoDismiss = 5) {
    this.refs.notificationSystem.addNotification({ message, level, position: 'br', autoDismiss })
  }

  getLink (system, taskId, ID, details) {
    if (system !== 'manual') {
      return (
        <a
          href={
            system === 'bitrix-task'
              ? `https://cube.bitrix24.pl/company/personal/user/${ID}/tasks/task/view/${taskId}/`
              : system === 'bitrix-calendar'
                ? `https://cube.bitrix24.pl/company/personal/user/${ID}/calendar/?EVENT_ID=${taskId}&EVENT_DATE=${details.reverseDay}/`
                : system === 'jira' ? `https://cubegroup.atlassian.net/browse/${taskId}` : 'http://#'
          }
          className='link'
          target='_blank'
          rel='noopener noreferrer'
        >{details.name}
        </a>
      )
    }
    return details.name
  }

  /** GET TASKS **/

  getData () {
    const { loggedUser = {} } = this.state
    this.setState({ loader: true })
    fetch(`${API_SERVER}/api/tasks/client-tasks`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...this.props.modalParams, user: loggedUser })
    }).catch(err => {
      console.error(err)
      this.showNotification('Wystąpił błąd: ' + (err || ''))
    })
      .then(res => res.json())
      .then(response => {
        if (response && !response.error) {
          const multipleMonths = [...new Set(response.data.map(item => item.month))].length > 1
          return this.setState({
            data: response.data,
            loader: false,
            multipleMonths
          })
        }
        console.error('Wystąpil błąd: ' + (response.error || ''))
        this.showNotification('Wystąpił błąd: ' + (response.error || ''))
      })
  }

  renderRow (task, key) {
    return (
      <Row
        className={systemParams[task.system].className}
        key={key}
      >
        <Col xs={1}>{systemParams[task.system].niceName}</Col>
        <Col xs={1}>{task.taskId}</Col>
        <Col xs={6}>{this.getLink(task.system, task.taskId, task.userId, task.details)}</Col>
        <Col xs={2}>{task.userName}</Col>
        <Col xs={1}>{task.month}</Col>
        <Col xs={1} className='text-right'>{toHours(task.time)}</Col>
      </Row>
    )
  }

  renderFilterInput (name) {
    return (
      <input
        type='text'
        name={name}
        placeholder='Szukaj...'
        className='form-control'
        style={{ marginTop: '10px' }}
        onChange={(e) => {
          const { name, value } = e.target
          this.setState((prevState) => {
            const newFilters = { ...prevState.filters }
            newFilters[name] = value
            return {
              ...prevState,
              filters: newFilters
            }
          })
        }}
      />
    )
  }

  renderSortButton (niceName, name) {
    return (
      <button
        data-sort={name}
        className={`btn ${name === this.state.sortField ? 'btn-success' : ''}`}
        style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 8px', backgroundColor: name === this.state.sortField ? '#049F0C' : '#888', color: '#ffffff' }}
        onClick={(e) => {
          const { sortDirection, sortField } = this.state
          let newSortField = e.target.getAttribute('data-sort') || e.currentTarget.getAttribute('data-sort')
          let newSortDirection = sortDirection
          switch (true) {
            case sortDirection === null || sortField !== newSortField:
              newSortDirection = 'ASC'
              break
            case sortDirection === 'ASC':
              newSortDirection = 'DESC'
              break
            case sortDirection === 'DESC':
              newSortDirection = null
              newSortField = null
              break
          }
          this.setState({ sortField: newSortField, sortDirection: newSortDirection })
        }}
      >{niceName} <i className='fa fa-sort' />
      </button>
    )
  }

  mergeTasks (tasks) {
    const mergedTasks = {}
    const { dayStart, dayEnd } = this.props.modalParams
    const month = `${moment(dayStart, 'YYYY-MM').format('YYYY-MM')} - ${moment(dayEnd, 'YYYY-MM').format('YYYY-MM')}`

    tasks.forEach(task => {
      const { time, taskName, taskId, userName } = task

      if (!mergedTasks[`${taskName}|${userName}|${taskId}`]) {
        mergedTasks[`${taskName}|${userName}|${taskId}`] = { ...task, month }
      } else {
        mergedTasks[`${taskName}|${userName}|${taskId}`].time += time
      }
    })

    const result = Object.values(mergedTasks)

    return result
  }

  /** RENDER MODAL **/

  modalContent () {
    const { data = [], filters, sortField, sortDirection, groupTasks } = this.state
    const entryData = groupTasks ? this.mergeTasks(data) : data
    const filteredData = entryData.filter((row) => {
      return Object.keys(row).every((column) => {
        if (!filters[column]) {
          return true
        } else {
          return row[column].toLowerCase().includes((filters[column].toLowerCase() || ''))
        }
      })
    })

    if (sortField && sortDirection) {
      filteredData.sort((a, b) => {
        const valA = a[sortField]
        const valB = b[sortField]

        if (!valA || !valB) {
          return 0
        }

        if (typeof valA === 'number' && typeof valB === 'number') {
          return sortDirection === 'ASC' ? valA - valB : valB - valA
        }

        return valA.localeCompare(valB, 'pl', { sensitivity: 'base' }) * (sortDirection === 'ASC' ? 1 : -1)
      })
    }

    return (
      <div className='tasks-modal'>
        {this.state.loader ? <div className='lds-dual-ring' id='spinner' style={{ left: '50%', top: '50%' }} /> : null}
        <Row className='title' style={{ backgroundColor: '#f7f7f7' }}>
          <Col xs={1}>
            {this.renderSortButton('SYSTEM', 'system')}
            {this.renderFilterInput('system')}
          </Col>
          <Col xs={1}>
            {this.renderSortButton('ID', 'taskId')}
            {this.renderFilterInput('taskId')}
          </Col>
          <Col xs={6}>
            {this.renderSortButton('ZADANIE', 'taskName')}
            {this.renderFilterInput('taskName')}
          </Col>
          <Col xs={2}>
            {this.renderSortButton('PRACOWNIK', 'userName')}
            {this.renderFilterInput('userName')}
          </Col>
          <Col xs={1}>
            {this.renderSortButton('MIESIĄC', 'month')}
            {this.renderFilterInput('month')}
          </Col>
          <Col xs={1} className='text-right text-nowrap'>
            {this.renderSortButton('CZAS', 'time')}
            <h5 className='form-control'>
              {toHours(filteredData.reduce((sum, { time }) => sum + time, 0))}
            </h5>
          </Col>
        </Row>
        {filteredData.map((el, key) => this.renderRow(el, key))}
      </div>
    )
  }

  setModalParams (show = false, title = '', content = '', method = null) {
    this.setState({
      modalShow: show,
      modalTitle: title,
      modalContent: content,
      modalMethod: method
    }, () => method === null ? this.props.setTasksModalParams({}) : null)
  }

  render () {
    const { modalShow, modalTitle, modalMethod } = this.state
    const modalContent = this.modalContent()

    return (
      <div>
        <NotificationSystem ref='notificationSystem' style={style} />
        <SCModal
          modalShow={modalShow}
          modalTitle={modalTitle}
          modalContent={modalContent}
          setModalParams={this.setModalParams}
          propMethod={modalMethod}
          setHeight
          agreeButton={false}
          closeText='Zamknij'
          size='wide'
          utilityButton={this.state.multipleMonths}
          utilityCallback={() => {
            const groupTasks = !this.state.groupTasks
            this.setState({ groupTasks })
          }}
          utilityText={this.state.groupTasks ? 'Rozgrupuj zadania' : 'Grupuj zadania'}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    tasks: state.tasks
  }
}

export default connect(mapStateToProps)(TasksModal)
