const tasks = (state = {
  params: {
    isShowActive: true,
    reportCost: true,
    estimatedProfit: true
  },
  tasksFakeUser: {}
}, action) => {
  switch (action.type) {
    case 'TASKS_PARAMS':
      return Object.assign({}, state, {
        ...state,
        params: action.params
      })
    case 'SET_TASKS_FAKE_USER':
      return Object.assign({}, '', {
        ...state,
        tasksFakeUser: action.user
      })
    default:
      return state
  }
}

export default tasks
