import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { allowedFromDepartments, allowedTimeReportsDepartments, supportDepartments, checkAuthIdUser, checkUserType } from '../../authData'
import { Notification, notificationSystemRef } from '../../../../useNotification'
import Loader from '../../Components/Loader'
import { Col, Grid, Row, Tab, Table, Tabs } from 'react-bootstrap'
import { Card } from '../../../../components/Card/Card'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'
import { getSettings as getData, saveSettings } from './b2bApi'
import './b2b.css'

const tabs = [
  { name: 'default', label: 'Podstawowe' },
  { name: 'departments', label: 'Wg działu' },
  { name: 'users', label: 'Wg pracownika' }
]

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 1 }),
  control: style => ({ ...style, minHeight: 22, borderStyle: 'none', borderBottom: '1px solid #E3E3E3' }),
  valueContainer: style => ({ ...style, padding: '0 8px' }),
  clearIndicator: style => ({ ...style, padding: '0 4px', width: 20 }),
  indicatorSeparator: style => ({ style, display: 'none' })
}

export default () => {
  const { type } = useParams()
  /* auth */
  const props = useSelector((state) => state)
  const { fakeUser, user, main } = props
  const loggedUser = fakeUser.ID ? fakeUser : user
  const isFinance = checkUserType(loggedUser, 'finance')
  const isDev = checkUserType(loggedUser, 'dev')
  const isAuthIdUser = checkAuthIdUser(loggedUser,
    ['200'] // Cezary Śledziak
  )

  /* fetch data */
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState({})

  const fetchData = useCallback(async () => {
    setLoader(true)
    const fetchedData = await getData(type)
    setData(fetchedData)
    setLoader(false)
  }, [])

  useEffect(() => {
    fetchData().catch(console.error)
  }, [])

  const handleSubmit = async () => {
    setLoader(true)
    const responseData = await saveSettings(data, type)
    setData(responseData)
    setLoader(false)
  }

  const addRow = async type => {
    data.jobs[type] = [...data.jobs[type], { id: 0, list: '' }]
    setData((data) => ({ ...data }))
  }

  const getDepartmentsOptions = () => {
    const { departments = [] } = main || {}
    return departments
      .filter(el => allowedFromDepartments
        .concat(allowedTimeReportsDepartments.map(el => `${el}`))
        .concat(supportDepartments.map(el => `${el}`))
        .includes(el.ID))
      .map(el => ({
        value: +el.ID,
        label: el.NAME
      }))
  }

  const getUsersOptions = () => {
    const { users = [] } = main || {}
    return users.length && users
      .filter(el => el.TYPE === 'employee' && el.ACTIVE)
      .map(el => ({
        value: +el.ID,
        label: el.NAME
      })).sort((a, b) => a.label > b.label ? 1 : -1)
  }

  const [options, setOptions] = useState({})
  useEffect(() => {
    setOptions({
      users: getUsersOptions(),
      departments: getDepartmentsOptions()
    })
  }, [])

  const getLabel = (arr = [], value) => {
    const obj = arr.length ? arr.find(o => o.ID === value + '') : ''
    return obj ? obj.NAME || obj.TITLE : ''
  }

  const getInitValue = (type, value) => {
    return {
      value,
      label: getLabel(main[type], value)
    }
  }

  return (isFinance || isDev || isAuthIdUser)
    ? <div className='content b2b'>
      <Notification ref={notificationSystemRef} />
      <Loader display={loader} />
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <div className='breadcrumb'>
                  <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                    to={`/settlements/${type}settings`}
                                                                   >Ustawienia {type}
                  </Link>
                </div>
              }
            />
          </Col>
        </Row>
        <div className={loader ? 'noEvents' : ''}>
          <Row>
            <Col md={12}>
              <Card
                title={`Ustawienia ${type}`}
                content={
                  <Tabs defaultActiveKey='default' id='tabs'>
                    {
                      tabs.map((tab, keyT) =>
                        <Tab eventKey={tab.name} title={tab.label} key={keyT}>
                          <Table>
                            <thead>
                              <tr>
                                <th className={tab.name === 'default' ? 'd-none' : ''}> </th>
                                <th>lista czynności</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                              data.jobs &&
                              data.jobs[tab.name] &&
                              Array.isArray(data.jobs[tab.name]) &&
                              data.jobs[tab.name].length > 0 &&
                              data.jobs[tab.name].map((el, key) => {
                                const listLength = el.list && el.list.split('\n').length
                                return (
                                  <tr key={key}>
                                    <td className={tab.name === 'default' ? 'd-none' : ''}>
                                      <Select
                                        options={options[tab.name]}
                                        styles={selectStyles}
                                        value={getInitValue(tab.name, el.id)}
                                        onBlur={handleSubmit}
                                        onChange={(e) => {
                                          el.id = e.value
                                          setData((data) => ({ ...data }))
                                        }}
                                      />
                                      {
                                      keyT === 2 &&
                                        <button
                                          className='btn btn-sm' onClick={() => {
                                            data.jobs[tab.name] = data.jobs[tab.name].filter(elem => elem !== el)
                                            setData((data) => ({ ...data }))
                                            handleSubmit().catch(console.error)
                                          }}
                                        >usuń
                                        </button>
                                    }
                                    </td>
                                    <td>
                                      <textarea
                                        name='job'
                                        value={el.list}
                                        className='hoverInput textarea'
                                        rows={listLength >= 3 ? listLength : 3}
                                        placeholder='Lista czynności oddzielona enterem'
                                        onBlur={handleSubmit}
                                        onChange={(e) => {
                                          const { value } = e.target
                                          el.list = value
                                          setData((data) => ({ ...data }))
                                        }}
                                      />
                                    </td>
                                  </tr>
                                )
                              }
                              )
                            }
                            </tbody>
                            <tfoot>
                              <tr>
                                <td>
                                  <button className='btn btn-sm' onClick={() => addRow(tab.name)}>+ Dodaj</button>
                                </td>
                              </tr>
                            </tfoot>
                          </Table>
                        </Tab>)
                    }
                  </Tabs>
                }
              />
            </Col>
          </Row>
        </div>
      </Grid>
    </div>
    : <div className='content SalaryGrid'>
      <div>Brak dostępu</div>
      </div>
}
